unit Sphinx.WebLogin;

{$I Sphinx.inc}

interface

uses
{$IFDEF PAS2JS}
  JS, Web,
{$ENDIF}
  System.Classes, System.SysUtils,
  Sphinx.LoginCommon,
  Sphinx.OidcClient;

const
{$IF COMPILERVERSION > 28}
  pidWeb = $10000;
{$ELSE}
  pidWeb = $1000;
{$IFEND}
{$IFDEF PAS2JS}
  TMSWebPlatform = pidWeb;
{$ELSE}
  TMSWebPlatform = pidWin32 + pidWin64 + pidWeb;
{$ENDIF}

type
  TUserLoggedInArgs = Sphinx.LoginCommon.TUserLoggedInArgs;
  TAuthResult = Sphinx.OidcClient.TAuthResult;

  [ComponentPlatforms(TMSWebPlatform)]
  /// <summary>
  ///   Component to authenticate a TMS Web Core application to a Sphinx server.
  /// </summary>
  /// <remarks>
  ///   Use TSphinxWebLogin component to perform authentication and authorization to a Sphinx server from a TMS Web Core application.
  ///
  ///   It abstracts the OAuth 2 authentication code with PKCE flow required by Sphinx, by discovering the authorization and token
  ///   endpoints, performing the HTTP requests passing the proper parameters, processing the response provided to the
  ///   redirect URLs and saving the identity and access tokens in to local web storage.
  ///
  ///   Usage is simple, just properly set some key properties like <see cref="Authority">Authority</see>,
  ///   <see cref="ClientId">ClientId</see> and <see cref="Scope">Scope</see> and then use available methods like
  ///   <see cref="Login">Login</see> to effectively login to the server.
  /// </remarks>
  TSphinxWebLogin = class(TComponent)
  private
    FStorage: TLoginStorage;
    FClient: TOidcClient;
    FScope: string;
    FRedirectUri: string;
    FOnUserLoggedIn: TUserLoggedInEvent;
    function GetStorage: TLoginStorage;
    procedure SetAuthority(const Value: string);
    procedure SetClientId(const Value: string);
    procedure ReleaseStorage;
    function GetAuthority: string;
    function GetClientId: string;
    function CheckCallback: Boolean; {$IFDEF PAS2JS}async;{$ENDIF}
    procedure SetupOidcClient;
  strict protected
    procedure DoUserLoggedIn(LoginResult: TAuthResult);
    property Storage: TLoginStorage read GetStorage;
    property Client: TOidcClient read FClient;
  protected
    procedure Loaded; override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;

    /// <summary>
    ///   Starts the process to login to the application.
    /// </summary>
    /// <remarks>
    ///   If the user is already logged in, then Login method just fires the <see cref="OnUserLoggedIn">OnUserLoggedIn</see> event
    ///   with the information about the logged user.
    ///
    ///   If the user is not logged in, the user browser is redirected to the Sphinx login page. From there user can
    ///   authenticate (by entering login credentials), or create a new account (if enabled), recover password, confirm
    ///   registered e-mail, among other operations.
    ///
    ///   If the user effectively logs in by providing credentials, then Sphinx server redirects the browser back to the URL
    ///   provided in the <see cref="RedirectUri">RedirectUri</see> property - which should be set to the web application URL itself.
    ///
    ///   Upon reload, the web application will process the authorization code provided in the redirect URL and use it to
    ///   request the identity and access tokens to the Sphinx server.
    ///
    ///   Once the tokens are retrived, they are saved in browser local storage, which effectively indicates the user is logged in.
    ///   Then the event <see cref="OnUserLoggedIn">OnUserLoggedIn</see> will be fired providing all the information about the
    ///   logged user.
    /// </remarks>
    procedure Login; {$IFDEF PAS2JS}async;{$ENDIF}

    /// <summary>
    ///   Logs out the user from the web application.
    /// </summary>
    /// <remarks>
    ///   This method removes the tokens saved in browser local storage. This clear up information about the logged user,
    ///   so it's not available anymore. A call to <see cref="Login">Login</see> method will again redirect to the login page.
    /// </remarks>
    procedure Logout;

    /// <summary>
    ///   Indicates if a user is logged in the application.
    /// </summary>
    function IsLoggedIn: Boolean;

    /// <summary>
    ///   Provides information about the logged user.
    /// </summary>
    /// <remarks>
    ///   If there is a user logged in the application, this property provides detailed information about him/her, including
    ///   the identity and access tokens. If no user is logged in, nil is returned.
    /// </remarks>
    function AuthResult: TAuthResult;
  published
    /// <summary>
    ///   The base URL of the Sphinx server to authenticate to.
    /// </summary>
    /// <remarks>
    ///   This property is required and must contain the base URL of the Sphinx server used to authenticate the users.
    ///   From this URL, the component will automatically find the authorization endpoint (usually at /oauth/authorize)
    ///   and the token endpoint (usually at /oauth/token) to perform the correct requests.
    ///
    ///   Thus, you should not provide the URL to the endpoints themselves, but to the root URL of the Sphinx server.
    /// </remarks>
    property Authority: string read GetAuthority write SetAuthority;

    /// <summary>
    ///   The client id used to identity the web application in the Sphinx server.
    /// </summary>
    /// <remarks>
    ///   A required property that should contain the client id of web application. This should match the id of one of the
    ///   client applications registered in the Sphinx server. The registered client application should support
    ///   the authorization code grant type, and it's also recommended that it requires PKCE for increased security.
    ///   It should also have the web application URL registered in the list of valid redirect uris.
    /// </remarks>
    property ClientId: string read GetClientId write SetClientId;

    /// <summary>
    ///   The scope to be required to the Sphinx server.
    /// </summary>
    /// <remarks>
    ///   This should contian the scope being requested to the server. At the very least this should include "openid"
    ///   but you can also add "email". If your web application communicates with a REST API, then you can also include
    ///   the scopes that your API might require.
    /// </remarks>
    property Scope: string read FScope write FScope;

    /// <summary>
    ///   The redirect URI used to process the request sent by the server with the authorization code.
    /// </summary>
    /// <remarks>
    ///   This property should just contain the URL where your web application is available. When the server requests this URL,
    ///   the web application will be reloaded, the component will be created, and automatically process the parameters
    ///   sent to the redirect URL, if any
    /// </remarks>
    property RedirectUri: string read FRedirectUri write FRedirectUri;

    /// <summary>
    ///   Event fired whenever a user is logged in.
    /// </summary>
    property OnUserLoggedIn: TUserLoggedInEvent read FOnUserLoggedIn write FOnUserLoggedIn;
  end;

implementation

{ TSphinxWebLogin }

function TSphinxWebLogin.AuthResult: TAuthResult;
begin
  Result := FStorage.AuthResult;
end;

function TSphinxWebLogin.CheckCallback: Boolean;
var
  CallbackUrl: string;
  AuthResult: TAuthResult;
begin
  SetupOidcClient;
{$IFDEF PAS2JS}
  CallbackUrl := window.location.href;
{$ELSE}
  CallbackUrl := '';
{$ENDIF}
  Result := Client.IsValidCallback(CallbackUrl);
  if Result then
  begin
    AuthResult := {$IFDEF PAS2JS}await{$ENDIF}(Client.FinishAuthorize(CallbackUrl));
    Storage.Save(AuthResult);
    DoUserLoggedIn(AuthResult);
  end
end;

constructor TSphinxWebLogin.Create(AOwner: TComponent);
begin
  inherited;
  FClient := TOidcClient.Create;
end;

destructor TSphinxWebLogin.Destroy;
begin
  FStorage.Free;
  FClient.Free;
  inherited;
end;

procedure TSphinxWebLogin.DoUserLoggedIn(LoginResult: TAuthResult);
var
  Args: TUserLoggedInArgs;
begin
  if not Assigned(FOnUserLoggedIn) then Exit;

  Args := TUserLoggedInArgs.Create(Self, LoginResult);
  try
    FOnUserLoggedIn(Args);
  finally
    Args.Free;
  end;
end;

function TSphinxWebLogin.GetAuthority: string;
begin
  Result := Client.Authority;
end;

function TSphinxWebLogin.GetClientId: string;
begin
  Result := Client.ClientId;
end;

function TSphinxWebLogin.GetStorage: TLoginStorage;
begin
  if FStorage = nil then
    FStorage := TLoginStorage.Create(Authority, ClientId);
  Result := FStorage;
end;

function TSphinxWebLogin.IsLoggedIn: Boolean;
var
  AuthResult: TAuthResult;
begin
  AuthResult := Storage.AuthResult;
  Result := (AuthResult <> nil) and (AuthResult.IdToken <> '');
  if Result then
    if (AuthResult.AccessToken <> '') and AuthResult.IsExpired then
      Result := False;
end;

procedure TSphinxWebLogin.Loaded;
begin
  inherited;
  CheckCallback;
end;

procedure TSphinxWebLogin.Login;
begin
  SetupOidcClient;
  if not {$IFDEF PAS2JS}await{$ENDIF}(CheckCallback) then
  begin
    if IsLoggedIn then
      DoUserLoggedIn(Storage.AuthResult)
{$IFDEF PAS2JS}
    else
      window.location.href := await(Client.StartAuthorize(window.location.hash)).AuthorizeUrl;
{$ENDIF}
  end;
end;

procedure TSphinxWebLogin.Logout;
begin
  if FStorage <> nil then
    FStorage.Clear;
end;

procedure TSphinxWebLogin.ReleaseStorage;
begin
  FreeAndNil(FStorage);
end;

procedure TSphinxWebLogin.SetAuthority(const Value: string);
begin
  if Client.Authority <> Value then
  begin
    Client.Authority := Value;
    ReleaseStorage;
  end;
end;

procedure TSphinxWebLogin.SetClientId(const Value: string);
begin
  if Client.ClientId <> Value then
  begin
    Client.ClientId := Value;
    ReleaseStorage;
  end;
end;

procedure TSphinxWebLogin.SetupOidcClient;
begin
  Client.Scope := Scope;
  Client.RedirectUri := RedirectUri;
end;

end.
