unit CommunityBuildingsList;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseList, WEBLib.ExtCtrls, WEBLib.Actions,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.Grids, WEBLib.DBCtrls, Vcl.Controls, WEBLib.Lists,
  smx.webcore.types;

type
  TCommunityBuildingsListForm = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetExternalId: TStringField;
    MainDatasetCharityTenant: TStringField;
    MainDatasetBuildingName: TStringField;
    MainDatasetAddress: TStringField;
    MainDatasetPostCode: TStringField;
    RefreshTimer: TTimer;
    procedure MainDatasetAfterPost(DataSet: TDataSet);
    procedure RefreshTimerTimer(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
  protected
    function GetEditFormClass: TFormClass; override;
    procedure PrepareForm; override;
    function RecordViewOption: TViewOption; override;
    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;
    procedure RefreshControls;
    function CreateGridInFormCreate: boolean; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  CommunityBuildingsListForm: TCommunityBuildingsListForm;

implementation

{$R *.dfm}
uses
  CommunityBuildingEdit;

function TCommunityBuildingsListForm.CreateGridInFormCreate: boolean;
begin
  result := false;
end;

function TCommunityBuildingsListForm.GetEditFormClass: TFormClass;
begin
  result := TCommunityBuildingEditForm;
end;

procedure TCommunityBuildingsListForm.MainDatasetAfterPost(DataSet: TDataSet);
begin
  inherited;
  RefreshTimer.Enabled := true;
end;

procedure TCommunityBuildingsListForm.PrepareForm;
begin
  FIdField := 'Id';
end;

function TCommunityBuildingsListForm.RecordViewOption: TViewOption;
begin
  Result := TViewOption.voCreateAndEdit
end;

procedure TCommunityBuildingsListForm.RefreshControls;
begin
  FGridPlugin.Load;
end;

function TCommunityBuildingsListForm.InitSortCol: Integer;
begin
  result := 0;
end;

function TCommunityBuildingsListForm.InitSortDirection: TSortDirection;
begin
  result := sdAsc
end;

procedure TCommunityBuildingsListForm.RefreshTimerTimer(Sender: TObject);
begin
  RefreshTimer.Enabled := false;
  RefreshControls;
end;

procedure TCommunityBuildingsListForm.WebFormShow(Sender: TObject);
begin
  CreateGridPlugin;
  RefreshControls;
end;


procedure TCommunityBuildingsListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetExternalId := TStringField.Create(Self);
  MainDatasetCharityTenant := TStringField.Create(Self);
  MainDatasetBuildingName := TStringField.Create(Self);
  MainDatasetAddress := TStringField.Create(Self);
  MainDatasetPostCode := TStringField.Create(Self);
  RefreshTimer := TTimer.Create(Self);

  DataTable.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetExternalId.BeforeLoadDFMValues;
  MainDatasetCharityTenant.BeforeLoadDFMValues;
  MainDatasetBuildingName.BeforeLoadDFMValues;
  MainDatasetAddress.BeforeLoadDFMValues;
  MainDatasetPostCode.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  try
    Caption := 'CommunityBuilding';
    SetEvent(Self, 'OnShow', 'WebFormShow');
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'BuildingName';
      Title := 'BuildingName';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Address';
      Title := 'Address';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'PostCode';
      Title := 'Post Code';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    MainDataset.EntitySetName := 'CommunityBuilding';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetExternalId.SetParentComponent(MainDataset);
    MainDatasetExternalId.Name := 'MainDatasetExternalId';
    MainDatasetExternalId.FieldName := 'ExternalId';
    MainDatasetExternalId.Size := 64;
    MainDatasetCharityTenant.SetParentComponent(MainDataset);
    MainDatasetCharityTenant.Name := 'MainDatasetCharityTenant';
    MainDatasetCharityTenant.FieldName := 'CharityTenant';
    MainDatasetBuildingName.SetParentComponent(MainDataset);
    MainDatasetBuildingName.Name := 'MainDatasetBuildingName';
    MainDatasetBuildingName.FieldName := 'BuildingName';
    MainDatasetBuildingName.Size := 255;
    MainDatasetAddress.SetParentComponent(MainDataset);
    MainDatasetAddress.Name := 'MainDatasetAddress';
    MainDatasetAddress.FieldName := 'Address';
    MainDatasetAddress.Size := 255;
    MainDatasetPostCode.SetParentComponent(MainDataset);
    MainDatasetPostCode.Name := 'MainDatasetPostCode';
    MainDatasetPostCode.FieldName := 'PostCode';
    MainDatasetPostCode.Size := 15;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 357;
    RefreshTimer.Top := 11;
  finally
    DataTable.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetExternalId.AfterLoadDFMValues;
    MainDatasetCharityTenant.AfterLoadDFMValues;
    MainDatasetBuildingName.AfterLoadDFMValues;
    MainDatasetAddress.AfterLoadDFMValues;
    MainDatasetPostCode.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
  end;
end;

end.
