unit ImportDataEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.DBCtrls, Data.DB, WEBLib.DB, Vcl.Mask, WEBLib.Mask, WebForm.Core,
  WEBLib.DropDown, WEBLib.Country, WEBLib.ExtCtrls, WEBLib.Actions,
  WEBLib.WebCtrls;

type
  TImportDataEditForm = class(TCoreWebForm)
    FormCaption: TLabel;
    MessageLabel: TLabel;
    CancelButton: TButton;
    SaveButton: TButton;
    DataState: THTMLSpan;
    PriorButton: TButton;
    NextButton: TButton;
    EditDataSource: TDataSource;
    EditElements: TElementActionList;
    CloseTimer: TTimer;
    DonorIdLabel: TDBLabel;
    ExternalIdLabel: TDBLabel;
    edFirstName: TDBEdit;
    edLastName: TDBEdit;
    edAdd1: TDBEdit;
    edAdd2: TDBEdit;
    edAdd3: TDBEdit;
    edCity: TDBEdit;
    edPostCode: TDBEdit;
    edEmail: TDBEdit;
    edMobile: TDBEdit;
    edTelephone: TDBEdit;
    DateSigned: TDBDateTimePicker;
    ContactByPhone: TDBCheckBox;
    ContactByEmail: TDBCheckBox;
    ContactByText: TDBCheckBox;
    ContactByPost: TDBCheckBox;
    CountryList: TCountryDropDown;
    TitleEdit: TDBEdit;
    DataEditAmount: TDBEdit;
    CloseButton: TButton;
    edCounty: TDBEdit;
    ExternalIdEdit: TDBEdit;
    MessageDialog: TMessageDlg;
    procedure CancelButtonClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure ContactByClick(Sender: TObject);
    procedure DataEditAmountKeyPress(Sender: TObject; var Key: Char);
    procedure DateSignedChange(Sender: TObject);
    procedure EditDataSourceDataChange(Sender: TObject; Field: TField);
    procedure SaveButtonClick(Sender: TObject);
  private
    { Private declarations }
    fEditDataApplyUpdates: boolean;
    FModified: boolean;
    FStoreCountryCode: string;

    procedure SetDataSet(Value: TDataSet);
    procedure ResetButtons(Value: boolean);
    procedure SetCountryDropDown;

    procedure SaveData;
  public
    { Public declarations }
    property DataSet: TDataSet write SetDataSet;
    property EditDataApplyUpdates: boolean read fEditDataApplyUpdates;

  protected procedure LoadDFMValues; override; end;

var
  ImportDataEditForm: TImportDataEditForm;

implementation

{$R *.dfm}

uses
   ImportData;

procedure TImportDataEditForm.CancelButtonClick(Sender: TObject);
begin
  EditDataSource.DataSet.Cancel;
  ResetButtons(False);
end;

   { TImportDataEditForm }

procedure TImportDataEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  fEditDataApplyUpdates := false;
end;

procedure TImportDataEditForm.CloseButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TImportDataEditForm.ContactByClick(Sender: TObject);
begin
  FModified := true;
end;

procedure TImportDataEditForm.DataEditAmountKeyPress(Sender: TObject; var Key:
    Char);
begin
  if (not (Key in ['0'..'9','.'])) or ((Key = '.') and (Pos('.', DataEditAmount.text) > 0)) then
  begin
    Key := #0;
  end
end;

procedure TImportDataEditForm.DateSignedChange(Sender: TObject);
begin
  if DateSigned.Date > Date then
  begin
    DateSigned.Date := 0;
    MessageDialog.ShowDialog('Can not enter a date in the future', WEBLib.Dialogs.mtError, [mbOK]);
  end;
end;

procedure TImportDataEditForm.EditDataSourceDataChange(Sender: TObject; Field:
    TField);
begin
  if Field <> nil then
  begin
    ResetButtons(EditDataSource.DataSet.Modified);
  end;
end;

procedure TImportDataEditForm.SaveButtonClick(Sender: TObject);
begin
  SaveData;
  ModalResult := mrOK;
//  Close;
end;

procedure TImportDataEditForm.SetDataSet(Value: TDataSet);
begin
  EditDataSource.DataSet := Value;
  SetCountryDropDown;
end;

procedure TImportDataEditForm.ResetButtons(Value: boolean);
begin
  SaveButton.Enabled := Value;
  CancelButton.Enabled := Value;
  FModified := Value;
end;


procedure TImportDataEditForm.SetCountryDropDown;
var
  lCountryCode: string;
  lCountry: string;
begin
  if EditDataSource.DataSet.FieldByName('CountryCode').AsString <> '' then
  begin
    lCountryCode := EditDataSource.DataSet.FieldByName('CountryCode').AsString;

    if lCountryCode <> '' then
    begin
      lCountry := GetCountryByCode(lCountryCode);
      CountryList.Text := lCountry;
      FStoreCountryCode := lCountryCode;
    end;
  end;
end;

procedure TImportDataEditForm.SaveData;
var
  lTitle: string;
  lCountry: string;
  lCountryCode: string;
  lCurrentCountryCode: string;
begin
  lCountry := CountryList.Text;

  if CountryList.Text <> '' then
  begin
    lCountryCode := GetCountryCode(lCountry);

    if (FStoreCountryCode <> lCountryCode) then
    begin
      EditDataSource.Edit;
      EditDataSource.Dataset.FieldByName('CountryCode').AsString := lCountryCode;
      FModified := true;
    end;
  end;

  if (EditDataSource.DataSet.State in dsEditModes) and ((EditDataSource.DataSet.Modified) or (FModified)) then
  begin
    EditDataSource.Dataset.FieldByName('ContactByPhone').AsBoolean := ContactByPhone.Checked;
    EditDataSource.Dataset.FieldByName('ContactByPost').AsBoolean := ContactByPost.Checked;
    EditDataSource.Dataset.FieldByName('ContactByText').AsBoolean := ContactByText.Checked;
    EditDataSource.Dataset.FieldByName('ContactByEmail').AsBoolean := ContactByEmail.Checked;

    EditDataSource.DataSet.Post;
    fEditDataApplyUpdates := true;
    ResetButtons(False);
  end;
end;


procedure TImportDataEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormCaption := TLabel.Create('FormCaption');
  MessageLabel := TLabel.Create('MessageLabel');
  DonorIdLabel := TDBLabel.Create('DonorIdLabel');
  ExternalIdLabel := TDBLabel.Create('ExternalIdLabel');
  CancelButton := TButton.Create('CancelButton');
  SaveButton := TButton.Create('SaveButton');
  DataState := THTMLSpan.Create('DataState');
  PriorButton := TButton.Create('PriorButton');
  NextButton := TButton.Create('NextButton');
  edFirstName := TDBEdit.Create('FirstName');
  edLastName := TDBEdit.Create('LastName');
  edAdd1 := TDBEdit.Create('Add1');
  edAdd2 := TDBEdit.Create('Add2');
  edAdd3 := TDBEdit.Create('Add3');
  edCity := TDBEdit.Create('City');
  edPostCode := TDBEdit.Create('PostCode');
  edEmail := TDBEdit.Create('Email');
  edMobile := TDBEdit.Create('Mobile');
  edTelephone := TDBEdit.Create('Phone');
  DateSigned := TDBDateTimePicker.Create('DateSigned');
  ContactByPhone := TDBCheckBox.Create('ContactByPhone');
  ContactByEmail := TDBCheckBox.Create('ContactByEmail');
  ContactByText := TDBCheckBox.Create('ContactByText');
  ContactByPost := TDBCheckBox.Create('ContactByPost');
  CountryList := TCountryDropDown.Create('CountryList');
  TitleEdit := TDBEdit.Create('Title');
  DataEditAmount := TDBEdit.Create('DataEditAmount');
  CloseButton := TButton.Create('DonorCloseButton');
  edCounty := TDBEdit.Create('CountyEdit');
  ExternalIdEdit := TDBEdit.Create('ExternalIdEdit');
  MessageDialog := TMessageDlg.Create(Self);
  EditDataSource := TDataSource.Create(Self);
  EditElements := TElementActionList.Create(Self);
  CloseTimer := TTimer.Create(Self);

  FormCaption.BeforeLoadDFMValues;
  MessageLabel.BeforeLoadDFMValues;
  DonorIdLabel.BeforeLoadDFMValues;
  ExternalIdLabel.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  DataState.BeforeLoadDFMValues;
  PriorButton.BeforeLoadDFMValues;
  NextButton.BeforeLoadDFMValues;
  edFirstName.BeforeLoadDFMValues;
  edLastName.BeforeLoadDFMValues;
  edAdd1.BeforeLoadDFMValues;
  edAdd2.BeforeLoadDFMValues;
  edAdd3.BeforeLoadDFMValues;
  edCity.BeforeLoadDFMValues;
  edPostCode.BeforeLoadDFMValues;
  edEmail.BeforeLoadDFMValues;
  edMobile.BeforeLoadDFMValues;
  edTelephone.BeforeLoadDFMValues;
  DateSigned.BeforeLoadDFMValues;
  ContactByPhone.BeforeLoadDFMValues;
  ContactByEmail.BeforeLoadDFMValues;
  ContactByText.BeforeLoadDFMValues;
  ContactByPost.BeforeLoadDFMValues;
  CountryList.BeforeLoadDFMValues;
  TitleEdit.BeforeLoadDFMValues;
  DataEditAmount.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  edCounty.BeforeLoadDFMValues;
  ExternalIdEdit.BeforeLoadDFMValues;
  MessageDialog.BeforeLoadDFMValues;
  EditDataSource.BeforeLoadDFMValues;
  EditElements.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  try
    Width := 646;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    FormCaption.SetParentComponent(Self);
    FormCaption.Name := 'FormCaption';
    FormCaption.Left := 48;
    FormCaption.Top := 8;
    FormCaption.Width := 49;
    FormCaption.Height := 10;
    FormCaption.Margins.Left := 3;
    FormCaption.Margins.Top := 3;
    FormCaption.Margins.Right := 3;
    FormCaption.Margins.Bottom := 3;
    FormCaption.Caption := 'Form Caption';
    FormCaption.ElementClassName := 'HeadlineClass';
    FormCaption.ElementFont := efCSS;
    FormCaption.ElementPosition := epIgnore;
    FormCaption.HeightStyle := ssAuto;
    FormCaption.HeightPercent := 100.000000000000000000;
    FormCaption.HTMLType := tDIV;
    FormCaption.WidthStyle := ssAuto;
    FormCaption.WidthPercent := 100.000000000000000000;
    MessageLabel.SetParentComponent(Self);
    MessageLabel.Name := 'MessageLabel';
    MessageLabel.Left := 45;
    MessageLabel.Top := 27;
    MessageLabel.Width := 49;
    MessageLabel.Height := 10;
    MessageLabel.Margins.Left := 3;
    MessageLabel.Margins.Top := 3;
    MessageLabel.Margins.Right := 3;
    MessageLabel.Margins.Bottom := 3;
    MessageLabel.Caption := 'MessageLabel';
    MessageLabel.ElementFont := efCSS;
    MessageLabel.ElementPosition := epIgnore;
    MessageLabel.HeightStyle := ssAuto;
    MessageLabel.HeightPercent := 100.000000000000000000;
    MessageLabel.HTMLType := tDIV;
    MessageLabel.WidthStyle := ssAuto;
    MessageLabel.WidthPercent := 100.000000000000000000;
    DonorIdLabel.SetParentComponent(Self);
    DonorIdLabel.Name := 'DonorIdLabel';
    DonorIdLabel.Left := 155;
    DonorIdLabel.Top := 18;
    DonorIdLabel.Width := 47;
    DonorIdLabel.Height := 10;
    DonorIdLabel.Caption := 'DonorIdLabel';
    DonorIdLabel.ElementFont := efCSS;
    DonorIdLabel.HeightStyle := ssAuto;
    DonorIdLabel.HeightPercent := 100.000000000000000000;
    DonorIdLabel.WidthPercent := 100.000000000000000000;
    DonorIdLabel.DataField := 'Id';
    DonorIdLabel.DataSource := EditDataSource;
    ExternalIdLabel.SetParentComponent(Self);
    ExternalIdLabel.Name := 'ExternalIdLabel';
    ExternalIdLabel.Left := 155;
    ExternalIdLabel.Top := 31;
    ExternalIdLabel.Width := 53;
    ExternalIdLabel.Height := 10;
    ExternalIdLabel.Caption := 'ExternalIdLabel';
    ExternalIdLabel.ElementFont := efCSS;
    ExternalIdLabel.HeightStyle := ssAuto;
    ExternalIdLabel.HeightPercent := 100.000000000000000000;
    ExternalIdLabel.WidthStyle := ssAuto;
    ExternalIdLabel.WidthPercent := 100.000000000000000000;
    ExternalIdLabel.DataField := 'ExternalId';
    ExternalIdLabel.DataSource := EditDataSource;
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 128;
    CancelButton.Top := 269;
    CancelButton.Width := 96;
    CancelButton.Height := 25;
    CancelButton.Margins.Left := 3;
    CancelButton.Margins.Top := 3;
    CancelButton.Margins.Right := 3;
    CancelButton.Margins.Bottom := 3;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 3;
    CancelButton.ElementClassName := 'btn btn-primary';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.TabOrder := 22;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 16;
    SaveButton.Top := 269;
    SaveButton.Width := 96;
    SaveButton.Height := 25;
    SaveButton.Margins.Left := 3;
    SaveButton.Margins.Top := 3;
    SaveButton.Margins.Right := 3;
    SaveButton.Margins.Bottom := 3;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 2;
    SaveButton.ElementClassName := 'btn btn-secondary';
    SaveButton.ElementFont := efCSS;
    SaveButton.ElementPosition := epIgnore;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 21;
    SaveButton.WidthStyle := ssAuto;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    DataState.SetParentComponent(Self);
    DataState.Name := 'DataState';
    DataState.Left := 7;
    DataState.Top := 44;
    DataState.Width := 100;
    DataState.Height := 25;
    DataState.Margins.Left := 3;
    DataState.Margins.Top := 3;
    DataState.Margins.Right := 3;
    DataState.Margins.Bottom := 3;
    DataState.HeightStyle := ssAuto;
    DataState.WidthStyle := ssAuto;
    DataState.ChildOrder := 5;
    DataState.ElementPosition := epIgnore;
    DataState.ElementFont := efCSS;
    DataState.Role := '';
    PriorButton.SetParentComponent(Self);
    PriorButton.Name := 'PriorButton';
    PriorButton.Left := 152;
    PriorButton.Top := 46;
    PriorButton.Width := 33;
    PriorButton.Height := 25;
    PriorButton.Margins.Left := 3;
    PriorButton.Margins.Top := 3;
    PriorButton.Margins.Right := 3;
    PriorButton.Margins.Bottom := 3;
    PriorButton.Caption := '<';
    PriorButton.ChildOrder := 6;
    PriorButton.ElementClassName := 'btn btn-info';
    PriorButton.ElementFont := efCSS;
    PriorButton.ElementPosition := epIgnore;
    PriorButton.HeightStyle := ssAuto;
    PriorButton.HeightPercent := 100.000000000000000000;
    PriorButton.Visible := False;
    PriorButton.WidthStyle := ssAuto;
    PriorButton.WidthPercent := 100.000000000000000000;
    NextButton.SetParentComponent(Self);
    NextButton.Name := 'NextButton';
    NextButton.Left := 191;
    NextButton.Top := 46;
    NextButton.Width := 33;
    NextButton.Height := 25;
    NextButton.Margins.Left := 3;
    NextButton.Margins.Top := 3;
    NextButton.Margins.Right := 3;
    NextButton.Margins.Bottom := 3;
    NextButton.Caption := '>';
    NextButton.ChildOrder := 7;
    NextButton.ElementClassName := 'btn btn-info';
    NextButton.ElementFont := efCSS;
    NextButton.ElementPosition := epIgnore;
    NextButton.HeightStyle := ssAuto;
    NextButton.HeightPercent := 100.000000000000000000;
    NextButton.Visible := False;
    NextButton.WidthStyle := ssAuto;
    NextButton.WidthPercent := 100.000000000000000000;
    edFirstName.SetParentComponent(Self);
    edFirstName.Name := 'edFirstName';
    edFirstName.Left := 16;
    edFirstName.Top := 107;
    edFirstName.Width := 133;
    edFirstName.Height := 14;
    edFirstName.ChildOrder := 9;
    edFirstName.ElementClassName := 'form-control';
    edFirstName.ElementFont := efCSS;
    edFirstName.ElementPosition := epIgnore;
    edFirstName.HeightStyle := ssAuto;
    edFirstName.HeightPercent := 100.000000000000000000;
    edFirstName.TabOrder := 2;
    edFirstName.Text := 'edFirstName';
    edFirstName.WidthStyle := ssAuto;
    edFirstName.WidthPercent := 100.000000000000000000;
    edFirstName.DataField := 'FirstName';
    edFirstName.DataSource := EditDataSource;
    edLastName.SetParentComponent(Self);
    edLastName.Name := 'edLastName';
    edLastName.Left := 16;
    edLastName.Top := 125;
    edLastName.Width := 133;
    edLastName.Height := 14;
    edLastName.ChildOrder := 10;
    edLastName.ElementClassName := 'form-control';
    edLastName.ElementFont := efCSS;
    edLastName.ElementPosition := epIgnore;
    edLastName.HeightStyle := ssAuto;
    edLastName.HeightPercent := 100.000000000000000000;
    edLastName.TabOrder := 3;
    edLastName.Text := 'edLastName';
    edLastName.WidthStyle := ssAuto;
    edLastName.WidthPercent := 100.000000000000000000;
    edLastName.DataField := 'LastName';
    edLastName.DataSource := EditDataSource;
    edAdd1.SetParentComponent(Self);
    edAdd1.Name := 'edAdd1';
    edAdd1.Left := 169;
    edAdd1.Top := 61;
    edAdd1.Width := 133;
    edAdd1.Height := 14;
    edAdd1.ChildOrder := 11;
    edAdd1.ElementClassName := 'form-control';
    edAdd1.ElementFont := efCSS;
    edAdd1.ElementPosition := epIgnore;
    edAdd1.HeightStyle := ssAuto;
    edAdd1.HeightPercent := 100.000000000000000000;
    edAdd1.TabOrder := 4;
    edAdd1.Text := 'edAdd1';
    edAdd1.WidthStyle := ssAuto;
    edAdd1.WidthPercent := 100.000000000000000000;
    edAdd1.DataField := 'Add1';
    edAdd1.DataSource := EditDataSource;
    edAdd2.SetParentComponent(Self);
    edAdd2.Name := 'edAdd2';
    edAdd2.Left := 169;
    edAdd2.Top := 79;
    edAdd2.Width := 133;
    edAdd2.Height := 14;
    edAdd2.ChildOrder := 12;
    edAdd2.ElementClassName := 'form-control';
    edAdd2.ElementFont := efCSS;
    edAdd2.ElementPosition := epIgnore;
    edAdd2.HeightStyle := ssAuto;
    edAdd2.HeightPercent := 100.000000000000000000;
    edAdd2.TabOrder := 5;
    edAdd2.Text := 'edAdd2';
    edAdd2.WidthStyle := ssAuto;
    edAdd2.WidthPercent := 100.000000000000000000;
    edAdd2.DataField := 'Add2';
    edAdd2.DataSource := EditDataSource;
    edAdd3.SetParentComponent(Self);
    edAdd3.Name := 'edAdd3';
    edAdd3.Left := 169;
    edAdd3.Top := 97;
    edAdd3.Width := 133;
    edAdd3.Height := 14;
    edAdd3.ChildOrder := 13;
    edAdd3.ElementClassName := 'form-control';
    edAdd3.ElementFont := efCSS;
    edAdd3.ElementPosition := epIgnore;
    edAdd3.HeightStyle := ssAuto;
    edAdd3.HeightPercent := 100.000000000000000000;
    edAdd3.TabOrder := 6;
    edAdd3.Text := 'edAdd3';
    edAdd3.WidthStyle := ssAuto;
    edAdd3.WidthPercent := 100.000000000000000000;
    edAdd3.DataField := 'Add3';
    edAdd3.DataSource := EditDataSource;
    edCity.SetParentComponent(Self);
    edCity.Name := 'edCity';
    edCity.Left := 169;
    edCity.Top := 114;
    edCity.Width := 133;
    edCity.Height := 14;
    edCity.ChildOrder := 14;
    edCity.ElementClassName := 'form-control';
    edCity.ElementFont := efCSS;
    edCity.ElementPosition := epIgnore;
    edCity.HeightStyle := ssAuto;
    edCity.HeightPercent := 100.000000000000000000;
    edCity.TabOrder := 7;
    edCity.Text := 'edCity';
    edCity.WidthStyle := ssAuto;
    edCity.WidthPercent := 100.000000000000000000;
    edCity.DataField := 'City';
    edCity.DataSource := EditDataSource;
    edPostCode.SetParentComponent(Self);
    edPostCode.Name := 'edPostCode';
    edPostCode.Left := 169;
    edPostCode.Top := 132;
    edPostCode.Width := 133;
    edPostCode.Height := 14;
    edPostCode.CharCase := wecUpperCase;
    edPostCode.ChildOrder := 15;
    edPostCode.ElementClassName := 'form-control';
    edPostCode.ElementFont := efCSS;
    edPostCode.ElementPosition := epIgnore;
    edPostCode.HeightStyle := ssAuto;
    edPostCode.HeightPercent := 100.000000000000000000;
    edPostCode.TabOrder := 8;
    edPostCode.Text := 'edPostCode';
    edPostCode.WidthStyle := ssAuto;
    edPostCode.WidthPercent := 100.000000000000000000;
    edPostCode.DataField := 'PostCode';
    edPostCode.DataSource := EditDataSource;
    edEmail.SetParentComponent(Self);
    edEmail.Name := 'edEmail';
    edEmail.Left := 16;
    edEmail.Top := 145;
    edEmail.Width := 133;
    edEmail.Height := 14;
    edEmail.CharCase := wecLowerCase;
    edEmail.ChildOrder := 16;
    edEmail.ElementClassName := 'form-control';
    edEmail.ElementFont := efCSS;
    edEmail.ElementPosition := epIgnore;
    edEmail.HeightStyle := ssAuto;
    edEmail.HeightPercent := 100.000000000000000000;
    edEmail.TabOrder := 11;
    edEmail.Text := 'edEmail';
    edEmail.WidthStyle := ssAuto;
    edEmail.WidthPercent := 100.000000000000000000;
    edEmail.DataField := 'Email';
    edEmail.DataSource := EditDataSource;
    edMobile.SetParentComponent(Self);
    edMobile.Name := 'edMobile';
    edMobile.Left := 16;
    edMobile.Top := 163;
    edMobile.Width := 133;
    edMobile.Height := 14;
    edMobile.ChildOrder := 17;
    edMobile.ElementClassName := 'form-control';
    edMobile.ElementFont := efCSS;
    edMobile.ElementPosition := epIgnore;
    edMobile.HeightStyle := ssAuto;
    edMobile.HeightPercent := 100.000000000000000000;
    edMobile.TabOrder := 10;
    edMobile.Text := 'edMobile';
    edMobile.WidthStyle := ssAuto;
    edMobile.WidthPercent := 100.000000000000000000;
    edMobile.DataField := 'Mobile';
    edMobile.DataSource := EditDataSource;
    edTelephone.SetParentComponent(Self);
    edTelephone.Name := 'edTelephone';
    edTelephone.Left := 16;
    edTelephone.Top := 181;
    edTelephone.Width := 133;
    edTelephone.Height := 14;
    edTelephone.ChildOrder := 18;
    edTelephone.ElementClassName := 'form-control';
    edTelephone.ElementFont := efCSS;
    edTelephone.ElementPosition := epIgnore;
    edTelephone.HeightStyle := ssAuto;
    edTelephone.HeightPercent := 100.000000000000000000;
    edTelephone.TabOrder := 9;
    edTelephone.Text := 'edTelephone';
    edTelephone.WidthStyle := ssAuto;
    edTelephone.WidthPercent := 100.000000000000000000;
    edTelephone.DataField := 'Telephone';
    edTelephone.DataSource := EditDataSource;
    DateSigned.SetParentComponent(Self);
    DateSigned.Name := 'DateSigned';
    DateSigned.Left := 315;
    DateSigned.Top := 197;
    DateSigned.Width := 86;
    DateSigned.Height := 15;
    DateSigned.ElementClassName := 'form-control';
    DateSigned.HeightStyle := ssAuto;
    DateSigned.WidthStyle := ssAuto;
    DateSigned.BorderStyle := bsSingle;
    DateSigned.ChildOrder := 26;
    DateSigned.Color := clWhite;
    DateSigned.Date := 44461.755385451390000000;
    DateSigned.ElementFont := efCSS;
    DateSigned.ElementPosition := epIgnore;
    DateSigned.Role := '';
    DateSigned.TabOrder := 20;
    DateSigned.Text := '';
    SetEvent(DateSigned, Self, 'OnChange', 'DateSignedChange');
    DateSigned.DataField := 'DonationDate';
    DateSigned.DataSource := EditDataSource;
    ContactByPhone.SetParentComponent(Self);
    ContactByPhone.Name := 'ContactByPhone';
    ContactByPhone.Left := 212;
    ContactByPhone.Top := 227;
    ContactByPhone.Width := 75;
    ContactByPhone.Height := 15;
    ContactByPhone.ChildOrder := 27;
    ContactByPhone.ElementClassName := 'form-check-input';
    ContactByPhone.ElementFont := efCSS;
    ContactByPhone.ElementPosition := epIgnore;
    ContactByPhone.HeightStyle := ssAuto;
    ContactByPhone.HeightPercent := 100.000000000000000000;
    ContactByPhone.TabOrder := 14;
    ContactByPhone.WidthStyle := ssAuto;
    ContactByPhone.WidthPercent := 100.000000000000000000;
    SetEvent(ContactByPhone, Self, 'OnClick', 'ContactByClick');
    ContactByPhone.DataField := 'ContactByPhone';
    ContactByPhone.DataSource := EditDataSource;
    ContactByEmail.SetParentComponent(Self);
    ContactByEmail.Name := 'ContactByEmail';
    ContactByEmail.Left := 21;
    ContactByEmail.Top := 245;
    ContactByEmail.Width := 75;
    ContactByEmail.Height := 15;
    ContactByEmail.ChildOrder := 27;
    ContactByEmail.ElementClassName := 'form-check-input';
    ContactByEmail.ElementFont := efCSS;
    ContactByEmail.ElementPosition := epIgnore;
    ContactByEmail.HeightStyle := ssAuto;
    ContactByEmail.HeightPercent := 100.000000000000000000;
    ContactByEmail.TabOrder := 16;
    ContactByEmail.WidthStyle := ssAuto;
    ContactByEmail.WidthPercent := 100.000000000000000000;
    SetEvent(ContactByEmail, Self, 'OnClick', 'ContactByClick');
    ContactByEmail.DataField := 'ContactByEmail';
    ContactByEmail.DataSource := EditDataSource;
    ContactByText.SetParentComponent(Self);
    ContactByText.Name := 'ContactByText';
    ContactByText.Left := 122;
    ContactByText.Top := 245;
    ContactByText.Width := 75;
    ContactByText.Height := 15;
    ContactByText.ChildOrder := 27;
    ContactByText.ElementClassName := 'form-check-input';
    ContactByText.ElementFont := efCSS;
    ContactByText.ElementPosition := epIgnore;
    ContactByText.HeightStyle := ssAuto;
    ContactByText.HeightPercent := 100.000000000000000000;
    ContactByText.TabOrder := 19;
    ContactByText.WidthStyle := ssAuto;
    ContactByText.WidthPercent := 100.000000000000000000;
    SetEvent(ContactByText, Self, 'OnClick', 'ContactByClick');
    ContactByText.DataField := 'ContactByText';
    ContactByText.DataSource := EditDataSource;
    ContactByPost.SetParentComponent(Self);
    ContactByPost.Name := 'ContactByPost';
    ContactByPost.Left := 191;
    ContactByPost.Top := 245;
    ContactByPost.Width := 75;
    ContactByPost.Height := 15;
    ContactByPost.ChildOrder := 27;
    ContactByPost.ElementClassName := 'form-check-input';
    ContactByPost.ElementFont := efCSS;
    ContactByPost.ElementPosition := epIgnore;
    ContactByPost.HeightStyle := ssAuto;
    ContactByPost.HeightPercent := 100.000000000000000000;
    ContactByPost.TabOrder := 13;
    ContactByPost.WidthStyle := ssAuto;
    ContactByPost.WidthPercent := 100.000000000000000000;
    SetEvent(ContactByPost, Self, 'OnClick', 'ContactByClick');
    ContactByPost.DataField := 'ContactByPost';
    ContactByPost.DataSource := EditDataSource;
    CountryList.SetParentComponent(Self);
    CountryList.Name := 'CountryList';
    CountryList.Left := 169;
    CountryList.Top := 150;
    CountryList.Width := 133;
    CountryList.Height := 15;
    CountryList.HeightStyle := ssAuto;
    CountryList.WidthStyle := ssAuto;
    CountryList.ChildOrder := 30;
    CountryList.Color := clWhite;
    CountryList.ElementInputClassName := 'form-control';
    CountryList.ElementFont := efCSS;
    CountryList.ElementPosition := epIgnore;
    CountryList.ParentColor := False;
    CountryList.TabOrder := 20;
    CountryList.Text := '';
    TitleEdit.SetParentComponent(Self);
    TitleEdit.Name := 'TitleEdit';
    TitleEdit.Left := 16;
    TitleEdit.Top := 89;
    TitleEdit.Width := 133;
    TitleEdit.Height := 14;
    TitleEdit.ChildOrder := 9;
    TitleEdit.ElementClassName := 'form-control';
    TitleEdit.ElementFont := efCSS;
    TitleEdit.ElementPosition := epIgnore;
    TitleEdit.HeightStyle := ssAuto;
    TitleEdit.HeightPercent := 100.000000000000000000;
    TitleEdit.TabOrder := 2;
    TitleEdit.Text := 'Title';
    TitleEdit.WidthStyle := ssAuto;
    TitleEdit.WidthPercent := 100.000000000000000000;
    TitleEdit.DataField := 'Title';
    TitleEdit.DataSource := EditDataSource;
    DataEditAmount.SetParentComponent(Self);
    DataEditAmount.Name := 'DataEditAmount';
    DataEditAmount.Left := 315;
    DataEditAmount.Top := 171;
    DataEditAmount.Width := 40;
    DataEditAmount.Height := 15;
    DataEditAmount.ChildOrder := 29;
    DataEditAmount.ElementPosition := epIgnore;
    DataEditAmount.HeightStyle := ssAuto;
    DataEditAmount.HeightPercent := 100.000000000000000000;
    DataEditAmount.Text := 'DataEditAmount';
    DataEditAmount.WidthStyle := ssAuto;
    DataEditAmount.WidthPercent := 100.000000000000000000;
    SetEvent(DataEditAmount, Self, 'OnKeyPress', 'DataEditAmountKeyPress');
    DataEditAmount.DataField := 'Amount';
    DataEditAmount.DataSource := EditDataSource;
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 231;
    CloseButton.Top := 269;
    CloseButton.Width := 96;
    CloseButton.Height := 25;
    CloseButton.Margins.Left := 3;
    CloseButton.Margins.Top := 3;
    CloseButton.Margins.Right := 3;
    CloseButton.Margins.Bottom := 3;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 2;
    CloseButton.ElementClassName := 'btn btn-secondary';
    CloseButton.ElementFont := efCSS;
    CloseButton.ElementPosition := epIgnore;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 21;
    CloseButton.WidthStyle := ssAuto;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    edCounty.SetParentComponent(Self);
    edCounty.Name := 'edCounty';
    edCounty.Left := 315;
    edCounty.Top := 132;
    edCounty.Width := 133;
    edCounty.Height := 14;
    edCounty.ChildOrder := 15;
    edCounty.ElementClassName := 'form-control';
    edCounty.ElementFont := efCSS;
    edCounty.ElementPosition := epIgnore;
    edCounty.HeightStyle := ssAuto;
    edCounty.HeightPercent := 100.000000000000000000;
    edCounty.TabOrder := 8;
    edCounty.Text := 'edCounty';
    edCounty.WidthStyle := ssAuto;
    edCounty.WidthPercent := 100.000000000000000000;
    edCounty.DataField := 'County';
    edCounty.DataSource := EditDataSource;
    ExternalIdEdit.SetParentComponent(Self);
    ExternalIdEdit.Name := 'ExternalIdEdit';
    ExternalIdEdit.Left := 169;
    ExternalIdEdit.Top := 181;
    ExternalIdEdit.Width := 133;
    ExternalIdEdit.Height := 14;
    ExternalIdEdit.CharCase := wecUpperCase;
    ExternalIdEdit.ChildOrder := 15;
    ExternalIdEdit.ElementClassName := 'form-control';
    ExternalIdEdit.ElementFont := efCSS;
    ExternalIdEdit.ElementPosition := epIgnore;
    ExternalIdEdit.HeightStyle := ssAuto;
    ExternalIdEdit.HeightPercent := 100.000000000000000000;
    ExternalIdEdit.ReadOnly := True;
    ExternalIdEdit.TabOrder := 8;
    ExternalIdEdit.Text := 'ExternalIdEdit';
    ExternalIdEdit.WidthStyle := ssAuto;
    ExternalIdEdit.WidthPercent := 100.000000000000000000;
    MessageDialog.SetParentComponent(Self);
    MessageDialog.Name := 'MessageDialog';
    MessageDialog.Left := 325;
    MessageDialog.Top := 53;
    MessageDialog.Width := 16;
    MessageDialog.Height := 16;
    MessageDialog.HeightStyle := ssAuto;
    MessageDialog.WidthStyle := ssAuto;
    MessageDialog.Buttons := [];
    MessageDialog.Opacity := 0.200000000000000000;
    EditDataSource.SetParentComponent(Self);
    EditDataSource.Name := 'EditDataSource';
    SetEvent(EditDataSource, Self, 'OnDataChange', 'EditDataSourceDataChange');
    EditDataSource.Left := 107;
    EditDataSource.Top := 11;
    EditElements.SetParentComponent(Self);
    EditElements.Name := 'EditElements';
    EditElements.Actions.Clear;
    with EditElements.Actions.Add do
    begin
      Enabled := False;
      Event := heKeydown;
      ID := '';
      Name := 'EnterToTab';
      PreventDefault := False;
      Selector := 'input';
      StopPropagation := False;
    end;
    with EditElements.Actions.Add do
    begin
      Event := heBlur;
      ID := '';
      Name := 'ValidateData';
      PreventDefault := False;
      Selector := 'input,select';
      StopPropagation := False;
    end;
    EditElements.Left := 283;
    EditElements.Top := 16;
    CloseTimer.SetParentComponent(Self);
    CloseTimer.Name := 'CloseTimer';
    CloseTimer.Enabled := False;
    CloseTimer.Interval := 2000;
    CloseTimer.Left := 336;
    CloseTimer.Top := 27;
  finally
    FormCaption.AfterLoadDFMValues;
    MessageLabel.AfterLoadDFMValues;
    DonorIdLabel.AfterLoadDFMValues;
    ExternalIdLabel.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    DataState.AfterLoadDFMValues;
    PriorButton.AfterLoadDFMValues;
    NextButton.AfterLoadDFMValues;
    edFirstName.AfterLoadDFMValues;
    edLastName.AfterLoadDFMValues;
    edAdd1.AfterLoadDFMValues;
    edAdd2.AfterLoadDFMValues;
    edAdd3.AfterLoadDFMValues;
    edCity.AfterLoadDFMValues;
    edPostCode.AfterLoadDFMValues;
    edEmail.AfterLoadDFMValues;
    edMobile.AfterLoadDFMValues;
    edTelephone.AfterLoadDFMValues;
    DateSigned.AfterLoadDFMValues;
    ContactByPhone.AfterLoadDFMValues;
    ContactByEmail.AfterLoadDFMValues;
    ContactByText.AfterLoadDFMValues;
    ContactByPost.AfterLoadDFMValues;
    CountryList.AfterLoadDFMValues;
    TitleEdit.AfterLoadDFMValues;
    DataEditAmount.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    edCounty.AfterLoadDFMValues;
    ExternalIdEdit.AfterLoadDFMValues;
    MessageDialog.AfterLoadDFMValues;
    EditDataSource.AfterLoadDFMValues;
    EditElements.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
  end;
end;

end.
