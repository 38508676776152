unit OtherClaimsAdjustmentsEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, OtherClaimsEdit, Data.DB, WEBLib.DB,
  Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.DBCtrls, Vcl.Controls, WEBLib.ExtCtrls,
  WEBLib.WebCtrls;

type
  TOtherClaimsAdjustmentsEditForm = class(TOtherClaimsEditForm)
    AdjustmentTypeCombo: TDBLookupComboBox;
    procedure EditDataSourceDataChange(Sender: TObject; Field: TField);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
  protected
    [async]
    procedure GetRules; async; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsAdjustmentsEditForm: TOtherClaimsAdjustmentsEditForm;

implementation

{$R *.dfm}

procedure TOtherClaimsAdjustmentsEditForm.EditDataSourceDataChange(Sender:
    TObject; Field: TField);
begin
  inherited;
  //
end;

procedure TOtherClaimsAdjustmentsEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  AdjustmentTypeCombo.LookupValues.AddPair('Refund','Refund');
  AdjustmentTypeCombo.LookupValues.AddPair('NotGiftAidable','Not Gift-Aidable');
  AdjustmentTypeCombo.LookupValues.AddPair('Other','Other');
end;

procedure TOtherClaimsAdjustmentsEditForm.GetRules;
begin
  OtherClaimsType := 'Adjustment';
  inherited;
end;


procedure TOtherClaimsAdjustmentsEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  AdjustmentTypeCombo := TDBLookupComboBox.Create('AdjustmentTypeCombo');

  AdjustmentTypeCombo.BeforeLoadDFMValues;
  try
    AdjustmentTypeCombo.SetParentComponent(Self);
    AdjustmentTypeCombo.Name := 'AdjustmentTypeCombo';
    AdjustmentTypeCombo.Left := 11;
    AdjustmentTypeCombo.Top := 178;
    AdjustmentTypeCombo.Width := 123;
    AdjustmentTypeCombo.Height := 15;
    AdjustmentTypeCombo.ElementClassName := 'form-select';
    AdjustmentTypeCombo.ElementPosition := epIgnore;
    AdjustmentTypeCombo.HeightStyle := ssAuto;
    AdjustmentTypeCombo.HeightPercent := 100.000000000000000000;
    AdjustmentTypeCombo.WidthStyle := ssAuto;
    AdjustmentTypeCombo.WidthPercent := 100.000000000000000000;
    AdjustmentTypeCombo.DataField := 'AdjustmentType';
    AdjustmentTypeCombo.DataSource := EditDataSource;
  finally
    AdjustmentTypeCombo.AfterLoadDFMValues;
  end;
end;

end.