unit OtherClaimsList;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseList, WEBLib.ExtCtrls, WEBLib.Actions,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.Grids,
  WEBLib.DBCtrls, Vcl.Controls, WEBLib.Lists, Vcl.StdCtrls, WEBLib.StdCtrls,
  smx.webcore.types;

type
  TOtherClaimsListForm = class(TBaseListForm)
    RefreshTimer: TTimer;
    FilterCombo: TComboBox;
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetExternalId: TStringField;
    MainDatasetTransactionDate: TDateField;
    MainDatasetImportId: TIntegerField;
    MainDatasetClaimId: TIntegerField;
    MainDatasetGiftAid: TFloatField;
    MainDatasetAmount: TFloatField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetClaimDate: TDateTimeField;
    MainDatasetTaxYear: TIntegerField;
    MainDatasetErrorText: TStringField;
    MainDatasetCharityTenant: TStringField;
    MainDatasetSourceType: TStringField;
    MainDatasetComment: TStringField;
    OtherClaimsTitleLabel: TLabel;
    procedure WebFormCreate(Sender: TObject);
    procedure AddButtonClick(Sender: TObject);
    procedure DataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer;
        AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure RefreshTimerTimer(Sender: TObject);
    procedure FilterComboChange(Sender: TObject);
  private
    { Private declarations }
    fFilterStr: string;
    fOtherClaimsType: string;
    [async]
    procedure EditForm(RecordId: integer); async;
    procedure RefreshControls;
    //procedure SetOtherClaimsType(OtherClaimsType: string);
    procedure SetOtherClaimsType(OtherClaimsType: string);
    procedure SetOtherClaimsTitle(OtherClaimsTitle: string);

    [async]
    procedure ApplyFilter; async;

    procedure LoadFilterCombo;
  protected
    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;
    function CreateGridInFormCreate: boolean; override;
    procedure DeleteSelectedRecord(RecordId: integer);

  public
    { Public declarations }
    procedure PrepareForm; override;
    procedure LoadData; override;
    property OtherClaimsType: string write SetOtherClaimsType;
    property OtherClaimsTitle: string write SetOtherClaimsTitle;
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsListForm: TOtherClaimsListForm;

implementation

uses
  OtherClaimsEdit, MainDataModule, WEBLib.WebCtrls, SMX.Web.Layout.Utils,
  XData.Web.Client, Grid.Settings, Grid.Plugins;


{$R *.dfm}

procedure TOtherClaimsListForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  MessageLabel.Caption := '';
end;

procedure TOtherClaimsListForm.AddButtonClick(Sender: TObject);
begin
  inherited;
  EditForm(0);
end;

procedure TOtherClaimsListForm.PrepareForm;
begin
  FIdField := 'Id';
  LoadFilterCombo;
end;

procedure TOtherClaimsListForm.RefreshControls;
var
  FilterStr: string;
begin
  FilterStr := FilterCombo.Values[FilterCombo.ItemIndex];
  FGridPlugin.SetPreparedFilter(FilterStr);
end;

procedure TOtherClaimsListForm.ApplyFilter;
var
  lJobParams: JS.TJSObject;
  lRetval: TXDataClientResponse;
  FilterRequest: string;
begin
  FilterRequest := FilterCombo.Text;
  lRetval := await(TXDataClientResponse,
    MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.GetFilter',[FilterRequest]));

  fFilterStr := JS.ToString(lRetval.ResultAsObject['value']);
  RefreshTimer.Enabled := false;
end;

procedure TOtherClaimsListForm.DataTableGetCellChildren(Sender: TObject; ACol,
    ARow: Integer; AField: TField; AValue: string; AElement:
    TJSHTMLElementRecord);
var
  RecordId: integer;
  Span: THTMLSpan;


  procedure EditClick(Sender: TObject);
  begin
    EditForm(RecordId);
  end;

  procedure DeleteClick(Sender: TObject);
  begin
    DeleteSelectedRecord(RecordId);
  end;

begin
  if ARow = 0 then
    Exit;


  if not (DataTable.Columns[ACol].Title = 'Actions') then
  begin
    Exit;
  end;

  RecordId := MainDataSet.FieldByName('Id').AsInteger;

  if ( MainDataSet.FieldByName('ClaimStatus').AsString = 'Unclaimed') or
     ( MainDataSet.FieldByName('ClaimStatus').AsString = 'Invalid') then
  begin
    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-edit', 'Edit');
    Span.OnClick := @EditClick;

    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-trash', 'Delete');
    Span.OnClick := @DeleteClick;
  end;

end;

procedure TOtherClaimsListForm.EditForm(RecordId: integer);
var
  AForm: TOtherClaimsEditForm;

begin
  AForm := TOtherClaimsEditForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());

    if RecordId = 0 then
      MainDataset.Insert
    else
    begin
      MainDataset.Locate('Id', RecordId, []);
      MainDataset.Edit;
    end;
    AForm.DataSet := MainDataset;
    AForm.OtherClaimsType := MainDataset.EntitySetName;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    if AForm.EditDataApplyUpdates then
    begin
      MainDataSet.ApplyUpdates;
    end;
    AForm.Free;
    AForm := nil;
  end;

end;

procedure TOtherClaimsListForm.MainDatasetAfterApplyUpdates(Sender: TDataSet;
    Info: TResolveResults);
begin
  inherited;
  RefreshControls;
end;

procedure TOtherClaimsListForm.RefreshTimerTimer(Sender: TObject);
begin
  RefreshTimer.Enabled := false;
  RefreshControls;
end;

procedure TOtherClaimsListForm.SetOtherClaimsTitle(OtherClaimsTitle: string);
begin
  OtherClaimsTitleLabel.Caption := OtherClaimsTitle;
end;

procedure TOtherClaimsListForm.SetOtherClaimsType(OtherClaimsType: string);
begin
  fOtherClaimsType := OtherClaimsType;
  MainDataset.Active := false;
  MainDataset.EntitySetName := OtherClaimsType;
  MainDataset.Active := true;

  CreateGridPlugin;

  RefreshControls;
end;

procedure TOtherClaimsListForm.FilterComboChange(Sender: TObject);
var
  FilterIdx: integer;
  FilterStr: string;
begin
  RefreshControls;
end;

function TOtherClaimsListForm.InitSortCol: Integer;
begin
  Result := 0;
end;

function TOtherClaimsListForm.InitSortDirection: TSortDirection;
begin
  Result := sdDesc;
end;

procedure TOtherClaimsListForm.LoadData;
begin
  RefreshControls;
end;

procedure TOtherClaimsListForm.LoadFilterCombo;
begin
  FilterCombo.Items.AddPair('Unclaimed', 'ClaimStatus eq ''Unclaimed''');
  FilterCombo.Items.AddPair('Claimed', 'ClaimStatus eq ''Claimed''');
  FilterCombo.Items.AddPair('Unclaimed - Tax Year 2023/2024', 'TransactionDate ge ''06.04.2023'' and TransactionDate le ''05.04.2024'' and ClaimStatus eq ''Unclaimed''');
  FilterCombo.Items.AddPair('Claimed - Tax Year 2023/2024',   'TransactionDate ge ''06.04.2023'' and TransactionDate le ''05.04.2024'' and ClaimStatus eq ''Claimed''');

  FilterCombo.Items.AddPair('All', '');
  FilterCombo.ItemIndex := 0;
end;

function TOtherClaimsListForm.CreateGridInFormCreate: boolean;
begin
  result := false;
end;

procedure TOtherClaimsListForm.DeleteSelectedRecord(RecordId: integer);
begin
  if MainDataSet.Locate('Id', RecordId, []) then
  begin
    try
      MainDataSet.Edit;
      MainDataSet.FieldByName('ClaimStatus').AsString := 'Cancelled';
      MainDataSet.Post;
    finally
      MainDataSet.ApplyUpdates;
    end;
  end;
end;


procedure TOtherClaimsListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  OtherClaimsTitleLabel := TLabel.Create('OtherClaimsTitle');
  FilterCombo := TComboBox.Create('FilterCombo');
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetExternalId := TStringField.Create(Self);
  MainDatasetTransactionDate := TDateField.Create(Self);
  MainDatasetImportId := TIntegerField.Create(Self);
  MainDatasetClaimId := TIntegerField.Create(Self);
  MainDatasetGiftAid := TFloatField.Create(Self);
  MainDatasetAmount := TFloatField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetClaimDate := TDateTimeField.Create(Self);
  MainDatasetTaxYear := TIntegerField.Create(Self);
  MainDatasetErrorText := TStringField.Create(Self);
  MainDatasetCharityTenant := TStringField.Create(Self);
  MainDatasetSourceType := TStringField.Create(Self);
  MainDatasetComment := TStringField.Create(Self);
  RefreshTimer := TTimer.Create(Self);

  OtherClaimsTitleLabel.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  FilterCombo.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetExternalId.BeforeLoadDFMValues;
  MainDatasetTransactionDate.BeforeLoadDFMValues;
  MainDatasetImportId.BeforeLoadDFMValues;
  MainDatasetClaimId.BeforeLoadDFMValues;
  MainDatasetGiftAid.BeforeLoadDFMValues;
  MainDatasetAmount.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetClaimDate.BeforeLoadDFMValues;
  MainDatasetTaxYear.BeforeLoadDFMValues;
  MainDatasetErrorText.BeforeLoadDFMValues;
  MainDatasetCharityTenant.BeforeLoadDFMValues;
  MainDatasetSourceType.BeforeLoadDFMValues;
  MainDatasetComment.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  try
    OtherClaimsTitleLabel.SetParentComponent(Self);
    OtherClaimsTitleLabel.Name := 'OtherClaimsTitleLabel';
    OtherClaimsTitleLabel.Left := 21;
    OtherClaimsTitleLabel.Top := 300;
    OtherClaimsTitleLabel.Width := 46;
    OtherClaimsTitleLabel.Height := 10;
    OtherClaimsTitleLabel.Caption := 'Other Claims';
    OtherClaimsTitleLabel.ElementPosition := epIgnore;
    OtherClaimsTitleLabel.HeightStyle := ssAuto;
    OtherClaimsTitleLabel.HeightPercent := 100.000000000000000000;
    OtherClaimsTitleLabel.HTMLType := tH2;
    OtherClaimsTitleLabel.WidthStyle := ssAuto;
    OtherClaimsTitleLabel.WidthPercent := 100.000000000000000000;
    DataTable.Top := 73;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'TransactionDate';
      Title := 'Transaction Date';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimStatus';
      Title := 'ClaimStatus';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Comment';
      Title := 'Note';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    AddButton.Left := 21;
    AddButton.Top := 277;
    FilterCombo.SetParentComponent(Self);
    FilterCombo.Name := 'FilterCombo';
    FilterCombo.Left := 160;
    FilterCombo.Top := 264;
    FilterCombo.Width := 203;
    FilterCombo.Height := 15;
    FilterCombo.ElementClassName := 'form-select';
    FilterCombo.ElementPosition := epIgnore;
    FilterCombo.HeightStyle := ssAuto;
    FilterCombo.HeightPercent := 100.000000000000000000;
    FilterCombo.WidthStyle := ssAuto;
    FilterCombo.WidthPercent := 100.000000000000000000;
    SetEvent(FilterCombo, Self, 'OnChange', 'FilterComboChange');
    FilterCombo.ItemIndex := -1;
    MainDataset.AfterApplyUpdates := MainDatasetAfterApplyUpdates;
    MainDataset.EntitySetName := 'OtherClaim';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetExternalId.SetParentComponent(MainDataset);
    MainDatasetExternalId.Name := 'MainDatasetExternalId';
    MainDatasetExternalId.FieldName := 'ExternalId';
    MainDatasetExternalId.Size := 64;
    MainDatasetTransactionDate.SetParentComponent(MainDataset);
    MainDatasetTransactionDate.Name := 'MainDatasetTransactionDate';
    MainDatasetTransactionDate.FieldName := 'TransactionDate';
    MainDatasetTransactionDate.Required := True;
    MainDatasetImportId.SetParentComponent(MainDataset);
    MainDatasetImportId.Name := 'MainDatasetImportId';
    MainDatasetImportId.FieldName := 'ImportId';
    MainDatasetClaimId.SetParentComponent(MainDataset);
    MainDatasetClaimId.Name := 'MainDatasetClaimId';
    MainDatasetClaimId.FieldName := 'ClaimId';
    MainDatasetGiftAid.SetParentComponent(MainDataset);
    MainDatasetGiftAid.Name := 'MainDatasetGiftAid';
    MainDatasetGiftAid.FieldName := 'GiftAid';
    MainDatasetGiftAid.Required := True;
    MainDatasetAmount.SetParentComponent(MainDataset);
    MainDatasetAmount.Name := 'MainDatasetAmount';
    MainDatasetAmount.FieldName := 'Amount';
    MainDatasetAmount.Required := True;
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    MainDatasetClaimStatus.Required := True;
    MainDatasetClaimStatus.Size := 10;
    MainDatasetClaimDate.SetParentComponent(MainDataset);
    MainDatasetClaimDate.Name := 'MainDatasetClaimDate';
    MainDatasetClaimDate.FieldName := 'ClaimDate';
    MainDatasetTaxYear.SetParentComponent(MainDataset);
    MainDatasetTaxYear.Name := 'MainDatasetTaxYear';
    MainDatasetTaxYear.FieldName := 'TaxYear';
    MainDatasetErrorText.SetParentComponent(MainDataset);
    MainDatasetErrorText.Name := 'MainDatasetErrorText';
    MainDatasetErrorText.FieldName := 'ErrorText';
    MainDatasetErrorText.Size := 1000;
    MainDatasetCharityTenant.SetParentComponent(MainDataset);
    MainDatasetCharityTenant.Name := 'MainDatasetCharityTenant';
    MainDatasetCharityTenant.FieldName := 'CharityTenant';
    MainDatasetSourceType.SetParentComponent(MainDataset);
    MainDatasetSourceType.Name := 'MainDatasetSourceType';
    MainDatasetSourceType.FieldName := 'SourceType';
    MainDatasetSourceType.Size := 9;
    MainDatasetComment.SetParentComponent(MainDataset);
    MainDatasetComment.Name := 'MainDatasetComment';
    MainDatasetComment.FieldName := 'Comment';
    MainDatasetComment.Size := 250;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 384;
    RefreshTimer.Top := 11;
  finally
    OtherClaimsTitleLabel.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    FilterCombo.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetExternalId.AfterLoadDFMValues;
    MainDatasetTransactionDate.AfterLoadDFMValues;
    MainDatasetImportId.AfterLoadDFMValues;
    MainDatasetClaimId.AfterLoadDFMValues;
    MainDatasetGiftAid.AfterLoadDFMValues;
    MainDatasetAmount.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetClaimDate.AfterLoadDFMValues;
    MainDatasetTaxYear.AfterLoadDFMValues;
    MainDatasetErrorText.AfterLoadDFMValues;
    MainDatasetCharityTenant.AfterLoadDFMValues;
    MainDatasetSourceType.AfterLoadDFMValues;
    MainDatasetComment.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
  end;
end;

end.