unit ConnectedCharitiesList;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseList, WEBLib.ExtCtrls, WEBLib.Actions,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.Grids, WEBLib.DBCtrls, Vcl.Controls, WEBLib.Lists,
  smx.webcore.types;

type
  TConnectedCharitiesListForm = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetExternalId: TStringField;
    MainDatasetCharityTenant: TStringField;
    MainDatasetHMRCRef: TStringField;
    MainDatasetRegulatorNumber: TStringField;
    MainDatasetCharityName: TStringField;
    RefreshTimer: TTimer;
    procedure MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure MainDatasetAfterPost(DataSet: TDataSet);
    procedure RefreshTimerTimer(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
  protected
    function GetEditFormClass: TFormClass; override;
    procedure PrepareForm; override;
    procedure LoadData; override;
    function RecordViewOption: TViewOption; override;
    procedure RefreshControls;
    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;
    function CreateGridInFormCreate: boolean; override;

  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ConnectedCharitiesListForm: TConnectedCharitiesListForm;

implementation

{$R *.dfm}

uses
  ConnectedCharitiesEdit;


function TConnectedCharitiesListForm.GetEditFormClass: TFormClass;
begin
  result := TConnectedCharitiesEditForm;
end;

procedure TConnectedCharitiesListForm.LoadData;
begin
  CreateGridPlugin;
  RefreshControls;
end;

procedure TConnectedCharitiesListForm.PrepareForm;
begin
  FIdField := 'Id';
end;

procedure TConnectedCharitiesListForm.WebFormShow(Sender: TObject);
begin
  inherited;
  LoadData;
end;

function TConnectedCharitiesListForm.RecordViewOption: TViewOption;
begin
  Result := TViewOption.voCreateAndEdit
end;

procedure TConnectedCharitiesListForm.RefreshControls;
begin
  FGridPlugin.Load;
end;

function TConnectedCharitiesListForm.InitSortCol: Integer;
begin
  result := 0;
end;

function TConnectedCharitiesListForm.InitSortDirection: TSortDirection;
begin
  result := sdAsc
end;

function TConnectedCharitiesListForm.CreateGridInFormCreate: boolean;
begin
  result := false;
end;

procedure TConnectedCharitiesListForm.MainDatasetAfterApplyUpdates(Sender:
    TDataSet; Info: TResolveResults);
begin
  inherited;
  RefreshControls;
end;

procedure TConnectedCharitiesListForm.MainDatasetAfterPost(DataSet: TDataSet);
begin
  inherited;
  MainDataset.ApplyUpdates;
  RefreshTimer.Enabled := true;
end;

procedure TConnectedCharitiesListForm.RefreshTimerTimer(Sender: TObject);
begin
  RefreshTimer.Enabled := false;
  RefreshControls;
end;


procedure TConnectedCharitiesListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetExternalId := TStringField.Create(Self);
  MainDatasetCharityTenant := TStringField.Create(Self);
  MainDatasetHMRCRef := TStringField.Create(Self);
  MainDatasetRegulatorNumber := TStringField.Create(Self);
  MainDatasetCharityName := TStringField.Create(Self);
  RefreshTimer := TTimer.Create(Self);

  DataTable.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetExternalId.BeforeLoadDFMValues;
  MainDatasetCharityTenant.BeforeLoadDFMValues;
  MainDatasetHMRCRef.BeforeLoadDFMValues;
  MainDatasetRegulatorNumber.BeforeLoadDFMValues;
  MainDatasetCharityName.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'CharityName';
      Title := 'Charity Name';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'HMRCRef';
      Title := 'HMRC Ref';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ExternalId';
      Title := 'External Ref';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    MainDataset.AfterApplyUpdates := MainDatasetAfterApplyUpdates;
    MainDataset.EntitySetName := 'ConnectedCharity';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetExternalId.SetParentComponent(MainDataset);
    MainDatasetExternalId.Name := 'MainDatasetExternalId';
    MainDatasetExternalId.FieldName := 'ExternalId';
    MainDatasetExternalId.Size := 64;
    MainDatasetCharityTenant.SetParentComponent(MainDataset);
    MainDatasetCharityTenant.Name := 'MainDatasetCharityTenant';
    MainDatasetCharityTenant.FieldName := 'CharityTenant';
    MainDatasetHMRCRef.SetParentComponent(MainDataset);
    MainDatasetHMRCRef.Name := 'MainDatasetHMRCRef';
    MainDatasetHMRCRef.FieldName := 'HMRCRef';
    MainDatasetRegulatorNumber.SetParentComponent(MainDataset);
    MainDatasetRegulatorNumber.Name := 'MainDatasetRegulatorNumber';
    MainDatasetRegulatorNumber.FieldName := 'RegulatorNumber';
    MainDatasetRegulatorNumber.Size := 40;
    MainDatasetCharityName.SetParentComponent(MainDataset);
    MainDatasetCharityName.Name := 'MainDatasetCharityName';
    MainDatasetCharityName.FieldName := 'CharityName';
    MainDatasetCharityName.Required := True;
    MainDatasetCharityName.Size := 255;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 395;
    RefreshTimer.Top := 16;
  finally
    DataTable.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetExternalId.AfterLoadDFMValues;
    MainDatasetCharityTenant.AfterLoadDFMValues;
    MainDatasetHMRCRef.AfterLoadDFMValues;
    MainDatasetRegulatorNumber.AfterLoadDFMValues;
    MainDatasetCharityName.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
  end;
end;

end.