unit SysConfigForm;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TSysConfig = class(TCoreWebForm)
    CancelButton: TButton;
    SaveButton: TButton;
    procedure CancelButtonClick(Sender: TObject);
    procedure SaveButtonClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  SysConfig: TSysConfig;

implementation

{$R *.dfm}

procedure TSysConfig.CancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TSysConfig.SaveButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TSysConfig.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CancelButton := TButton.Create('CancelButton');
  SaveButton := TButton.Create('SaveButton');

  CancelButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  try
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 347;
    CancelButton.Top := 283;
    CancelButton.Width := 64;
    CancelButton.Height := 17;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 3;
    CancelButton.ElementClassName := 'btn btn-primary';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 267;
    SaveButton.Top := 283;
    SaveButton.Width := 64;
    SaveButton.Height := 17;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 2;
    SaveButton.ElementClassName := 'btn btn-secondary';
    SaveButton.ElementFont := efCSS;
    SaveButton.ElementPosition := epIgnore;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthStyle := ssAuto;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
  finally
    CancelButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
  end;
end;

end.