unit ConnectedCharitiesEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseForm, WEBLib.ExtCtrls, WEBLib.Actions,
  Data.DB, WEBLib.DB, WEBLib.WebCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.DBCtrls;

type
  TConnectedCharitiesEditForm = class(TBaseEditForm)
    CharityNameEdit: TDBEdit;
    HMRCRefEdit: TDBEdit;
    ExternalIdEdit: TDBEdit;
    RegulatorNumberEdit: TDBEdit;
    CharityTenantEdit: TDBEdit;
    procedure DataSourceDataChange(Sender: TObject; Field: TField);
    procedure DataSourceStateChange(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  ConnectedCharitiesEditForm: TConnectedCharitiesEditForm;

implementation

{$R *.dfm}

uses
  ConnectedCharitiesList;

procedure TConnectedCharitiesEditForm.DataSourceDataChange(Sender: TObject;
    Field: TField);
begin
  inherited;
  SaveButton.Enabled := true;
end;

procedure TConnectedCharitiesEditForm.DataSourceStateChange(Sender: TObject);
begin
  inherited;
  SaveButton.Enabled := true;
end;

procedure TConnectedCharitiesEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CharityNameEdit := TDBEdit.Create('CharityNameEdit');
  HMRCRefEdit := TDBEdit.Create('HMRCRefEdit');
  ExternalIdEdit := TDBEdit.Create('ExternalIdEdit');
  RegulatorNumberEdit := TDBEdit.Create('RegulatorNumberEdit');
  CharityTenantEdit := TDBEdit.Create('CharityTenantEdit');

  FormCaption.BeforeLoadDFMValues;
  CharityNameEdit.BeforeLoadDFMValues;
  HMRCRefEdit.BeforeLoadDFMValues;
  ExternalIdEdit.BeforeLoadDFMValues;
  RegulatorNumberEdit.BeforeLoadDFMValues;
  CharityTenantEdit.BeforeLoadDFMValues;
  try
    FormCaption.Width := 81;
    FormCaption.Caption := 'Connected Charity Edit';
    CharityNameEdit.SetParentComponent(Self);
    CharityNameEdit.Name := 'CharityNameEdit';
    CharityNameEdit.Left := 36;
    CharityNameEdit.Top := 85;
    CharityNameEdit.Width := 198;
    CharityNameEdit.Height := 15;
    CharityNameEdit.ChildOrder := 3;
    CharityNameEdit.ElementPosition := epIgnore;
    CharityNameEdit.HeightStyle := ssAuto;
    CharityNameEdit.HeightPercent := 100.000000000000000000;
    CharityNameEdit.Text := 'CharityNameEdit';
    CharityNameEdit.WidthStyle := ssAuto;
    CharityNameEdit.WidthPercent := 100.000000000000000000;
    CharityNameEdit.DataField := 'CharityName';
    CharityNameEdit.DataSource := DataSource;
    HMRCRefEdit.SetParentComponent(Self);
    HMRCRefEdit.Name := 'HMRCRefEdit';
    HMRCRefEdit.Left := 36;
    HMRCRefEdit.Top := 104;
    HMRCRefEdit.Width := 198;
    HMRCRefEdit.Height := 15;
    HMRCRefEdit.ChildOrder := 4;
    HMRCRefEdit.ElementPosition := epIgnore;
    HMRCRefEdit.HeightStyle := ssAuto;
    HMRCRefEdit.HeightPercent := 100.000000000000000000;
    HMRCRefEdit.TabOrder := 1;
    HMRCRefEdit.Text := 'HMRCRefEdit';
    HMRCRefEdit.WidthStyle := ssAuto;
    HMRCRefEdit.WidthPercent := 100.000000000000000000;
    HMRCRefEdit.DataField := 'HMRCRef';
    HMRCRefEdit.DataSource := DataSource;
    ExternalIdEdit.SetParentComponent(Self);
    ExternalIdEdit.Name := 'ExternalIdEdit';
    ExternalIdEdit.Left := 36;
    ExternalIdEdit.Top := 123;
    ExternalIdEdit.Width := 198;
    ExternalIdEdit.Height := 15;
    ExternalIdEdit.ChildOrder := 6;
    ExternalIdEdit.ElementPosition := epIgnore;
    ExternalIdEdit.HeightStyle := ssAuto;
    ExternalIdEdit.HeightPercent := 100.000000000000000000;
    ExternalIdEdit.TabOrder := 3;
    ExternalIdEdit.Text := 'ExternalIdEdit';
    ExternalIdEdit.WidthStyle := ssAuto;
    ExternalIdEdit.WidthPercent := 100.000000000000000000;
    ExternalIdEdit.DataField := 'ExternalId';
    ExternalIdEdit.DataSource := DataSource;
    RegulatorNumberEdit.SetParentComponent(Self);
    RegulatorNumberEdit.Name := 'RegulatorNumberEdit';
    RegulatorNumberEdit.Left := 36;
    RegulatorNumberEdit.Top := 141;
    RegulatorNumberEdit.Width := 198;
    RegulatorNumberEdit.Height := 15;
    RegulatorNumberEdit.ChildOrder := 6;
    RegulatorNumberEdit.ElementPosition := epIgnore;
    RegulatorNumberEdit.HeightStyle := ssAuto;
    RegulatorNumberEdit.HeightPercent := 100.000000000000000000;
    RegulatorNumberEdit.TabOrder := 3;
    RegulatorNumberEdit.Text := 'RegulatorNumberEdit';
    RegulatorNumberEdit.WidthStyle := ssAuto;
    RegulatorNumberEdit.WidthPercent := 100.000000000000000000;
    RegulatorNumberEdit.DataField := 'RegulatorNumber';
    RegulatorNumberEdit.DataSource := DataSource;
    CharityTenantEdit.SetParentComponent(Self);
    CharityTenantEdit.Name := 'CharityTenantEdit';
    CharityTenantEdit.Left := 36;
    CharityTenantEdit.Top := 160;
    CharityTenantEdit.Width := 198;
    CharityTenantEdit.Height := 15;
    CharityTenantEdit.ChildOrder := 6;
    CharityTenantEdit.ElementPosition := epIgnore;
    CharityTenantEdit.HeightStyle := ssAuto;
    CharityTenantEdit.HeightPercent := 100.000000000000000000;
    CharityTenantEdit.TabOrder := 3;
    CharityTenantEdit.Text := 'CharityTenantEdit';
    CharityTenantEdit.WidthStyle := ssAuto;
    CharityTenantEdit.WidthPercent := 100.000000000000000000;
    CharityTenantEdit.DataField := 'CharityTenant';
    CharityTenantEdit.DataSource := DataSource;
  finally
    FormCaption.AfterLoadDFMValues;
    CharityNameEdit.AfterLoadDFMValues;
    HMRCRefEdit.AfterLoadDFMValues;
    ExternalIdEdit.AfterLoadDFMValues;
    RegulatorNumberEdit.AfterLoadDFMValues;
    CharityTenantEdit.AfterLoadDFMValues;
  end;
end;

end.