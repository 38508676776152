unit ManualEntryList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  XData.Web.Client,
  smx.webcore.types;

type
  TManualEntryListForm = class(TBaseListForm)
    RefreshTimer: TTimer;
    TitleEdit: TDBEdit;
    FirstNameEdit: TDBEdit;
    MiddleNameEdit: TDBEdit;
    LastNameEdit: TDBEdit;
    Add1Edit: TDBEdit;
    Add2Edit: TDBEdit;
    Add3Edit: TDBEdit;
    DateAddedEdit: TDBDateTimePicker;
    PostCodeEdit: TDBEdit;
    AmountEdit: TDBEdit;
    OverseasAddressEdit: TDBCheckBox;
    SponsoredEdit: TDBCheckBox;
    CityEdit: TDBEdit;
    WaitForBatchIdTimer: TTimer;
    MergeButton: TButton;
    WaitForBatchDataTimer: TTimer;
    DonationsData: TLabel;
    ClearButton: TButton;
    NextRecordTimer: TTimer;
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetTitle: TStringField;
    MainDatasetFirstName: TStringField;
    MainDatasetMiddleName: TStringField;
    MainDatasetLastName: TStringField;
    MainDatasetSuffix: TStringField;
    MainDatasetAdd1: TStringField;
    MainDatasetAdd2: TStringField;
    MainDatasetAdd3: TStringField;
    MainDatasetCity: TStringField;
    MainDatasetPostCode: TStringField;
    MainDatasetEmail: TStringField;
    MainDatasetMobile: TStringField;
    MainDatasetTelephone: TStringField;
    MainDatasetMatchHash: TStringField;
    MainDatasetContactByPhone: TBooleanField;
    MainDatasetContactByPost: TBooleanField;
    MainDatasetContactByText: TBooleanField;
    MainDatasetContactByEmail: TBooleanField;
    MainDatasetDOB: TDateField;
    MainDatasetHearingStatus: TStringField;
    MainDatasetEmailStatus: TStringField;
    MainDatasetLatitude: TFloatField;
    MainDatasetLongitude: TFloatField;
    MainDatasetExternalId: TStringField;
    MainDatasetCountryCode: TStringField;
    MainDatasetVisualStatus: TStringField;
    MainDatasetMobilityStatus: TStringField;
    MainDatasetSourceType: TStringField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetCharityTenant: TStringField;
    MainDatasetImportState: TStringField;
    MainDatasetErrorText: TStringField;
    MainDatasetImportBatchId: TIntegerField;
    MainDatasetSponsored: TBooleanField;
    MainDatasetDonationDate: TDateTimeField;
    MainDatasetGiftAid: TFloatField;
    MainDatasetAmount: TFloatField;
    MainDatasetExternalDonationId: TStringField;
    MainDatasetMatchedPerson: TIntegerField;
    MessageDialog: TMessageDlg;
    WebMessageDlg1: TMessageDlg;
    procedure AddButtonClick(Sender: TObject);
    procedure AmountEditKeyPress(Sender: TObject; var Key: Char);
    procedure DataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
      AElement: TJSHTMLElementRecord);
    procedure MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure MainDatasetAfterOpen(DataSet: TDataSet);
    procedure MergeButtonClick(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
    procedure RefreshTimerTimer(Sender: TObject);
    procedure SaveDonationClick(Sender: TObject);
    procedure WaitForBatchDataTimerTimer(Sender: TObject);
    procedure WaitForBatchIdTimerTimer(Sender: TObject);
    procedure ClearButtonClick(Sender: TObject);
    procedure DateAddedEditChange(Sender: TObject);
    procedure DateAddedEditExit(Sender: TObject);
    procedure NextRecordTimerTimer(Sender: TObject);
    procedure PostCodeEditExit(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FBatchId: Integer;
    FBatchTotal: Currency;
    FNoOfDonations: Integer;
    FNoOfErrors: Integer;
    FMergeEnabled: boolean;

    FPostCodeValid: boolean;
    FDateAddedValid: boolean;

    [async]
    procedure RefreshData; async;
    [async]
    procedure GetNewBatchId; async;
    [async]
    procedure RefreshBatchData; async;

    procedure SaveNewDonation;
    procedure SaveNewDonationWithBatchId;
    [async]
    procedure MergeDataJob; async;
    procedure ValidatePostCode;
    procedure CheckValidation;
  protected
    procedure DeleteSelectedRecord(RecordId: integer);
    procedure EditSelectedRecord(RecordId: integer);
    procedure LoadData; override;
    function CreateGridInFormCreate: Boolean; override;

    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;

    procedure SetPostCodeValidation(bValid: boolean);
    procedure SetDateAddedValidation(bValid: boolean);

  public
    { Public declarations }
    procedure ViewData(const ARecordId: string);
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
    procedure PrepareForm; override;
//    procedure LoadData; override;
    function GetEditFormClass: TFormClass; override;
    property PostCodeValid: boolean read FPostCodeValid write SetPostCodeValidation;
    property DateAddedValid: boolean read FDateAddedValid write SetDateAddedValidation;



  protected procedure LoadDFMValues; override; end;

var
  ManualEntryListForm: TManualEntryListForm;

implementation

{$R *.dfm}

uses
  SMX.Web.Layout.Utils,
  MainDataModule,
  SMX.Web.Service.Consts,
  SMX.CRM.Utils,
  SMX.Web.Document.Utils;

procedure TManualEntryListForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);
begin
end;

function TManualEntryListForm.GetEditFormClass: TFormClass;
begin
end;

procedure TManualEntryListForm.ViewData(const ARecordId: string);
begin
  FBatchId := 0;
  LoadData;
  RefreshData;
end;

procedure TManualEntryListForm.WebFormDestroy(Sender: TObject);
begin
  inherited;
  //
end;

procedure TManualEntryListForm.AddButtonClick(Sender: TObject);
begin
  inherited;
  SaveNewDonation;
end;

procedure TManualEntryListForm.AmountEditKeyPress(Sender: TObject; var Key:
    Char);
begin
  //  inherited;
  if not (Key in ['0'..'9','.','-']) then
  begin
    TDocUtils.setControlValidity('AmountEdit', vsInvalid);
    Key := #0;
  end
  else
  begin
    TDocUtils.setControlValidity('AmountEdit', vsValid);
  end;
end;

procedure TManualEntryListForm.DataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField;
  AValue: string; AElement: TJSHTMLElementRecord);
var
  RecordId: integer;
  Span: THTMLSpan;

  procedure EditClick(Sender: TObject);
  begin
    EditSelectedRecord(RecordId);
  end;

  procedure DeleteClick(Sender: TObject);
  begin
    DeleteSelectedRecord(RecordId);
  end;


begin
  if ARow = 0 then
    Exit;

  if DataTable.Columns[ACol].Title = 'Donor' then
  begin
    DataTable.Cells[ACol, ARow] := MainDataset.FieldByName('Title').AsString + ' ' +
      MainDataset.FieldByName('FirstName').AsString + ' ' + MainDataset.FieldByName('LastName').AsString;
  end;

  if DataTable.Columns[ACol].Title = 'Address' then
  begin
    DataTable.Cells[ACol, ARow] := MainDataset.FieldByName('Add1').AsString + ' ' +
      MainDataset.FieldByName('City').AsString;
  end;


  if DataTable.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := MainDataSet.FieldByName('Id').AsInteger;

    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-edit', 'Edit');
    Span.OnClick := @EditClick;

    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-trash', 'Delete');
    Span.OnClick := @DeleteClick;
  end;

end;

procedure TManualEntryListForm.DeleteSelectedRecord(RecordId: integer);
begin
  if MainDataSet.Locate('Id', RecordId, []) then
  begin
    try
      MainDataSet.Delete;
      MainDataSet.Post;
    finally
      MainDataSet.ApplyUpdates;
    end;
  end;
end;

procedure TManualEntryListForm.EditSelectedRecord(RecordId: integer);
begin
  if MainDataSet.Locate('Id', RecordId, []) then
    MainDataSet.Edit;
end;

procedure TManualEntryListForm.MainDatasetAfterOpen(Dataset: TDataSet);
begin
  // Need to set dataset into Insert mode when form opens
  NextRecordTimer.Enabled := true;
end;

procedure TManualEntryListForm.PrepareForm;
begin
  FIdField := 'Id';
  // Set BatchId to zero so no result set is returned. New BatchId will be
  // created on saving of the first donation
  FBatchId := 0;
end;

procedure TManualEntryListForm.MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  inherited;
  RefreshTimer.Enabled := true;
end;

procedure TManualEntryListForm.RefreshTimerTimer(Sender: TObject);
begin
  RefreshTimer.Enabled := false;
  RefreshData;
end;

procedure TManualEntryListForm.RefreshData;
var
  FilterStr: string;

begin
  FilterStr := 'ImportBatchId eq ' + FBatchId.ToString;
  FGridPlugin.SetPreparedFilter(FilterStr);

  FPostCodeValid := false;
  FDateAddedValid := false;
  CheckValidation;
end;

procedure TManualEntryListForm.GetNewBatchId;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  sString: string;
  nInt: Integer;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.CreateNewBatch',
    ['Manual']));
  lResult := lRetval.ResultAsObject;
  FBatchId := JS.toInteger(lResult['value']);

  WaitForBatchIdTimer.Enabled := true;
end;

function TManualEntryListForm.InitSortCol: Integer;
begin
  result := 0;
end;

function TManualEntryListForm.InitSortDirection: TSortDirection;
begin
  result := sdDesc;
end;

procedure TManualEntryListForm.MergeButtonClick(Sender: TObject);
begin
  MergeDataJob;
  FBatchId := 0;
  RefreshData;
end;

procedure TManualEntryListForm.SaveDonationClick(Sender: TObject);
begin
  SaveNewDonation;
end;

procedure TManualEntryListForm.SaveNewDonation;
begin
  if FBatchId = 0 then
  begin
    GetNewBatchId;
  end
  else
  begin
    SaveNewDonationWithBatchId;
  end;
end;

procedure TManualEntryListForm.SaveNewDonationWithBatchId;
begin
  try
    MainDataset.FieldByName('ImportBatchId').AsInteger := FBatchId;
    MainDataset.FieldByName('SourceType').AsString := 'Manual';
    MainDataset.FieldByName('GiftAid').AsFloat := 0.00;
    MainDataset.FieldByName('ImportState').AsString := 'Valid';
    MainDataset.Post;
  finally
    MainDataset.ApplyUpdates;
  end;
end;

procedure TManualEntryListForm.SetDateAddedValidation(bValid: boolean);
begin
  FDateAddedValid := bValid;
  CheckValidation;
end;

procedure TManualEntryListForm.SetPostCodeValidation(bValid: boolean);
begin
  FPostCodeValid := bValid;
  CheckValidation;
end;

procedure TManualEntryListForm.WaitForBatchIdTimerTimer(Sender: TObject);
begin
  if FBatchId <> 0 then
  begin
    SaveNewDonationWithBatchId;
    WaitForBatchIdTimer.Enabled := false;
  end;
end;

procedure TManualEntryListForm.RefreshBatchData;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
begin
  if FBatchId <> 0 then
  begin
    lRetval := await(TXDataClientResponse,
      MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.GetManualBatchData', [FBatchId]));
    lResult := lRetval.ResultAsObject;

    FBatchTotal := JS.toNumber(lResult['BatchTotal']);

    FNoOfDonations := JS.toInteger(lResult['NoOfDonations']);
    FNoOfErrors := JS.toInteger(lResult['NoOfErrors']);
    FMergeEnabled := FNoOfErrors = 0;
    WaitForBatchDataTimer.Enabled := true;
  end;
end;

procedure TManualEntryListForm.WaitForBatchDataTimerTimer(Sender: TObject);
begin
  MergeButton.Enabled := FMergeEnabled;
  DonationsData.Caption := Format('Donations Total: %0.2f No of Donations: %d', [FBatchTotal, FNoOfDonations]);
  WaitForBatchDataTimer.Enabled := false;
end;

procedure TManualEntryListForm.MergeDataJob;
var
  lJobParams: JS.TJSObject;
  lRetval: TXDataClientResponse;
  JobId: Integer;
begin
  if FBatchId <> 0 then
  begin
    lJobParams := JS.TJSObject.new;
    lJobParams.Properties['ImportId'] := FBatchId;

    lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_SCHEDULEJOB,
      ['TImportMergeJob', 'AnyAdminUser', JS.TJSJSON.stringify(lJobParams), 'Undecided', 'Production']));

    JobId := JS.toInteger(lRetval.ResultAsObject['value']);

    // EnableRefreshTimer;

    ShowAToast('Data Merge', Format('The job to merge your import data has been placed. The Job Number is %d', [JobId]),
      'clock.png');
  end;
end;

procedure TManualEntryListForm.LoadData;
begin
  MainDataset.Load;
end;

procedure TManualEntryListForm.CheckValidation;
begin
  if FPostCodeValid and FDateAddedValid then
    AddButton.Enabled := true
  else
    AddButton.Enabled := false;
end;

procedure TManualEntryListForm.ClearButtonClick(Sender: TObject);
begin
  try
    MainDataSet.Cancel;
  finally
    NextRecordTimer.Enabled := true;
  end;
end;

procedure TManualEntryListForm.NextRecordTimerTimer(Sender: TObject);
begin
  NextRecordTimer.Enabled := false;
  MainDataSet.Insert;
end;

procedure TManualEntryListForm.WebFormShow(Sender: TObject);
begin
  CreateGridPlugin;
  RefreshData;
end;

function TManualEntryListForm.CreateGridInFormCreate: Boolean;
begin
  cbPageSize.ItemIndex := 3;
  result := false;
end;

procedure TManualEntryListForm.DateAddedEditChange(Sender: TObject);
begin
  if DateAddedEdit.Date > Date then
  begin
    TDocUtils.setControlValidity('DateAddedEdit', vsInvalid);
    DateAddedValid := false;
    DateAddedEdit.Date := 0;
  end
  else
  begin
    TDocUtils.setControlValidity('DateAddedEdit', vsValid);
    DateAddedValid := true;
  end;
end;

procedure TManualEntryListForm.DateAddedEditExit(Sender: TObject);
begin
//
end;

procedure TManualEntryListForm.PostCodeEditExit(Sender: TObject);
begin
  ValidatePostCode;
end;

procedure TManualEntryListForm.ValidatePostCode;
begin
  if (Trim(PostCodeEdit.Text) = '') then
  begin
    TDocUtils.setControlValidity('PostCodeEdit', vsInvalid);
    PostCodeValid := false;
    Exit;
  end;

  PostCodeEdit.Text := TCRMUtils.FormatPostCode(PostCodeEdit.Text);
  if TCRMUtils.IsPostCode(PostCodeEdit.Text) <> pcFull then
  begin
    TDocUtils.setControlValidity('PostCodeEdit', vsInvalid);
    PostCodeValid := false;
    Exit;
  end;
  TDocUtils.setControlValidity('PostCodeEdit', vsValid);
  PostCodeValid := True;
end;


procedure TManualEntryListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DonationsData := TLabel.Create(Self);
  TitleEdit := TDBEdit.Create('TitleEdit');
  FirstNameEdit := TDBEdit.Create('FirstNameEdit');
  MiddleNameEdit := TDBEdit.Create('MiddleNameEdit');
  LastNameEdit := TDBEdit.Create('LastNameEdit');
  Add1Edit := TDBEdit.Create('Add1Edit');
  Add2Edit := TDBEdit.Create('Add2Edit');
  Add3Edit := TDBEdit.Create('Add3Edit');
  DateAddedEdit := TDBDateTimePicker.Create('DateAddedEdit');
  PostCodeEdit := TDBEdit.Create('PostCodeEdit');
  AmountEdit := TDBEdit.Create('AmountEdit');
  OverseasAddressEdit := TDBCheckBox.Create('OverseasAddressEdit');
  SponsoredEdit := TDBCheckBox.Create('SponsoredEdit');
  CityEdit := TDBEdit.Create('CityEdit');
  MergeButton := TButton.Create('MergeButton');
  ClearButton := TButton.Create('ClearSelectButton');
  MessageDialog := TMessageDlg.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetTitle := TStringField.Create(Self);
  MainDatasetFirstName := TStringField.Create(Self);
  MainDatasetMiddleName := TStringField.Create(Self);
  MainDatasetLastName := TStringField.Create(Self);
  MainDatasetSuffix := TStringField.Create(Self);
  MainDatasetAdd1 := TStringField.Create(Self);
  MainDatasetAdd2 := TStringField.Create(Self);
  MainDatasetAdd3 := TStringField.Create(Self);
  MainDatasetCity := TStringField.Create(Self);
  MainDatasetPostCode := TStringField.Create(Self);
  MainDatasetEmail := TStringField.Create(Self);
  MainDatasetMobile := TStringField.Create(Self);
  MainDatasetTelephone := TStringField.Create(Self);
  MainDatasetMatchHash := TStringField.Create(Self);
  MainDatasetContactByPhone := TBooleanField.Create(Self);
  MainDatasetContactByPost := TBooleanField.Create(Self);
  MainDatasetContactByText := TBooleanField.Create(Self);
  MainDatasetContactByEmail := TBooleanField.Create(Self);
  MainDatasetDOB := TDateField.Create(Self);
  MainDatasetHearingStatus := TStringField.Create(Self);
  MainDatasetEmailStatus := TStringField.Create(Self);
  MainDatasetLatitude := TFloatField.Create(Self);
  MainDatasetLongitude := TFloatField.Create(Self);
  MainDatasetExternalId := TStringField.Create(Self);
  MainDatasetCountryCode := TStringField.Create(Self);
  MainDatasetVisualStatus := TStringField.Create(Self);
  MainDatasetMobilityStatus := TStringField.Create(Self);
  MainDatasetSourceType := TStringField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetCharityTenant := TStringField.Create(Self);
  MainDatasetImportState := TStringField.Create(Self);
  MainDatasetErrorText := TStringField.Create(Self);
  MainDatasetImportBatchId := TIntegerField.Create(Self);
  MainDatasetSponsored := TBooleanField.Create(Self);
  MainDatasetDonationDate := TDateTimeField.Create(Self);
  MainDatasetGiftAid := TFloatField.Create(Self);
  MainDatasetAmount := TFloatField.Create(Self);
  MainDatasetExternalDonationId := TStringField.Create(Self);
  MainDatasetMatchedPerson := TIntegerField.Create(Self);
  RefreshTimer := TTimer.Create(Self);
  WaitForBatchIdTimer := TTimer.Create(Self);
  WaitForBatchDataTimer := TTimer.Create(Self);
  NextRecordTimer := TTimer.Create(Self);

  MessageLabel.BeforeLoadDFMValues;
  DonationsData.BeforeLoadDFMValues;
  cbPageSize.BeforeLoadDFMValues;
  edSearch.BeforeLoadDFMValues;
  lcPaginator.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  AddButton.BeforeLoadDFMValues;
  SearchButton.BeforeLoadDFMValues;
  ListWaitMessage.BeforeLoadDFMValues;
  TitleEdit.BeforeLoadDFMValues;
  FirstNameEdit.BeforeLoadDFMValues;
  MiddleNameEdit.BeforeLoadDFMValues;
  LastNameEdit.BeforeLoadDFMValues;
  Add1Edit.BeforeLoadDFMValues;
  Add2Edit.BeforeLoadDFMValues;
  Add3Edit.BeforeLoadDFMValues;
  DateAddedEdit.BeforeLoadDFMValues;
  PostCodeEdit.BeforeLoadDFMValues;
  AmountEdit.BeforeLoadDFMValues;
  OverseasAddressEdit.BeforeLoadDFMValues;
  SponsoredEdit.BeforeLoadDFMValues;
  CityEdit.BeforeLoadDFMValues;
  MergeButton.BeforeLoadDFMValues;
  ClearButton.BeforeLoadDFMValues;
  MessageDialog.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetTitle.BeforeLoadDFMValues;
  MainDatasetFirstName.BeforeLoadDFMValues;
  MainDatasetMiddleName.BeforeLoadDFMValues;
  MainDatasetLastName.BeforeLoadDFMValues;
  MainDatasetSuffix.BeforeLoadDFMValues;
  MainDatasetAdd1.BeforeLoadDFMValues;
  MainDatasetAdd2.BeforeLoadDFMValues;
  MainDatasetAdd3.BeforeLoadDFMValues;
  MainDatasetCity.BeforeLoadDFMValues;
  MainDatasetPostCode.BeforeLoadDFMValues;
  MainDatasetEmail.BeforeLoadDFMValues;
  MainDatasetMobile.BeforeLoadDFMValues;
  MainDatasetTelephone.BeforeLoadDFMValues;
  MainDatasetMatchHash.BeforeLoadDFMValues;
  MainDatasetContactByPhone.BeforeLoadDFMValues;
  MainDatasetContactByPost.BeforeLoadDFMValues;
  MainDatasetContactByText.BeforeLoadDFMValues;
  MainDatasetContactByEmail.BeforeLoadDFMValues;
  MainDatasetDOB.BeforeLoadDFMValues;
  MainDatasetHearingStatus.BeforeLoadDFMValues;
  MainDatasetEmailStatus.BeforeLoadDFMValues;
  MainDatasetLatitude.BeforeLoadDFMValues;
  MainDatasetLongitude.BeforeLoadDFMValues;
  MainDatasetExternalId.BeforeLoadDFMValues;
  MainDatasetCountryCode.BeforeLoadDFMValues;
  MainDatasetVisualStatus.BeforeLoadDFMValues;
  MainDatasetMobilityStatus.BeforeLoadDFMValues;
  MainDatasetSourceType.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetCharityTenant.BeforeLoadDFMValues;
  MainDatasetImportState.BeforeLoadDFMValues;
  MainDatasetErrorText.BeforeLoadDFMValues;
  MainDatasetImportBatchId.BeforeLoadDFMValues;
  MainDatasetSponsored.BeforeLoadDFMValues;
  MainDatasetDonationDate.BeforeLoadDFMValues;
  MainDatasetGiftAid.BeforeLoadDFMValues;
  MainDatasetAmount.BeforeLoadDFMValues;
  MainDatasetExternalDonationId.BeforeLoadDFMValues;
  MainDatasetMatchedPerson.BeforeLoadDFMValues;
  MainDataSource.BeforeLoadDFMValues;
  WebElementActionList1.BeforeLoadDFMValues;
  ListCloseTimer.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  WaitForBatchIdTimer.BeforeLoadDFMValues;
  WaitForBatchDataTimer.BeforeLoadDFMValues;
  NextRecordTimer.BeforeLoadDFMValues;
  try
    Width := 385;
    Height := 425;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    MessageLabel.Left := 287;
    MessageLabel.Top := 229;
    DonationsData.SetParentComponent(Self);
    DonationsData.Name := 'DonationsData';
    DonationsData.Left := 16;
    DonationsData.Top := 389;
    DonationsData.Width := 52;
    DonationsData.Height := 10;
    DonationsData.Caption := 'DonationsData';
    DonationsData.ElementPosition := epIgnore;
    DonationsData.HeightStyle := ssAuto;
    DonationsData.HeightPercent := 100.000000000000000000;
    DonationsData.Visible := False;
    DonationsData.WidthStyle := ssAuto;
    DonationsData.WidthPercent := 100.000000000000000000;
    cbPageSize.Left := 287;
    cbPageSize.Top := 210;
    cbPageSize.Visible := False;
    edSearch.Left := 160;
    edSearch.Top := 225;
    edSearch.Visible := False;
    lcPaginator.Visible := False;
    DataTable.Left := 16;
    DataTable.Top := 272;
    DataTable.Height := 105;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'DonationDate';
      Title := 'Date';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Donor';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Address';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ImportState';
      Title := 'ClaimStatus';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    AddButton.Left := 289;
    AddButton.Top := 387;
    AddButton.Caption := 'Save';
    SetEvent(AddButton, Self, 'OnClick', 'SaveDonationClick');
    SearchButton.Left := 245;
    SearchButton.Top := 223;
    SearchButton.Visible := False;
    ListWaitMessage.Left := 240;
    ListWaitMessage.Top := 175;
    TitleEdit.SetParentComponent(Self);
    TitleEdit.Name := 'TitleEdit';
    TitleEdit.Left := 11;
    TitleEdit.Top := 5;
    TitleEdit.Width := 81;
    TitleEdit.Height := 15;
    TitleEdit.ChildOrder := 4;
    TitleEdit.ElementClassName := 'form-control';
    TitleEdit.ElementFont := efCSS;
    TitleEdit.ElementPosition := epIgnore;
    TitleEdit.HeightStyle := ssAuto;
    TitleEdit.HeightPercent := 100.000000000000000000;
    TitleEdit.TabOrder := 1;
    TitleEdit.Text := 'TitleEdit';
    TitleEdit.WidthStyle := ssAuto;
    TitleEdit.WidthPercent := 100.000000000000000000;
    TitleEdit.DataField := 'Title';
    TitleEdit.DataSource := MainDataSource;
    FirstNameEdit.SetParentComponent(Self);
    FirstNameEdit.Name := 'FirstNameEdit';
    FirstNameEdit.Left := 11;
    FirstNameEdit.Top := 24;
    FirstNameEdit.Width := 81;
    FirstNameEdit.Height := 15;
    FirstNameEdit.ChildOrder := 5;
    FirstNameEdit.ElementClassName := 'form-control';
    FirstNameEdit.ElementFont := efCSS;
    FirstNameEdit.ElementPosition := epIgnore;
    FirstNameEdit.HeightStyle := ssAuto;
    FirstNameEdit.HeightPercent := 100.000000000000000000;
    FirstNameEdit.TabOrder := 2;
    FirstNameEdit.Text := 'FirstNameEdit';
    FirstNameEdit.WidthStyle := ssAuto;
    FirstNameEdit.WidthPercent := 100.000000000000000000;
    FirstNameEdit.DataField := 'FirstName';
    FirstNameEdit.DataSource := MainDataSource;
    MiddleNameEdit.SetParentComponent(Self);
    MiddleNameEdit.Name := 'MiddleNameEdit';
    MiddleNameEdit.Left := 11;
    MiddleNameEdit.Top := 43;
    MiddleNameEdit.Width := 81;
    MiddleNameEdit.Height := 15;
    MiddleNameEdit.ChildOrder := 6;
    MiddleNameEdit.ElementClassName := 'form-control';
    MiddleNameEdit.ElementFont := efCSS;
    MiddleNameEdit.ElementPosition := epIgnore;
    MiddleNameEdit.HeightStyle := ssAuto;
    MiddleNameEdit.HeightPercent := 100.000000000000000000;
    MiddleNameEdit.TabOrder := 3;
    MiddleNameEdit.Text := 'MiddleNameEdit';
    MiddleNameEdit.WidthStyle := ssAuto;
    MiddleNameEdit.WidthPercent := 100.000000000000000000;
    MiddleNameEdit.DataField := 'MiddleName';
    MiddleNameEdit.DataSource := MainDataSource;
    LastNameEdit.SetParentComponent(Self);
    LastNameEdit.Name := 'LastNameEdit';
    LastNameEdit.Left := 11;
    LastNameEdit.Top := 61;
    LastNameEdit.Width := 81;
    LastNameEdit.Height := 15;
    LastNameEdit.ChildOrder := 7;
    LastNameEdit.ElementClassName := 'form-control';
    LastNameEdit.ElementFont := efCSS;
    LastNameEdit.ElementPosition := epIgnore;
    LastNameEdit.HeightStyle := ssAuto;
    LastNameEdit.HeightPercent := 100.000000000000000000;
    LastNameEdit.TabOrder := 4;
    LastNameEdit.Text := 'LastNameEdit';
    LastNameEdit.WidthStyle := ssAuto;
    LastNameEdit.WidthPercent := 100.000000000000000000;
    LastNameEdit.DataField := 'LastName';
    LastNameEdit.DataSource := MainDataSource;
    Add1Edit.SetParentComponent(Self);
    Add1Edit.Name := 'Add1Edit';
    Add1Edit.Left := 11;
    Add1Edit.Top := 80;
    Add1Edit.Width := 81;
    Add1Edit.Height := 15;
    Add1Edit.ChildOrder := 8;
    Add1Edit.ElementClassName := 'form-control';
    Add1Edit.ElementFont := efCSS;
    Add1Edit.ElementPosition := epIgnore;
    Add1Edit.HeightStyle := ssAuto;
    Add1Edit.HeightPercent := 100.000000000000000000;
    Add1Edit.TabOrder := 5;
    Add1Edit.Text := 'Add1Edit';
    Add1Edit.WidthStyle := ssAuto;
    Add1Edit.WidthPercent := 100.000000000000000000;
    Add1Edit.DataField := 'Add1';
    Add1Edit.DataSource := MainDataSource;
    Add2Edit.SetParentComponent(Self);
    Add2Edit.Name := 'Add2Edit';
    Add2Edit.Left := 11;
    Add2Edit.Top := 99;
    Add2Edit.Width := 81;
    Add2Edit.Height := 15;
    Add2Edit.ChildOrder := 8;
    Add2Edit.ElementClassName := 'form-control';
    Add2Edit.ElementFont := efCSS;
    Add2Edit.ElementPosition := epIgnore;
    Add2Edit.HeightStyle := ssAuto;
    Add2Edit.HeightPercent := 100.000000000000000000;
    Add2Edit.TabOrder := 6;
    Add2Edit.Text := 'Add2Edit';
    Add2Edit.WidthStyle := ssAuto;
    Add2Edit.WidthPercent := 100.000000000000000000;
    Add2Edit.DataField := 'Add2';
    Add2Edit.DataSource := MainDataSource;
    Add3Edit.SetParentComponent(Self);
    Add3Edit.Name := 'Add3Edit';
    Add3Edit.Left := 11;
    Add3Edit.Top := 117;
    Add3Edit.Width := 81;
    Add3Edit.Height := 15;
    Add3Edit.ChildOrder := 8;
    Add3Edit.ElementClassName := 'form-control';
    Add3Edit.ElementFont := efCSS;
    Add3Edit.ElementPosition := epIgnore;
    Add3Edit.HeightStyle := ssAuto;
    Add3Edit.HeightPercent := 100.000000000000000000;
    Add3Edit.TabOrder := 7;
    Add3Edit.Text := 'Add3Edit';
    Add3Edit.WidthStyle := ssAuto;
    Add3Edit.WidthPercent := 100.000000000000000000;
    Add3Edit.DataField := 'Add3';
    Add3Edit.DataSource := MainDataSource;
    DateAddedEdit.SetParentComponent(Self);
    DateAddedEdit.Name := 'DateAddedEdit';
    DateAddedEdit.Left := 5;
    DateAddedEdit.Top := 176;
    DateAddedEdit.Width := 113;
    DateAddedEdit.Height := 15;
    DateAddedEdit.ElementClassName := 'form-control';
    DateAddedEdit.HeightStyle := ssAuto;
    DateAddedEdit.WidthStyle := ssAuto;
    DateAddedEdit.BorderStyle := bsSingle;
    DateAddedEdit.ChildOrder := 12;
    DateAddedEdit.Color := clWhite;
    DateAddedEdit.Date := 45184.273425937500000000;
    DateAddedEdit.ElementFont := efCSS;
    DateAddedEdit.ElementPosition := epIgnore;
    DateAddedEdit.Role := '';
    DateAddedEdit.TabOrder := 12;
    DateAddedEdit.Text := '';
    SetEvent(DateAddedEdit, Self, 'OnChange', 'DateAddedEditChange');
    SetEvent(DateAddedEdit, Self, 'OnExit', 'DateAddedEditExit');
    DateAddedEdit.DataField := 'DonationDate';
    DateAddedEdit.DataSource := MainDataSource;
    PostCodeEdit.SetParentComponent(Self);
    PostCodeEdit.Name := 'PostCodeEdit';
    PostCodeEdit.Left := 11;
    PostCodeEdit.Top := 157;
    PostCodeEdit.Width := 81;
    PostCodeEdit.Height := 15;
    PostCodeEdit.CharCase := wecUpperCase;
    PostCodeEdit.ChildOrder := 13;
    PostCodeEdit.ElementClassName := 'form-control';
    PostCodeEdit.ElementFont := efCSS;
    PostCodeEdit.ElementPosition := epIgnore;
    PostCodeEdit.HeightStyle := ssAuto;
    PostCodeEdit.HeightPercent := 100.000000000000000000;
    PostCodeEdit.TabOrder := 9;
    PostCodeEdit.Text := 'PostCodeEdit';
    PostCodeEdit.WidthStyle := ssAuto;
    PostCodeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(PostCodeEdit, Self, 'OnExit', 'PostCodeEditExit');
    PostCodeEdit.DataField := 'PostCode';
    PostCodeEdit.DataSource := MainDataSource;
    AmountEdit.SetParentComponent(Self);
    AmountEdit.Name := 'AmountEdit';
    AmountEdit.Left := 11;
    AmountEdit.Top := 195;
    AmountEdit.Width := 81;
    AmountEdit.Height := 15;
    AmountEdit.ChildOrder := 14;
    AmountEdit.ElementClassName := 'form-control';
    AmountEdit.ElementFont := efCSS;
    AmountEdit.ElementPosition := epIgnore;
    AmountEdit.HeightStyle := ssAuto;
    AmountEdit.HeightPercent := 100.000000000000000000;
    AmountEdit.TabOrder := 11;
    AmountEdit.Text := 'AmountEdit';
    AmountEdit.WidthStyle := ssAuto;
    AmountEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AmountEdit, Self, 'OnKeyPress', 'AmountEditKeyPress');
    AmountEdit.DataField := 'Amount';
    AmountEdit.DataSource := MainDataSource;
    OverseasAddressEdit.SetParentComponent(Self);
    OverseasAddressEdit.Name := 'OverseasAddressEdit';
    OverseasAddressEdit.Left := 11;
    OverseasAddressEdit.Top := 213;
    OverseasAddressEdit.Width := 75;
    OverseasAddressEdit.Height := 15;
    OverseasAddressEdit.Caption := 'Overseas Address';
    OverseasAddressEdit.ChildOrder := 14;
    OverseasAddressEdit.ElementClassName := 'custom-control custom-checkbox';
    OverseasAddressEdit.ElementButtonClassName := 'custom-control-input';
    OverseasAddressEdit.ElementLabelClassName := 'custom-control-label';
    OverseasAddressEdit.ElementFont := efCSS;
    OverseasAddressEdit.ElementPosition := epIgnore;
    OverseasAddressEdit.HeightStyle := ssAuto;
    OverseasAddressEdit.HeightPercent := 100.000000000000000000;
    OverseasAddressEdit.TabOrder := 13;
    OverseasAddressEdit.WidthStyle := ssAuto;
    OverseasAddressEdit.WidthPercent := 100.000000000000000000;
    OverseasAddressEdit.DataField := 'OverseasAddress';
    OverseasAddressEdit.DataSource := MainDataSource;
    SponsoredEdit.SetParentComponent(Self);
    SponsoredEdit.Name := 'SponsoredEdit';
    SponsoredEdit.Left := 11;
    SponsoredEdit.Top := 232;
    SponsoredEdit.Width := 75;
    SponsoredEdit.Height := 15;
    SponsoredEdit.Caption := 'SponsoredEdit';
    SponsoredEdit.ChildOrder := 15;
    SponsoredEdit.ElementClassName := 'custom-control custom-checkbox';
    SponsoredEdit.ElementButtonClassName := 'custom-control-input';
    SponsoredEdit.ElementLabelClassName := 'custom-control-label';
    SponsoredEdit.ElementFont := efCSS;
    SponsoredEdit.ElementPosition := epIgnore;
    SponsoredEdit.HeightStyle := ssAuto;
    SponsoredEdit.HeightPercent := 100.000000000000000000;
    SponsoredEdit.TabOrder := 14;
    SponsoredEdit.WidthStyle := ssAuto;
    SponsoredEdit.WidthPercent := 100.000000000000000000;
    SponsoredEdit.DataField := 'Sponsored';
    SponsoredEdit.DataSource := MainDataSource;
    CityEdit.SetParentComponent(Self);
    CityEdit.Name := 'CityEdit';
    CityEdit.Left := 11;
    CityEdit.Top := 139;
    CityEdit.Width := 81;
    CityEdit.Height := 15;
    CityEdit.ChildOrder := 16;
    CityEdit.ElementClassName := 'form-control';
    CityEdit.ElementFont := efCSS;
    CityEdit.ElementPosition := epIgnore;
    CityEdit.HeightStyle := ssAuto;
    CityEdit.HeightPercent := 100.000000000000000000;
    CityEdit.TabOrder := 8;
    CityEdit.Text := 'CityEdit';
    CityEdit.WidthStyle := ssAuto;
    CityEdit.WidthPercent := 100.000000000000000000;
    CityEdit.DataField := 'City';
    CityEdit.DataSource := MainDataSource;
    MergeButton.SetParentComponent(Self);
    MergeButton.Name := 'MergeButton';
    MergeButton.Left := 221;
    MergeButton.Top := 387;
    MergeButton.Width := 64;
    MergeButton.Height := 16;
    MergeButton.Caption := 'Merge';
    MergeButton.ChildOrder := 23;
    MergeButton.HeightPercent := 100.000000000000000000;
    MergeButton.WidthPercent := 100.000000000000000000;
    SetEvent(MergeButton, Self, 'OnClick', 'MergeButtonClick');
    ClearButton.SetParentComponent(Self);
    ClearButton.Name := 'ClearButton';
    ClearButton.Left := 153;
    ClearButton.Top := 387;
    ClearButton.Width := 64;
    ClearButton.Height := 17;
    ClearButton.Caption := 'Clear';
    ClearButton.ChildOrder := 24;
    ClearButton.HeightPercent := 100.000000000000000000;
    ClearButton.WidthPercent := 100.000000000000000000;
    SetEvent(ClearButton, Self, 'OnClick', 'ClearButtonClick');
    MessageDialog.SetParentComponent(Self);
    MessageDialog.Name := 'MessageDialog';
    MessageDialog.Left := 347;
    MessageDialog.Top := 128;
    MessageDialog.Width := 16;
    MessageDialog.Height := 16;
    MessageDialog.Buttons := [];
    MessageDialog.Opacity := 0.200000000000000000;
    WebMessageDlg1.SetParentComponent(Self);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 325;
    WebMessageDlg1.Top := 53;
    WebMessageDlg1.Width := 16;
    WebMessageDlg1.Height := 16;
    WebMessageDlg1.HeightStyle := ssAuto;
    WebMessageDlg1.WidthStyle := ssAuto;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    MainDataset.AfterApplyUpdates := MainDatasetAfterApplyUpdates;
    MainDataset.EntitySetName := 'ImportDonation';
    MainDataset.Left := 149;
    MainDataset.Top := 59;
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetTitle.SetParentComponent(MainDataset);
    MainDatasetTitle.Name := 'MainDatasetTitle';
    MainDatasetTitle.FieldName := 'Title';
    MainDatasetTitle.Size := 25;
    MainDatasetFirstName.SetParentComponent(MainDataset);
    MainDatasetFirstName.Name := 'MainDatasetFirstName';
    MainDatasetFirstName.FieldName := 'FirstName';
    MainDatasetFirstName.Size := 50;
    MainDatasetMiddleName.SetParentComponent(MainDataset);
    MainDatasetMiddleName.Name := 'MainDatasetMiddleName';
    MainDatasetMiddleName.FieldName := 'MiddleName';
    MainDatasetMiddleName.Size := 50;
    MainDatasetLastName.SetParentComponent(MainDataset);
    MainDatasetLastName.Name := 'MainDatasetLastName';
    MainDatasetLastName.FieldName := 'LastName';
    MainDatasetLastName.Size := 50;
    MainDatasetSuffix.SetParentComponent(MainDataset);
    MainDatasetSuffix.Name := 'MainDatasetSuffix';
    MainDatasetSuffix.FieldName := 'Suffix';
    MainDatasetSuffix.Size := 50;
    MainDatasetAdd1.SetParentComponent(MainDataset);
    MainDatasetAdd1.Name := 'MainDatasetAdd1';
    MainDatasetAdd1.FieldName := 'Add1';
    MainDatasetAdd1.Size := 75;
    MainDatasetAdd2.SetParentComponent(MainDataset);
    MainDatasetAdd2.Name := 'MainDatasetAdd2';
    MainDatasetAdd2.FieldName := 'Add2';
    MainDatasetAdd2.Size := 75;
    MainDatasetAdd3.SetParentComponent(MainDataset);
    MainDatasetAdd3.Name := 'MainDatasetAdd3';
    MainDatasetAdd3.FieldName := 'Add3';
    MainDatasetAdd3.Size := 75;
    MainDatasetCity.SetParentComponent(MainDataset);
    MainDatasetCity.Name := 'MainDatasetCity';
    MainDatasetCity.FieldName := 'City';
    MainDatasetCity.Size := 75;
    MainDatasetPostCode.SetParentComponent(MainDataset);
    MainDatasetPostCode.Name := 'MainDatasetPostCode';
    MainDatasetPostCode.FieldName := 'PostCode';
    MainDatasetPostCode.Size := 15;
    MainDatasetEmail.SetParentComponent(MainDataset);
    MainDatasetEmail.Name := 'MainDatasetEmail';
    MainDatasetEmail.FieldName := 'Email';
    MainDatasetEmail.Size := 75;
    MainDatasetMobile.SetParentComponent(MainDataset);
    MainDatasetMobile.Name := 'MainDatasetMobile';
    MainDatasetMobile.FieldName := 'Mobile';
    MainDatasetMobile.Size := 25;
    MainDatasetTelephone.SetParentComponent(MainDataset);
    MainDatasetTelephone.Name := 'MainDatasetTelephone';
    MainDatasetTelephone.FieldName := 'Telephone';
    MainDatasetTelephone.Size := 25;
    MainDatasetMatchHash.SetParentComponent(MainDataset);
    MainDatasetMatchHash.Name := 'MainDatasetMatchHash';
    MainDatasetMatchHash.FieldName := 'MatchHash';
    MainDatasetMatchHash.Size := 64;
    MainDatasetContactByPhone.SetParentComponent(MainDataset);
    MainDatasetContactByPhone.Name := 'MainDatasetContactByPhone';
    MainDatasetContactByPhone.FieldName := 'ContactByPhone';
    MainDatasetContactByPhone.Required := True;
    MainDatasetContactByPost.SetParentComponent(MainDataset);
    MainDatasetContactByPost.Name := 'MainDatasetContactByPost';
    MainDatasetContactByPost.FieldName := 'ContactByPost';
    MainDatasetContactByPost.Required := True;
    MainDatasetContactByText.SetParentComponent(MainDataset);
    MainDatasetContactByText.Name := 'MainDatasetContactByText';
    MainDatasetContactByText.FieldName := 'ContactByText';
    MainDatasetContactByText.Required := True;
    MainDatasetContactByEmail.SetParentComponent(MainDataset);
    MainDatasetContactByEmail.Name := 'MainDatasetContactByEmail';
    MainDatasetContactByEmail.FieldName := 'ContactByEmail';
    MainDatasetContactByEmail.Required := True;
    MainDatasetDOB.SetParentComponent(MainDataset);
    MainDatasetDOB.Name := 'MainDatasetDOB';
    MainDatasetDOB.FieldName := 'DOB';
    MainDatasetHearingStatus.SetParentComponent(MainDataset);
    MainDatasetHearingStatus.Name := 'MainDatasetHearingStatus';
    MainDatasetHearingStatus.FieldName := 'HearingStatus';
    MainDatasetHearingStatus.Required := True;
    MainDatasetHearingStatus.Size := 8;
    MainDatasetEmailStatus.SetParentComponent(MainDataset);
    MainDatasetEmailStatus.Name := 'MainDatasetEmailStatus';
    MainDatasetEmailStatus.FieldName := 'EmailStatus';
    MainDatasetEmailStatus.Required := True;
    MainDatasetEmailStatus.Size := 10;
    MainDatasetLatitude.SetParentComponent(MainDataset);
    MainDatasetLatitude.Name := 'MainDatasetLatitude';
    MainDatasetLatitude.FieldName := 'Latitude';
    MainDatasetLongitude.SetParentComponent(MainDataset);
    MainDatasetLongitude.Name := 'MainDatasetLongitude';
    MainDatasetLongitude.FieldName := 'Longitude';
    MainDatasetExternalId.SetParentComponent(MainDataset);
    MainDatasetExternalId.Name := 'MainDatasetExternalId';
    MainDatasetExternalId.FieldName := 'ExternalId';
    MainDatasetExternalId.Size := 64;
    MainDatasetCountryCode.SetParentComponent(MainDataset);
    MainDatasetCountryCode.Name := 'MainDatasetCountryCode';
    MainDatasetCountryCode.FieldName := 'CountryCode';
    MainDatasetCountryCode.Size := 3;
    MainDatasetVisualStatus.SetParentComponent(MainDataset);
    MainDatasetVisualStatus.Name := 'MainDatasetVisualStatus';
    MainDatasetVisualStatus.FieldName := 'VisualStatus';
    MainDatasetVisualStatus.Required := True;
    MainDatasetVisualStatus.Size := 8;
    MainDatasetMobilityStatus.SetParentComponent(MainDataset);
    MainDatasetMobilityStatus.Name := 'MainDatasetMobilityStatus';
    MainDatasetMobilityStatus.FieldName := 'MobilityStatus';
    MainDatasetMobilityStatus.Required := True;
    MainDatasetMobilityStatus.Size := 8;
    MainDatasetSourceType.SetParentComponent(MainDataset);
    MainDatasetSourceType.Name := 'MainDatasetSourceType';
    MainDatasetSourceType.FieldName := 'SourceType';
    MainDatasetSourceType.Size := 9;
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    MainDatasetClaimStatus.Required := True;
    MainDatasetClaimStatus.Size := 19;
    MainDatasetCharityTenant.SetParentComponent(MainDataset);
    MainDatasetCharityTenant.Name := 'MainDatasetCharityTenant';
    MainDatasetCharityTenant.FieldName := 'CharityTenant';
    MainDatasetImportState.SetParentComponent(MainDataset);
    MainDatasetImportState.Name := 'MainDatasetImportState';
    MainDatasetImportState.FieldName := 'ImportState';
    MainDatasetImportState.Required := True;
    MainDatasetImportState.Size := 5;
    MainDatasetErrorText.SetParentComponent(MainDataset);
    MainDatasetErrorText.Name := 'MainDatasetErrorText';
    MainDatasetErrorText.FieldName := 'ErrorText';
    MainDatasetErrorText.Size := 1000;
    MainDatasetImportBatchId.SetParentComponent(MainDataset);
    MainDatasetImportBatchId.Name := 'MainDatasetImportBatchId';
    MainDatasetImportBatchId.FieldName := 'ImportBatchId';
    MainDatasetImportBatchId.Required := True;
    MainDatasetSponsored.SetParentComponent(MainDataset);
    MainDatasetSponsored.Name := 'MainDatasetSponsored';
    MainDatasetSponsored.FieldName := 'Sponsored';
    MainDatasetSponsored.Required := True;
    MainDatasetDonationDate.SetParentComponent(MainDataset);
    MainDatasetDonationDate.Name := 'MainDatasetDonationDate';
    MainDatasetDonationDate.FieldName := 'DonationDate';
    MainDatasetDonationDate.Required := True;
    MainDatasetGiftAid.SetParentComponent(MainDataset);
    MainDatasetGiftAid.Name := 'MainDatasetGiftAid';
    MainDatasetGiftAid.FieldName := 'GiftAid';
    MainDatasetGiftAid.Required := True;
    MainDatasetAmount.SetParentComponent(MainDataset);
    MainDatasetAmount.Name := 'MainDatasetAmount';
    MainDatasetAmount.FieldName := 'Amount';
    MainDatasetAmount.Required := True;
    MainDatasetExternalDonationId.SetParentComponent(MainDataset);
    MainDatasetExternalDonationId.Name := 'MainDatasetExternalDonationId';
    MainDatasetExternalDonationId.FieldName := 'ExternalDonationId';
    MainDatasetExternalDonationId.Size := 255;
    MainDatasetMatchedPerson.SetParentComponent(MainDataset);
    MainDatasetMatchedPerson.Name := 'MainDatasetMatchedPerson';
    MainDatasetMatchedPerson.FieldName := 'MatchedPerson';
    MainDataSource.Left := 208;
    MainDataSource.Top := 59;
    WebElementActionList1.Left := 165;
    WebElementActionList1.Top := 176;
    ListCloseTimer.Left := 299;
    ListCloseTimer.Top := 171;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    RefreshTimer.Interval := 500;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 149;
    RefreshTimer.Top := 11;
    WaitForBatchIdTimer.SetParentComponent(Self);
    WaitForBatchIdTimer.Name := 'WaitForBatchIdTimer';
    WaitForBatchIdTimer.Enabled := False;
    SetEvent(WaitForBatchIdTimer, Self, 'OnTimer', 'WaitForBatchIdTimerTimer');
    WaitForBatchIdTimer.Left := 229;
    WaitForBatchIdTimer.Top := 11;
    WaitForBatchDataTimer.SetParentComponent(Self);
    WaitForBatchDataTimer.Name := 'WaitForBatchDataTimer';
    WaitForBatchDataTimer.Enabled := False;
    SetEvent(WaitForBatchDataTimer, Self, 'OnTimer', 'WaitForBatchDataTimerTimer');
    WaitForBatchDataTimer.Left := 320;
    WaitForBatchDataTimer.Top := 16;
    NextRecordTimer.SetParentComponent(Self);
    NextRecordTimer.Name := 'NextRecordTimer';
    NextRecordTimer.Enabled := False;
    NextRecordTimer.Interval := 500;
    SetEvent(NextRecordTimer, Self, 'OnTimer', 'NextRecordTimerTimer');
    NextRecordTimer.Left := 320;
    NextRecordTimer.Top := 75;
  finally
    MessageLabel.AfterLoadDFMValues;
    DonationsData.AfterLoadDFMValues;
    cbPageSize.AfterLoadDFMValues;
    edSearch.AfterLoadDFMValues;
    lcPaginator.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    AddButton.AfterLoadDFMValues;
    SearchButton.AfterLoadDFMValues;
    ListWaitMessage.AfterLoadDFMValues;
    TitleEdit.AfterLoadDFMValues;
    FirstNameEdit.AfterLoadDFMValues;
    MiddleNameEdit.AfterLoadDFMValues;
    LastNameEdit.AfterLoadDFMValues;
    Add1Edit.AfterLoadDFMValues;
    Add2Edit.AfterLoadDFMValues;
    Add3Edit.AfterLoadDFMValues;
    DateAddedEdit.AfterLoadDFMValues;
    PostCodeEdit.AfterLoadDFMValues;
    AmountEdit.AfterLoadDFMValues;
    OverseasAddressEdit.AfterLoadDFMValues;
    SponsoredEdit.AfterLoadDFMValues;
    CityEdit.AfterLoadDFMValues;
    MergeButton.AfterLoadDFMValues;
    ClearButton.AfterLoadDFMValues;
    MessageDialog.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetTitle.AfterLoadDFMValues;
    MainDatasetFirstName.AfterLoadDFMValues;
    MainDatasetMiddleName.AfterLoadDFMValues;
    MainDatasetLastName.AfterLoadDFMValues;
    MainDatasetSuffix.AfterLoadDFMValues;
    MainDatasetAdd1.AfterLoadDFMValues;
    MainDatasetAdd2.AfterLoadDFMValues;
    MainDatasetAdd3.AfterLoadDFMValues;
    MainDatasetCity.AfterLoadDFMValues;
    MainDatasetPostCode.AfterLoadDFMValues;
    MainDatasetEmail.AfterLoadDFMValues;
    MainDatasetMobile.AfterLoadDFMValues;
    MainDatasetTelephone.AfterLoadDFMValues;
    MainDatasetMatchHash.AfterLoadDFMValues;
    MainDatasetContactByPhone.AfterLoadDFMValues;
    MainDatasetContactByPost.AfterLoadDFMValues;
    MainDatasetContactByText.AfterLoadDFMValues;
    MainDatasetContactByEmail.AfterLoadDFMValues;
    MainDatasetDOB.AfterLoadDFMValues;
    MainDatasetHearingStatus.AfterLoadDFMValues;
    MainDatasetEmailStatus.AfterLoadDFMValues;
    MainDatasetLatitude.AfterLoadDFMValues;
    MainDatasetLongitude.AfterLoadDFMValues;
    MainDatasetExternalId.AfterLoadDFMValues;
    MainDatasetCountryCode.AfterLoadDFMValues;
    MainDatasetVisualStatus.AfterLoadDFMValues;
    MainDatasetMobilityStatus.AfterLoadDFMValues;
    MainDatasetSourceType.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetCharityTenant.AfterLoadDFMValues;
    MainDatasetImportState.AfterLoadDFMValues;
    MainDatasetErrorText.AfterLoadDFMValues;
    MainDatasetImportBatchId.AfterLoadDFMValues;
    MainDatasetSponsored.AfterLoadDFMValues;
    MainDatasetDonationDate.AfterLoadDFMValues;
    MainDatasetGiftAid.AfterLoadDFMValues;
    MainDatasetAmount.AfterLoadDFMValues;
    MainDatasetExternalDonationId.AfterLoadDFMValues;
    MainDatasetMatchedPerson.AfterLoadDFMValues;
    MainDataSource.AfterLoadDFMValues;
    WebElementActionList1.AfterLoadDFMValues;
    ListCloseTimer.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
    WaitForBatchIdTimer.AfterLoadDFMValues;
    WaitForBatchDataTimer.AfterLoadDFMValues;
    NextRecordTimer.AfterLoadDFMValues;
  end;
end;

end.
