unit ImportMappings;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, DB, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.DBCtrls, Vcl.Controls, WEBLib.Grids, WEBLib.ExtCtrls,
  XData.Web.JsonDataset, XData.Web.Dataset, Data.DB;

type
  TMappingsForm = class(TCoreWebForm)
    ImportMapping: TXDataWebDataSet;
    ImportMappingId: TIntegerField;
    ImportMappingDateAdded: TDateTimeField;
    ImportMappingAddedBy: TIntegerField;
    ImportMappingLastUpdatedBy: TIntegerField;
    ImportMappingLastUpdated: TDateTimeField;
    ImportMappingName: TStringField;
    ImportMappingMapping: TStringField;
    ImportMappingHasTitles: TBooleanField;
    EditTimer: TTimer;
    WebPanel1: TPanel;
    ImportDataTable: TTableControl;
    FormCaption: TLabel;
    MappingNameEdit: TDBEdit;
    DataSource: TDataSource;
    SaveButton: TButton;
    CloseButton: TButton;
    CloseTimer: TTimer;
    procedure CloseButtonClick(Sender: TObject);
    procedure CloseTimerTimer(Sender: TObject);
    procedure EditTimerTimer(Sender: TObject);
    procedure ImportMappingAfterPost(DataSet: TDataSet);
    procedure MappingNameEditChange(Sender: TObject);
    procedure SaveButtonClick(Sender: TObject);
  private
    { Private declarations }
    fImportMapping: TDataSet;
    FImportRowData: TStringList;
    FJSONImportData: string;
    FDelimeter: char;
    FHasHeadings: boolean;
    fMappingName: string;
    [async]
    FJSONFileData: string;
    procedure SetJSONFileData(JSONFileData: string);
    procedure ParseJSONImportData;
    procedure AddDataToGrid(AColumnCount: integer; ARowCount: integer; ADataRow: string);
    procedure ConfigureGrid(HeaderData: string; AColumnCount: integer);
    procedure CreateComboBoxes(AColumnCount: integer);
    procedure UpdateComboBoxs;
    procedure SaveMapping;
    function GetJSONMappingData: string;
    procedure SetDataset(Value: TDataSet);
  public
    { Public declarations }
    property JSONFileData: string read FJSONFileData write SetJSONFileData;
    property Delimeter: char read FDelimeter write FDelimeter;
    property MappingName: string read FMappingName write FMappingName;
  protected procedure LoadDFMValues; override; end;

var
  MappingsForm: TMappingsForm;

implementation

{$R *.dfm}


uses
  XData.Web.Client, SMX.Web.Layout.Utils, MainDataModule;

const
  ComboFieldItems =
  'Title, Firstname,Lastname,Add1,Add2,Add3,City,County,' +
  'PostCode,Country,Date,Amount,ExternalId,External_DonationId';



procedure TMappingsForm.ConfigureGrid(HeaderData: string; AColumnCount: integer);
var
  ColIdx, RowIdx: integer;
begin
  FImportRowData := TStringList.Create;
  FImportRowData.Delimiter := ',';
  FImportRowData.DelimitedText := HeaderData;
  ImportDataTable.ColCount := AColumnCount;
  ImportDataTable.RowCount := 7;
end;

procedure TMappingsForm.CreateComboBoxes(AColumnCount: integer);
var
  DataComboBox: TComboBox;
  Idx: integer;
  ComboNameId: string;
begin
  for Idx := 0  to AColumnCount-1 do
  begin
    DataComboBox := TComboBox.Create(Self);
    ComboNameId := Format('WebCombo%0.2d', [Idx]);
    DataComboBox.Parent := WebPanel1;
    DataComboBox.ElementID := ComboNameId;
    DataComboBox.Name := ComboNameId;
    DataComboBox.ElementClassName := 'form-select';
    DataComboBox.ElementPosition := epIgnore;
    DataComboBox.HeightStyle := ssAuto;
    DataComboBox.WidthStyle := ssAuto;
    FImportRowData.Objects[Idx] := DataComboBox;

    DataComboBox.Items.DelimitedText := ComboFieldItems;
    ImportDataTable.Cells[Idx, 1] := '';
    ImportDataTable.CellElements[Idx,1].appendChild(DataComboBox.ElementHandle);
  end;
end;

procedure TMappingsForm.ParseJSONImportData;
var
  JSImportData: TJSObject;
  JSRowList: TJSArray;
  ARowData: TStringList;
  FileName: string;
  DataRow: string;
  Idx: integer;
  RowCount: integer;
  RowIdx: integer;
  ColumnCount: integer;
begin
  JSImportData := TJSJSON.parseObject(FJSONImportData);

  FileName := String(JSImportData['OriginalFileName']);
  ColumnCount := Integer(JSImportData['ColCount']);
  FHasHeadings := Boolean(JSImportData['HasHeadings']);
  try
    JSRowList := TJSArray(JSImportData['Data']);
    RowCount := JSRowList.Length;
  except

  end;

  for Idx := 0 to RowCount-1 do
  begin
    DataRow := string(JSRowList[Idx]);
    if Idx < 1 then
    begin
      ConfigureGrid(DataRow, ColumnCount);
      RowIdx := 0;
      AddDataToGrid(ColumnCount, 0, DataRow);
      CreateComboBoxes(ColumnCount);
    end
    else
    begin
      RowIdx := Idx+1;
      AddDataToGrid(ColumnCount, RowIdx, DataRow);
    end;
  end;
end;

procedure TMappingsForm.AddDataToGrid(AColumnCount: integer; ARowCount: integer; ADataRow: string);
var
  nCol, nRow: integer;
  DataRowSL: TStringList;
  DataValue: string;
begin
  nRow := ARowCount;
  DataRowSL := TStringList.Create;
  try
    try
      DataRowSL.Delimiter := ',';
      DataRowSL.StrictDelimiter := true;
      DataRowSL.DelimitedText := ADataRow;

      for nCol := 0 to AColumnCount-1 do
      begin
        DataValue := DataRowSL[nCol];
        ImportDataTable.Cells[nCol, nRow] := DataValue;
      end;
    except

    end;
  finally
    DataRowSL.Free;
  end;
end;

procedure TMappingsForm.CloseButtonClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrClose;
end;

procedure TMappingsForm.CloseTimerTimer(Sender: TObject);
begin
  //inherited;
  CloseTimer.Enabled := False;
  ModalResult := mrOK;
end;

procedure TMappingsForm.EditTimerTimer(Sender: TObject);
begin
  inherited;
  ImportMapping.Insert;
  EditTimer.Enabled := false;
end;

procedure TMappingsForm.SaveButtonClick(Sender: TObject);
begin
  inherited;
  SaveMapping;
  SaveButton.Enabled := false;
  CloseTimer.Enabled := true;
end;

function TMappingsForm.GetJSONMappingData: string;
var
  Idx: integer;
  sFieldName: string;
  WC_Object: TJSObject;
  MyString: string;
  JSONData: string;
  MapData: string;
  FieldCombo: TComboBox;
  FieldTitle: string;
  lLen: integer;

  sl: TStringlist;
begin
  JSONData := '{ ';
  for Idx := 0 to FImportRowData.Count-1 do
  begin
    FieldCombo := TComboBox(FImportRowData.Objects[Idx]);

    if assigned(FieldCombo) then
    begin
      FieldTitle := FieldCombo.Text;

      if FieldTitle <> '' then
      begin
        MapData :=  Format('"%s":%d,',[FieldTitle, Idx]);
        JSONData := JSONData + MapData;
      end;
    end;
  end;

  lLen := Length(JSONData);
  JSONData[lLen] := ' ';
  JSONData := JSONData + '}';
  result := JSONData;
end;

procedure TMappingsForm.ImportMappingAfterPost(DataSet: TDataSet);
begin
  inherited;
  ImportMapping.ApplyUpdates;
end;

procedure TMappingsForm.MappingNameEditChange(Sender: TObject);
begin
  inherited;
  FMappingName := MappingNameEdit.Text;

  //if FMappingName <> '' then
    SaveButton.Enabled := FMappingName <> '';
end;

procedure TMappingsForm.SetDataset(Value: TDataSet);
begin
  DataSource.DataSet := Value;
end;

procedure TMappingsForm.SetJSONFileData(JSONFileData: string);
begin
  FJSONImportData := JSONFileData;
  ParseJSONImportData;
  ImportMapping.Load;
  EditTimer.Enabled := true;
end;

procedure TMappingsForm.UpdateComboBoxs;
begin

end;

procedure TMappingsForm.SaveMapping;
var
  MappingData: string;
begin
  MappingData := GetJSONMappingData;

  if MappingData <> '' then
  begin
    ImportMapping.FieldByName('Mapping').AsString := MappingData;
    ImportMapping.FieldByName('HasTitles').AsBoolean := FHasHeadings;
    ImportMapping.Post;
  end;
end;


procedure TMappingsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FormCaption := TLabel.Create('FormCaption');
  WebPanel1 := TPanel.Create(Self);
  ImportDataTable := TTableControl.Create('MappingTable');
  MappingNameEdit := TDBEdit.Create('MappingNameEdit');
  SaveButton := TButton.Create('SaveButton');
  CloseButton := TButton.Create('CloseButton');
  ImportMapping := TXDataWebDataSet.Create(Self);
  ImportMappingId := TIntegerField.Create(Self);
  ImportMappingDateAdded := TDateTimeField.Create(Self);
  ImportMappingAddedBy := TIntegerField.Create(Self);
  ImportMappingLastUpdatedBy := TIntegerField.Create(Self);
  ImportMappingLastUpdated := TDateTimeField.Create(Self);
  ImportMappingName := TStringField.Create(Self);
  ImportMappingMapping := TStringField.Create(Self);
  ImportMappingHasTitles := TBooleanField.Create(Self);
  EditTimer := TTimer.Create(Self);
  DataSource := TDataSource.Create(Self);
  CloseTimer := TTimer.Create(Self);

  FormCaption.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  ImportDataTable.BeforeLoadDFMValues;
  MappingNameEdit.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  ImportMapping.BeforeLoadDFMValues;
  ImportMappingId.BeforeLoadDFMValues;
  ImportMappingDateAdded.BeforeLoadDFMValues;
  ImportMappingAddedBy.BeforeLoadDFMValues;
  ImportMappingLastUpdatedBy.BeforeLoadDFMValues;
  ImportMappingLastUpdated.BeforeLoadDFMValues;
  ImportMappingName.BeforeLoadDFMValues;
  ImportMappingMapping.BeforeLoadDFMValues;
  ImportMappingHasTitles.BeforeLoadDFMValues;
  EditTimer.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  try
    Width := 626;
    Height := 407;
    FormCaption.SetParentComponent(Self);
    FormCaption.Name := 'FormCaption';
    FormCaption.Left := 48;
    FormCaption.Top := 7;
    FormCaption.Width := 61;
    FormCaption.Height := 10;
    FormCaption.Margins.Left := 3;
    FormCaption.Margins.Top := 3;
    FormCaption.Margins.Right := 3;
    FormCaption.Margins.Bottom := 3;
    FormCaption.Caption := 'Import Mappings';
    FormCaption.ElementClassName := 'HeadlineClass';
    FormCaption.ElementFont := efCSS;
    FormCaption.ElementPosition := epIgnore;
    FormCaption.HeightStyle := ssAuto;
    FormCaption.HeightPercent := 100.000000000000000000;
    FormCaption.HTMLType := tDIV;
    FormCaption.WidthStyle := ssAuto;
    FormCaption.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 36;
    WebPanel1.Top := 223;
    WebPanel1.Width := 374;
    WebPanel1.Height := 25;
    WebPanel1.HeightStyle := ssAuto;
    WebPanel1.WidthStyle := ssAuto;
    WebPanel1.Caption := 'WebPanel1';
    WebPanel1.ChildOrder := 20;
    WebPanel1.ElementFont := efCSS;
    WebPanel1.ElementPosition := epIgnore;
    WebPanel1.Visible := False;
    ImportDataTable.SetParentComponent(Self);
    ImportDataTable.Name := 'ImportDataTable';
    ImportDataTable.Left := 36;
    ImportDataTable.Top := 77;
    ImportDataTable.Width := 374;
    ImportDataTable.Height := 133;
    ImportDataTable.HeightStyle := ssAuto;
    ImportDataTable.WidthStyle := ssAuto;
    ImportDataTable.BorderColor := clSilver;
    ImportDataTable.ChildOrder := 13;
    ImportDataTable.ElementFont := efCSS;
    ImportDataTable.ElementPosition := epIgnore;
    ImportDataTable.RowCount := 5;
    MappingNameEdit.SetParentComponent(Self);
    MappingNameEdit.Name := 'MappingNameEdit';
    MappingNameEdit.Left := 267;
    MappingNameEdit.Top := 47;
    MappingNameEdit.Width := 143;
    MappingNameEdit.Height := 15;
    MappingNameEdit.ChildOrder := 11;
    MappingNameEdit.ElementClassName := 'form-control';
    MappingNameEdit.ElementFont := efCSS;
    MappingNameEdit.ElementPosition := epIgnore;
    MappingNameEdit.HeightStyle := ssAuto;
    MappingNameEdit.HeightPercent := 100.000000000000000000;
    MappingNameEdit.Text := 'MappingNameEdit';
    MappingNameEdit.WidthStyle := ssAuto;
    MappingNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MappingNameEdit, Self, 'OnChange', 'MappingNameEditChange');
    MappingNameEdit.DataField := 'Name';
    MappingNameEdit.DataSource := DataSource;
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 277;
    SaveButton.Top := 299;
    SaveButton.Width := 64;
    SaveButton.Height := 17;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 5;
    SaveButton.ElementClassName := 'btn btn-secondary';
    SaveButton.ElementPosition := epIgnore;
    SaveButton.Enabled := False;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthStyle := ssAuto;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 346;
    CloseButton.Top := 299;
    CloseButton.Width := 64;
    CloseButton.Height := 17;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 6;
    CloseButton.ElementClassName := 'btn btn-secondary';
    CloseButton.ElementPosition := epIgnore;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthStyle := ssAuto;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    ImportMapping.SetParentComponent(Self);
    ImportMapping.Name := 'ImportMapping';
    ImportMapping.AfterPost := ImportMappingAfterPost;
    ImportMapping.EntitySetName := 'ImportMappings';
    ImportMapping.Connection := MainData.DataConnection;
    ImportMapping.Left := 32;
    ImportMapping.Top := 301;
    ImportMappingId.SetParentComponent(ImportMapping);
    ImportMappingId.Name := 'ImportMappingId';
    ImportMappingId.FieldName := 'Id';
    ImportMappingId.Required := True;
    ImportMappingDateAdded.SetParentComponent(ImportMapping);
    ImportMappingDateAdded.Name := 'ImportMappingDateAdded';
    ImportMappingDateAdded.FieldName := 'DateAdded';
    ImportMappingDateAdded.Required := True;
    ImportMappingAddedBy.SetParentComponent(ImportMapping);
    ImportMappingAddedBy.Name := 'ImportMappingAddedBy';
    ImportMappingAddedBy.FieldName := 'AddedBy';
    ImportMappingAddedBy.Required := True;
    ImportMappingLastUpdatedBy.SetParentComponent(ImportMapping);
    ImportMappingLastUpdatedBy.Name := 'ImportMappingLastUpdatedBy';
    ImportMappingLastUpdatedBy.FieldName := 'LastUpdatedBy';
    ImportMappingLastUpdated.SetParentComponent(ImportMapping);
    ImportMappingLastUpdated.Name := 'ImportMappingLastUpdated';
    ImportMappingLastUpdated.FieldName := 'LastUpdated';
    ImportMappingName.SetParentComponent(ImportMapping);
    ImportMappingName.Name := 'ImportMappingName';
    ImportMappingName.FieldName := 'Name';
    ImportMappingName.Size := 255;
    ImportMappingMapping.SetParentComponent(ImportMapping);
    ImportMappingMapping.Name := 'ImportMappingMapping';
    ImportMappingMapping.FieldName := 'Mapping';
    ImportMappingMapping.Size := 4096;
    ImportMappingHasTitles.SetParentComponent(ImportMapping);
    ImportMappingHasTitles.Name := 'ImportMappingHasTitles';
    ImportMappingHasTitles.FieldName := 'HasTitles';
    ImportMappingHasTitles.Required := True;
    EditTimer.SetParentComponent(Self);
    EditTimer.Name := 'EditTimer';
    EditTimer.Enabled := False;
    SetEvent(EditTimer, Self, 'OnTimer', 'EditTimerTimer');
    EditTimer.Left := 304;
    EditTimer.Top := 256;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := ImportMapping;
    DataSource.Left := 187;
    DataSource.Top := 27;
    CloseTimer.SetParentComponent(Self);
    CloseTimer.Name := 'CloseTimer';
    CloseTimer.Enabled := False;
    CloseTimer.Interval := 1500;
    SetEvent(CloseTimer, Self, 'OnTimer', 'CloseTimerTimer');
    CloseTimer.Left := 48;
    CloseTimer.Top := 251;
  finally
    FormCaption.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    ImportDataTable.AfterLoadDFMValues;
    MappingNameEdit.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    ImportMapping.AfterLoadDFMValues;
    ImportMappingId.AfterLoadDFMValues;
    ImportMappingDateAdded.AfterLoadDFMValues;
    ImportMappingAddedBy.AfterLoadDFMValues;
    ImportMappingLastUpdatedBy.AfterLoadDFMValues;
    ImportMappingLastUpdated.AfterLoadDFMValues;
    ImportMappingName.AfterLoadDFMValues;
    ImportMappingMapping.AfterLoadDFMValues;
    ImportMappingHasTitles.AfterLoadDFMValues;
    EditTimer.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
  end;
end;

end.