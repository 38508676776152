unit JobDetailForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Lists,
  WEBLib.WebCtrls,
  WEBLib.Toast,
  WebForm.Core,
  JobDetailBaseForm;

type
  TJobDetail = class(TJobDetailBase)
    JobStats: THTMLSpan;
    JobInfo: THTMLDiv;
  private
    { Private declarations }
  protected
    procedure LoadStats(AData: JS.TJSObject); override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  JobDetail: TJobDetail;

implementation

uses
  XData.Web.Client,
  MainForm,
  MainDataModule,
  SMX.Web.Utils,
  Auth.Service,
  SMX.Shared,
  JobErrorForm,
  SMX.Web.Service.Consts;

{$R *.dfm}

const
  STATS_TPL =
    '<div class="statsitem"><span class="text-secondary">%s : </span><span class="text-primary text-right">%s</span></div>';

  { TJobDetail }

procedure TJobDetail.LoadStats(AData: JS.TJSObject);
var
  jItem: JS.TJSObject;
  aJS: JS.TJSArray;
  I: Integer;
  lHasData: Boolean;
  lVal, lStatus, lKey, lValue: string;
begin
  lHasData := False;
  lStatus := DataSource.Dataset.FieldByName('Status').AsString;
  if (lStatus = 'Complete') or (lStatus = 'Processed') or (lStatus = 'Preview') then
  begin
    JobStats.HTML.Add(Format(STATS_TPL, ['Time taken',
      TUtils.SecondsAsTime(DataSource.Dataset.FieldByName('TimeTaken').AsLargeInt)]));
    lHasData := True;
  end;

  aJS := JS.toArray(AData['value']);
  if (aJS = nil) or (aJS.Length = 0) then
  begin
    JobInfo.Visible := lHasData;
    Exit;
  end;

  for I := 0 to aJS.Length - 1 do
  begin
    jItem := JS.toObject(aJS[I]);
    lVal := JS.toString(jItem['value']);
    JobStats.HTML.Add(Format(STATS_TPL, [JS.toString(jItem['caption']), lVal]));
  end;
end;

procedure TJobDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  JobInfo := THTMLDiv.Create('JobInfo');
  JobStats := THTMLSpan.Create('JobStats');

  DownloadFiles.BeforeLoadDFMValues;
  FileList.BeforeLoadDFMValues;
  JobInfo.BeforeLoadDFMValues;
  JobStats.BeforeLoadDFMValues;
  try
    ElementClassName := 'PopUpForm';
    FileList.Items.Clear;
    with FileList.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 0';
    end;
    with FileList.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 1';
    end;
    with FileList.Items.Add do
    begin
      ItemClassName := 'list-group-item';
      LinkClassName := 'list-group-link';
      Text := 'Item 2';
    end;
    JobInfo.SetParentComponent(Self);
    JobInfo.Name := 'JobInfo';
    JobInfo.Left := 203;
    JobInfo.Top := 191;
    JobInfo.Width := 113;
    JobInfo.Height := 96;
    JobInfo.HeightStyle := ssAuto;
    JobInfo.WidthStyle := ssAuto;
    JobInfo.ChildOrder := 19;
    JobInfo.ElementPosition := epIgnore;
    JobInfo.ElementFont := efCSS;
    JobInfo.Role := '';
    JobStats.SetParentComponent(JobInfo);
    JobStats.Name := 'JobStats';
    JobStats.Left := 6;
    JobStats.Top := 29;
    JobStats.Width := 96;
    JobStats.Height := 40;
    JobStats.HeightStyle := ssAuto;
    JobStats.WidthStyle := ssAuto;
    JobStats.ChildOrder := 14;
    JobStats.ElementPosition := epIgnore;
    JobStats.ElementFont := efCSS;
    JobStats.Role := '';
  finally
    DownloadFiles.AfterLoadDFMValues;
    FileList.AfterLoadDFMValues;
    JobInfo.AfterLoadDFMValues;
    JobStats.AfterLoadDFMValues;
  end;
end;

end.
