unit Main.WebForm.Core;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Sphinx.WebLogin,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Toast,
  App.Types;

type
  TMainFormCore = class(TForm)
    TTL: TLabel;
    DashboardLabel: TLabel;
    ExceptionTestLink: TLabel;
    SupportLink: TLabel;
    LogoutLabel: TLabel;
    LoggedInUserLabel: TLabel;
    MainToast: TToast;
    MainMessageDlg: TMessageDlg;
    procedure DashboardLabelClick(Sender: TObject);
    procedure ExceptionTestLinkClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
  protected
    FDisplayedForm: TForm;
    procedure OnFormSelfClose(Sender: TObject);
    //procedure ShowForm(AFormClass: TWebFormClass; AProc: TFormCreatedProc = nil);
    procedure ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc = nil; AlwaysClose: boolean = false);
    function CloseDisplayedForm: Boolean;
    [async]
    procedure ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc = nil); async;
    procedure ShowDashboard;
    procedure ErrorHandler(AMessage: string);

    procedure SetLabels; virtual;

  public
    { Public declarations }
    /// <param name="AIcon">
    /// A FontAwesome icon such as 'fad fa-bug'
    /// </param>
    procedure ShowAToast(const ATitle, AMessage, AIcon: string);
    procedure DisplayMessage(const AMessage: string; DlgType: TMsgDlgType = mtInformation);
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  App.Config,
  MainDataModule,
  WebForm.Core, SharedDataModule, DashboardForm;

procedure TMainFormCore.WebFormCreate(Sender: TObject);
begin
   MainData.ShowMessageProc := DisplayMessage;
  // MainData.OnAuthUpdatedProc := OnAuthUpdated;
  // MainData.OnUserChanged := OnUserChanged;
  MainData.ErrorProc := ErrorHandler;
  SharedData.JobsDataset.DisableControls;
  SharedData.LoadJobs;
  MainData.PrepareDashboard;

  ShowDashboard;
end;

{ TMainFormCore }

function TMainFormCore.CloseDisplayedForm: Boolean;
begin
  Result := True;
  if Assigned(FDisplayedForm) then
  begin
    if FDisplayedForm.ClassType.InheritsFrom(TCoreWebForm) then
    begin
      Result := TCoreWebForm(FDisplayedForm).CanClose;
      if Result then
        TCoreWebForm(FDisplayedForm).TearDown;
    end;
    if not Result then
      Exit;
    FDisplayedForm.Free;
    FDisplayedForm := nil;
  end;

end;

procedure TMainFormCore.DashboardLabelClick(Sender: TObject);
begin
  ShowDashboard;
end;

procedure TMainFormCore.DisplayMessage(const AMessage: string; DlgType: TMsgDlgType);
begin
  MainMessageDlg.ShowDialog(AMessage, DlgType, [mbOK]);
end;

procedure TMainFormCore.ErrorHandler(AMessage: string);
begin
  ShowAToast('An Error Occurred', AMessage, 'fad fa-bug');
end;

procedure TMainFormCore.ExceptionTestLinkClick(Sender: TObject);
begin
  raise Exception.Create('Test Exception Raised');
end;

procedure TMainFormCore.OnFormSelfClose(Sender: TObject);
begin
  ShowDashboard;
end;

procedure TMainFormCore.SetLabels;
begin

end;

procedure TMainFormCore.ShowAToast(const ATitle, AMessage, AIcon: string);
const
  MESSAGE_TEMPLATE = '<span class="ToastMessage">%s</span>';
  MESSAGE_TITLE = '<i class="%s fa-fw fa-lg"></i> %s';
var
  lToast: TToastItem;
begin
  lToast := MainToast.Items.Add(Format(MESSAGE_TITLE, [AIcon, ATitle]), Format(MESSAGE_TEMPLATE, [AMessage]));
  lToast.Show;
end;

procedure TMainFormCore.ShowDashboard;

 procedure DashboardCreated(AForm: TObject);
  begin
    TDashboard(AForm).JobsDataset := SharedData.JobsDataset;
  end;

begin
  //ShowForm(TDashboard, @DashboardCreated, true);
  ShowForm(TDashboard, @DashboardCreated);
end;

procedure TMainFormCore.ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc; AlwaysClose: boolean);

  procedure AfterFormCreated(AForm: TObject);
  begin

    if AFormClass.InheritsFrom(TCoreWebForm) then
      TCoreWebForm(AForm).OnSelfClose := OnFormSelfClose;

    if Assigned(AProc) then
      AProc(AForm);

  end;

begin
  // Note that MainPanel is no longer a component. This now just uses a div on the
  // template with that id.
  if Assigned(FDisplayedForm) and (FDisplayedForm.ClassType = AFormClass) and (AlwaysClose = False) then
    Exit;

  if not CloseDisplayedForm then
    Exit;

  Application.CreateForm(AFormClass, 'MainPanel', FDisplayedForm, @AfterFormCreated)

end;

procedure TMainFormCore.ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc);
var
  AForm: TForm;
  lRetval: TModalResult;
begin
  AForm := AFormClass.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TForm, AForm.Load());
    lRetval := await(TModalResult, AForm.Execute);
    if (lRetval = mrOK) and (OnSuccessProc <> nil) then
      OnSuccessProc();
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TMainFormCore.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TTL := TLabel.Create('TTL');
  DashboardLabel := TLabel.Create('DashboardLink');
  ExceptionTestLink := TLabel.Create('ExceptionTestLink');
  SupportLink := TLabel.Create('SupportLink');
  LogoutLabel := TLabel.Create('LogoutLink');
  LoggedInUserLabel := TLabel.Create('UserNameLabel');
  MainMessageDlg := TMessageDlg.Create(Self);
  MainToast := TToast.Create(Self);

  TTL.BeforeLoadDFMValues;
  DashboardLabel.BeforeLoadDFMValues;
  ExceptionTestLink.BeforeLoadDFMValues;
  SupportLink.BeforeLoadDFMValues;
  LogoutLabel.BeforeLoadDFMValues;
  LoggedInUserLabel.BeforeLoadDFMValues;
  MainMessageDlg.BeforeLoadDFMValues;
  MainToast.BeforeLoadDFMValues;
  try
    Name := 'MainFormCore';
    Width := 621;
    Height := 458;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    TTL.SetParentComponent(Self);
    TTL.Name := 'TTL';
    TTL.Left := 517;
    TTL.Top := 64;
    TTL.Width := 59;
    TTL.Height := 10;
    TTL.Cursor := crHandPoint;
    TTL.Caption := 'Lock Application';
    TTL.ElementFont := efCSS;
    TTL.HeightStyle := ssAuto;
    TTL.HeightPercent := 100.000000000000000000;
    TTL.HTMLType := tSPAN;
    TTL.WidthPercent := 100.000000000000000000;
    DashboardLabel.SetParentComponent(Self);
    DashboardLabel.Name := 'DashboardLabel';
    DashboardLabel.Left := 19;
    DashboardLabel.Top := 5;
    DashboardLabel.Width := 38;
    DashboardLabel.Height := 10;
    DashboardLabel.Cursor := crHandPoint;
    DashboardLabel.Caption := 'Dashboard';
    DashboardLabel.ElementPosition := epIgnore;
    DashboardLabel.HeightStyle := ssAuto;
    DashboardLabel.HeightPercent := 100.000000000000000000;
    DashboardLabel.WidthStyle := ssAuto;
    DashboardLabel.WidthPercent := 100.000000000000000000;
    SetEvent(DashboardLabel, Self, 'OnClick', 'DashboardLabelClick');
    ExceptionTestLink.SetParentComponent(Self);
    ExceptionTestLink.Name := 'ExceptionTestLink';
    ExceptionTestLink.Left := 19;
    ExceptionTestLink.Top := 27;
    ExceptionTestLink.Width := 50;
    ExceptionTestLink.Height := 10;
    ExceptionTestLink.Cursor := crHandPoint;
    ExceptionTestLink.Caption := 'Exception Test';
    ExceptionTestLink.ElementFont := efCSS;
    ExceptionTestLink.HeightStyle := ssAuto;
    ExceptionTestLink.HeightPercent := 100.000000000000000000;
    ExceptionTestLink.WidthPercent := 100.000000000000000000;
    SetEvent(ExceptionTestLink, Self, 'OnClick', 'ExceptionTestLinkClick');
    SupportLink.SetParentComponent(Self);
    SupportLink.Name := 'SupportLink';
    SupportLink.Left := 19;
    SupportLink.Top := 53;
    SupportLink.Width := 28;
    SupportLink.Height := 10;
    SupportLink.Caption := 'Support';
    SupportLink.ElementFont := efCSS;
    SupportLink.ElementPosition := epIgnore;
    SupportLink.HeightStyle := ssAuto;
    SupportLink.HeightPercent := 100.000000000000000000;
    SupportLink.WidthStyle := ssAuto;
    SupportLink.WidthPercent := 100.000000000000000000;
    LogoutLabel.SetParentComponent(Self);
    LogoutLabel.Name := 'LogoutLabel';
    LogoutLabel.Left := 517;
    LogoutLabel.Top := 41;
    LogoutLabel.Width := 25;
    LogoutLabel.Height := 10;
    LogoutLabel.Cursor := crHandPoint;
    LogoutLabel.Caption := 'Logout';
    LogoutLabel.ElementPosition := epIgnore;
    LogoutLabel.HeightStyle := ssAuto;
    LogoutLabel.HeightPercent := 100.000000000000000000;
    LogoutLabel.HTMLType := tDIV;
    LogoutLabel.WidthStyle := ssAuto;
    LogoutLabel.WidthPercent := 100.000000000000000000;
    LoggedInUserLabel.SetParentComponent(Self);
    LoggedInUserLabel.Name := 'LoggedInUserLabel';
    LoggedInUserLabel.Left := 517;
    LoggedInUserLabel.Top := 11;
    LoggedInUserLabel.Width := 67;
    LoggedInUserLabel.Height := 10;
    LoggedInUserLabel.Caption := 'LoggedInUserLabel';
    LoggedInUserLabel.ElementPosition := epIgnore;
    LoggedInUserLabel.HeightStyle := ssAuto;
    LoggedInUserLabel.HeightPercent := 100.000000000000000000;
    LoggedInUserLabel.HTMLType := tDIV;
    LoggedInUserLabel.WidthStyle := ssAuto;
    LoggedInUserLabel.WidthPercent := 100.000000000000000000;
    MainMessageDlg.SetParentComponent(Self);
    MainMessageDlg.Name := 'MainMessageDlg';
    MainMessageDlg.Left := 527;
    MainMessageDlg.Top := 176;
    MainMessageDlg.Width := 16;
    MainMessageDlg.Height := 16;
    MainMessageDlg.Buttons := [];
    MainMessageDlg.Opacity := 0.200000000000000000;
    MainMessageDlg.ElementButtonClassName := 'btn';
    MainMessageDlg.ElementDialogClassName := 'shadow-lg mb-5 bg-white rounded';
    MainMessageDlg.ElementTitleClassName := 'text-body';
    MainMessageDlg.ElementContentClassName := 'text-body';
    MainToast.SetParentComponent(Self);
    MainToast.Name := 'MainToast';
    MainToast.AutoHideDelay := 3000;
    MainToast.Position := tpBottomRight;
    MainToast.Left := 523;
    MainToast.Top := 117;
  finally
    TTL.AfterLoadDFMValues;
    DashboardLabel.AfterLoadDFMValues;
    ExceptionTestLink.AfterLoadDFMValues;
    SupportLink.AfterLoadDFMValues;
    LogoutLabel.AfterLoadDFMValues;
    LoggedInUserLabel.AfterLoadDFMValues;
    MainMessageDlg.AfterLoadDFMValues;
    MainToast.AfterLoadDFMValues;
  end;
end;

end.
