unit DashboardManager;

interface

uses
  System.SysUtils,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  smx.Reports.Types,
  Dashboard.ReturnTypes;

type

  EDashboardException = class(Exception)
  private
    FMyId: Integer;
    FReportId: Integer;
    FLastRefresh: TDateTime;
  public
    constructor Create(Item: TDashboardBase);
    property ReportId: Integer read FReportId;
    property MyId: Integer read FMyId;
    property LastRefresh: TDateTime read FLastRefresh;
  end;

  TDashboardManager = class(TForm)
  private
    FContainerId: string;
    function GetDisplayTable(AItem: TDashboardBase): string;
    function GetDisplayText(AItem: TDashboardBase): string;
    function StartOfWeek(const AWeekNumber: Integer): TDateTime;

    function HandleFloat(const AFormat: string; ItemData: JS.TJSObject): string;
    function HandleCurrency(const AFormat: string; ItemData: JS.TJSObject): string;
    function HandleDateTime(const AFormat: string; ItemData: JS.TJSObject): string;
    function HandleInteger(const AFormat: string; ItemData: JS.TJSObject): string;
    function HandleWeekNumber(const AFormat: string; ItemData: JS.TJSObject): string;

  public
    [async]
    procedure LoadDashboardItem(AItem: TDashboardBase); async;
    procedure ClearDashboard;
    property ContainerId: string read FContainerId write FContainerId;
  end;

implementation

uses
  Data.DB,
  System.DateUtils,
  smx.Web.Document.Utils;

procedure TDashboardManager.ClearDashboard;
begin
  TDocUtils.emptyDiv(FContainerId);
end;

function TDashboardManager.GetDisplayTable(AItem: TDashboardBase): string;
var
  lData, lConfig, ItemConfig, ItemData: JS.TJSObject;
  ConfigArray, DataArray, ItemArray: JS.TJSArray;
  I, J: Integer;
  lRowTpl, lDataRow, lDataType, fn, fv, cClass: string;
  ft { , fmt } : string;
  // lDateVal: TDateTime;
  lProc: TColProcess;
begin

  Result := '<table class="$class"><tr>'.Replace('$class', TABLE_CLASS[AItem.TableProcess]);

  lConfig := TJSObject(TJSJSON.parse(AItem.Config));
  ConfigArray := JS.toArray(lConfig['Columns']);

  lData := TJSObject(TJSJSON.parse(AItem.Data));
  lDataType := JS.toString(lData['format']); // named-json
  DataArray := JS.toArray(lData['value']);

  lRowTpl := '<tr>';
  for I := 0 to ConfigArray.Length - 1 do
  begin
    ItemConfig := JS.toObject(ConfigArray[I]);
    if TColProcess(JS.toInteger(ItemConfig['Process'])) = cpIgnore then
      Continue;

    cClass := JS.toString(ItemConfig['ColClass']);
    Result := Result + '<th class="' + cClass + '">' + JS.toString(ItemConfig['Title']) + '</th>';
    lRowTpl := lRowTpl + '<td class="' + HALIGN_CLASS[THorizontalAlign(JS.toInteger(ItemConfig['Align']))] + ' ' +
      cClass + '">$' + JS.toString(ItemConfig['FieldName']) + '</td>';
  end;

  lRowTpl := lRowTpl + '</tr>';
  Result := Result + '</tr>';

  for I := 0 to DataArray.Length - 1 do
  begin
    ItemArray := JS.toArray(DataArray[I]);
    lDataRow := lRowTpl;
    for J := 0 to ItemArray.Length - 1 do
    begin
      ItemData := JS.toObject(ItemArray[J]);
      ItemConfig := JS.toObject(ConfigArray[J]);

      lProc := TColProcess(JS.toInteger(ItemConfig['Process']));

      fn := '$' + JS.toString(ItemData['fieldname']);
      ft := JS.toString(ItemConfig['DataType']);

      if lProc = cpIgnore then
        Continue
      else if lProc = cpWeekNumber then
        fv := HandleWeekNumber(JS.toString(ItemConfig['Format']), ItemData)
      else
      begin
        if ft = 'Integer' then
          fv := HandleInteger(JS.toString(ItemConfig['Format']), ItemData)
        else if ft = 'ShortInt' then
          fv := JS.toInteger(ItemData['value']).toString
        else if ft = 'String' then
          fv := JS.toString(ItemData['value'])
        else if ft = 'Float' then
          fv := HandleFloat(JS.toString(ItemConfig['Format']), ItemData)
        else if ft = 'Currency' then
          fv := HandleCurrency(JS.toString(ItemConfig['Format']), ItemData)
        else if ft = 'DateTime' then
          fv := HandleDateTime(JS.toString(ItemConfig['Format']), ItemData)
        else
          fv := JS.toString(ItemData['value']);
      end;

      lDataRow := lDataRow.Replace(fn, fv);
    end;
    Result := Result + lDataRow;
  end;

  Result := Result + '</table>';

end;

function TDashboardManager.GetDisplayText(AItem: TDashboardBase): string;
var
  lObj: JS.TJSObject;
begin
  lObj := TJSObject(TJSJSON.parse(AItem.Data));
  Result := '<div class="display_text">' + JS.toString(lObj['value']) + '</div>';
end;

function TDashboardManager.HandleCurrency(const AFormat: string; ItemData: JS.TJSObject): string;
var
  fmt: string;
begin
  if AFormat = '' then
    fmt := '#,##0.00'
  else
    fmt := AFormat;
  Result := '&#163;' + FormatFloat(fmt, JS.toNumber(ItemData['value']));
end;

function TDashboardManager.HandleDateTime(const AFormat: string; ItemData: JS.TJSObject): string;
var
  fmt: string;
  lDateVal: TDateTime;
begin
  fmt := AFormat;
  if TryRFC3339ToDateTime(JS.toString(ItemData['value']), lDateVal) then
  begin
    if fmt = '' then
    begin
      if TimeOf(lDateVal) > 0 then
        fmt := 'dd/mm/yyyy hh:nn:ss'
      else
        fmt := 'dd/mm/yyyy';
    end;
    Result := FormatDateTime(fmt, lDateVal);
  end
  else
    Result := '';
end;

function TDashboardManager.HandleFloat(const AFormat: string; ItemData: JS.TJSObject): string;
var
  fmt: string;
begin
  if AFormat = '' then
    fmt := '#,##0.000'
  else
    fmt := AFormat;
  Result := FormatFloat(fmt, JS.toNumber(ItemData['value']));
end;

function TDashboardManager.HandleInteger(const AFormat: string; ItemData: JS.TJSObject): string;
var
  fmt: string;
begin
  if AFormat = '' then
    fmt := '#,##0'
  else
    fmt := AFormat;
  Result := FormatFloat(fmt, JS.toInteger(ItemData['value']));
end;

function TDashboardManager.HandleWeekNumber(const AFormat: string; ItemData: JS.TJSObject): string;
var
  fmt: string;
  lDateVal: TDateTime;
begin
  if AFormat = '' then
    fmt := 'dd/mm/yyyy'
  else
    fmt := AFormat;

  lDateVal := StartOfWeek(JS.toInteger(ItemData['value']));
  Result := FormatDateTime(fmt, lDateVal);

end;

{ TDashBoardManager }

procedure TDashboardManager.LoadDashboardItem(AItem: TDashboardBase);
const
  TPL = '<div id="$ID" class="dashboardItem"><h4>$TITLE</h4>$SUBT<div id="$ID_content" class="content $TYPE">$CONTENT</div></div>';
var
  lContent, lDisplay, lId, subt: string;
  lExists: Boolean;
begin

  if AItem.Status = TDashboardItemStatus.disError then
  begin
{$IFDEF RELEASE}
    if not AuthService.IsSuperUser then
      Exit;
{$ENDIF}
  end;

  lId := 'dash_' + AItem.MyId.toString;

  case AItem.DisplayType of
    ddNone:
      ;
    ddText:
      lContent := GetDisplayText(AItem);
    ddGraph:
      ;
    ddTable:
      lContent := GetDisplayTable(AItem);
    ddHTMLFile:
      ;
  end;

  lExists := TDocUtils.elementIdExists(lId);

  if lExists then
  begin
    TDocUtils.writeHTML(lId + '_content', lContent);
    Exit;
  end;

  if AItem.SubTitle <> '' then
    subt := '<h6>' + AItem.SubTitle + '</h6>'
  else
    subt := '';

  lDisplay := TPL.Replace('$ID', lId, [rfReplaceAll]).Replace('$TITLE', AItem.Title).Replace('$SUBT', subt)
    .Replace('$CONTENT', lContent).Replace('$TYPE', DASHBOARD_CLASS[AItem.DisplayType]);

  TDocUtils.appendHTML(FContainerId, lDisplay);

end;

function TDashboardManager.StartOfWeek(const AWeekNumber: Integer): TDateTime;
var
  ThisWeek, ThisYear: Word;
begin
  ThisWeek := WeekOf(Today);
  ThisYear := YearOf(Today);

  if AWeekNumber > ThisWeek then
    ThisYear := ThisYear - 1;

  Result := StartOfAWeek(ThisYear, AWeekNumber);
end;

{ EDashboardException }

constructor EDashboardException.Create(Item: TDashboardBase);
begin
  message := Item.Data;
  FReportId := Item.ReportId;
  FMyId := Item.MyId;
  FLastRefresh := Item.LastRefresh;
end;

end.
