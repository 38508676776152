unit AdvancedSearchForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.WebCtrls,
  WEBLib.Toast,
  WebForm.Core;

type
  TAdvancedSearch = class(TCoreWebForm)
    LastNameSelect: TEdit;
    FirstNameSelect: TEdit;
    Add1Select: TEdit;
    PostCodeSelect: TEdit;
    DateSignedSelect: TDateTimePicker;
    ClearSelectButton: TButton;
    FindSelectButton: TButton;
    FirstNameOperator: TComboBox;
    LastNameOperator: TComboBox;
    Add1Operator: TComboBox;
    DateSignedOperator: TComboBox;
    SearchCloseButton: TButton;
    DateSignedAfterDiv: THTMLDiv;
    DateSignedLatest: TDateTimePicker;
    procedure ClearSelectButtonClick(Sender: TObject);
    procedure CloseThisButtonClick(Sender: TObject);
    procedure DateSignedLatestExit(Sender: TObject);
    procedure DateSignedOperatorChange(Sender: TObject);
    procedure FindSelectButtonClick(Sender: TObject);
    procedure PostCodeSelectExit(Sender: TObject);
    procedure SearchCloseButtonClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    procedure ClearSelections;
    function GetFilterQuery: string;
    { Private declarations }
  public
    { Public declarations }
    property FilterQuery: string read GetFilterQuery;
  protected procedure LoadDFMValues; override; end;

var
  AdvancedSearch: TAdvancedSearch;

implementation

uses
  System.DateUtils,
  SMX.CRM.Utils;

{$R *.dfm}

const
  OP_EQUALS = 0;
  OP_STARTS = 1;
  OP_CONTAINS = 2;
  DOP_NONE = 0;
  DOP_ON = 1;
  DOP_BETWEEN = 2;
  DOP_BEFORE = 3;
  DOP_AFTER = 4;

procedure TAdvancedSearch.ClearSelectButtonClick(Sender: TObject);
begin
  ClearSelections;
end;

procedure TAdvancedSearch.ClearSelections;
begin
  LastNameSelect.Text := '';
  FirstNameSelect.Text := '';
  Add1Select.Text := '';
  PostCodeSelect.Text := '';
  DateSignedSelect.Date := StartOfTheMonth(Today);
  DateSignedLatest.Date := Today;
  FirstNameOperator.ItemIndex := 0;
  LastNameOperator.ItemIndex := 0;
  Add1Operator.ItemIndex := 0;
  DateSignedOperator.ItemIndex := 0;
end;

procedure TAdvancedSearch.CloseThisButtonClick(Sender: TObject);
begin
  ModalResult := mrClose;
end;

procedure TAdvancedSearch.DateSignedLatestExit(Sender: TObject);
begin
  if DateSignedLatest.Date <= DateSignedSelect.Date then
    ControlInvalid('DateSignedLatest')
  else
    ControlNoValidity('DateSignedLatest');

end;

procedure TAdvancedSearch.DateSignedOperatorChange(Sender: TObject);
begin
  DateSignedAfterDiv.Visible := DateSignedOperator.ItemIndex = DOP_BETWEEN;
end;

procedure TAdvancedSearch.FindSelectButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

function TAdvancedSearch.GetFilterQuery: string;
var
  ts: TStringList;
  I: Integer;

  procedure AddFilter(const AValue, AField: string; AOperator: Integer);
  var
    mValue: string;
  begin
    mValue := AValue.Trim.ToUpper;
    if mValue = '' then
      Exit;
    if AOperator = OP_EQUALS then
      ts.Add(Format('(upper(%s) eq ''%s'')', [AField, mValue]))
    else if AOperator = OP_STARTS then
      ts.Add(Format('(startswith(upper(%s), ''%s''))', [AField, mValue]))
    else if AOperator = OP_CONTAINS then
      ts.Add(Format('(contains(upper(%s), ''%s''))', [AField, mValue]));
  end;

var
  lValue: string;
  lDateSignedOp: Integer;
begin
  ts := TStringList.Create;
  try
    lValue := LastNameSelect.Text;
    AddFilter(lValue, 'LastName', LastNameOperator.ItemIndex);

    lValue := FirstNameSelect.Text;
    AddFilter(lValue, 'FirstName', FirstNameOperator.ItemIndex);

    lValue := Add1Select.Text;
    AddFilter(lValue, 'Add1', Add1Operator.ItemIndex);

    if PostCodeSelect.Text <> '' then
    begin
      lValue := PostCodeSelect.Text;
      case TCRMUtils.IsPostCode(lValue) of
        pcPartial:
          ts.Add(Format('(startswith(PostCode, ''%s''))', [lValue]));
        pcFull:
          ts.Add(Format('(PostCode eq ''%s'')', [lValue]));
      end;
    end;

    lDateSignedOp := DateSignedOperator.ItemIndex;
    if lDateSignedOp = DOP_ON then
      ts.Add(Format('(DateSigned eq %s)', [FormatDateTime('YYYY-MM-DD', DateSignedSelect.Date)]))
    else if lDateSignedOp = DOP_AFTER then
      ts.Add(Format('(DateSigned ge %s)', [FormatDateTime('YYYY-MM-DD', DateSignedSelect.Date)]))
    else if lDateSignedOp = DOP_BEFORE then
      ts.Add(Format('(DateSigned le %s)', [FormatDateTime('YYYY-MM-DD', DateSignedSelect.Date)]))
    else if lDateSignedOp = DOP_BETWEEN then
    begin
      ts.Add(Format('(DateSigned ge %s)', [FormatDateTime('YYYY-MM-DD', DateSignedSelect.Date)]));

      if (DateSignedLatest.Date > DateSignedSelect.Date) then
        ts.Add(Format('(DateSigned le %s)', [FormatDateTime('YYYY-MM-DD', DateSignedLatest.Date)]))
      else
        Exit;
    end;

    Result := '';

    for I := 0 to ts.Count - 1 do
    begin
      if Result <> '' then
        Result := Result + ' and ';
      Result := Result + ts[I];
    end;

  finally
    ts.Free;
  end;

end;

procedure TAdvancedSearch.PostCodeSelectExit(Sender: TObject);
begin
  PostCodeSelect.Text := TCRMUtils.FormatPostCode(PostCodeSelect.Text)
end;

procedure TAdvancedSearch.SearchCloseButtonClick(Sender: TObject);
begin
  ModalResult := mrClose;
end;

procedure TAdvancedSearch.WebFormCreate(Sender: TObject);
begin
  inherited;
  ClearSelections;
end;

procedure TAdvancedSearch.LoadDFMValues;
begin
  inherited LoadDFMValues;

  LastNameSelect := TEdit.Create('LastNameSelect');
  FirstNameSelect := TEdit.Create('FirstNameSelect');
  Add1Select := TEdit.Create('Add1Select');
  PostCodeSelect := TEdit.Create('PostCodeSelect');
  DateSignedSelect := TDateTimePicker.Create('DateSignedSelect');
  ClearSelectButton := TButton.Create('ClearSelectButton');
  FindSelectButton := TButton.Create('FindSelectButton');
  FirstNameOperator := TComboBox.Create('FirstNameOperator');
  LastNameOperator := TComboBox.Create('LastNameOperator');
  Add1Operator := TComboBox.Create('Add1Operator');
  DateSignedOperator := TComboBox.Create('DateSignedOperator');
  SearchCloseButton := TButton.Create('SearchCloseButton');
  DateSignedAfterDiv := THTMLDiv.Create('DateSignedAfterDiv');
  DateSignedLatest := TDateTimePicker.Create('DateSignedLatest');

  LastNameSelect.BeforeLoadDFMValues;
  FirstNameSelect.BeforeLoadDFMValues;
  Add1Select.BeforeLoadDFMValues;
  PostCodeSelect.BeforeLoadDFMValues;
  DateSignedSelect.BeforeLoadDFMValues;
  ClearSelectButton.BeforeLoadDFMValues;
  FindSelectButton.BeforeLoadDFMValues;
  FirstNameOperator.BeforeLoadDFMValues;
  LastNameOperator.BeforeLoadDFMValues;
  Add1Operator.BeforeLoadDFMValues;
  DateSignedOperator.BeforeLoadDFMValues;
  SearchCloseButton.BeforeLoadDFMValues;
  DateSignedAfterDiv.BeforeLoadDFMValues;
  DateSignedLatest.BeforeLoadDFMValues;
  try
    Width := 364;
    Height := 190;
    ElementClassName := 'PopUpForm  SelectForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    LastNameSelect.SetParentComponent(Self);
    LastNameSelect.Name := 'LastNameSelect';
    LastNameSelect.Left := 79;
    LastNameSelect.Top := 69;
    LastNameSelect.Width := 81;
    LastNameSelect.Height := 15;
    LastNameSelect.ChildOrder := 5;
    LastNameSelect.ElementClassName := 'form-control';
    LastNameSelect.ElementFont := efCSS;
    LastNameSelect.ElementPosition := epIgnore;
    LastNameSelect.HeightStyle := ssAuto;
    LastNameSelect.HeightPercent := 100.000000000000000000;
    LastNameSelect.WidthStyle := ssAuto;
    LastNameSelect.WidthPercent := 100.000000000000000000;
    FirstNameSelect.SetParentComponent(Self);
    FirstNameSelect.Name := 'FirstNameSelect';
    FirstNameSelect.Left := 79;
    FirstNameSelect.Top := 51;
    FirstNameSelect.Width := 81;
    FirstNameSelect.Height := 15;
    FirstNameSelect.ChildOrder := 4;
    FirstNameSelect.ElementClassName := 'form-control';
    FirstNameSelect.ElementFont := efCSS;
    FirstNameSelect.ElementPosition := epIgnore;
    FirstNameSelect.HeightStyle := ssAuto;
    FirstNameSelect.HeightPercent := 100.000000000000000000;
    FirstNameSelect.WidthStyle := ssAuto;
    FirstNameSelect.WidthPercent := 100.000000000000000000;
    Add1Select.SetParentComponent(Self);
    Add1Select.Name := 'Add1Select';
    Add1Select.Left := 79;
    Add1Select.Top := 88;
    Add1Select.Width := 81;
    Add1Select.Height := 15;
    Add1Select.ChildOrder := 6;
    Add1Select.ElementClassName := 'form-control';
    Add1Select.ElementFont := efCSS;
    Add1Select.ElementPosition := epIgnore;
    Add1Select.HeightStyle := ssAuto;
    Add1Select.HeightPercent := 100.000000000000000000;
    Add1Select.WidthStyle := ssAuto;
    Add1Select.WidthPercent := 100.000000000000000000;
    PostCodeSelect.SetParentComponent(Self);
    PostCodeSelect.Name := 'PostCodeSelect';
    PostCodeSelect.Left := 32;
    PostCodeSelect.Top := 107;
    PostCodeSelect.Width := 81;
    PostCodeSelect.Height := 15;
    PostCodeSelect.ChildOrder := 7;
    PostCodeSelect.ElementClassName := 'form-control';
    PostCodeSelect.ElementFont := efCSS;
    PostCodeSelect.ElementPosition := epIgnore;
    PostCodeSelect.HeightStyle := ssAuto;
    PostCodeSelect.HeightPercent := 100.000000000000000000;
    PostCodeSelect.WidthStyle := ssAuto;
    PostCodeSelect.WidthPercent := 100.000000000000000000;
    SetEvent(PostCodeSelect, Self, 'OnExit', 'PostCodeSelectExit');
    DateSignedSelect.SetParentComponent(Self);
    DateSignedSelect.Name := 'DateSignedSelect';
    DateSignedSelect.Left := 229;
    DateSignedSelect.Top := 88;
    DateSignedSelect.Width := 113;
    DateSignedSelect.Height := 15;
    DateSignedSelect.ElementClassName := 'form-control';
    DateSignedSelect.HeightStyle := ssAuto;
    DateSignedSelect.WidthStyle := ssAuto;
    DateSignedSelect.BorderStyle := bsSingle;
    DateSignedSelect.ChildOrder := 8;
    DateSignedSelect.Color := clWhite;
    DateSignedSelect.Date := 44495.415940196760000000;
    DateSignedSelect.ElementFont := efCSS;
    DateSignedSelect.ElementPosition := epIgnore;
    DateSignedSelect.Role := '';
    DateSignedSelect.Text := '';
    ClearSelectButton.SetParentComponent(Self);
    ClearSelectButton.Name := 'ClearSelectButton';
    ClearSelectButton.Left := 32;
    ClearSelectButton.Top := 149;
    ClearSelectButton.Width := 64;
    ClearSelectButton.Height := 17;
    ClearSelectButton.Caption := 'Clear';
    ClearSelectButton.ChildOrder := 9;
    ClearSelectButton.ElementClassName := 'btn btn-info';
    ClearSelectButton.ElementFont := efCSS;
    ClearSelectButton.ElementPosition := epIgnore;
    ClearSelectButton.HeightStyle := ssAuto;
    ClearSelectButton.HeightPercent := 100.000000000000000000;
    ClearSelectButton.WidthStyle := ssAuto;
    ClearSelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(ClearSelectButton, Self, 'OnClick', 'ClearSelectButtonClick');
    FindSelectButton.SetParentComponent(Self);
    FindSelectButton.Name := 'FindSelectButton';
    FindSelectButton.Left := 112;
    FindSelectButton.Top := 149;
    FindSelectButton.Width := 64;
    FindSelectButton.Height := 17;
    FindSelectButton.Caption := 'Find';
    FindSelectButton.ChildOrder := 10;
    FindSelectButton.ElementClassName := ' btn btn-primary';
    FindSelectButton.ElementFont := efCSS;
    FindSelectButton.ElementPosition := epIgnore;
    FindSelectButton.HeightStyle := ssAuto;
    FindSelectButton.HeightPercent := 100.000000000000000000;
    FindSelectButton.WidthStyle := ssAuto;
    FindSelectButton.WidthPercent := 100.000000000000000000;
    SetEvent(FindSelectButton, Self, 'OnClick', 'FindSelectButtonClick');
    FirstNameOperator.SetParentComponent(Self);
    FirstNameOperator.Name := 'FirstNameOperator';
    FirstNameOperator.Left := 32;
    FirstNameOperator.Top := 51;
    FirstNameOperator.Width := 43;
    FirstNameOperator.Height := 15;
    FirstNameOperator.ElementClassName := 'form-select';
    FirstNameOperator.ElementFont := efCSS;
    FirstNameOperator.ElementPosition := epIgnore;
    FirstNameOperator.HeightStyle := ssAuto;
    FirstNameOperator.HeightPercent := 100.000000000000000000;
    FirstNameOperator.Text := 'Equals';
    FirstNameOperator.WidthStyle := ssAuto;
    FirstNameOperator.WidthPercent := 100.000000000000000000;
    FirstNameOperator.ItemIndex := 0;
    FirstNameOperator.Items.BeginUpdate;
    try
      FirstNameOperator.Items.Clear;
      FirstNameOperator.Items.Add('Equals');
      FirstNameOperator.Items.Add('Starts With');
      FirstNameOperator.Items.Add('Contains');
    finally
      FirstNameOperator.Items.EndUpdate;
    end;
    LastNameOperator.SetParentComponent(Self);
    LastNameOperator.Name := 'LastNameOperator';
    LastNameOperator.Left := 32;
    LastNameOperator.Top := 69;
    LastNameOperator.Width := 43;
    LastNameOperator.Height := 15;
    LastNameOperator.ElementClassName := 'form-select';
    LastNameOperator.ElementFont := efCSS;
    LastNameOperator.ElementPosition := epIgnore;
    LastNameOperator.HeightStyle := ssAuto;
    LastNameOperator.HeightPercent := 100.000000000000000000;
    LastNameOperator.Text := 'Equals';
    LastNameOperator.WidthStyle := ssAuto;
    LastNameOperator.WidthPercent := 100.000000000000000000;
    LastNameOperator.ItemIndex := 0;
    LastNameOperator.Items.BeginUpdate;
    try
      LastNameOperator.Items.Clear;
      LastNameOperator.Items.Add('Equals');
      LastNameOperator.Items.Add('Starts With');
      LastNameOperator.Items.Add('Contains');
    finally
      LastNameOperator.Items.EndUpdate;
    end;
    Add1Operator.SetParentComponent(Self);
    Add1Operator.Name := 'Add1Operator';
    Add1Operator.Left := 32;
    Add1Operator.Top := 89;
    Add1Operator.Width := 43;
    Add1Operator.Height := 15;
    Add1Operator.ElementClassName := 'form-select';
    Add1Operator.ElementFont := efCSS;
    Add1Operator.ElementPosition := epIgnore;
    Add1Operator.HeightStyle := ssAuto;
    Add1Operator.HeightPercent := 100.000000000000000000;
    Add1Operator.Text := 'Equals';
    Add1Operator.WidthStyle := ssAuto;
    Add1Operator.WidthPercent := 100.000000000000000000;
    Add1Operator.ItemIndex := 0;
    Add1Operator.Items.BeginUpdate;
    try
      Add1Operator.Items.Clear;
      Add1Operator.Items.Add('Equals');
      Add1Operator.Items.Add('Starts With');
      Add1Operator.Items.Add('Contains');
    finally
      Add1Operator.Items.EndUpdate;
    end;
    DateSignedOperator.SetParentComponent(Self);
    DateSignedOperator.Name := 'DateSignedOperator';
    DateSignedOperator.Left := 171;
    DateSignedOperator.Top := 88;
    DateSignedOperator.Width := 55;
    DateSignedOperator.Height := 15;
    DateSignedOperator.ElementClassName := 'form-select';
    DateSignedOperator.ElementFont := efCSS;
    DateSignedOperator.ElementPosition := epIgnore;
    DateSignedOperator.HeightStyle := ssAuto;
    DateSignedOperator.HeightPercent := 100.000000000000000000;
    DateSignedOperator.Text := 'Not selected';
    DateSignedOperator.WidthStyle := ssAuto;
    DateSignedOperator.WidthPercent := 100.000000000000000000;
    SetEvent(DateSignedOperator, Self, 'OnChange', 'DateSignedOperatorChange');
    DateSignedOperator.ItemIndex := -1;
    DateSignedOperator.Items.BeginUpdate;
    try
      DateSignedOperator.Items.Clear;
      DateSignedOperator.Items.Add('Not selected');
      DateSignedOperator.Items.Add('On');
      DateSignedOperator.Items.Add('Between');
      DateSignedOperator.Items.Add('On or Before');
      DateSignedOperator.Items.Add('On or After');
    finally
      DateSignedOperator.Items.EndUpdate;
    end;
    SearchCloseButton.SetParentComponent(Self);
    SearchCloseButton.Name := 'SearchCloseButton';
    SearchCloseButton.Left := 180;
    SearchCloseButton.Top := 168;
    SearchCloseButton.Width := 64;
    SearchCloseButton.Height := 17;
    SearchCloseButton.Caption := 'Close';
    SearchCloseButton.ChildOrder := 14;
    SearchCloseButton.ElementClassName := 'btn brn-secondary';
    SearchCloseButton.ElementFont := efCSS;
    SearchCloseButton.ElementPosition := epIgnore;
    SearchCloseButton.HeightStyle := ssAuto;
    SearchCloseButton.HeightPercent := 100.000000000000000000;
    SearchCloseButton.WidthStyle := ssAuto;
    SearchCloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(SearchCloseButton, Self, 'OnClick', 'SearchCloseButtonClick');
    DateSignedAfterDiv.SetParentComponent(Self);
    DateSignedAfterDiv.Name := 'DateSignedAfterDiv';
    DateSignedAfterDiv.Left := 229;
    DateSignedAfterDiv.Top := 123;
    DateSignedAfterDiv.Width := 113;
    DateSignedAfterDiv.Height := 27;
    DateSignedAfterDiv.HeightStyle := ssAuto;
    DateSignedAfterDiv.WidthStyle := ssAuto;
    DateSignedAfterDiv.ChildOrder := 15;
    DateSignedAfterDiv.ElementPosition := epIgnore;
    DateSignedAfterDiv.ElementFont := efCSS;
    DateSignedAfterDiv.Role := '';
    DateSignedAfterDiv.Visible := False;
    DateSignedLatest.SetParentComponent(DateSignedAfterDiv);
    DateSignedLatest.Name := 'DateSignedLatest';
    DateSignedLatest.Left := 0;
    DateSignedLatest.Top := 2;
    DateSignedLatest.Width := 113;
    DateSignedLatest.Height := 15;
    DateSignedLatest.ElementClassName := 'form-control';
    DateSignedLatest.HeightStyle := ssAuto;
    DateSignedLatest.WidthStyle := ssAuto;
    DateSignedLatest.BorderStyle := bsSingle;
    DateSignedLatest.ChildOrder := 15;
    DateSignedLatest.Color := clWhite;
    DateSignedLatest.Date := 44495.415940196760000000;
    DateSignedLatest.ElementFont := efCSS;
    DateSignedLatest.ElementPosition := epIgnore;
    DateSignedLatest.Role := '';
    DateSignedLatest.Text := '';
    SetEvent(DateSignedLatest, Self, 'OnExit', 'DateSignedLatestExit');
  finally
    LastNameSelect.AfterLoadDFMValues;
    FirstNameSelect.AfterLoadDFMValues;
    Add1Select.AfterLoadDFMValues;
    PostCodeSelect.AfterLoadDFMValues;
    DateSignedSelect.AfterLoadDFMValues;
    ClearSelectButton.AfterLoadDFMValues;
    FindSelectButton.AfterLoadDFMValues;
    FirstNameOperator.AfterLoadDFMValues;
    LastNameOperator.AfterLoadDFMValues;
    Add1Operator.AfterLoadDFMValues;
    DateSignedOperator.AfterLoadDFMValues;
    SearchCloseButton.AfterLoadDFMValues;
    DateSignedAfterDiv.AfterLoadDFMValues;
    DateSignedLatest.AfterLoadDFMValues;
  end;
end;

end.
