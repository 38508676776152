unit DonorList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.Lists,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  WEBLib.WebCtrls,
  WEBLib.Toast,
  XData.Web.Client,
  SMX.Shared,
  Vcl.Controls,
  SMX.webcore.types;

type
  TDonorListForm = class(TBaseListForm)
    AdvancedSearchButton: TButton;
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetTitle: TStringField;
    MainDatasetFirstName: TStringField;
    MainDatasetMiddleName: TStringField;
    MainDatasetLastName: TStringField;
    MainDatasetSuffix: TStringField;
    MainDatasetAdd1: TStringField;
    MainDatasetAdd2: TStringField;
    MainDatasetAdd3: TStringField;
    MainDatasetCity: TStringField;
    MainDatasetPostCode: TStringField;
    MainDatasetEmail: TStringField;
    MainDatasetMobile: TStringField;
    MainDatasetTelephone: TStringField;
    MainDatasetMatchHash: TStringField;
    MainDatasetContactByPhone: TBooleanField;
    MainDatasetContactByPost: TBooleanField;
    MainDatasetContactByText: TBooleanField;
    MainDatasetContactByEmail: TBooleanField;
    MainDatasetDOB: TDateField;
    MainDatasetHearingStatus: TStringField;
    MainDatasetEmailStatus: TStringField;
    MainDatasetLatitude: TFloatField;
    MainDatasetLongitude: TFloatField;
    MainDatasetExternalId: TStringField;
    MainDatasetCountryCode: TStringField;
    MainDatasetVisualStatus: TStringField;
    MainDatasetMobilityStatus: TStringField;
    MainDatasetSourceType: TStringField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetCharityTenant: TStringField;
    MainDatasetClaimStatusText: TStringField;
    MainDatasetFullName: TStringField;
    MainDatasetFullAddress: TStringField;
    MainDatasetComments: TStringField;
    MainDatasetImportId: TIntegerField;
    AddedDonor: TTimer;
    MainDatasetCounty: TStringField;
    MainDatasetLocationType: TStringField;
    MainDatasetOrganisationName: TStringField;
    procedure AddButtonClick(Sender: TObject);
    procedure AddedDonorTimer(Sender: TObject);
    procedure AdvancedSearchButtonClick(Sender: TObject);
    procedure MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure MainDatasetAfterPost(DataSet: TDataSet);
    procedure MainDatasetCalcFields(Dataset: TDataSet);
    procedure MainDatasetClaimStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    FBuildingQuery: Boolean;
    FFilterStr: string;
    FAddDonor: boolean;
    [async]
    procedure ShowDonationsRecord(const ARecordId: Integer); async;
    [async]
    procedure DoAdvancedSearch; async;
    { Private declarations }
  protected
    procedure PrepareForm; override;
    function RecordViewOption: TViewOption; override;
    function GetEditFormClass: TFormClass; override;
    procedure SearchData(const Value: string); override;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;

    function InitSortCol: Integer; override;

    function InitSortDirection: TSortDirection; override;

    function CreateGridInFormCreate: boolean; override;

    procedure LoadData; override;

    procedure RefreshData;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  DonorListForm: TDonorListForm;

implementation

uses
  MainDataModule,
  DonorForm,
  Grid.Settings,
  DonorBaseViewForm,
  AdvancedSearchForm,
  SMX.Web.Layout.Utils,
  SMX.CRM.Utils,
  DonationList,
  SMX.Web.Utils;

{$R *.dfm}

procedure TDonorListForm.AddButtonClick(Sender: TObject);
begin
  inherited;
  FAddDonor := true;
end;

procedure TDonorListForm.AddedDonorTimer(Sender: TObject);
begin
  AddedDonor.Enabled := false;
  RefreshData;
end;

procedure TDonorListForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  UnLockControls(True);
end;

{ TDonorListForm }

procedure TDonorListForm.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure _DonationsClick(Sender: TObject);
  begin
    ShowDonationsRecord(ARecordId.ToInteger);
  end;

var
  Span, RefundSpan: THTMLSpan;
begin
  inherited;

  if RecordViewOption <> voNone then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-pound-sign', 'Show donations');
    Span.OnClick := @_DonationsClick;
  end;

end;

procedure TDonorListForm.AdvancedSearchButtonClick(Sender: TObject);
begin
  DoAdvancedSearch;
end;

procedure TDonorListForm.DoAdvancedSearch;
var
  AForm: TAdvancedSearch;
  lRetval: TModalResult;
  lQuery: string;
begin
  AForm := TAdvancedSearch.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    await(TAdvancedSearch, AForm.Load());
    lRetval := await(TModalResult, AForm.Execute);
    if lRetval = mrOK then
    begin
      lQuery := AForm.FilterQuery;
      if lQuery <> '' then
        FGridPlugin.SetPreparedFilter(lQuery);
    end;
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

function TDonorListForm.GetEditFormClass: TFormClass;
begin
  Result := TDonorEditForm;
end;

procedure TDonorListForm.MainDatasetCalcFields(Dataset: TDataSet);
begin
  MainDatasetClaimStatusText.Value := TUtils.SplitOnCaps(MainDatasetClaimStatus.Value);
  MainDatasetFullName.Value := TCRMUtils.FullName(MainDataset);
  MainDatasetFullAddress.Value := TCRMUtils.FormattedAddress(MainDataset, ',');
end;

procedure TDonorListForm.MainDatasetClaimStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := TUtils.SplitOnCaps(Text);
end;

procedure TDonorListForm.PrepareForm;
begin
  FIdField := 'Id';
end;

function TDonorListForm.RecordViewOption: TViewOption;
begin
  Result := voCreateAndEdit;
end;

procedure TDonorListForm.RefreshData;
begin
  FGridPlugin.SetPreparedFilter(FFilterStr);
end;

procedure TDonorListForm.SearchData(const Value: string);
var
  lPostCode: TPostCodePart;
  lShopQuery, lQuery: string;
begin
  { TODO : Handle if Shop selected }
  if FBuildingQuery then
    Exit;
  FBuildingQuery := True;

  lPostCode := TCRMUtils.IsPostCode(Value);
  if lPostCode = pcFull then
    lQuery := format('(PostCode eq ''%s'')', [Uppercase(Value)])
  else if lPostCode = pcPartial then
    lQuery := format('(startswith(PostCode, ''%s''))', [Uppercase(Value)])
  else if TUtils.IsNumber(Value) then
    lQuery := format('((Id eq %s) or (ExternalId eq ''%s''))', [Value, Value])
  else if Pos('@', Value) > 0 then
    lQuery := format('(Email eq ''%s'')', [Lowercase(Value)])
  else
  begin
    lQuery := format
      ('startswith(upper(LastName), ''%s'') or startswith(upper(Add1), ''%s'') or startswith(upper(FirstName), ''%s'') or (ExternalId eq ''%s'')',
      [Uppercase(Value), Uppercase(Value), Uppercase(Value), Uppercase(Value)]);
  end;
  FGridPlugin.SetPreparedFilter(lShopQuery + lQuery);

  FBuildingQuery := False;
end;

procedure TDonorListForm.ShowDonationsRecord(const ARecordId: Integer);
var
  AForm: TDonations_List;
begin
  AForm := TDonations_List.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    AForm.DonorId := ARecordId;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    AForm.Free;
    AForm := nil;
  end;
end;

function TDonorListForm.InitSortCol: Integer;
begin
  Result := 0;
end;

function TDonorListForm.InitSortDirection: TSortDirection;
begin
  Result := sdDesc;
end;

procedure TDonorListForm.LoadData;
begin
  CreateGridPlugin;
  //Need to set the dataset to open but no data showing
  FFilterStr := 'Id eq -1';
  RefreshData;
end;

function TDonorListForm.CreateGridInFormCreate: boolean;
begin
  result := false;
end;

procedure TDonorListForm.MainDatasetAfterPost(DataSet: TDataSet);
var
  FirstName: string;
  LastName: string;
  PostCode: string;
begin
  inherited;

  if FAddDonor then
  begin
    FirstName := MainDataSet.FieldByName('FirstName').AsString;
    LastName := MainDataSet.FieldByName('LastName').AsString;
    PostCode := MainDataSet.FieldByName('PostCode').AsString;
    FFilterStr := Format('FirstName eq ''%s'' and LastName eq ''%s'' and PostCode eq ''%s''', [FirstName, LastName, PostCode]);
    FAddDonor := false;
    AddedDonor.Enabled := true;
  end;
end;

procedure TDonorListForm.WebFormShow(Sender: TObject);
begin
  inherited;
  LoadData;
end;

procedure TDonorListForm.MainDatasetAfterApplyUpdates(Sender: TDataSet; Info:
    TResolveResults);
var
  nIDX: integer;
begin
  inherited;
  nIDX := 0;
end;

procedure TDonorListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  AdvancedSearchButton := TButton.Create('AdvancedSearchButton');
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetTitle := TStringField.Create(Self);
  MainDatasetFirstName := TStringField.Create(Self);
  MainDatasetMiddleName := TStringField.Create(Self);
  MainDatasetLastName := TStringField.Create(Self);
  MainDatasetSuffix := TStringField.Create(Self);
  MainDatasetAdd1 := TStringField.Create(Self);
  MainDatasetAdd2 := TStringField.Create(Self);
  MainDatasetAdd3 := TStringField.Create(Self);
  MainDatasetCity := TStringField.Create(Self);
  MainDatasetPostCode := TStringField.Create(Self);
  MainDatasetEmail := TStringField.Create(Self);
  MainDatasetMobile := TStringField.Create(Self);
  MainDatasetTelephone := TStringField.Create(Self);
  MainDatasetMatchHash := TStringField.Create(Self);
  MainDatasetContactByPhone := TBooleanField.Create(Self);
  MainDatasetContactByPost := TBooleanField.Create(Self);
  MainDatasetContactByText := TBooleanField.Create(Self);
  MainDatasetContactByEmail := TBooleanField.Create(Self);
  MainDatasetDOB := TDateField.Create(Self);
  MainDatasetHearingStatus := TStringField.Create(Self);
  MainDatasetEmailStatus := TStringField.Create(Self);
  MainDatasetLatitude := TFloatField.Create(Self);
  MainDatasetLongitude := TFloatField.Create(Self);
  MainDatasetExternalId := TStringField.Create(Self);
  MainDatasetCountryCode := TStringField.Create(Self);
  MainDatasetVisualStatus := TStringField.Create(Self);
  MainDatasetMobilityStatus := TStringField.Create(Self);
  MainDatasetSourceType := TStringField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetCharityTenant := TStringField.Create(Self);
  MainDatasetClaimStatusText := TStringField.Create(Self);
  MainDatasetFullName := TStringField.Create(Self);
  MainDatasetFullAddress := TStringField.Create(Self);
  MainDatasetComments := TStringField.Create(Self);
  MainDatasetImportId := TIntegerField.Create(Self);
  MainDatasetCounty := TStringField.Create(Self);
  MainDatasetLocationType := TStringField.Create(Self);
  MainDatasetOrganisationName := TStringField.Create(Self);
  AddedDonor := TTimer.Create(Self);

  edSearch.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  AdvancedSearchButton.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetTitle.BeforeLoadDFMValues;
  MainDatasetFirstName.BeforeLoadDFMValues;
  MainDatasetMiddleName.BeforeLoadDFMValues;
  MainDatasetLastName.BeforeLoadDFMValues;
  MainDatasetSuffix.BeforeLoadDFMValues;
  MainDatasetAdd1.BeforeLoadDFMValues;
  MainDatasetAdd2.BeforeLoadDFMValues;
  MainDatasetAdd3.BeforeLoadDFMValues;
  MainDatasetCity.BeforeLoadDFMValues;
  MainDatasetPostCode.BeforeLoadDFMValues;
  MainDatasetEmail.BeforeLoadDFMValues;
  MainDatasetMobile.BeforeLoadDFMValues;
  MainDatasetTelephone.BeforeLoadDFMValues;
  MainDatasetMatchHash.BeforeLoadDFMValues;
  MainDatasetContactByPhone.BeforeLoadDFMValues;
  MainDatasetContactByPost.BeforeLoadDFMValues;
  MainDatasetContactByText.BeforeLoadDFMValues;
  MainDatasetContactByEmail.BeforeLoadDFMValues;
  MainDatasetDOB.BeforeLoadDFMValues;
  MainDatasetHearingStatus.BeforeLoadDFMValues;
  MainDatasetEmailStatus.BeforeLoadDFMValues;
  MainDatasetLatitude.BeforeLoadDFMValues;
  MainDatasetLongitude.BeforeLoadDFMValues;
  MainDatasetExternalId.BeforeLoadDFMValues;
  MainDatasetCountryCode.BeforeLoadDFMValues;
  MainDatasetVisualStatus.BeforeLoadDFMValues;
  MainDatasetMobilityStatus.BeforeLoadDFMValues;
  MainDatasetSourceType.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetCharityTenant.BeforeLoadDFMValues;
  MainDatasetClaimStatusText.BeforeLoadDFMValues;
  MainDatasetFullName.BeforeLoadDFMValues;
  MainDatasetFullAddress.BeforeLoadDFMValues;
  MainDatasetComments.BeforeLoadDFMValues;
  MainDatasetImportId.BeforeLoadDFMValues;
  MainDatasetCounty.BeforeLoadDFMValues;
  MainDatasetLocationType.BeforeLoadDFMValues;
  MainDatasetOrganisationName.BeforeLoadDFMValues;
  AddedDonor.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    edSearch.Hint := 'Any Id, Last Name, Post Code, 1st line of address';
    edSearch.ShowHint := True;
    edSearch.TextHint := 'Any Id, Last Name, Post Code, 1st line of address';
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'ExternalId';
      Title := 'External Id';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'FirstName';
      Title := 'First Name';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'LastName';
      Title := 'Surname';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'PostCode';
      Title := 'Post Code';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimStatusText';
      Title := 'Claim Status';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Telephone';
      Title := 'Telephone';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    AdvancedSearchButton.SetParentComponent(Self);
    AdvancedSearchButton.Name := 'AdvancedSearchButton';
    AdvancedSearchButton.Left := 383;
    AdvancedSearchButton.Top := 43;
    AdvancedSearchButton.Width := 37;
    AdvancedSearchButton.Height := 17;
    AdvancedSearchButton.Caption := 'Advanced Search';
    AdvancedSearchButton.ChildOrder := 10;
    AdvancedSearchButton.ElementClassName := 'btn btn-primary';
    AdvancedSearchButton.ElementFont := efCSS;
    AdvancedSearchButton.ElementPosition := epIgnore;
    AdvancedSearchButton.HeightStyle := ssAuto;
    AdvancedSearchButton.HeightPercent := 100.000000000000000000;
    AdvancedSearchButton.WidthStyle := ssAuto;
    AdvancedSearchButton.WidthPercent := 100.000000000000000000;
    SetEvent(AdvancedSearchButton, Self, 'OnClick', 'AdvancedSearchButtonClick');
    MainDataset.AfterApplyUpdates := MainDatasetAfterApplyUpdates;
    SetEvent(MainDataset, Self, 'OnCalcFields', 'MainDatasetCalcFields');
    MainDataset.EntitySetName := 'Donor';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetTitle.SetParentComponent(MainDataset);
    MainDatasetTitle.Name := 'MainDatasetTitle';
    MainDatasetTitle.FieldName := 'Title';
    MainDatasetTitle.Size := 25;
    MainDatasetFirstName.SetParentComponent(MainDataset);
    MainDatasetFirstName.Name := 'MainDatasetFirstName';
    MainDatasetFirstName.FieldName := 'FirstName';
    MainDatasetFirstName.Size := 50;
    MainDatasetMiddleName.SetParentComponent(MainDataset);
    MainDatasetMiddleName.Name := 'MainDatasetMiddleName';
    MainDatasetMiddleName.FieldName := 'MiddleName';
    MainDatasetMiddleName.Size := 50;
    MainDatasetLastName.SetParentComponent(MainDataset);
    MainDatasetLastName.Name := 'MainDatasetLastName';
    MainDatasetLastName.FieldName := 'LastName';
    MainDatasetLastName.Size := 50;
    MainDatasetSuffix.SetParentComponent(MainDataset);
    MainDatasetSuffix.Name := 'MainDatasetSuffix';
    MainDatasetSuffix.FieldName := 'Suffix';
    MainDatasetSuffix.Size := 50;
    MainDatasetAdd1.SetParentComponent(MainDataset);
    MainDatasetAdd1.Name := 'MainDatasetAdd1';
    MainDatasetAdd1.FieldName := 'Add1';
    MainDatasetAdd1.Size := 75;
    MainDatasetAdd2.SetParentComponent(MainDataset);
    MainDatasetAdd2.Name := 'MainDatasetAdd2';
    MainDatasetAdd2.FieldName := 'Add2';
    MainDatasetAdd2.Size := 75;
    MainDatasetAdd3.SetParentComponent(MainDataset);
    MainDatasetAdd3.Name := 'MainDatasetAdd3';
    MainDatasetAdd3.FieldName := 'Add3';
    MainDatasetAdd3.Size := 75;
    MainDatasetCity.SetParentComponent(MainDataset);
    MainDatasetCity.Name := 'MainDatasetCity';
    MainDatasetCity.FieldName := 'City';
    MainDatasetCity.Size := 75;
    MainDatasetPostCode.SetParentComponent(MainDataset);
    MainDatasetPostCode.Name := 'MainDatasetPostCode';
    MainDatasetPostCode.FieldName := 'PostCode';
    MainDatasetPostCode.Size := 15;
    MainDatasetEmail.SetParentComponent(MainDataset);
    MainDatasetEmail.Name := 'MainDatasetEmail';
    MainDatasetEmail.FieldName := 'Email';
    MainDatasetEmail.Size := 75;
    MainDatasetMobile.SetParentComponent(MainDataset);
    MainDatasetMobile.Name := 'MainDatasetMobile';
    MainDatasetMobile.FieldName := 'Mobile';
    MainDatasetMobile.Size := 25;
    MainDatasetTelephone.SetParentComponent(MainDataset);
    MainDatasetTelephone.Name := 'MainDatasetTelephone';
    MainDatasetTelephone.FieldName := 'Telephone';
    MainDatasetTelephone.Size := 25;
    MainDatasetMatchHash.SetParentComponent(MainDataset);
    MainDatasetMatchHash.Name := 'MainDatasetMatchHash';
    MainDatasetMatchHash.FieldName := 'MatchHash';
    MainDatasetMatchHash.Size := 64;
    MainDatasetContactByPhone.SetParentComponent(MainDataset);
    MainDatasetContactByPhone.Name := 'MainDatasetContactByPhone';
    MainDatasetContactByPhone.FieldName := 'ContactByPhone';
    MainDatasetContactByPhone.Required := True;
    MainDatasetContactByPost.SetParentComponent(MainDataset);
    MainDatasetContactByPost.Name := 'MainDatasetContactByPost';
    MainDatasetContactByPost.FieldName := 'ContactByPost';
    MainDatasetContactByPost.Required := True;
    MainDatasetContactByText.SetParentComponent(MainDataset);
    MainDatasetContactByText.Name := 'MainDatasetContactByText';
    MainDatasetContactByText.FieldName := 'ContactByText';
    MainDatasetContactByText.Required := True;
    MainDatasetContactByEmail.SetParentComponent(MainDataset);
    MainDatasetContactByEmail.Name := 'MainDatasetContactByEmail';
    MainDatasetContactByEmail.FieldName := 'ContactByEmail';
    MainDatasetContactByEmail.Required := True;
    MainDatasetDOB.SetParentComponent(MainDataset);
    MainDatasetDOB.Name := 'MainDatasetDOB';
    MainDatasetDOB.FieldName := 'DOB';
    MainDatasetHearingStatus.SetParentComponent(MainDataset);
    MainDatasetHearingStatus.Name := 'MainDatasetHearingStatus';
    MainDatasetHearingStatus.FieldName := 'HearingStatus';
    MainDatasetHearingStatus.Required := True;
    MainDatasetHearingStatus.Size := 8;
    MainDatasetEmailStatus.SetParentComponent(MainDataset);
    MainDatasetEmailStatus.Name := 'MainDatasetEmailStatus';
    MainDatasetEmailStatus.FieldName := 'EmailStatus';
    MainDatasetEmailStatus.Required := True;
    MainDatasetEmailStatus.Size := 10;
    MainDatasetLatitude.SetParentComponent(MainDataset);
    MainDatasetLatitude.Name := 'MainDatasetLatitude';
    MainDatasetLatitude.FieldName := 'Latitude';
    MainDatasetLongitude.SetParentComponent(MainDataset);
    MainDatasetLongitude.Name := 'MainDatasetLongitude';
    MainDatasetLongitude.FieldName := 'Longitude';
    MainDatasetExternalId.SetParentComponent(MainDataset);
    MainDatasetExternalId.Name := 'MainDatasetExternalId';
    MainDatasetExternalId.FieldName := 'ExternalId';
    MainDatasetExternalId.Size := 64;
    MainDatasetCountryCode.SetParentComponent(MainDataset);
    MainDatasetCountryCode.Name := 'MainDatasetCountryCode';
    MainDatasetCountryCode.FieldName := 'CountryCode';
    MainDatasetCountryCode.Size := 3;
    MainDatasetVisualStatus.SetParentComponent(MainDataset);
    MainDatasetVisualStatus.Name := 'MainDatasetVisualStatus';
    MainDatasetVisualStatus.FieldName := 'VisualStatus';
    MainDatasetVisualStatus.Required := True;
    MainDatasetVisualStatus.Size := 8;
    MainDatasetMobilityStatus.SetParentComponent(MainDataset);
    MainDatasetMobilityStatus.Name := 'MainDatasetMobilityStatus';
    MainDatasetMobilityStatus.FieldName := 'MobilityStatus';
    MainDatasetMobilityStatus.Required := True;
    MainDatasetMobilityStatus.Size := 8;
    MainDatasetSourceType.SetParentComponent(MainDataset);
    MainDatasetSourceType.Name := 'MainDatasetSourceType';
    MainDatasetSourceType.FieldName := 'SourceType';
    MainDatasetSourceType.Size := 9;
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    MainDatasetClaimStatus.Required := True;
    MainDatasetClaimStatus.Size := 19;
    MainDatasetCharityTenant.SetParentComponent(MainDataset);
    MainDatasetCharityTenant.Name := 'MainDatasetCharityTenant';
    MainDatasetCharityTenant.FieldName := 'CharityTenant';
    MainDatasetClaimStatusText.SetParentComponent(MainDataset);
    MainDatasetClaimStatusText.Name := 'MainDatasetClaimStatusText';
    MainDatasetClaimStatusText.FieldKind := fkCalculated;
    MainDatasetClaimStatusText.FieldName := 'ClaimStatusText';
    MainDatasetClaimStatusText.Calculated := True;
    MainDatasetFullName.SetParentComponent(MainDataset);
    MainDatasetFullName.Name := 'MainDatasetFullName';
    MainDatasetFullName.FieldKind := fkCalculated;
    MainDatasetFullName.FieldName := 'FullName';
    MainDatasetFullName.Calculated := True;
    MainDatasetFullAddress.SetParentComponent(MainDataset);
    MainDatasetFullAddress.Name := 'MainDatasetFullAddress';
    MainDatasetFullAddress.FieldKind := fkCalculated;
    MainDatasetFullAddress.FieldName := 'FullAddress';
    MainDatasetFullAddress.Calculated := True;
    MainDatasetComments.SetParentComponent(MainDataset);
    MainDatasetComments.Name := 'MainDatasetComments';
    MainDatasetComments.FieldName := 'Comments';
    MainDatasetComments.Size := 255;
    MainDatasetImportId.SetParentComponent(MainDataset);
    MainDatasetImportId.Name := 'MainDatasetImportId';
    MainDatasetImportId.FieldName := 'ImportId';
    MainDatasetCounty.SetParentComponent(MainDataset);
    MainDatasetCounty.Name := 'MainDatasetCounty';
    MainDatasetCounty.FieldName := 'County';
    MainDatasetCounty.Size := 75;
    MainDatasetLocationType.SetParentComponent(MainDataset);
    MainDatasetLocationType.Name := 'MainDatasetLocationType';
    MainDatasetLocationType.FieldName := 'LocationType';
    MainDatasetLocationType.Size := 8;
    MainDatasetOrganisationName.SetParentComponent(MainDataset);
    MainDatasetOrganisationName.Name := 'MainDatasetOrganisationName';
    MainDatasetOrganisationName.FieldName := 'OrganisationName';
    MainDatasetOrganisationName.Size := 250;
    AddedDonor.SetParentComponent(Self);
    AddedDonor.Name := 'AddedDonor';
    AddedDonor.Enabled := False;
    SetEvent(AddedDonor, Self, 'OnTimer', 'AddedDonorTimer');
    AddedDonor.Left := 384;
    AddedDonor.Top := 80;
  finally
    edSearch.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    AdvancedSearchButton.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetTitle.AfterLoadDFMValues;
    MainDatasetFirstName.AfterLoadDFMValues;
    MainDatasetMiddleName.AfterLoadDFMValues;
    MainDatasetLastName.AfterLoadDFMValues;
    MainDatasetSuffix.AfterLoadDFMValues;
    MainDatasetAdd1.AfterLoadDFMValues;
    MainDatasetAdd2.AfterLoadDFMValues;
    MainDatasetAdd3.AfterLoadDFMValues;
    MainDatasetCity.AfterLoadDFMValues;
    MainDatasetPostCode.AfterLoadDFMValues;
    MainDatasetEmail.AfterLoadDFMValues;
    MainDatasetMobile.AfterLoadDFMValues;
    MainDatasetTelephone.AfterLoadDFMValues;
    MainDatasetMatchHash.AfterLoadDFMValues;
    MainDatasetContactByPhone.AfterLoadDFMValues;
    MainDatasetContactByPost.AfterLoadDFMValues;
    MainDatasetContactByText.AfterLoadDFMValues;
    MainDatasetContactByEmail.AfterLoadDFMValues;
    MainDatasetDOB.AfterLoadDFMValues;
    MainDatasetHearingStatus.AfterLoadDFMValues;
    MainDatasetEmailStatus.AfterLoadDFMValues;
    MainDatasetLatitude.AfterLoadDFMValues;
    MainDatasetLongitude.AfterLoadDFMValues;
    MainDatasetExternalId.AfterLoadDFMValues;
    MainDatasetCountryCode.AfterLoadDFMValues;
    MainDatasetVisualStatus.AfterLoadDFMValues;
    MainDatasetMobilityStatus.AfterLoadDFMValues;
    MainDatasetSourceType.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetCharityTenant.AfterLoadDFMValues;
    MainDatasetClaimStatusText.AfterLoadDFMValues;
    MainDatasetFullName.AfterLoadDFMValues;
    MainDatasetFullAddress.AfterLoadDFMValues;
    MainDatasetComments.AfterLoadDFMValues;
    MainDatasetImportId.AfterLoadDFMValues;
    MainDatasetCounty.AfterLoadDFMValues;
    MainDatasetLocationType.AfterLoadDFMValues;
    MainDatasetOrganisationName.AfterLoadDFMValues;
    AddedDonor.AfterLoadDFMValues;
  end;
end;

end.
