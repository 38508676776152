 program GAOnline;

uses
  Vcl.Forms,
  WEBLib.Forms,
  SMX.Web.Layout.Utils in 'C:\SMXi Libraries\Web-Core-Utils\SMX.Web.Layout.Utils.pas',
  SMX.CRM.Utils in 'C:\SMXi Libraries\Web-Core-Utils\SMX.CRM.Utils.pas',
  SMX.Web.Document.Utils in 'C:\SMXi Libraries\Web-Core-Utils\SMX.Web.Document.Utils.pas',
  App.Types in '..\..\XData-Core\Web-Core\App.Types.pas',
  App.Config in '..\..\XData-Core\Web-Core\App.Config.pas',
  smx.webcore.types in '..\..\XData-Core\Web-Core\smx.webcore.types.pas',
  Main.WebForm.Core in '..\..\XData-Core\Web-Core\Main.WebForm.Core.pas'{*.html},
  WebForm.Core in '..\..\XData-Core\Web-Core\WebForm.Core.pas'{*.html},
  Dataset.Plugins in '..\..\XData-Core\Web-Core\Dataset.Plugins.pas',
  Grid.Plugins in '..\..\XData-Core\Web-Core\Grid.Plugins.pas',
  Grid.Settings in '..\..\XData-Core\Web-Core\Grid.Settings.pas',
  Paginator.Plugins in '..\..\XData-Core\Web-Core\Paginator.Plugins.pas',
  MainDataModule in 'MainDataModule.pas',
  BaseForm in '..\..\Web\BaseForm.pas'{*.html},
  BaseList in '..\..\Web\BaseList.pas'{*.html},
  DashboardForm in '..\..\Web\DashboardForm.pas'{*.html},
  ErrorForm in '..\..\Web\ErrorForm.pas'{*.html},
  SMX.Shared in '..\..\Shared\SMX.Shared.pas',
  UserForm in '..\..\Web\UserForm.pas'{*.html},
  UserList in '..\..\Web\UserList.pas'{*.html},
  JobDetailForm in '..\..\Web\JobDetailForm.pas'{*.html},
  SelfUpdateForm in '..\..\Web\SelfUpdateForm.pas'{*.html},
  JobBaseForm in '..\..\Web\JobBaseForm.pas'{*.html},
  JobErrorForm in '..\..\Web\JobErrorForm.pas'{*.html},
  SysConfigForm in '..\..\Web\SysConfigForm.pas'{*.html},
  DashDataForm in '..\..\Web\DashDataForm.pas'{*.html},
  DonorForm in '..\..\Web\DonorForm.pas'{*.html},
  DonorList in '..\..\Web\DonorList.pas'{*.html},
  GiftAidClaimsList in '..\..\Web\GiftAidClaimsList.pas'{*.html},
  DonorBaseViewForm in '..\..\Web\DonorBaseViewForm.pas'{*.html},
  AdvancedSearchForm in '..\..\Web\AdvancedSearchForm.pas'{*.html},
  DupesForm in '..\..\Web\DupesForm.pas'{*.html},
  DonationsForm in '..\..\Web\DonationsForm.pas'{*.html},
  ImportForm in '..\..\Web\ImportForm.pas',
  GiftAidDetailsForm in '..\..\Web\GiftAidDetailsForm.pas'{*.html},
  GiftAidClaimForm in '..\..\Web\GiftAidClaimForm.pas'{*.html},
  GAConfigForm in '..\..\Web\GAConfigForm.pas'{*.html},
  ImportBatch in '..\..\Web\ImportBatch.pas'{*.html},
  SMX.Web.Utils in 'C:\SMXi Libraries\Web-Core-Utils\SMX.Web.Utils.pas',
  InputQueryModule in '..\..\Web\InputQueryModule.pas'{*.html},
  Dashboard.ReturnTypes in '..\..\XData-Core\Reporting\Dashboard.ReturnTypes.pas',
  smx.Reports.Types in '..\..\XData-Core\Reporting\smx.Reports.Types.pas',
  smx.Dashboard.store in '..\..\XData-Core\Web-Core\smx.Dashboard.store.pas',
  DashboardManager in '..\..\XData-Core\Web-Core\DashboardManager.pas',
  SMX.Auth.Shared in '..\..\XData-Core\Auth-Shared\SMX.Auth.Shared.pas',
  DonationDataEdit in '..\..\Web\DonationDataEdit.pas'{*.html},
  DonationList in '..\..\Web\DonationList.pas'{*.html},
  ImportData in '..\..\Web\ImportData.pas'{*.html},
  ImportDataEdit in '..\..\Web\ImportDataEdit.pas'{*.html},
  ImportList in '..\..\Web\ImportList.pas'{*.html},
  SMX.Web.Service.Consts in '..\..\XData-Core\Web-Core\SMX.Web.Service.Consts.pas',
  SharedDataModule in '..\..\Web\SharedDataModule.pas',
  MainForm in '..\..\Web\MainForm.pas'{*.html},
  Auth.Service in 'Auth.Service.pas',
  smx.Reports.Support in '..\..\XData-Core\Reporting\smx.Reports.Support.pas',
  ManualEntryList in '..\..\Web\ManualEntryList.pas'{*.html},
  JobDetailBaseForm in '..\..\Web\JobDetailBaseForm.pas'{*.html},
  Job.DetailForm.Manager in '..\..\Web\Job.DetailForm.Manager.pas',
  OtherClaimsEdit in '..\..\Web\OtherClaimsEdit.pas'{*.html},
  OtherClaimsAdjustmentsEdit in '..\..\Web\OtherClaimsAdjustmentsEdit.pas'{*.html},
  OtherClaimsAdjustmentsList in '..\..\Web\OtherClaimsAdjustmentsList.pas'{*.html},
  OtherClaimsList in '..\..\Web\OtherClaimsList.pas'{*.html},
  CommunityBuildingsList in '..\..\Web\CommunityBuildingsList.pas'{*.html},
  CommunityBuildingEdit in '..\..\Web\CommunityBuildingEdit.pas'{*.html},
  OtherClaimsCommunityBuildingsList in '..\..\Web\OtherClaimsCommunityBuildingsList.pas'{*.html},
  OtherClaimsCommunityBuildingsEdit in '..\..\Web\OtherClaimsCommunityBuildingsEdit.pas'{*.html},
  ConnectedCharitiesList in '..\..\Web\ConnectedCharitiesList.pas'{*.html},
  ConnectedCharitiesEdit in '..\..\Web\ConnectedCharitiesEdit.pas'{*.html},
  OtherClaimsConnectedCharitiesList in '..\..\Web\OtherClaimsConnectedCharitiesList.pas'{*.html},
  OtherClaimsConnectedCharitiesEdit in '..\..\Web\OtherClaimsConnectedCharitiesEdit.pas'{*.html},
  CharityEdit in '..\..\Web\CharityEdit.pas'{*.html},
  ImportMappings in '..\..\Web\ImportMappings.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TMainData, MainData);
  Application.Run;
end.
