unit UserForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseForm,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Actions,
  WEBLib.WebCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Toast,
  Vcl.Imaging.GIFImg,
  smx.webcore.types;

type
  TUserEditForm = class(TBaseEditForm)
    UserIdLabel: TDBLabel;
    Title: TDBEdit;
    FirstName: TDBEdit;
    LastName: TDBEdit;
    Email: TDBEdit;
    Mobile: TDBEdit;
    Phone: TDBEdit;
    JobTitle: TDBEdit;
    UserLevel: TDBLookupComboBox;
    UserStatus: TDBComboBox;
    UserName: TDBEdit;
    UserNameWarning: THTMLSpan;
    EmailMessage: THTMLSpan;
    NewPasswordButton: TButton;
    procedure DataSourceDataChange(Sender: TObject; Field: TField);
    procedure DataSourceStateChange(Sender: TObject);
    procedure EmailExit(Sender: TObject);
    procedure NewPasswordButtonClick(Sender: TObject);
    procedure UserNameChange(Sender: TObject);
    procedure UserNameExit(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FUserNameStatus: TRefStatus;
    FValidatedUserName: string;
    FEmailValid: Boolean;

    [async]
    procedure CheckUserName; async;
    function DisplayName: string;
    procedure SetFormCaption;

    [async]
    procedure SendNewPassword; async;
  protected
    procedure AfterSetDataset; override;
    function CanSaveThis: Boolean; override;
    function GetRequiredEditLevel: string; override;
    function Validate(const AControlName: string): Boolean; override;
    procedure NewPasswordAllowed;
    procedure AfterApplyUpdates(Sender: TDataSet; Info: TResolveResults); override;
    function SaveTitle: string; override;
    function SaveDescription: string; override;
  public
    { Public declarations }
    procedure SetLookUpValues(const AName: string; Value: TLookupValues); override;
  protected procedure LoadDFMValues; override; end;

implementation

uses
  Auth.Service,
  XData.Web.Client,
  smx.Web.Document.Utils,
  SMX.CRM.Utils,
  MainDataModule,
  smx.Shared;

{$R *.dfm}

procedure TUserEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  UserLevel.LookupValues.AddPair('User', 'User');
  UserLevel.LookupValues.AddPair('AdminManager', 'Administrator');
  // if AuthService.LocationType = 'System' then
  UserLevel.LookupValues.AddPair('SuperUser', 'Super User');
  UserNameWarning.HTML.Text := '';
end;

{ TForm1 }

procedure TUserEditForm.AfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  inherited;
  ShowAToast(TCRMUtils.FullName(DataSource.Dataset), 'Your changes have been saved', 'contact.png');
end;

procedure TUserEditForm.AfterSetDataset;
begin
  inherited;
end;

function TUserEditForm.CanSaveThis: Boolean;
begin
  result := inherited;

  case FUserNameStatus of
    RefEmpty:
      ErrorList.Add('you must set a username');
    RefNotChecked:
      ;
    RefChecking:
      ErrorList.Add('The system is currently checking the username. Please save again');
    RefUnique:
      ;
    RefInUse:
      ErrorList.Add('This username is already being used, please select another one.');
  end;

  result := result and ((not IsErrorList) or (ErrorList.Count = 0));

  NewPasswordAllowed;
end;

procedure TUserEditForm.CheckUserName;
var
  lRetval: TXDataClientResponse;
  lOutcome: Boolean;
  lUserName: string;
begin
  lUserName := UserName.Text;
  if lUserName = FValidatedUserName then
    Exit;
  FValidatedUserName := lUserName;
  { TODO : To sort out - diff for sphinx }
//  lRetval := await(TXDataClientResponse, WebClient.RawInvokeAsync(SVC_CHECK_USERNAME, [lUserName]));
  lOutcome := Boolean(TJSObject(lRetval.result)['value']);
  if lOutcome then
  begin
    FUserNameStatus := RefUnique;
    UserNameWarning.HTML.Text := '<i class="fad fa-check"></i> OK';
    UserNameWarning.ElementClassName := 'text-success';
    UserNameWarning.Hint := '';
  end
  else
  begin
    FUserNameStatus := RefInUse;
    UserNameWarning.HTML.Text := '<i class="fad fa-exclamation-triangle"></i> In Use';
    UserNameWarning.ElementClassName := 'text-warning';
    UserNameWarning.Hint := 'This username is already in use';
  end;

end;

procedure TUserEditForm.DataSourceDataChange(Sender: TObject; Field: TField);
begin
  inherited;
  if Field = nil then
  begin
    SetFormCaption;

    UserName.Enabled := DataSource.State = TDatasetState.dsInsert;

    if DataSource.State <> TDatasetState.dsInsert then
    begin
      FUserNameStatus := RefUnique;
      UserNameWarning.HTML.Text := ''; // '<i class="fad fa-check"></i> OK';
      UserNameWarning.ElementClassName := 'text-success';
      UserNameWarning.Hint := '';
      if FLoading then
        UserStatus.SetFocus;
    end
    else
    begin
      if DataSource.Dataset.FieldByName('Id').AsString = '' then
      begin
        FUserNameStatus := RefEmpty;
        UserNameWarning.HTML.Text := '<i class="fad fa-user-tag"></i> Username Needed';
        UserNameWarning.ElementClassName := 'text-info';
      end
      else
        FUserNameStatus := RefNotChecked;
      if FLoading then
        UserName.SetFocus;
    end;

  end;
end;

procedure TUserEditForm.DataSourceStateChange(Sender: TObject);
begin
  inherited;
  NewPasswordAllowed;
end;

function TUserEditForm.DisplayName: string;
begin
  result := TCRMUtils.FullName(DataSource.Dataset);
  if result = '' then
    result := DataSource.Dataset.FieldByName('Username').AsString
end;

procedure TUserEditForm.EmailExit(Sender: TObject);
begin
  inherited;
  FEmailValid := TCRMUtils.IsEmailValid(Email.Text);
  if not FEmailValid then
  begin
    TDocUtils.addClass('Email', 'is-invalid');
    if DataSource.State = dsInsert then
      EmailMessage.HTML.Text := 'Without a valid email this user can''t be sent a password';
  end
  else
  begin
    TDocUtils.removeClass('Email', 'is-invalid');
    EmailMessage.HTML.Text := '';
  end;
end;

function TUserEditForm.GetRequiredEditLevel: string;
begin
  result := SCOPE_ADMIN;
end;

procedure TUserEditForm.NewPasswordAllowed;
begin
  NewPasswordButton.Enabled := (not(DataSource.State in dsEditModes)) and
    (DataSource.Dataset.FieldByName('Email').AsString <> '');
end;

procedure TUserEditForm.NewPasswordButtonClick(Sender: TObject);
begin
  SendNewPassword;
end;

function TUserEditForm.SaveDescription: string;
begin
  case FLastActionState of
    dsEdit:
      result := 'Your changes for ' + DisplayName + ' have been saved';
    dsInsert:
      result := DisplayName + ' has been added. If they have an email and username a password will be emailed to them.';
  else
    result := DisplayName;
  end;
end;

function TUserEditForm.SaveTitle: string;
begin
  case FLastActionState of
    dsEdit:
      result := 'Changes for ' + DisplayName;
    dsInsert:
      result := DisplayName + ' Added';
  else
    result := DisplayName;
  end;
end;

procedure TUserEditForm.SendNewPassword;
var
  lUserName: string;
begin
  lUserName := UserName.Text;
  { TODO : To sort out - diff for sphinx }
//  await(TXDataClientResponse, MainData.AuthClient.RawInvokeAsync(ILOGINSVC_FORGOTPASSWORD, [lUserName]));
  ShowAToast('Password', 'A new password has been sent to ' + DataSource.Dataset.FieldByName('FirstName').AsString,
    'password_field.png');
end;

procedure TUserEditForm.SetFormCaption;
var
  lName: string;
begin
  lName := DisplayName;
  if lName = '' then
    FormCaption.Caption := ActionText + 'a new user'
  else
    FormCaption.Caption := ActionText + lName;
end;

procedure TUserEditForm.SetLookUpValues(const AName: string; Value: TLookupValues);
begin

end;

procedure TUserEditForm.UserNameChange(Sender: TObject);
begin
  UserName.Text := Uppercase(UserName.Text);
end;

procedure TUserEditForm.UserNameExit(Sender: TObject);
begin
  if (DataSource.State = dsInsert) and (UserName.Text <> FValidatedUserName) then
  begin
    CheckUserName;
  end;
end;

function TUserEditForm.Validate(const AControlName: string): Boolean;
begin
  inherited;
end;

procedure TUserEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  UserIdLabel := TDBLabel.Create('UserIdLabel');
  FirstName := TDBEdit.Create('FirstName');
  LastName := TDBEdit.Create('LastName');
  Email := TDBEdit.Create('Email');
  Mobile := TDBEdit.Create('Mobile');
  Phone := TDBEdit.Create('Phone');
  JobTitle := TDBEdit.Create('JobTitle');
  UserLevel := TDBLookupComboBox.Create('UserLevel');
  UserStatus := TDBComboBox.Create('UserStatus');
  UserName := TDBEdit.Create('UserName');
  Title := TDBEdit.Create('Title');
  UserNameWarning := THTMLSpan.Create('UserNameWarning');
  EmailMessage := THTMLSpan.Create('EmailMessage');
  NewPasswordButton := TButton.Create('NewPasswordButton');

  UserIdLabel.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  FirstName.BeforeLoadDFMValues;
  LastName.BeforeLoadDFMValues;
  Email.BeforeLoadDFMValues;
  Mobile.BeforeLoadDFMValues;
  Phone.BeforeLoadDFMValues;
  JobTitle.BeforeLoadDFMValues;
  UserLevel.BeforeLoadDFMValues;
  UserStatus.BeforeLoadDFMValues;
  UserName.BeforeLoadDFMValues;
  Title.BeforeLoadDFMValues;
  UserNameWarning.BeforeLoadDFMValues;
  EmailMessage.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  DataState.BeforeLoadDFMValues;
  NewPasswordButton.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  EditElements.BeforeLoadDFMValues;
  try
    UserIdLabel.SetParentComponent(Self);
    UserIdLabel.Name := 'UserIdLabel';
    UserIdLabel.Left := 21;
    UserIdLabel.Top := 267;
    UserIdLabel.Width := 41;
    UserIdLabel.Height := 10;
    UserIdLabel.Caption := 'UserIdLabel';
    UserIdLabel.HeightPercent := 100.000000000000000000;
    UserIdLabel.WidthPercent := 100.000000000000000000;
    UserIdLabel.DataField := 'Id';
    UserIdLabel.DataSource := DataSource;
    CancelButton.TabOrder := 12;
    FirstName.SetParentComponent(Self);
    FirstName.Name := 'FirstName';
    FirstName.Left := 75;
    FirstName.Top := 89;
    FirstName.Width := 133;
    FirstName.Height := 14;
    FirstName.ChildOrder := 5;
    FirstName.ElementClassName := 'form-control';
    FirstName.HeightPercent := 100.000000000000000000;
    FirstName.TabOrder := 6;
    FirstName.Text := 'FirstName';
    FirstName.WidthPercent := 100.000000000000000000;
    FirstName.DataField := 'FirstName';
    FirstName.DataSource := DataSource;
    LastName.SetParentComponent(Self);
    LastName.Name := 'LastName';
    LastName.Left := 73;
    LastName.Top := 107;
    LastName.Width := 133;
    LastName.Height := 14;
    LastName.ChildOrder := 6;
    LastName.ElementClassName := 'form-control';
    LastName.HeightPercent := 100.000000000000000000;
    LastName.TabOrder := 7;
    LastName.Text := 'LastName';
    LastName.WidthPercent := 100.000000000000000000;
    LastName.DataField := 'LastName';
    LastName.DataSource := DataSource;
    Email.SetParentComponent(Self);
    Email.Name := 'Email';
    Email.Left := 73;
    Email.Top := 125;
    Email.Width := 133;
    Email.Height := 14;
    Email.AutoCompletion := acNone;
    Email.CharCase := wecLowerCase;
    Email.ChildOrder := 7;
    Email.ElementClassName := 'form-control';
    Email.HeightPercent := 100.000000000000000000;
    Email.TabOrder := 8;
    Email.Text := 'Email';
    Email.WidthPercent := 100.000000000000000000;
    SetEvent(Email, Self, 'OnExit', 'EmailExit');
    Email.DataField := 'Email';
    Email.DataSource := DataSource;
    Mobile.SetParentComponent(Self);
    Mobile.Name := 'Mobile';
    Mobile.Left := 73;
    Mobile.Top := 143;
    Mobile.Width := 133;
    Mobile.Height := 14;
    Mobile.AutoCompletion := acTelephone;
    Mobile.ChildOrder := 8;
    Mobile.ElementClassName := 'form-control';
    Mobile.HeightPercent := 100.000000000000000000;
    Mobile.TabOrder := 10;
    Mobile.Text := 'Mobile';
    Mobile.WidthPercent := 100.000000000000000000;
    Mobile.DataField := 'Mobile';
    Mobile.DataSource := DataSource;
    Phone.SetParentComponent(Self);
    Phone.Name := 'Phone';
    Phone.Left := 73;
    Phone.Top := 161;
    Phone.Width := 133;
    Phone.Height := 14;
    Phone.AutoCompletion := acTelephone;
    Phone.ChildOrder := 9;
    Phone.ElementClassName := 'form-control';
    Phone.HeightPercent := 100.000000000000000000;
    Phone.TabOrder := 9;
    Phone.Text := 'Phone';
    Phone.WidthPercent := 100.000000000000000000;
    Phone.DataField := 'Phone';
    Phone.DataSource := DataSource;
    JobTitle.SetParentComponent(Self);
    JobTitle.Name := 'JobTitle';
    JobTitle.Left := 222;
    JobTitle.Top := 53;
    JobTitle.Width := 133;
    JobTitle.Height := 14;
    JobTitle.ChildOrder := 11;
    JobTitle.ElementClassName := 'form-control';
    JobTitle.HeightPercent := 100.000000000000000000;
    JobTitle.TabOrder := 4;
    JobTitle.Text := 'JobTitle';
    JobTitle.WidthPercent := 100.000000000000000000;
    JobTitle.DataField := 'JobTitle';
    JobTitle.DataSource := DataSource;
    UserLevel.SetParentComponent(Self);
    UserLevel.Name := 'UserLevel';
    UserLevel.Left := 295;
    UserLevel.Top := 35;
    UserLevel.Width := 97;
    UserLevel.Height := 15;
    UserLevel.ElementClassName := 'form-select';
    UserLevel.HeightPercent := 100.000000000000000000;
    UserLevel.TabOrder := 2;
    UserLevel.WidthPercent := 100.000000000000000000;
    UserLevel.DataField := 'UserLevel';
    UserLevel.DataSource := DataSource;
    UserStatus.SetParentComponent(Self);
    UserStatus.Name := 'UserStatus';
    UserStatus.Left := 181;
    UserStatus.Top := 34;
    UserStatus.Width := 97;
    UserStatus.Height := 15;
    UserStatus.ElementClassName := 'form-select';
    UserStatus.HeightPercent := 100.000000000000000000;
    UserStatus.TabOrder := 1;
    UserStatus.Text := 'UserStatus';
    UserStatus.WidthPercent := 100.000000000000000000;
    UserStatus.ItemIndex := -1;
    UserStatus.Items.BeginUpdate;
    try
      UserStatus.Items.Clear;
      UserStatus.Items.Add('Active');
      UserStatus.Items.Add('Left');
      UserStatus.Items.Add('Retired');
      UserStatus.Items.Add('Suspended');
      UserStatus.Items.Add('Deleted');
    finally
      UserStatus.Items.EndUpdate;
    end;
    UserStatus.DataField := 'Status';
    UserStatus.DataSource := DataSource;
    UserName.SetParentComponent(Self);
    UserName.Name := 'UserName';
    UserName.Left := 32;
    UserName.Top := 35;
    UserName.Width := 131;
    UserName.Height := 15;
    UserName.AutoCompletion := acNone;
    UserName.CharCase := wecUpperCase;
    UserName.ChildOrder := 14;
    UserName.ElementClassName := 'form-control';
    UserName.HeightPercent := 100.000000000000000000;
    UserName.Required := True;
    UserName.Text := 'UserName';
    UserName.WidthPercent := 100.000000000000000000;
    SetEvent(UserName, Self, 'OnChange', 'UserNameChange');
    SetEvent(UserName, Self, 'OnExit', 'UserNameExit');
    UserName.DataField := 'UserName';
    UserName.DataSource := DataSource;
    Title.SetParentComponent(Self);
    Title.Name := 'Title';
    Title.Left := 73;
    Title.Top := 71;
    Title.Width := 133;
    Title.Height := 14;
    Title.ChildOrder := 4;
    Title.ElementClassName := 'form-control';
    Title.HeightPercent := 100.000000000000000000;
    Title.TabOrder := 5;
    Title.Text := 'Title';
    Title.WidthPercent := 100.000000000000000000;
    Title.DataField := 'Title';
    Title.DataSource := DataSource;
    UserNameWarning.SetParentComponent(Self);
    UserNameWarning.Name := 'UserNameWarning';
    UserNameWarning.Left := 222;
    UserNameWarning.Top := 76;
    UserNameWarning.Width := 67;
    UserNameWarning.Height := 27;
    UserNameWarning.HeightStyle := ssAuto;
    UserNameWarning.WidthStyle := ssAuto;
    UserNameWarning.ChildOrder := 16;
    UserNameWarning.ElementPosition := epIgnore;
    UserNameWarning.ElementFont := efCSS;
    UserNameWarning.Role := '';
    EmailMessage.SetParentComponent(Self);
    EmailMessage.Name := 'EmailMessage';
    EmailMessage.Left := 224;
    EmailMessage.Top := 112;
    EmailMessage.Width := 67;
    EmailMessage.Height := 27;
    EmailMessage.HeightStyle := ssAuto;
    EmailMessage.WidthStyle := ssAuto;
    EmailMessage.ChildOrder := 17;
    EmailMessage.ElementPosition := epIgnore;
    EmailMessage.ElementFont := efCSS;
    EmailMessage.Role := '';
    SaveButton.TabOrder := 11;
    DataState.Left := 325;
    DataState.Top := 76;
    NewPasswordButton.SetParentComponent(Self);
    NewPasswordButton.Name := 'NewPasswordButton';
    NewPasswordButton.Left := 192;
    NewPasswordButton.Top := 197;
    NewPasswordButton.Width := 64;
    NewPasswordButton.Height := 17;
    NewPasswordButton.Caption := 'New Password';
    NewPasswordButton.ChildOrder := 22;
    NewPasswordButton.ElementClassName := 'btn  btn-info';
    NewPasswordButton.ElementFont := efCSS;
    NewPasswordButton.ElementPosition := epIgnore;
    NewPasswordButton.HeightStyle := ssAuto;
    NewPasswordButton.HeightPercent := 100.000000000000000000;
    NewPasswordButton.TabOrder := 13;
    NewPasswordButton.WidthStyle := ssAuto;
    NewPasswordButton.WidthPercent := 100.000000000000000000;
    SetEvent(NewPasswordButton, Self, 'OnClick', 'NewPasswordButtonClick');
    DataSource.Left := 197;
    DataSource.Top := 5;
    EditElements.Left := 251;
    EditElements.Top := 5;
  finally
    UserIdLabel.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    FirstName.AfterLoadDFMValues;
    LastName.AfterLoadDFMValues;
    Email.AfterLoadDFMValues;
    Mobile.AfterLoadDFMValues;
    Phone.AfterLoadDFMValues;
    JobTitle.AfterLoadDFMValues;
    UserLevel.AfterLoadDFMValues;
    UserStatus.AfterLoadDFMValues;
    UserName.AfterLoadDFMValues;
    Title.AfterLoadDFMValues;
    UserNameWarning.AfterLoadDFMValues;
    EmailMessage.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    DataState.AfterLoadDFMValues;
    NewPasswordButton.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    EditElements.AfterLoadDFMValues;
  end;
end;

end.
