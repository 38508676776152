unit SMX.Auth.Shared;

interface

uses
  Sphinx.Consts;

{$SCOPEDENUMS ON}


{$IFDEF PAS2JS}
type
  TUserScope = (None, readonly, User, AdminUser, Administrator, SuperUser);
{$ENDIF}

const
  CLAIM_FIRSTNAME = JwtClaimNames.GivenName; // 'given_name';
  CLAIM_FULLNAME = JwtClaimNames.Name; // 'name';
  CLAIM_USERID = 'userid';
  CLAIM_LOCATIONTYPE = 'locationtype';
  CLAIM_SCOPE = 'scope';
  CLAIM_ROLE = 'role';
  CLAIM_TENANT_ID = 'tenant-id';  //DO NOT CHANGE THIS IS USED BY XData!!!//
  CLAIM_ORGANISATION_NAME = 'org-name';

  SCOPE_NONE = 'None';
  SCOPE_READONLY = 'ReadOnly';
  SCOPE_USER = 'User';
  SCOPE_ADMIN_USER = 'AdminUser';
  SCOPE_ADMIN = 'Administrator';
  SCOPE_SUPERUSER = 'SuperUser';

implementation

{$SCOPEDENUMS OFF}

end.
