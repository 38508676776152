unit OtherClaimsAdjustmentsList;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseList, WEBLib.ExtCtrls, WEBLib.Actions,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.Grids,
  WEBLib.DBCtrls, Vcl.Controls, WEBLib.Lists, Vcl.StdCtrls, WEBLib.StdCtrls,
  smx.webcore.types;

type
  TOtherClaimsAdjustmentsListForm = class(TBaseListForm)
    RefreshTimer: TTimer;
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetExternalId: TStringField;
    MainDatasetTransactionDate: TDateField;
    MainDatasetImportId: TIntegerField;
    MainDatasetClaimId: TIntegerField;
    MainDatasetGiftAid: TFloatField;
    MainDatasetAmount: TFloatField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetClaimDate: TDateTimeField;
    MainDatasetTaxYear: TIntegerField;
    MainDatasetErrorText: TStringField;
    MainDatasetCharityTenant: TStringField;
    MainDatasetSourceType: TStringField;
    MainDatasetComment: TStringField;
    MainDatasetAdjustmentType: TStringField;
    procedure AddButtonClick(Sender: TObject);
    procedure DataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer;
        AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure MainDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure RefreshTimerTimer(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    fOtherClaimsType: string;
    [async]
    procedure EditForm(RecordId: integer); async;
    procedure CancelSelectedRecord(RecordId: integer);

    procedure PrepareForm; override;
    procedure RefreshControls;
    [async]
    procedure ApplyFilters; async;
  protected
    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;
    function CreateGridInFormCreate: boolean; override;

  public
    { Public declarations }
    procedure LoadData; overload;
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsAdjustmentsListForm: TOtherClaimsAdjustmentsListForm;

implementation

{$R *.dfm}

uses
  OtherClaimsAdjustmentsEdit, MainDataModule, WEBLib.WebCtrls, SMX.Web.Layout.Utils,
  XData.Web.Client, SMX.Web.Utils;


procedure TOtherClaimsAdjustmentsListForm.AddButtonClick(Sender: TObject);
begin
  inherited;
  EditForm(0);
end;

procedure TOtherClaimsAdjustmentsListForm.PrepareForm;
begin
  FIdField := 'Id';
end;

procedure TOtherClaimsAdjustmentsListForm.RefreshControls;
var
  FilterStr: string;
begin
  FGridPlugin.SetPreparedFilter('ClaimStatus eq Unclaimed');
end;

procedure TOtherClaimsAdjustmentsListForm.ApplyFilters;
var
  lJobParams: JS.TJSObject;
  lRetval: TXDataClientResponse;
  FilterRequest: string;
begin

end;

procedure TOtherClaimsAdjustmentsListForm.DataTableGetCellChildren(Sender:
    TObject; ACol, ARow: Integer; AField: TField; AValue: string; AElement:
    TJSHTMLElementRecord);
var
  RecordId: integer;
  Span: THTMLSpan;


  procedure EditClick(Sender: TObject);
  begin
    EditForm(RecordId);
  end;

  procedure CancelClick(Sender: TObject);
  begin
    CancelSelectedRecord(RecordId);
  end;

begin
  if ARow = 0 then
    Exit;


  if not ((DataTable.Columns[ACol].Title = 'Actions') or (DataTable.Columns[ACol].Title = 'Type')) then
  begin
    Exit;
  end;

  RecordId := MainDataSet.FieldByName('Id').AsInteger;

  if (MainDataSet.FieldByName('ClaimStatus').AsString = 'Unclaimed') or
     (MainDataSet.FieldByName('ClaimStatus').AsString = 'Invalid') then
  begin
    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-edit', 'Edit');
    Span.OnClick := @EditClick;

    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-trash', 'Cancel');
    Span.OnClick := @CancelClick;
  end;

  if DataTable.Columns[ACol].Title = 'Type' then
  begin
    DataTable.Cells[ACol, ARow] :=
    TUtils.SplitOnCaps(MainDataSet.FieldByName('AdjustmentType').AsString);
  end;
end;

procedure TOtherClaimsAdjustmentsListForm.EditForm(RecordId: integer);
var
  AForm: TOtherClaimsAdjustmentsEditForm;

begin
  AForm := TOtherClaimsAdjustmentsEditForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());

    if RecordId = 0 then
      MainDataset.Insert
    else
    begin
      MainDataset.Locate('Id', RecordId, []);
      MainDataset.Edit;
    end;
    AForm.DataSet := MainDataset;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    if AForm.EditDataApplyUpdates then
    begin
      MainDataSet.ApplyUpdates;
    end;
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TOtherClaimsAdjustmentsListForm.LoadData;
begin
  CreateGridPlugin;
  RefreshControls;
end;

procedure TOtherClaimsAdjustmentsListForm.MainDatasetAfterApplyUpdates(Sender:
    TDataSet; Info: TResolveResults);
begin
  inherited;
  RefreshControls;
end;

procedure TOtherClaimsAdjustmentsListForm.RefreshTimerTimer(Sender: TObject);
begin
  RefreshTimer.Enabled := false;
  RefreshControls;
end;

function TOtherClaimsAdjustmentsListForm.InitSortCol: Integer;
begin
  Result := 0;
end;

function TOtherClaimsAdjustmentsListForm.InitSortDirection: TSortDirection;
begin
  Result := sdDesc;
end;

procedure TOtherClaimsAdjustmentsListForm.CancelSelectedRecord(RecordId: integer);
begin
  if MainDataSet.Locate('Id', RecordId, []) then
  begin
    try
      MainDataSet.Edit;
      MainDataSet.FieldByName('ClaimStatus').AsString := 'Cancelled';
      MainDataSet.Post;
    finally
      MainDataSet.ApplyUpdates;
    end;
  end;
end;

function TOtherClaimsAdjustmentsListForm.CreateGridInFormCreate: boolean;
begin
  result := false;
end;

procedure TOtherClaimsAdjustmentsListForm.WebFormShow(Sender: TObject);
begin
  inherited;
  LoadData;
end;


procedure TOtherClaimsAdjustmentsListForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetExternalId := TStringField.Create(Self);
  MainDatasetTransactionDate := TDateField.Create(Self);
  MainDatasetImportId := TIntegerField.Create(Self);
  MainDatasetClaimId := TIntegerField.Create(Self);
  MainDatasetGiftAid := TFloatField.Create(Self);
  MainDatasetAmount := TFloatField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetClaimDate := TDateTimeField.Create(Self);
  MainDatasetTaxYear := TIntegerField.Create(Self);
  MainDatasetErrorText := TStringField.Create(Self);
  MainDatasetCharityTenant := TStringField.Create(Self);
  MainDatasetSourceType := TStringField.Create(Self);
  MainDatasetComment := TStringField.Create(Self);
  MainDatasetAdjustmentType := TStringField.Create(Self);
  RefreshTimer := TTimer.Create(Self);

  cbPageSize.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetExternalId.BeforeLoadDFMValues;
  MainDatasetTransactionDate.BeforeLoadDFMValues;
  MainDatasetImportId.BeforeLoadDFMValues;
  MainDatasetClaimId.BeforeLoadDFMValues;
  MainDatasetGiftAid.BeforeLoadDFMValues;
  MainDatasetAmount.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetClaimDate.BeforeLoadDFMValues;
  MainDatasetTaxYear.BeforeLoadDFMValues;
  MainDatasetErrorText.BeforeLoadDFMValues;
  MainDatasetCharityTenant.BeforeLoadDFMValues;
  MainDatasetSourceType.BeforeLoadDFMValues;
  MainDatasetComment.BeforeLoadDFMValues;
  MainDatasetAdjustmentType.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    cbPageSize.Top := 44;
    DataTable.Top := 73;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'TransactionDate';
      Title := 'Transaction Date';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimStatus';
      Title := 'Claim Status';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'Comment';
      Title := 'Comment';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Type';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    MainDataset.AfterApplyUpdates := MainDatasetAfterApplyUpdates;
    MainDataset.EntitySetName := 'Adjustment';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetExternalId.SetParentComponent(MainDataset);
    MainDatasetExternalId.Name := 'MainDatasetExternalId';
    MainDatasetExternalId.FieldName := 'ExternalId';
    MainDatasetExternalId.Size := 64;
    MainDatasetTransactionDate.SetParentComponent(MainDataset);
    MainDatasetTransactionDate.Name := 'MainDatasetTransactionDate';
    MainDatasetTransactionDate.FieldName := 'TransactionDate';
    MainDatasetTransactionDate.Required := True;
    MainDatasetImportId.SetParentComponent(MainDataset);
    MainDatasetImportId.Name := 'MainDatasetImportId';
    MainDatasetImportId.FieldName := 'ImportId';
    MainDatasetClaimId.SetParentComponent(MainDataset);
    MainDatasetClaimId.Name := 'MainDatasetClaimId';
    MainDatasetClaimId.FieldName := 'ClaimId';
    MainDatasetGiftAid.SetParentComponent(MainDataset);
    MainDatasetGiftAid.Name := 'MainDatasetGiftAid';
    MainDatasetGiftAid.FieldName := 'GiftAid';
    MainDatasetGiftAid.Required := True;
    MainDatasetAmount.SetParentComponent(MainDataset);
    MainDatasetAmount.Name := 'MainDatasetAmount';
    MainDatasetAmount.FieldName := 'Amount';
    MainDatasetAmount.Required := True;
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    MainDatasetClaimStatus.Required := True;
    MainDatasetClaimStatus.Size := 10;
    MainDatasetClaimDate.SetParentComponent(MainDataset);
    MainDatasetClaimDate.Name := 'MainDatasetClaimDate';
    MainDatasetClaimDate.FieldName := 'ClaimDate';
    MainDatasetTaxYear.SetParentComponent(MainDataset);
    MainDatasetTaxYear.Name := 'MainDatasetTaxYear';
    MainDatasetTaxYear.FieldName := 'TaxYear';
    MainDatasetErrorText.SetParentComponent(MainDataset);
    MainDatasetErrorText.Name := 'MainDatasetErrorText';
    MainDatasetErrorText.FieldName := 'ErrorText';
    MainDatasetErrorText.Size := 1000;
    MainDatasetCharityTenant.SetParentComponent(MainDataset);
    MainDatasetCharityTenant.Name := 'MainDatasetCharityTenant';
    MainDatasetCharityTenant.FieldName := 'CharityTenant';
    MainDatasetSourceType.SetParentComponent(MainDataset);
    MainDatasetSourceType.Name := 'MainDatasetSourceType';
    MainDatasetSourceType.FieldName := 'SourceType';
    MainDatasetSourceType.Size := 9;
    MainDatasetComment.SetParentComponent(MainDataset);
    MainDatasetComment.Name := 'MainDatasetComment';
    MainDatasetComment.FieldName := 'Comment';
    MainDatasetComment.Size := 250;
    MainDatasetAdjustmentType.SetParentComponent(MainDataset);
    MainDatasetAdjustmentType.Name := 'MainDatasetAdjustmentType';
    MainDatasetAdjustmentType.FieldName := 'AdjustmentType';
    MainDatasetAdjustmentType.Size := 14;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 384;
    RefreshTimer.Top := 11;
  finally
    cbPageSize.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetExternalId.AfterLoadDFMValues;
    MainDatasetTransactionDate.AfterLoadDFMValues;
    MainDatasetImportId.AfterLoadDFMValues;
    MainDatasetClaimId.AfterLoadDFMValues;
    MainDatasetGiftAid.AfterLoadDFMValues;
    MainDatasetAmount.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetClaimDate.AfterLoadDFMValues;
    MainDatasetTaxYear.AfterLoadDFMValues;
    MainDatasetErrorText.AfterLoadDFMValues;
    MainDatasetCharityTenant.AfterLoadDFMValues;
    MainDatasetSourceType.AfterLoadDFMValues;
    MainDatasetComment.AfterLoadDFMValues;
    MainDatasetAdjustmentType.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
  end;
end;

end.
