unit SelfUpdateForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.DB,
  WEBLib.WebCtrls,
  XData.Web.Client,
  WEBLib.Toast,
  WebForm.Core;

type
  TSelfUpdate = class(TCoreWebForm)
    UserDataset: TXDataWebDataSet;
    DataSource: TDataSource;
    Phone: TDBEdit;
    Mobile: TDBEdit;
    Email: TDBEdit;
    LastName: TDBEdit;
    FirstName: TDBEdit;
    Title: TDBEdit;
    SaveButton: TButton;
    CancelButton: TButton;
    CurrentPassword: TEdit;
    NewPassword: TEdit;
    ConfirmPassword: TEdit;
    ChangePasswordButton: TButton;
    PasswordMessage: THTMLSpan;
    UpdaterDialog: TMessageDlg;
    procedure CancelButtonClick(Sender: TObject);
    procedure ChangePasswordButtonClick(Sender: TObject);
    procedure ConfirmPasswordChange(Sender: TObject);
    procedure ConfirmPasswordExit(Sender: TObject);
    procedure DataSourceDataChange(Sender: TObject; Field: TField);
    procedure DataSourceStateChange(Sender: TObject);
    procedure SaveButtonClick(Sender: TObject);
    procedure UserDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure UserDatasetAfterPost(Dataset: TDataSet);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    [async]
    procedure ChangePassword; async;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  SelfUpdate: TSelfUpdate;

implementation

uses
  MainDataModule,
  Auth.Service,
  smx.Web.Document.Utils, SMX.Web.Service.Consts;

{$R *.dfm}

procedure TSelfUpdate.CancelButtonClick(Sender: TObject);
begin
  UserDataset.Cancel;
end;

procedure TSelfUpdate.ChangePassword;
var
  nPW, cPW: string;
  lRetval: TXDataClientResponse;
  lResult: string;
begin

  { TODO : Sphinx needs to manage this }
  ButtonInvalid(ChangePasswordButton);
  {$IFDEF STANDALONE}
  nPW := Trim(NewPassword.Text);
  cPW := Trim(CurrentPassword.Text);
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(SVC_UPDATEPASSWORD, [cPW, nPW]));
  lResult := JS.toString(lRetval.ResultAsObject['value']);
  {$ENDIF}
  if lResult = 'OK' then
  begin
    UpdaterDialog.ShowDialog('Your password has been changed', WEBLib.Dialogs.mtInformation, [mbOK]);
  end
  else
  begin
    UpdaterDialog.ShowDialog('Your password has Not been changed: ' + lResult, WEBLib.Dialogs.mtWarning, [mbOK]);
  end;
  ButtonValid(ChangePasswordButton);
end;

procedure TSelfUpdate.ChangePasswordButtonClick(Sender: TObject);
begin
  ChangePassword;
end;

procedure TSelfUpdate.ConfirmPasswordChange(Sender: TObject);
var
  nPW, cPW: string;
begin
  nPW := Trim(NewPassword.Text);
  cPW := Trim(ConfirmPassword.Text);
  ChangePasswordButton.Enabled := (Trim(CurrentPassword.Text) <> '') and (nPW <> '') and (nPW = cPW);
end;

procedure TSelfUpdate.ConfirmPasswordExit(Sender: TObject);
var
  nPW, cPW: string;
begin
  nPW := Trim(NewPassword.Text);
  cPW := Trim(ConfirmPassword.Text);

  ChangePasswordButton.Enabled := (Trim(CurrentPassword.Text) <> '') and (nPW <> '') and (nPW = cPW);
  if ChangePasswordButton.Enabled then
  begin
    if nPW <> cPW then
    begin
      PasswordMessage.HTML.Text := 'Passwords don''t match';
      ControlInvalid('ConfirmPassword');
    end;
  end
  else
  begin
    PasswordMessage.HTML.Text := '';
    ControlNoValidity('ConfirmPassword');
  end;
end;

procedure TSelfUpdate.DataSourceDataChange(Sender: TObject; Field: TField);
begin
  if Field <> nil then
  begin
    ButtonValidity(SaveButton, UserDataset.Modified);
    CancelButton.Enabled := SaveButton.Enabled;
  end;
end;

procedure TSelfUpdate.DataSourceStateChange(Sender: TObject);
begin
  ButtonValidity(SaveButton, (UserDataset.State in dsEditModes) and UserDataset.Modified);
  CancelButton.Enabled := SaveButton.Enabled;
end;

procedure TSelfUpdate.SaveButtonClick(Sender: TObject);
begin
  if (UserDataset.State in dsEditModes) and UserDataset.Modified then
    UserDataset.Post;
end;

procedure TSelfUpdate.UserDatasetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  ShowAToast('Your Details', 'Your changes have been saved', 'contact.png');
end;

procedure TSelfUpdate.UserDatasetAfterPost(Dataset: TDataSet);
begin
  UserDataset.ApplyUpdates;
end;

procedure TSelfUpdate.WebFormCreate(Sender: TObject);
begin
  { TODO : Needs to be Sphinx Aware }
  PasswordMessage.HTML.Text := '';
  UserDataset.QueryString := '$filter=Id eq ' + AuthService.UserId.ToString;
  UserDataset.Load;
end;

procedure TSelfUpdate.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Phone := TDBEdit.Create('Phone');
  Mobile := TDBEdit.Create('Mobile');
  Email := TDBEdit.Create('Email');
  LastName := TDBEdit.Create('LastName');
  FirstName := TDBEdit.Create('FirstName');
  Title := TDBEdit.Create('Title');
  SaveButton := TButton.Create('SaveButton');
  CancelButton := TButton.Create('CancelButton');
  CurrentPassword := TEdit.Create('CurrentPassword');
  NewPassword := TEdit.Create('NewPassword');
  ConfirmPassword := TEdit.Create('ConfirmPassword');
  ChangePasswordButton := TButton.Create('ChangePasswordButton');
  PasswordMessage := THTMLSpan.Create('PasswordMessage');
  UpdaterDialog := TMessageDlg.Create(Self);
  UserDataset := TXDataWebDataSet.Create(Self);
  DataSource := TDataSource.Create(Self);

  Phone.BeforeLoadDFMValues;
  Mobile.BeforeLoadDFMValues;
  Email.BeforeLoadDFMValues;
  LastName.BeforeLoadDFMValues;
  FirstName.BeforeLoadDFMValues;
  Title.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  CurrentPassword.BeforeLoadDFMValues;
  NewPassword.BeforeLoadDFMValues;
  ConfirmPassword.BeforeLoadDFMValues;
  ChangePasswordButton.BeforeLoadDFMValues;
  PasswordMessage.BeforeLoadDFMValues;
  UpdaterDialog.BeforeLoadDFMValues;
  UserDataset.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    Phone.SetParentComponent(Self);
    Phone.Name := 'Phone';
    Phone.Left := 51;
    Phone.Top := 183;
    Phone.Width := 133;
    Phone.Height := 14;
    Phone.AutoCompletion := acTelephone;
    Phone.ChildOrder := 9;
    Phone.ElementClassName := 'form-control';
    Phone.ElementFont := efCSS;
    Phone.ElementPosition := epIgnore;
    Phone.HeightStyle := ssAuto;
    Phone.HeightPercent := 100.000000000000000000;
    Phone.TabOrder := 6;
    Phone.Text := 'Phone';
    Phone.WidthStyle := ssAuto;
    Phone.WidthPercent := 100.000000000000000000;
    Phone.DataField := 'Phone';
    Phone.DataSource := DataSource;
    Mobile.SetParentComponent(Self);
    Mobile.Name := 'Mobile';
    Mobile.Left := 51;
    Mobile.Top := 165;
    Mobile.Width := 133;
    Mobile.Height := 14;
    Mobile.AutoCompletion := acTelephone;
    Mobile.CharCase := wecLowerCase;
    Mobile.ChildOrder := 8;
    Mobile.ElementClassName := 'form-control';
    Mobile.ElementFont := efCSS;
    Mobile.ElementPosition := epIgnore;
    Mobile.HeightStyle := ssAuto;
    Mobile.HeightPercent := 100.000000000000000000;
    Mobile.TabOrder := 5;
    Mobile.Text := 'Mobile';
    Mobile.WidthStyle := ssAuto;
    Mobile.WidthPercent := 100.000000000000000000;
    Mobile.DataField := 'Mobile';
    Mobile.DataSource := DataSource;
    Email.SetParentComponent(Self);
    Email.Name := 'Email';
    Email.Left := 51;
    Email.Top := 147;
    Email.Width := 133;
    Email.Height := 14;
    Email.AutoCompletion := acEmail;
    Email.CharCase := wecLowerCase;
    Email.ChildOrder := 7;
    Email.ElementClassName := 'form-control';
    Email.ElementFont := efCSS;
    Email.ElementPosition := epIgnore;
    Email.HeightStyle := ssAuto;
    Email.HeightPercent := 100.000000000000000000;
    Email.TabOrder := 4;
    Email.Text := 'Email';
    Email.WidthStyle := ssAuto;
    Email.WidthPercent := 100.000000000000000000;
    Email.DataField := 'Email';
    Email.DataSource := DataSource;
    LastName.SetParentComponent(Self);
    LastName.Name := 'LastName';
    LastName.Left := 51;
    LastName.Top := 129;
    LastName.Width := 133;
    LastName.Height := 14;
    LastName.ChildOrder := 6;
    LastName.ElementClassName := 'form-control';
    LastName.ElementFont := efCSS;
    LastName.ElementPosition := epIgnore;
    LastName.HeightStyle := ssAuto;
    LastName.HeightPercent := 100.000000000000000000;
    LastName.TabOrder := 3;
    LastName.Text := 'LastName';
    LastName.WidthStyle := ssAuto;
    LastName.WidthPercent := 100.000000000000000000;
    LastName.DataField := 'LastName';
    LastName.DataSource := DataSource;
    FirstName.SetParentComponent(Self);
    FirstName.Name := 'FirstName';
    FirstName.Left := 51;
    FirstName.Top := 111;
    FirstName.Width := 133;
    FirstName.Height := 14;
    FirstName.ChildOrder := 5;
    FirstName.ElementClassName := 'form-control';
    FirstName.ElementFont := efCSS;
    FirstName.ElementPosition := epIgnore;
    FirstName.HeightStyle := ssAuto;
    FirstName.HeightPercent := 100.000000000000000000;
    FirstName.TabOrder := 2;
    FirstName.Text := 'FirstName';
    FirstName.WidthStyle := ssAuto;
    FirstName.WidthPercent := 100.000000000000000000;
    FirstName.DataField := 'FirstName';
    FirstName.DataSource := DataSource;
    Title.SetParentComponent(Self);
    Title.Name := 'Title';
    Title.Left := 51;
    Title.Top := 93;
    Title.Width := 133;
    Title.Height := 14;
    Title.ChildOrder := 4;
    Title.ElementClassName := 'form-control';
    Title.ElementFont := efCSS;
    Title.ElementPosition := epIgnore;
    Title.HeightStyle := ssAuto;
    Title.HeightPercent := 100.000000000000000000;
    Title.TabOrder := 1;
    Title.Text := 'Title';
    Title.WidthStyle := ssAuto;
    Title.WidthPercent := 100.000000000000000000;
    Title.DataField := 'Title';
    Title.DataSource := DataSource;
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 32;
    SaveButton.Top := 208;
    SaveButton.Width := 64;
    SaveButton.Height := 17;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 2;
    SaveButton.ElementClassName := 'btn btn-success';
    SaveButton.ElementFont := efCSS;
    SaveButton.ElementPosition := epIgnore;
    SaveButton.Enabled := False;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 7;
    SaveButton.WidthStyle := ssAuto;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 107;
    CancelButton.Top := 208;
    CancelButton.Width := 64;
    CancelButton.Height := 17;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 3;
    CancelButton.ElementClassName := 'btn btn-secondary';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.Enabled := False;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.TabOrder := 8;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    CurrentPassword.SetParentComponent(Self);
    CurrentPassword.Name := 'CurrentPassword';
    CurrentPassword.Left := 283;
    CurrentPassword.Top := 86;
    CurrentPassword.Width := 102;
    CurrentPassword.Height := 15;
    CurrentPassword.ChildOrder := 8;
    CurrentPassword.ElementFont := efCSS;
    CurrentPassword.ElementPosition := epIgnore;
    CurrentPassword.HeightStyle := ssAuto;
    CurrentPassword.HeightPercent := 100.000000000000000000;
    CurrentPassword.PasswordChar := '+';
    CurrentPassword.TabOrder := 9;
    CurrentPassword.WidthStyle := ssAuto;
    CurrentPassword.WidthPercent := 100.000000000000000000;
    SetEvent(CurrentPassword, Self, 'OnExit', 'ConfirmPasswordExit');
    NewPassword.SetParentComponent(Self);
    NewPassword.Name := 'NewPassword';
    NewPassword.Left := 283;
    NewPassword.Top := 105;
    NewPassword.Width := 102;
    NewPassword.Height := 15;
    NewPassword.ChildOrder := 9;
    NewPassword.ElementFont := efCSS;
    NewPassword.ElementPosition := epIgnore;
    NewPassword.HeightStyle := ssAuto;
    NewPassword.HeightPercent := 100.000000000000000000;
    NewPassword.PasswordChar := '*';
    NewPassword.TabOrder := 10;
    NewPassword.WidthStyle := ssAuto;
    NewPassword.WidthPercent := 100.000000000000000000;
    SetEvent(NewPassword, Self, 'OnExit', 'ConfirmPasswordExit');
    ConfirmPassword.SetParentComponent(Self);
    ConfirmPassword.Name := 'ConfirmPassword';
    ConfirmPassword.Left := 283;
    ConfirmPassword.Top := 123;
    ConfirmPassword.Width := 102;
    ConfirmPassword.Height := 15;
    ConfirmPassword.ChildOrder := 10;
    ConfirmPassword.ElementFont := efCSS;
    ConfirmPassword.ElementPosition := epIgnore;
    ConfirmPassword.HeightStyle := ssAuto;
    ConfirmPassword.HeightPercent := 100.000000000000000000;
    ConfirmPassword.PasswordChar := '*';
    ConfirmPassword.TabOrder := 11;
    ConfirmPassword.WidthStyle := ssAuto;
    ConfirmPassword.WidthPercent := 100.000000000000000000;
    SetEvent(ConfirmPassword, Self, 'OnChange', 'ConfirmPasswordChange');
    SetEvent(ConfirmPassword, Self, 'OnExit', 'ConfirmPasswordExit');
    ChangePasswordButton.SetParentComponent(Self);
    ChangePasswordButton.Name := 'ChangePasswordButton';
    ChangePasswordButton.Left := 283;
    ChangePasswordButton.Top := 183;
    ChangePasswordButton.Width := 64;
    ChangePasswordButton.Height := 17;
    ChangePasswordButton.Caption := 'Change';
    ChangePasswordButton.ChildOrder := 11;
    ChangePasswordButton.ElementClassName := 'btn btn-primary';
    ChangePasswordButton.ElementFont := efCSS;
    ChangePasswordButton.ElementPosition := epIgnore;
    ChangePasswordButton.Enabled := False;
    ChangePasswordButton.HeightStyle := ssAuto;
    ChangePasswordButton.HeightPercent := 100.000000000000000000;
    ChangePasswordButton.TabOrder := 12;
    ChangePasswordButton.WidthStyle := ssAuto;
    ChangePasswordButton.WidthPercent := 100.000000000000000000;
    SetEvent(ChangePasswordButton, Self, 'OnClick', 'ChangePasswordButtonClick');
    PasswordMessage.SetParentComponent(Self);
    PasswordMessage.Name := 'PasswordMessage';
    PasswordMessage.Left := 288;
    PasswordMessage.Top := 149;
    PasswordMessage.Width := 67;
    PasswordMessage.Height := 27;
    PasswordMessage.HeightStyle := ssAuto;
    PasswordMessage.WidthStyle := ssAuto;
    PasswordMessage.ChildOrder := 12;
    PasswordMessage.ElementPosition := epIgnore;
    PasswordMessage.ElementFont := efCSS;
    PasswordMessage.Role := '';
    UpdaterDialog.SetParentComponent(Self);
    UpdaterDialog.Name := 'UpdaterDialog';
    UpdaterDialog.Left := 304;
    UpdaterDialog.Top := 235;
    UpdaterDialog.Width := 16;
    UpdaterDialog.Height := 16;
    UpdaterDialog.HeightStyle := ssAuto;
    UpdaterDialog.WidthStyle := ssAuto;
    UpdaterDialog.Buttons := [];
    UpdaterDialog.Opacity := 0.200000000000000000;
    UpdaterDialog.ElementButtonClassName := 'btn btn-primary';
    UpdaterDialog.ElementDialogClassName := 'shadow-lg mb-5 bg-white rounded';
    UpdaterDialog.ElementTitleClassName := 'text-body';
    UpdaterDialog.ElementContentClassName := 'text-body';
    UserDataset.SetParentComponent(Self);
    UserDataset.Name := 'UserDataset';
    UserDataset.AfterApplyUpdates := UserDatasetAfterApplyUpdates;
    UserDataset.AfterPost := UserDatasetAfterPost;
    UserDataset.EntitySetName := 'User';
    UserDataset.Connection := MainData.DataConnection;
    UserDataset.Left := 75;
    UserDataset.Top := 48;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := UserDataset;
    SetEvent(DataSource, Self, 'OnStateChange', 'DataSourceStateChange');
    SetEvent(DataSource, Self, 'OnDataChange', 'DataSourceDataChange');
    DataSource.Left := 128;
    DataSource.Top := 48;
  finally
    Phone.AfterLoadDFMValues;
    Mobile.AfterLoadDFMValues;
    Email.AfterLoadDFMValues;
    LastName.AfterLoadDFMValues;
    FirstName.AfterLoadDFMValues;
    Title.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    CurrentPassword.AfterLoadDFMValues;
    NewPassword.AfterLoadDFMValues;
    ConfirmPassword.AfterLoadDFMValues;
    ChangePasswordButton.AfterLoadDFMValues;
    PasswordMessage.AfterLoadDFMValues;
    UpdaterDialog.AfterLoadDFMValues;
    UserDataset.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
  end;
end;

end.
