unit GiftAidClaimForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  WEBLib.ExtCtrls,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.WebCtrls,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls;

type
  TGiftAidClaimJob = class(TJobBase)
    AgreeCheckBox: TCheckBox;
    AdjustmentsEdit: TEdit;
    AdjustmentComments: TEdit;
    AuthorisedOfficial: TLookupComboBox;
    AuthOfficials: TXDataWebDataSet;
    AuthOfficialsId: TIntegerField;
    AuthOfficialsFirstName: TStringField;
    AuthOfficialsLastName: TStringField;
    ConfigDataset: TXDataWebDataSet;
    ConfigDatasetId: TIntegerField;
    ConfigDatasetHMRCOfficial: TIntegerField;
    ConfigDatasetCharityRef: TStringField;
    ConfigDatasetCharityType: TStringField;
    ConfigDatasetGatewayId: TStringField;
    ConfigDatasetGatewayPassword: TStringField;
    ConfigDatasetRegulator: TStringField;
    ConfigDatasetRegulatorNumber: TStringField;
    ConfigDatasetEndOfYear: TStringField;
    ConfigDatasetOrganisationType: TStringField;
    ConfigDatasetOrganisationName: TStringField;
    ConfigDatasetSubmissionMethod: TStringField;
    ErrorHandling: TLookupComboBox;
    JobsPlacedDateAdded: TDateTimeField;
    JobsPlacedAddedBy: TIntegerField;
    JobsPlacedLastUpdatedBy: TIntegerField;
    JobsPlacedLastUpdated: TDateTimeField;
    JobsPlacedJobCategory: TStringField;
    JobsPlacedLastPolled: TDateTimeField;
    JobsPlacedPollInterval: TLargeintField;
    JobsPlacedPollCount: TIntegerField;
    procedure WebFormCreate(Sender: TObject);
    procedure AdjustmentCommentsExit(Sender: TObject);
    procedure AgreeCheckBoxClick(Sender: TObject);
    procedure AuthOfficialsAfterOpen(Dataset: TDataSet);
    procedure ConfigDatasetAfterOpen(Dataset: TDataSet);
  private
    FUnclaimedAmount: Currency;
    FUnclaimedCount: Integer;
    [async]
    procedure LoadAuthOfficials; async;
    [async]
    procedure GetUnclaimedValues; async;
    { Private declarations }
  protected
    procedure JobParams(AJobParams: JS.TJSObject); override;
    function MinimumProcessLevel: string; override;
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function JobClass: string; override;
    function CanPlaceJob: Boolean; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  MainDataModule, XData.Web.Client;

procedure TGiftAidClaimJob.WebFormCreate(Sender: TObject);
begin
  inherited;

  OutputOption.Visible := False;
  JobRunType.Visible := False;

  GetUnclaimedValues;

  ErrorHandling.LookupValues.AddPair('OmitRecord', 'Omit Record');
  ErrorHandling.LookupValues.AddPair('CancelSubmission', 'Cancel Submission');
  ErrorHandling.ItemIndex := 0;

  ConfigDataset.Load;
end;

procedure TGiftAidClaimJob.AdjustmentCommentsExit(Sender: TObject);
begin
  ControlNoValidity(AdjustmentComments.ElementID);
end;

procedure TGiftAidClaimJob.AgreeCheckBoxClick(Sender: TObject);
begin
  ValidateJob;
end;

procedure TGiftAidClaimJob.AuthOfficialsAfterOpen(Dataset: TDataSet);
begin
  LoadAuthOfficials;
end;

{ TGiftAidClaimJob }

function TGiftAidClaimJob.CanPlaceJob: Boolean;
var
  sAdjust: string;
  lAdjust: Double;
begin
  result := inherited CanPlaceJob;
  if result then
  begin
    sAdjust := AdjustmentsEdit.Text;
    lAdjust := StrToFloatDef(sAdjust, 0);

    if (lAdjust > 0) and (Trim(AdjustmentComments.Text) = '') then
    begin
      ControlInvalid(AdjustmentComments.ElementID);
      SetJobMessage('You must include a comment in support of the adjustments.');
      Exit(False);
    end;

    result := AgreeCheckBox.Checked;

  end;
end;

procedure TGiftAidClaimJob.ConfigDatasetAfterOpen(Dataset: TDataSet);
const
  _Filter = '$filter=(Status eq ''Active'') and (HMRCValidation eq ''Validated'')';
begin
  inherited;
  AuthOfficials.QueryString := _Filter;
  AuthOfficials.Load;
end;

procedure TGiftAidClaimJob.GetUnclaimedValues;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderService.UnclaimedDonationSummary', []));
  lResult := lRetval.ResultAsObject;




end;

function TGiftAidClaimJob.JobClass: string;
begin
  result := 'TPrepareClaimJob'; //'TClaimGiftAidJob';
end;

function TGiftAidClaimJob.JobDescription: string;
begin
  result := 'Claiming Gift Aid - Preparation';
end;

function TGiftAidClaimJob.JobDisplayName: string;
begin
  result := 'Gift Aid Claim Preparation';
end;

procedure TGiftAidClaimJob.JobParams(AJobParams: JS.TJSObject);
begin
  inherited;
  AJobParams.Properties['Adjsutments'] := StrToFloatDef(AdjustmentsEdit.Text, 0);
  AJobParams.Properties['Comments'] := AdjustmentComments.Text;
  AJobParams.Properties['HMRCOfficial'] := StrToIntDef(AuthorisedOfficial.Value, 0);
  AJobParams.Properties['ErrorHandling'] := ErrorHandling.Value;
end;

procedure TGiftAidClaimJob.LoadAuthOfficials;
begin
  while not AuthOfficials.Eof do
  begin
    AuthorisedOfficial.LookupValues.AddPair(AuthOfficialsId.AsString, AuthOfficialsFirstName.Value + ' ' +
      AuthOfficialsLastName.Value);
    AuthOfficials.Next;
  end;

  if AuthOfficials.RecordCount = 1 then
    AuthorisedOfficial.ItemIndex := 0
  else
    AuthorisedOfficial.Value := ConfigDatasetHMRCOfficial.AsString;

end;

function TGiftAidClaimJob.MinimumProcessLevel: string;
begin
  result := 'AnyAdministrator';
end;


procedure TGiftAidClaimJob.LoadDFMValues;
begin
  inherited LoadDFMValues;

  AgreeCheckBox := TCheckBox.Create('AgreeCheckBox');
  AdjustmentsEdit := TEdit.Create('AdjustmentsEdit');
  AdjustmentComments := TEdit.Create('AdjustmentComments');
  AuthorisedOfficial := TLookupComboBox.Create('AuthorisedOfficial');
  ErrorHandling := TLookupComboBox.Create('ErrorHandling');
  JobsPlacedDateAdded := TDateTimeField.Create(Self);
  JobsPlacedAddedBy := TIntegerField.Create(Self);
  JobsPlacedLastUpdatedBy := TIntegerField.Create(Self);
  JobsPlacedLastUpdated := TDateTimeField.Create(Self);
  JobsPlacedJobCategory := TStringField.Create(Self);
  JobsPlacedLastPolled := TDateTimeField.Create(Self);
  JobsPlacedPollInterval := TLargeintField.Create(Self);
  JobsPlacedPollCount := TIntegerField.Create(Self);
  AuthOfficials := TXDataWebDataSet.Create(Self);
  AuthOfficialsId := TIntegerField.Create(Self);
  AuthOfficialsFirstName := TStringField.Create(Self);
  AuthOfficialsLastName := TStringField.Create(Self);
  ConfigDataset := TXDataWebDataSet.Create(Self);
  ConfigDatasetId := TIntegerField.Create(Self);
  ConfigDatasetHMRCOfficial := TIntegerField.Create(Self);
  ConfigDatasetCharityRef := TStringField.Create(Self);
  ConfigDatasetCharityType := TStringField.Create(Self);
  ConfigDatasetGatewayId := TStringField.Create(Self);
  ConfigDatasetGatewayPassword := TStringField.Create(Self);
  ConfigDatasetRegulator := TStringField.Create(Self);
  ConfigDatasetRegulatorNumber := TStringField.Create(Self);
  ConfigDatasetEndOfYear := TStringField.Create(Self);
  ConfigDatasetOrganisationType := TStringField.Create(Self);
  ConfigDatasetOrganisationName := TStringField.Create(Self);
  ConfigDatasetSubmissionMethod := TStringField.Create(Self);

  JobsPlacedTable.BeforeLoadDFMValues;
  OutputOption.BeforeLoadDFMValues;
  WaitMessage.BeforeLoadDFMValues;
  JobRunType.BeforeLoadDFMValues;
  AgreeCheckBox.BeforeLoadDFMValues;
  AdjustmentsEdit.BeforeLoadDFMValues;
  AdjustmentComments.BeforeLoadDFMValues;
  AuthorisedOfficial.BeforeLoadDFMValues;
  ErrorHandling.BeforeLoadDFMValues;
  JobsPlaced.BeforeLoadDFMValues;
  JobsPlacedDateAdded.BeforeLoadDFMValues;
  JobsPlacedAddedBy.BeforeLoadDFMValues;
  JobsPlacedLastUpdatedBy.BeforeLoadDFMValues;
  JobsPlacedLastUpdated.BeforeLoadDFMValues;
  JobsPlacedJobCategory.BeforeLoadDFMValues;
  JobsPlacedLastPolled.BeforeLoadDFMValues;
  JobsPlacedPollInterval.BeforeLoadDFMValues;
  JobsPlacedPollCount.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  AuthOfficials.BeforeLoadDFMValues;
  AuthOfficialsId.BeforeLoadDFMValues;
  AuthOfficialsFirstName.BeforeLoadDFMValues;
  AuthOfficialsLastName.BeforeLoadDFMValues;
  ConfigDataset.BeforeLoadDFMValues;
  ConfigDatasetId.BeforeLoadDFMValues;
  ConfigDatasetHMRCOfficial.BeforeLoadDFMValues;
  ConfigDatasetCharityRef.BeforeLoadDFMValues;
  ConfigDatasetCharityType.BeforeLoadDFMValues;
  ConfigDatasetGatewayId.BeforeLoadDFMValues;
  ConfigDatasetGatewayPassword.BeforeLoadDFMValues;
  ConfigDatasetRegulator.BeforeLoadDFMValues;
  ConfigDatasetRegulatorNumber.BeforeLoadDFMValues;
  ConfigDatasetEndOfYear.BeforeLoadDFMValues;
  ConfigDatasetOrganisationType.BeforeLoadDFMValues;
  ConfigDatasetOrganisationName.BeforeLoadDFMValues;
  ConfigDatasetSubmissionMethod.BeforeLoadDFMValues;
  try
    JobsPlacedTable.Left := 43;
    OutputOption.Top := 175;
    WaitMessage.Left := 69;
    WaitMessage.Top := 283;
    JobRunType.Visible := False;
    AgreeCheckBox.SetParentComponent(Self);
    AgreeCheckBox.Name := 'AgreeCheckBox';
    AgreeCheckBox.Left := 43;
    AgreeCheckBox.Top := 233;
    AgreeCheckBox.Width := 75;
    AgreeCheckBox.Height := 15;
    AgreeCheckBox.Caption := 'I Agree';
    AgreeCheckBox.ChildOrder := 10;
    AgreeCheckBox.ElementClassName := 'custom-control custom-checkbox';
    AgreeCheckBox.ElementButtonClassName := 'from-control-input';
    AgreeCheckBox.ElementLabelClassName := 'form-control-label';
    AgreeCheckBox.ElementFont := efCSS;
    AgreeCheckBox.ElementPosition := epIgnore;
    AgreeCheckBox.HeightStyle := ssAuto;
    AgreeCheckBox.HeightPercent := 100.000000000000000000;
    AgreeCheckBox.WidthStyle := ssAuto;
    AgreeCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(AgreeCheckBox, Self, 'OnClick', 'AgreeCheckBoxClick');
    AdjustmentsEdit.SetParentComponent(Self);
    AdjustmentsEdit.Name := 'AdjustmentsEdit';
    AdjustmentsEdit.Left := 139;
    AdjustmentsEdit.Top := 235;
    AdjustmentsEdit.Width := 81;
    AdjustmentsEdit.Height := 15;
    AdjustmentsEdit.ChildOrder := 11;
    AdjustmentsEdit.EditType := weFloat;
    AdjustmentsEdit.ElementClassName := 'form-control';
    AdjustmentsEdit.ElementFont := efCSS;
    AdjustmentsEdit.ElementPosition := epIgnore;
    AdjustmentsEdit.HeightStyle := ssAuto;
    AdjustmentsEdit.HeightPercent := 100.000000000000000000;
    AdjustmentsEdit.Text := '0.00';
    AdjustmentsEdit.WidthStyle := ssAuto;
    AdjustmentsEdit.WidthPercent := 100.000000000000000000;
    AdjustmentComments.SetParentComponent(Self);
    AdjustmentComments.Name := 'AdjustmentComments';
    AdjustmentComments.Left := 235;
    AdjustmentComments.Top := 235;
    AdjustmentComments.Width := 166;
    AdjustmentComments.Height := 15;
    AdjustmentComments.ChildOrder := 12;
    AdjustmentComments.ElementClassName := 'form-control';
    AdjustmentComments.ElementFont := efCSS;
    AdjustmentComments.ElementPosition := epIgnore;
    AdjustmentComments.HeightStyle := ssAuto;
    AdjustmentComments.HeightPercent := 100.000000000000000000;
    AdjustmentComments.WidthStyle := ssAuto;
    AdjustmentComments.WidthPercent := 100.000000000000000000;
    SetEvent(AdjustmentComments, Self, 'OnExit', 'AdjustmentCommentsExit');
    AuthorisedOfficial.SetParentComponent(Self);
    AuthorisedOfficial.Name := 'AuthorisedOfficial';
    AuthorisedOfficial.Left := 235;
    AuthorisedOfficial.Top := 256;
    AuthorisedOfficial.Width := 166;
    AuthorisedOfficial.Height := 15;
    AuthorisedOfficial.ElementClassName := 'form-select';
    AuthorisedOfficial.ElementFont := efCSS;
    AuthorisedOfficial.HeightPercent := 100.000000000000000000;
    AuthorisedOfficial.WidthPercent := 100.000000000000000000;
    AuthorisedOfficial.ItemIndex := -1;
    ErrorHandling.SetParentComponent(Self);
    ErrorHandling.Name := 'ErrorHandling';
    ErrorHandling.Left := 43;
    ErrorHandling.Top := 253;
    ErrorHandling.Width := 166;
    ErrorHandling.Height := 15;
    ErrorHandling.ElementClassName := 'form-select';
    ErrorHandling.ElementFont := efCSS;
    ErrorHandling.HeightPercent := 100.000000000000000000;
    ErrorHandling.WidthPercent := 100.000000000000000000;
    ErrorHandling.ItemIndex := -1;
    JobsPlacedDateAdded.SetParentComponent(JobsPlaced);
    JobsPlacedDateAdded.Name := 'JobsPlacedDateAdded';
    JobsPlacedDateAdded.FieldName := 'DateAdded';
    JobsPlacedDateAdded.Required := True;
    JobsPlacedAddedBy.SetParentComponent(JobsPlaced);
    JobsPlacedAddedBy.Name := 'JobsPlacedAddedBy';
    JobsPlacedAddedBy.FieldName := 'AddedBy';
    JobsPlacedAddedBy.Required := True;
    JobsPlacedLastUpdatedBy.SetParentComponent(JobsPlaced);
    JobsPlacedLastUpdatedBy.Name := 'JobsPlacedLastUpdatedBy';
    JobsPlacedLastUpdatedBy.FieldName := 'LastUpdatedBy';
    JobsPlacedLastUpdated.SetParentComponent(JobsPlaced);
    JobsPlacedLastUpdated.Name := 'JobsPlacedLastUpdated';
    JobsPlacedLastUpdated.FieldName := 'LastUpdated';
    JobsPlacedJobCategory.SetParentComponent(JobsPlaced);
    JobsPlacedJobCategory.Name := 'JobsPlacedJobCategory';
    JobsPlacedJobCategory.FieldName := 'JobCategory';
    JobsPlacedJobCategory.Size := 8;
    JobsPlacedLastPolled.SetParentComponent(JobsPlaced);
    JobsPlacedLastPolled.Name := 'JobsPlacedLastPolled';
    JobsPlacedLastPolled.FieldName := 'LastPolled';
    JobsPlacedPollInterval.SetParentComponent(JobsPlaced);
    JobsPlacedPollInterval.Name := 'JobsPlacedPollInterval';
    JobsPlacedPollInterval.FieldName := 'PollInterval';
    JobsPlacedPollCount.SetParentComponent(JobsPlaced);
    JobsPlacedPollCount.Name := 'JobsPlacedPollCount';
    JobsPlacedPollCount.FieldName := 'PollCount';
    CloseTimer.Left := 21;
    CloseTimer.Top := 283;
    AuthOfficials.SetParentComponent(Self);
    AuthOfficials.Name := 'AuthOfficials';
    AuthOfficials.AfterOpen := AuthOfficialsAfterOpen;
    AuthOfficials.EntitySetName := 'User';
    AuthOfficials.Connection := MainData.DataConnection;
    AuthOfficials.Left := 304;
    AuthOfficials.Top := 43;
    AuthOfficialsId.SetParentComponent(AuthOfficials);
    AuthOfficialsId.Name := 'AuthOfficialsId';
    AuthOfficialsId.FieldName := 'Id';
    AuthOfficialsFirstName.SetParentComponent(AuthOfficials);
    AuthOfficialsFirstName.Name := 'AuthOfficialsFirstName';
    AuthOfficialsFirstName.FieldName := 'FirstName';
    AuthOfficialsFirstName.Size := 50;
    AuthOfficialsLastName.SetParentComponent(AuthOfficials);
    AuthOfficialsLastName.Name := 'AuthOfficialsLastName';
    AuthOfficialsLastName.FieldName := 'LastName';
    AuthOfficialsLastName.Size := 50;
    ConfigDataset.SetParentComponent(Self);
    ConfigDataset.Name := 'ConfigDataset';
    ConfigDataset.AfterOpen := ConfigDatasetAfterOpen;
    ConfigDataset.EntitySetName := 'GiftAidParams';
    ConfigDataset.Connection := MainData.DataConnection;
    ConfigDataset.Left := 363;
    ConfigDataset.Top := 43;
    ConfigDatasetId.SetParentComponent(ConfigDataset);
    ConfigDatasetId.Name := 'ConfigDatasetId';
    ConfigDatasetId.FieldName := 'Id';
    ConfigDatasetId.Required := True;
    ConfigDatasetHMRCOfficial.SetParentComponent(ConfigDataset);
    ConfigDatasetHMRCOfficial.Name := 'ConfigDatasetHMRCOfficial';
    ConfigDatasetHMRCOfficial.FieldName := 'HMRCOfficial';
    ConfigDatasetHMRCOfficial.Required := True;
    ConfigDatasetCharityRef.SetParentComponent(ConfigDataset);
    ConfigDatasetCharityRef.Name := 'ConfigDatasetCharityRef';
    ConfigDatasetCharityRef.FieldName := 'CharityRef';
    ConfigDatasetCharityRef.Size := 50;
    ConfigDatasetCharityType.SetParentComponent(ConfigDataset);
    ConfigDatasetCharityType.Name := 'ConfigDatasetCharityType';
    ConfigDatasetCharityType.FieldName := 'CharityType';
    ConfigDatasetCharityType.Required := True;
    ConfigDatasetCharityType.Size := 15;
    ConfigDatasetGatewayId.SetParentComponent(ConfigDataset);
    ConfigDatasetGatewayId.Name := 'ConfigDatasetGatewayId';
    ConfigDatasetGatewayId.FieldName := 'GatewayId';
    ConfigDatasetGatewayId.Size := 50;
    ConfigDatasetGatewayPassword.SetParentComponent(ConfigDataset);
    ConfigDatasetGatewayPassword.Name := 'ConfigDatasetGatewayPassword';
    ConfigDatasetGatewayPassword.FieldName := 'GatewayPassword';
    ConfigDatasetGatewayPassword.Size := 64;
    ConfigDatasetRegulator.SetParentComponent(ConfigDataset);
    ConfigDatasetRegulator.Name := 'ConfigDatasetRegulator';
    ConfigDatasetRegulator.FieldName := 'Regulator';
    ConfigDatasetRegulator.Required := True;
    ConfigDatasetRegulator.Size := 5;
    ConfigDatasetRegulatorNumber.SetParentComponent(ConfigDataset);
    ConfigDatasetRegulatorNumber.Name := 'ConfigDatasetRegulatorNumber';
    ConfigDatasetRegulatorNumber.FieldName := 'RegulatorNumber';
    ConfigDatasetRegulatorNumber.Size := 50;
    ConfigDatasetEndOfYear.SetParentComponent(ConfigDataset);
    ConfigDatasetEndOfYear.Name := 'ConfigDatasetEndOfYear';
    ConfigDatasetEndOfYear.FieldName := 'EndOfYear';
    ConfigDatasetEndOfYear.Size := 5;
    ConfigDatasetOrganisationType.SetParentComponent(ConfigDataset);
    ConfigDatasetOrganisationType.Name := 'ConfigDatasetOrganisationType';
    ConfigDatasetOrganisationType.FieldName := 'OrganisationType';
    ConfigDatasetOrganisationType.Required := True;
    ConfigDatasetOrganisationType.Size := 16;
    ConfigDatasetOrganisationName.SetParentComponent(ConfigDataset);
    ConfigDatasetOrganisationName.Name := 'ConfigDatasetOrganisationName';
    ConfigDatasetOrganisationName.FieldName := 'OrganisationName';
    ConfigDatasetOrganisationName.Size := 250;
    ConfigDatasetSubmissionMethod.SetParentComponent(ConfigDataset);
    ConfigDatasetSubmissionMethod.Name := 'ConfigDatasetSubmissionMethod';
    ConfigDatasetSubmissionMethod.FieldName := 'SubmissionMethod';
    ConfigDatasetSubmissionMethod.Required := True;
    ConfigDatasetSubmissionMethod.Size := 4;
  finally
    JobsPlacedTable.AfterLoadDFMValues;
    OutputOption.AfterLoadDFMValues;
    WaitMessage.AfterLoadDFMValues;
    JobRunType.AfterLoadDFMValues;
    AgreeCheckBox.AfterLoadDFMValues;
    AdjustmentsEdit.AfterLoadDFMValues;
    AdjustmentComments.AfterLoadDFMValues;
    AuthorisedOfficial.AfterLoadDFMValues;
    ErrorHandling.AfterLoadDFMValues;
    JobsPlaced.AfterLoadDFMValues;
    JobsPlacedDateAdded.AfterLoadDFMValues;
    JobsPlacedAddedBy.AfterLoadDFMValues;
    JobsPlacedLastUpdatedBy.AfterLoadDFMValues;
    JobsPlacedLastUpdated.AfterLoadDFMValues;
    JobsPlacedJobCategory.AfterLoadDFMValues;
    JobsPlacedLastPolled.AfterLoadDFMValues;
    JobsPlacedPollInterval.AfterLoadDFMValues;
    JobsPlacedPollCount.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
    AuthOfficials.AfterLoadDFMValues;
    AuthOfficialsId.AfterLoadDFMValues;
    AuthOfficialsFirstName.AfterLoadDFMValues;
    AuthOfficialsLastName.AfterLoadDFMValues;
    ConfigDataset.AfterLoadDFMValues;
    ConfigDatasetId.AfterLoadDFMValues;
    ConfigDatasetHMRCOfficial.AfterLoadDFMValues;
    ConfigDatasetCharityRef.AfterLoadDFMValues;
    ConfigDatasetCharityType.AfterLoadDFMValues;
    ConfigDatasetGatewayId.AfterLoadDFMValues;
    ConfigDatasetGatewayPassword.AfterLoadDFMValues;
    ConfigDatasetRegulator.AfterLoadDFMValues;
    ConfigDatasetRegulatorNumber.AfterLoadDFMValues;
    ConfigDatasetEndOfYear.AfterLoadDFMValues;
    ConfigDatasetOrganisationType.AfterLoadDFMValues;
    ConfigDatasetOrganisationName.AfterLoadDFMValues;
    ConfigDatasetSubmissionMethod.AfterLoadDFMValues;
  end;
end;

end.
