unit MainDataModule;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Modules,
  WEBLib.Forms,
  XData.Web.Connection,
  XData.Web.Client,
  WEBLib.Sentry,
  App.Types,
  App.Config,
  Sphinx.WebLogin,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.ExtCtrls,
  Sphinx.LoginCommon,
  SMX.Auth.Shared,
  smx.Dashboard.store,
  Dashboard.ReturnTypes;

type
  TOnDashboardItemReady = procedure(AItem: TDashboardBase) of object;
  TOnClearDashboard = procedure of object;

  TMainData = class(TDataModule)
    WebClient: TXDataWebClient;
    DataConnection: TXDataWebConnection;
    WebSentry1: TSentry;
    SphinxLogin: TSphinxWebLogin;
    MainTimer: TTimer;
    procedure WebDataModuleDestroy(Sender: TObject);
    procedure DataConnectionRequest(Args: TXDataWebConnectionRequest);
    procedure SphinxLoginUserLoggedIn(Args: TUserLoggedInArgs);
    procedure WebDataModuleCreate(Sender: TObject);
  private
    { Private declarations }
    FErrorProc: TErrorMessageProc;
    FLastError: TApplicationError;
    FLastErrorTime: TDateTime;
    FErrorCount: Integer;
    FShowMessageProc: TShowMessageProc;
    FDashboardStore: TDashboardStore;
    FOnDashboardItem: TOnDashboardItemReady;
    FOnClearDashboard: TOnClearDashboard;
    FUserScope: TUserScope;

    procedure SentryErrorProc(E: Exception; const AComment: string; var AHandled: Boolean;
      const CallUIHandler: Boolean = True);

    procedure CheckConnection;

    [Async]
    procedure InitModule; async;
    [Async]
    procedure LoadConfig; async;
    function GetConfig: TAppConfig;
    [Async]
    procedure GetDashBoardList; async;
    procedure SetOnDashboardItem(const Value: TOnDashboardItemReady);
    [Async]
    procedure RefreshDashBoardItem(DashBoardId: Integer); async;
  protected
    function SentryAppId: string;
    function SentryDSN: string;
    function SphinxClientId: string;
    function SphinxRedirectUri: string;
    function SphinxScopes: string;
  public
    { Public declarations }
    procedure OnApplicationError(Sender: TObject; AError: TApplicationError; var Handled: Boolean);

    procedure PrepareDashboard;
    procedure ClearDashboard;

    property Config: TAppConfig read GetConfig;
    property ShowMessageProc: TShowMessageProc write FShowMessageProc;
    property ErrorProc: TErrorMessageProc write FErrorProc;
    property UserScope: TUserScope read FUserScope;

    property OnDashboardItem: TOnDashboardItemReady read FOnDashboardItem write SetOnDashboardItem;
    property OnClearDashboard: TOnClearDashboard read FOnClearDashboard write FOnClearDashboard;
  protected procedure LoadDFMValues; override; end;

var
  MainData: TMainData;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

uses
  System.DateUtils,
  System.Rtti,
  WEBLib.Rest,
  XData.Web.Request,
  XData.Web.Response,
  MainForm,
  SharedDataModule,
  smx.Reports.Support,
  smx.Reports.Types,
  DashboardManager,
  Auth.Service, SMX.JWT.Web.Helper;

{$R *.dfm}

const
  WEB_SENTRY_DSN = 'https://c17aa4b667554ef29e9954a14f27c8f8@o4504537489473536.ingest.sentry.io/4504854736207872';

procedure TMainData.WebDataModuleDestroy(Sender: TObject);
begin
  FDashboardStore.Free;
end;

procedure TMainData.CheckConnection;
var
  sURL: string;
  bConnected: boolean;
  bIsLoggedIn: boolean;
begin
  DataConnection.Connected := False;
  sURL := DataConnection.URL;
  bConnected := DataConnection.Connected;
  bIsLoggedIn := SphinxLogin.IsLoggedIn;

  if sURL = 'WOOF' then
    Sleep(50);

  if bConnected = true then
    Sleep(50);

  if bIsLoggedIn = true then
    Sleep(50);


  if (DataConnection.URL <> '') and (not DataConnection.Connected) and SphinxLogin.IsLoggedIn then
     DataConnection.Connected := True;

  bConnected := DataConnection.Connected;

  if bConnected = true then
    Sleep(50);


  if (not DataConnection.Connected) then
     raise Exception.Create('Could not connect to server');
end;

procedure TMainData.ClearDashboard;
begin
  FDashboardStore.Clear;
  if Assigned(FOnClearDashboard) then
    FOnClearDashboard();
end;

procedure TMainData.DataConnectionRequest(Args: TXDataWebConnectionRequest);
begin
  Args.Request.Headers.SetValue('Authorization', 'Bearer ' + SphinxLogin.AuthResult.AccessToken);
end;

function TMainData.GetConfig: TAppConfig;
begin
  Result := AppConfig;
end;

procedure TMainData.GetDashBoardList;
const
  IDashBoardSvc_MyDashBoard = 'IDashboardService.MyDashboard';
var
  lRetval: TXDataClientResponse;
  lList: JS.TJSArray;
  I: Integer;
begin
  FDashboardStore.Clear;
  CheckConnection;
  lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(IDashBoardSvc_MyDashBoard, []));
  lList := JS.ToArray(lRetval.ResultAsObject['value']);
  for I := 0 to lList.Length - 1 do
  begin
    RefreshDashBoardItem(JS.toInteger(lList[I]))
  end;
end;

procedure TMainData.InitModule;
var
  sStr1: string;
begin
  FErrorCount := 0;

{$IFDEF NO_SENTRY}
//do nothing
{$ELSE}
  WebSentry1.DSN := SentryDSN;
{$IFDEF RELEASE}
  WebSentry1.Release := SentryAppId + '.RELEASE@' + Application.Version;
{$ELSE}
  WebSentry1.Release := SentryAppId + '.DEBUG@' + Application.Version;
{$ENDIF}
  WebSentry1.Init;
{$ENDIF}
  FormatSettings.DateSeparator := '/';
  FormatSettings.ShortDateFormat := 'dd/mm/yyyy';


  {$IFDEF DEBUG}
  Application.ErrorType := aeFooter; // aeSilent, aeDialog, aeAlert, aeFooter
  {$ELSE}
  Application.ErrorType := aeSilent; // aeSilent, aeDialog, aeAlert, aeFooter
  Application.OnError := self.OnApplicationError;
  {$ENDIF}

  Await(LoadConfig);

  SharedData := TSharedData.Create(Application);
  SharedData.DataConnection := DataConnection;
  FDashboardStore := TDashboardStore.Create;

  SphinxLogin.ClientId := SphinxClientId;
  SphinxLogin.RedirectUri := SphinxRedirectUri;
  SphinxLogin.Scope := SphinxScopes;

  sStr1 := SphinxLogin.RedirectUri;

  if sStr1 = '' then
    sleep(50);




  SphinxLogin.Login;

end;

procedure TMainData.LoadConfig;
var
  Conn: TXDataWebConnection;
  Response: IHttpResponse;
begin

  Conn := TXDataWebConnection.Create(nil);
  try

    Response := Await(Conn.SendRequestAsync(THttpRequest.Create('config/config.json')));
    if Response.StatusCode = 200 then
    begin
      Config.Load(Response.ContentAsText);
      DataConnection.URL := Config.BaseUrl;
      SphinxLogin.Authority := Config.AuthURL;
    end;

  finally
    Conn.Free;
  end;

end;

procedure TMainData.OnApplicationError(Sender: TObject; AError: TApplicationError; var Handled: Boolean);
begin
  if (AError.ALineNumber = FLastError.ALineNumber) and (SecondsBetween(Now, FLastErrorTime) < 60) then
  begin
    Inc(FErrorCount);
  end
  else
  begin
    FLastError.ALineNumber := AError.ALineNumber;
    FErrorCount := 1;
  end;

  SentryErrorProc(EApplicationException.Create(AError, FErrorCount), '', Handled);

end;

procedure TMainData.PrepareDashboard;
begin
  GetDashBoardList;
end;

procedure TMainData.RefreshDashBoardItem(DashBoardId: Integer);
const
  IDashBoardSvc_DashboardItem = 'IDashboardService.DashBoardItem';
  // (const Id: Integer; const IdType: TReportIdType): TDashboardBase;
var
  lRetval: TXDataClientResponse;
  AItem: TDashboardBase;
  AObj: JS.TJSObject;
  lHandled: Boolean;
begin

  AItem := FDashboardStore.ItemById[DashBoardId];
  if (AItem = nil) or (TReportSupport.NeedsRefresh(AItem.Refresh, AItem.LastRefresh)) then
  begin
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(IDashBoardSvc_DashboardItem,
      [DashBoardId, ridMyDashboard]));

    AObj := lRetval.ResultAsObject;

    if AItem <> nil then
    begin
      FDashboardStore.UpdateFromJSON(AItem, AObj);
    end
    else
    begin
      AItem := FDashboardStore.AddFromJSON(AObj);
    end;

    if AItem.Status = TDashboardItemStatus.disError then
    begin
      lHandled := True;
      SentryErrorProc(EDashboardException.Create(AItem), '', lHandled, AuthService.IsSuperUser);
    end;

  end;

  if Assigned(FOnDashboardItem) then
  begin
    try
      FOnDashboardItem(AItem);
    except
    end;
  end;

end;

function TMainData.SentryAppId: string;
begin
  Result := Application.ExeName
end;

function TMainData.SentryDSN: string;
begin
  Result := WEB_SENTRY_DSN;
end;

procedure TMainData.SentryErrorProc(E: Exception; const AComment: string; var AHandled: Boolean;
  const CallUIHandler: Boolean);
var
  sendException: TJSObject;
begin

//  if Assigned(FErrorProc) then
//    FErrorProc('Error: ' + E.Message + '. NOT Logged at WebSentry');
//  Exit;

  if E.Message.Contains('FServerRecordCount') then
  begin
    // Swallow the excpetion - caused by a page being released before being fully loaded
{$IFDEF DEBUG}
    if CallUIHandler and Assigned(FErrorProc) then
      FErrorProc('Error: ' + E.Message + '. Not logged and Not notified in Release Version');
{$ENDIF}
    Exit;
  end;

{$IFDEF NO_SENTRY}
  if Assigned(FErrorProc) then
    FErrorProc('Error: ' + E.Message + '. NOT Logged at WebSentry');
{$ELSE}
  sendException := TJSObject(E);
  WebSentry1.CaptureException(sendException, AComment);
{$IFDEF RELEASE}
  if CallUIHandler and Assigned(FErrorProc) then
    FErrorProc('There has been an error and the developers have been notified');
  Exit;
{$ENDIF}
{$IFDEF DEBUG}
  if Assigned(FErrorProc) then
    FErrorProc('Error: ' + E.Message + '. Logged at WebSentry');
{$ENDIF}
{$ENDIF}
end;

procedure TMainData.SetOnDashboardItem(const Value: TOnDashboardItemReady);
var
  I: Integer;
begin
  FOnDashboardItem := Value;
  if Assigned(FOnDashboardItem) then
  begin
    for I := 0 to FDashboardStore.Count - 1 do
    begin
      try
        FOnDashboardItem(FDashboardStore.Items[I]);
      except
        on E: Exception do
        begin
          break;
        end;
      end;
    end;
  end;
end;

function TMainData.SphinxClientId: string;
begin
  Result := Config.SphinxClientId;
  if Result = '' then
     Result := 'GAMTWeb';
end;

procedure TMainData.SphinxLoginUserLoggedIn(Args: TUserLoggedInArgs);
var lURL: string;
bBool: boolean;
begin

  FUserScope := TRttiEnumerationType.GetValue<TUserScope>(AuthService.GetClaimValue(CLAIM_SCOPE));

  DataConnection.URL := Config.BaseUrl;

  lURL := DataConnection.URL;

  if lURL = '' then
    sleep(50);


  DataConnection.Connected := True;

  bBool := DataConnection.Connected;

  if bBool then
    Sleep(50);


  TMainPage.DisplayPage('content_here');
end;

function TMainData.SphinxRedirectUri: string;
var i: integer;
begin
  Result := Application.ExeName;
  i := Result.IndexOf('?');
  if i > 0 then
     Result := Result.Substring(0, i);
  i := Result.IndexOf('#');
  if i > 0 then
     Result := Result.Substring(0, i);
end;

function TMainData.SphinxScopes: string;
begin
  Result := 'openid email';
end;

procedure TMainData.WebDataModuleCreate(Sender: TObject);
begin
  InitModule;
end;

procedure TMainData.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebClient := TXDataWebClient.Create(Self);
  DataConnection := TXDataWebConnection.Create(Self);
  WebSentry1 := TSentry.Create(Self);
  SphinxLogin := TSphinxWebLogin.Create(Self);
  MainTimer := TTimer.Create(Self);

  WebClient.BeforeLoadDFMValues;
  DataConnection.BeforeLoadDFMValues;
  WebSentry1.BeforeLoadDFMValues;
  SphinxLogin.BeforeLoadDFMValues;
  MainTimer.BeforeLoadDFMValues;
  try
    Name := 'MainData';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    SetEvent(Self, 'OnDestroy', 'WebDataModuleDestroy');
    Height := 270;
    Width := 416;
    WebClient.SetParentComponent(Self);
    WebClient.Name := 'WebClient';
    WebClient.Connection := DataConnection;
    WebClient.Left := 40;
    WebClient.Top := 88;
    DataConnection.SetParentComponent(Self);
    DataConnection.Name := 'DataConnection';
    DataConnection.URL := 'http://localhost:8000/GAOnline/Index.html';
    SetEvent(DataConnection, Self, 'OnRequest', 'DataConnectionRequest');
    DataConnection.Left := 40;
    DataConnection.Top := 24;
    WebSentry1.SetParentComponent(Self);
    WebSentry1.Name := 'WebSentry1';
    WebSentry1.Enabled := True;
    WebSentry1.Release := '1.0';
    WebSentry1.Left := 176;
    WebSentry1.Top := 88;
    SphinxLogin.SetParentComponent(Self);
    SphinxLogin.Name := 'SphinxLogin';
    SetEvent(SphinxLogin, Self, 'OnUserLoggedIn', 'SphinxLoginUserLoggedIn');
    SphinxLogin.Left := 336;
    SphinxLogin.Top := 24;
    MainTimer.SetParentComponent(Self);
    MainTimer.Name := 'MainTimer';
    MainTimer.Enabled := False;
    MainTimer.Interval := 30000;
    MainTimer.Left := 336;
    MainTimer.Top := 192;
  finally
    WebClient.AfterLoadDFMValues;
    DataConnection.AfterLoadDFMValues;
    WebSentry1.AfterLoadDFMValues;
    SphinxLogin.AfterLoadDFMValues;
    MainTimer.AfterLoadDFMValues;
  end;
end;

end.
