unit CharityEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, Data.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.DB, Vcl.Controls, Vcl.StdCtrls, WEBLib.ExtCtrls,
  WEBLib.DBCtrls, WEBLib.StdCtrls, MainDataModule;

type
  TCharityEditForm = class(TCoreWebForm)
    MainDataset: TXDataWebDataSet;
    DataSource: TDataSource;
    edOrganisationName: TDBEdit;
    edHMRCOfficial: TDBEdit;
    cbCharityType: TDBLookupComboBox;
    edGatewayId: TDBEdit;
    cbRegulator: TDBLookupComboBox;
    edOtherRegulator: TDBEdit;
    edRegulatorNumber: TDBEdit;
    edEndOfYear: TDBEdit;
    edGatewayPassword: TDBEdit;
    cbOrganisationType: TDBLookupComboBox;
    cbSubmissionMethod: TDBLookupComboBox;
    cbRegulatorStatus: TDBLookupComboBox;
    cbGASDSelection: TDBLookupComboBox;
    EditButton: TButton;
    CloseButton: TButton;
    MainDatasetId: TIntegerField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetHMRCOfficial: TIntegerField;
    MainDatasetHMRCRef: TStringField;
    MainDatasetCharityType: TStringField;
    MainDatasetGatewayId: TStringField;
    MainDatasetRegulator: TStringField;
    MainDatasetOtherRegulator: TStringField;
    MainDatasetRegulatorNumber: TStringField;
    MainDatasetEndOfYear: TStringField;
    MainDatasetGatewayPassword: TStringField;
    MainDatasetOrganisationType: TStringField;
    MainDatasetOrganisationName: TStringField;
    MainDatasetSubmissionMethod: TStringField;
    MainDatasetRegulatorStatus: TStringField;
    MainDatasetGASDSelection: TStringField;
    SaveButton: TButton;
    procedure CloseButtonClick(Sender: TObject);
    procedure SaveButtonClick(Sender: TObject);
    procedure MainDatasetAfterPost(DataSet: TDataSet);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    procedure SetUpDropDowns;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  CharityEditForm: TCharityEditForm;

implementation

{$R *.dfm}

procedure TCharityEditForm.CloseButtonClick(Sender: TObject);
begin
  ModalResult := mrOk;
end;

procedure TCharityEditForm.SaveButtonClick(Sender: TObject);
begin
  MainDataSet.Post;
end;

procedure TCharityEditForm.SetUpDropDowns;
begin
  cbCharityType.LookupValues.AddPair('CharitableTrust', 'Charitable Trust');
  cbCharityType.LookupValues.AddPair('Other', 'Other');

  cbRegulator.LookupValues.AddPair('CCEW', 'Charity Commission for England and Wales');
  cbRegulator.LookupValues.AddPair('CCNI', 'Charity Commission for Northern Ireland');
  cbRegulator.LookupValues.AddPair('OSCR', 'Office of the ScottishCharity Regulator');
  cbRegulator.LookupValues.AddPair('OTH', 'Other');
  cbRegulator.LookupValues.AddPair('None', 'None');

  cbOrganisationType.LookupValues.AddPair('Individual', 'Individual');
  cbOrganisationType.LookupValues.AddPair('Company', 'Company');
  cbOrganisationType.LookupValues.AddPair('Agent', 'Agent');
  cbOrganisationType.LookupValues.AddPair('Bureau', 'Bureau');
  cbOrganisationType.LookupValues.AddPair('Partnership', 'Partnership');
  cbOrganisationType.LookupValues.AddPair('Trust', 'Trust');
  cbOrganisationType.LookupValues.AddPair('Employer', 'Employer');
  cbOrganisationType.LookupValues.AddPair('Government', 'Government');
  cbOrganisationType.LookupValues.AddPair('ActingInCapacity', 'Acting In Capacity');
  cbOrganisationType.LookupValues.AddPair('Other', 'Other');

  cbRegulatorStatus.LookupValues.AddPair('unknown', 'Unknown');
  cbRegulatorStatus.LookupValues.AddPair('active', 'Active');
  cbRegulatorStatus.LookupValues.AddPair('inactive', 'Inactive');

  cbGASDSelection.LookupValues.AddPair('GASD', 'Gift Aid Small Donations');
  cbGASDSelection.LookupValues.AddPair('CommunityBuildings', 'Community Buildings');

  cbSubmissionMethod.LookupValues.AddPair('Live', 'Live');
  cbSubmissionMethod.LookupValues.AddPair('LTS', 'LTS');
  cbSubmissionMethod.LookupValues.AddPair('ISV', 'ISV');

  MainDataset.QueryString := '$filter=Id eq 1';
  MainDataset.Load;
end;

procedure TCharityEditForm.MainDatasetAfterPost(DataSet: TDataSet);
begin
  MainDataset.ApplyUpdates;
end;

procedure TCharityEditForm.WebFormShow(Sender: TObject);
begin
  SetUpDropDowns;
end;

procedure TCharityEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  edOrganisationName := TDBEdit.Create('OrganisationNameEdit');
  edHMRCOfficial := TDBEdit.Create('HMRCOfficialEdit');
  cbCharityType := TDBLookupComboBox.Create('ClaimType');
  edGatewayId := TDBEdit.Create('GatewayIdEdit');
  cbRegulator := TDBLookupComboBox.Create('RegulatorCombo');
  edOtherRegulator := TDBEdit.Create('OtherRegulatorEdit');
  edRegulatorNumber := TDBEdit.Create('RegulatorNumberEdit');
  edEndOfYear := TDBEdit.Create('EndOfYearEdit');
  edGatewayPassword := TDBEdit.Create('GatewayPasswordEdit');
  cbOrganisationType := TDBLookupComboBox.Create('OrganisationTypeCombo');
  cbSubmissionMethod := TDBLookupComboBox.Create('SubmissionMethodCombo');
  cbRegulatorStatus := TDBLookupComboBox.Create('RegulatorStatusCombo');
  cbGASDSelection := TDBLookupComboBox.Create('GASDSelectionCombo');
  EditButton := TButton.Create('EditButton');
  CloseButton := TButton.Create('CloseButton');
  SaveButton := TButton.Create('SaveButton');
  MainDataset := TXDataWebDataSet.Create(Self);
  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetHMRCOfficial := TIntegerField.Create(Self);
  MainDatasetHMRCRef := TStringField.Create(Self);
  MainDatasetCharityType := TStringField.Create(Self);
  MainDatasetGatewayId := TStringField.Create(Self);
  MainDatasetRegulator := TStringField.Create(Self);
  MainDatasetOtherRegulator := TStringField.Create(Self);
  MainDatasetRegulatorNumber := TStringField.Create(Self);
  MainDatasetEndOfYear := TStringField.Create(Self);
  MainDatasetGatewayPassword := TStringField.Create(Self);
  MainDatasetOrganisationType := TStringField.Create(Self);
  MainDatasetOrganisationName := TStringField.Create(Self);
  MainDatasetSubmissionMethod := TStringField.Create(Self);
  MainDatasetRegulatorStatus := TStringField.Create(Self);
  MainDatasetGASDSelection := TStringField.Create(Self);
  DataSource := TDataSource.Create(Self);

  edOrganisationName.BeforeLoadDFMValues;
  edHMRCOfficial.BeforeLoadDFMValues;
  cbCharityType.BeforeLoadDFMValues;
  edGatewayId.BeforeLoadDFMValues;
  cbRegulator.BeforeLoadDFMValues;
  edOtherRegulator.BeforeLoadDFMValues;
  edRegulatorNumber.BeforeLoadDFMValues;
  edEndOfYear.BeforeLoadDFMValues;
  edGatewayPassword.BeforeLoadDFMValues;
  cbOrganisationType.BeforeLoadDFMValues;
  cbSubmissionMethod.BeforeLoadDFMValues;
  cbRegulatorStatus.BeforeLoadDFMValues;
  cbGASDSelection.BeforeLoadDFMValues;
  EditButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetHMRCOfficial.BeforeLoadDFMValues;
  MainDatasetHMRCRef.BeforeLoadDFMValues;
  MainDatasetCharityType.BeforeLoadDFMValues;
  MainDatasetGatewayId.BeforeLoadDFMValues;
  MainDatasetRegulator.BeforeLoadDFMValues;
  MainDatasetOtherRegulator.BeforeLoadDFMValues;
  MainDatasetRegulatorNumber.BeforeLoadDFMValues;
  MainDatasetEndOfYear.BeforeLoadDFMValues;
  MainDatasetGatewayPassword.BeforeLoadDFMValues;
  MainDatasetOrganisationType.BeforeLoadDFMValues;
  MainDatasetOrganisationName.BeforeLoadDFMValues;
  MainDatasetSubmissionMethod.BeforeLoadDFMValues;
  MainDatasetRegulatorStatus.BeforeLoadDFMValues;
  MainDatasetGASDSelection.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  try
    Width := 822;
    Height := 597;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    edOrganisationName.SetParentComponent(Self);
    edOrganisationName.Name := 'edOrganisationName';
    edOrganisationName.Left := 15;
    edOrganisationName.Top := 16;
    edOrganisationName.Width := 188;
    edOrganisationName.Height := 15;
    edOrganisationName.Hint := 'Organisation Name';
    edOrganisationName.ChildOrder := 9;
    edOrganisationName.ElementClassName := 'form-control';
    edOrganisationName.ElementPosition := epIgnore;
    edOrganisationName.HeightStyle := ssAuto;
    edOrganisationName.HeightPercent := 100.000000000000000000;
    edOrganisationName.ShowHint := True;
    edOrganisationName.TabOrder := 1;
    edOrganisationName.Text := 'edOrganisationName';
    edOrganisationName.WidthStyle := ssAuto;
    edOrganisationName.WidthPercent := 100.000000000000000000;
    edOrganisationName.DataField := 'OrganisationName';
    edOrganisationName.DataSource := DataSource;
    edHMRCOfficial.SetParentComponent(Self);
    edHMRCOfficial.Name := 'edHMRCOfficial';
    edHMRCOfficial.Left := 15;
    edHMRCOfficial.Top := 35;
    edHMRCOfficial.Width := 188;
    edHMRCOfficial.Height := 15;
    edHMRCOfficial.Hint := 'HMRC Official';
    edHMRCOfficial.ChildOrder := 9;
    edHMRCOfficial.ElementClassName := 'form-control';
    edHMRCOfficial.ElementPosition := epIgnore;
    edHMRCOfficial.HeightStyle := ssAuto;
    edHMRCOfficial.HeightPercent := 100.000000000000000000;
    edHMRCOfficial.ShowHint := True;
    edHMRCOfficial.Text := 'edHMRCOfficial';
    edHMRCOfficial.WidthStyle := ssAuto;
    edHMRCOfficial.WidthPercent := 100.000000000000000000;
    edHMRCOfficial.DataField := 'HMRCOfficial';
    edHMRCOfficial.DataSource := DataSource;
    cbCharityType.SetParentComponent(Self);
    cbCharityType.Name := 'cbCharityType';
    cbCharityType.Left := 15;
    cbCharityType.Top := 53;
    cbCharityType.Width := 131;
    cbCharityType.Height := 15;
    cbCharityType.Hint := 'Charity Type';
    cbCharityType.ElementClassName := 'form-select form-control';
    cbCharityType.ElementFont := efCSS;
    cbCharityType.ElementPosition := epIgnore;
    cbCharityType.HeightStyle := ssAuto;
    cbCharityType.HeightPercent := 100.000000000000000000;
    cbCharityType.ShowHint := True;
    cbCharityType.TabOrder := 12;
    cbCharityType.WidthStyle := ssAuto;
    cbCharityType.WidthPercent := 100.000000000000000000;
    cbCharityType.DataField := 'CharityType';
    cbCharityType.DataSource := DataSource;
    edGatewayId.SetParentComponent(Self);
    edGatewayId.Name := 'edGatewayId';
    edGatewayId.Left := 15;
    edGatewayId.Top := 72;
    edGatewayId.Width := 188;
    edGatewayId.Height := 15;
    edGatewayId.Hint := 'Gateway ID';
    edGatewayId.ChildOrder := 9;
    edGatewayId.ElementClassName := 'form-control';
    edGatewayId.ElementPosition := epIgnore;
    edGatewayId.HeightStyle := ssAuto;
    edGatewayId.HeightPercent := 100.000000000000000000;
    edGatewayId.ShowHint := True;
    edGatewayId.Text := 'edGatewayId';
    edGatewayId.WidthStyle := ssAuto;
    edGatewayId.WidthPercent := 100.000000000000000000;
    edGatewayId.DataField := 'GatewayId';
    edGatewayId.DataSource := DataSource;
    cbRegulator.SetParentComponent(Self);
    cbRegulator.Name := 'cbRegulator';
    cbRegulator.Left := 15;
    cbRegulator.Top := 91;
    cbRegulator.Width := 131;
    cbRegulator.Height := 15;
    cbRegulator.Hint := 'Regulator';
    cbRegulator.ElementClassName := 'form-select form-control';
    cbRegulator.ElementFont := efCSS;
    cbRegulator.ElementPosition := epIgnore;
    cbRegulator.HeightStyle := ssAuto;
    cbRegulator.HeightPercent := 100.000000000000000000;
    cbRegulator.ShowHint := True;
    cbRegulator.TabOrder := 12;
    cbRegulator.WidthStyle := ssAuto;
    cbRegulator.WidthPercent := 100.000000000000000000;
    cbRegulator.DataField := 'Regulator';
    cbRegulator.DataSource := DataSource;
    edOtherRegulator.SetParentComponent(Self);
    edOtherRegulator.Name := 'edOtherRegulator';
    edOtherRegulator.Left := 15;
    edOtherRegulator.Top := 109;
    edOtherRegulator.Width := 188;
    edOtherRegulator.Height := 15;
    edOtherRegulator.Hint := 'Other Regulator';
    edOtherRegulator.ChildOrder := 9;
    edOtherRegulator.ElementClassName := 'form-control';
    edOtherRegulator.ElementPosition := epIgnore;
    edOtherRegulator.HeightStyle := ssAuto;
    edOtherRegulator.HeightPercent := 100.000000000000000000;
    edOtherRegulator.ShowHint := True;
    edOtherRegulator.Text := 'edOtherRegulator';
    edOtherRegulator.WidthStyle := ssAuto;
    edOtherRegulator.WidthPercent := 100.000000000000000000;
    edOtherRegulator.DataField := 'OtherRegulator';
    edOtherRegulator.DataSource := DataSource;
    edRegulatorNumber.SetParentComponent(Self);
    edRegulatorNumber.Name := 'edRegulatorNumber';
    edRegulatorNumber.Left := 15;
    edRegulatorNumber.Top := 128;
    edRegulatorNumber.Width := 188;
    edRegulatorNumber.Height := 15;
    edRegulatorNumber.Hint := 'Regulator Number';
    edRegulatorNumber.ChildOrder := 9;
    edRegulatorNumber.ElementClassName := 'form-control';
    edRegulatorNumber.ElementPosition := epIgnore;
    edRegulatorNumber.HeightStyle := ssAuto;
    edRegulatorNumber.HeightPercent := 100.000000000000000000;
    edRegulatorNumber.ShowHint := True;
    edRegulatorNumber.Text := 'edRegulatorNumber';
    edRegulatorNumber.WidthStyle := ssAuto;
    edRegulatorNumber.WidthPercent := 100.000000000000000000;
    edRegulatorNumber.DataField := 'RegulatorNumber';
    edRegulatorNumber.DataSource := DataSource;
    edEndOfYear.SetParentComponent(Self);
    edEndOfYear.Name := 'edEndOfYear';
    edEndOfYear.Left := 15;
    edEndOfYear.Top := 147;
    edEndOfYear.Width := 71;
    edEndOfYear.Height := 15;
    edEndOfYear.Hint := 'End Of Year';
    edEndOfYear.ChildOrder := 9;
    edEndOfYear.ElementClassName := 'form-control';
    edEndOfYear.ElementPosition := epIgnore;
    edEndOfYear.HeightStyle := ssAuto;
    edEndOfYear.HeightPercent := 100.000000000000000000;
    edEndOfYear.ShowHint := True;
    edEndOfYear.Text := 'edEndOfYear';
    edEndOfYear.WidthStyle := ssAuto;
    edEndOfYear.WidthPercent := 100.000000000000000000;
    edEndOfYear.DataField := 'EndOfYear';
    edEndOfYear.DataSource := DataSource;
    edGatewayPassword.SetParentComponent(Self);
    edGatewayPassword.Name := 'edGatewayPassword';
    edGatewayPassword.Left := 15;
    edGatewayPassword.Top := 165;
    edGatewayPassword.Width := 188;
    edGatewayPassword.Height := 15;
    edGatewayPassword.Hint := 'Gateway Password';
    edGatewayPassword.ChildOrder := 9;
    edGatewayPassword.ElementClassName := 'form-control';
    edGatewayPassword.ElementPosition := epIgnore;
    edGatewayPassword.HeightStyle := ssAuto;
    edGatewayPassword.HeightPercent := 100.000000000000000000;
    edGatewayPassword.ShowHint := True;
    edGatewayPassword.Text := 'edGatewayPassword';
    edGatewayPassword.WidthStyle := ssAuto;
    edGatewayPassword.WidthPercent := 100.000000000000000000;
    edGatewayPassword.DataField := 'GatewayPassword';
    edGatewayPassword.DataSource := DataSource;
    cbOrganisationType.SetParentComponent(Self);
    cbOrganisationType.Name := 'cbOrganisationType';
    cbOrganisationType.Left := 16;
    cbOrganisationType.Top := 184;
    cbOrganisationType.Width := 131;
    cbOrganisationType.Height := 15;
    cbOrganisationType.Hint := 'Organisation Type';
    cbOrganisationType.ElementClassName := 'form-select form-control';
    cbOrganisationType.ElementFont := efCSS;
    cbOrganisationType.ElementPosition := epIgnore;
    cbOrganisationType.HeightStyle := ssAuto;
    cbOrganisationType.HeightPercent := 100.000000000000000000;
    cbOrganisationType.ShowHint := True;
    cbOrganisationType.TabOrder := 12;
    cbOrganisationType.WidthStyle := ssAuto;
    cbOrganisationType.WidthPercent := 100.000000000000000000;
    cbOrganisationType.DataField := 'OrganisationType';
    cbOrganisationType.DataSource := DataSource;
    cbSubmissionMethod.SetParentComponent(Self);
    cbSubmissionMethod.Name := 'cbSubmissionMethod';
    cbSubmissionMethod.Left := 15;
    cbSubmissionMethod.Top := 240;
    cbSubmissionMethod.Width := 131;
    cbSubmissionMethod.Height := 15;
    cbSubmissionMethod.Hint := 'Submission Method';
    cbSubmissionMethod.ElementClassName := 'form-select form-control';
    cbSubmissionMethod.ElementFont := efCSS;
    cbSubmissionMethod.ElementPosition := epIgnore;
    cbSubmissionMethod.HeightStyle := ssAuto;
    cbSubmissionMethod.HeightPercent := 100.000000000000000000;
    cbSubmissionMethod.ShowHint := True;
    cbSubmissionMethod.TabOrder := 12;
    cbSubmissionMethod.WidthStyle := ssAuto;
    cbSubmissionMethod.WidthPercent := 100.000000000000000000;
    cbSubmissionMethod.DataField := 'SubmissionMethod';
    cbSubmissionMethod.DataSource := DataSource;
    cbRegulatorStatus.SetParentComponent(Self);
    cbRegulatorStatus.Name := 'cbRegulatorStatus';
    cbRegulatorStatus.Left := 15;
    cbRegulatorStatus.Top := 203;
    cbRegulatorStatus.Width := 131;
    cbRegulatorStatus.Height := 15;
    cbRegulatorStatus.Hint := 'Regulator Status';
    cbRegulatorStatus.ElementClassName := 'form-select form-control';
    cbRegulatorStatus.ElementFont := efCSS;
    cbRegulatorStatus.ElementPosition := epIgnore;
    cbRegulatorStatus.HeightStyle := ssAuto;
    cbRegulatorStatus.HeightPercent := 100.000000000000000000;
    cbRegulatorStatus.ShowHint := True;
    cbRegulatorStatus.TabOrder := 12;
    cbRegulatorStatus.WidthStyle := ssAuto;
    cbRegulatorStatus.WidthPercent := 100.000000000000000000;
    cbRegulatorStatus.DataField := 'RegulatorStatus';
    cbRegulatorStatus.DataSource := DataSource;
    cbGASDSelection.SetParentComponent(Self);
    cbGASDSelection.Name := 'cbGASDSelection';
    cbGASDSelection.Left := 16;
    cbGASDSelection.Top := 221;
    cbGASDSelection.Width := 131;
    cbGASDSelection.Height := 15;
    cbGASDSelection.Hint := 'GASD Selection';
    cbGASDSelection.ElementClassName := 'form-select form-control';
    cbGASDSelection.ElementFont := efCSS;
    cbGASDSelection.ElementPosition := epIgnore;
    cbGASDSelection.HeightStyle := ssAuto;
    cbGASDSelection.HeightPercent := 100.000000000000000000;
    cbGASDSelection.ShowHint := True;
    cbGASDSelection.TabOrder := 12;
    cbGASDSelection.WidthStyle := ssAuto;
    cbGASDSelection.WidthPercent := 100.000000000000000000;
    cbGASDSelection.DataField := 'GASDSelection';
    cbGASDSelection.DataSource := DataSource;
    EditButton.SetParentComponent(Self);
    EditButton.Name := 'EditButton';
    EditButton.Left := 16;
    EditButton.Top := 289;
    EditButton.Width := 64;
    EditButton.Height := 17;
    EditButton.Caption := 'Edit';
    EditButton.ChildOrder := 13;
    EditButton.HeightPercent := 100.000000000000000000;
    EditButton.WidthPercent := 100.000000000000000000;
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 152;
    CloseButton.Top := 289;
    CloseButton.Width := 64;
    CloseButton.Height := 17;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 14;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 84;
    SaveButton.Top := 289;
    SaveButton.Width := 64;
    SaveButton.Height := 17;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 14;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    MainDataset.SetParentComponent(Self);
    MainDataset.Name := 'MainDataset';
    MainDataset.AfterPost := MainDatasetAfterPost;
    MainDataset.EntitySetName := 'Charity';
    MainDataset.Connection := MainData.DataConnection;
    MainDataset.Left := 661;
    MainDataset.Top := 21;
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetId.Required := True;
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetHMRCOfficial.SetParentComponent(MainDataset);
    MainDatasetHMRCOfficial.Name := 'MainDatasetHMRCOfficial';
    MainDatasetHMRCOfficial.FieldName := 'HMRCOfficial';
    MainDatasetHMRCRef.SetParentComponent(MainDataset);
    MainDatasetHMRCRef.Name := 'MainDatasetHMRCRef';
    MainDatasetHMRCRef.FieldName := 'HMRCRef';
    MainDatasetHMRCRef.Size := 50;
    MainDatasetCharityType.SetParentComponent(MainDataset);
    MainDatasetCharityType.Name := 'MainDatasetCharityType';
    MainDatasetCharityType.FieldName := 'CharityType';
    MainDatasetCharityType.Required := True;
    MainDatasetCharityType.Size := 15;
    MainDatasetGatewayId.SetParentComponent(MainDataset);
    MainDatasetGatewayId.Name := 'MainDatasetGatewayId';
    MainDatasetGatewayId.FieldName := 'GatewayId';
    MainDatasetGatewayId.Size := 50;
    MainDatasetRegulator.SetParentComponent(MainDataset);
    MainDatasetRegulator.Name := 'MainDatasetRegulator';
    MainDatasetRegulator.FieldName := 'Regulator';
    MainDatasetRegulator.Required := True;
    MainDatasetRegulator.Size := 4;
    MainDatasetOtherRegulator.SetParentComponent(MainDataset);
    MainDatasetOtherRegulator.Name := 'MainDatasetOtherRegulator';
    MainDatasetOtherRegulator.FieldName := 'OtherRegulator';
    MainDatasetOtherRegulator.Size := 250;
    MainDatasetRegulatorNumber.SetParentComponent(MainDataset);
    MainDatasetRegulatorNumber.Name := 'MainDatasetRegulatorNumber';
    MainDatasetRegulatorNumber.FieldName := 'RegulatorNumber';
    MainDatasetRegulatorNumber.Size := 50;
    MainDatasetEndOfYear.SetParentComponent(MainDataset);
    MainDatasetEndOfYear.Name := 'MainDatasetEndOfYear';
    MainDatasetEndOfYear.FieldName := 'EndOfYear';
    MainDatasetEndOfYear.Size := 5;
    MainDatasetGatewayPassword.SetParentComponent(MainDataset);
    MainDatasetGatewayPassword.Name := 'MainDatasetGatewayPassword';
    MainDatasetGatewayPassword.FieldName := 'GatewayPassword';
    MainDatasetGatewayPassword.Size := 64;
    MainDatasetOrganisationType.SetParentComponent(MainDataset);
    MainDatasetOrganisationType.Name := 'MainDatasetOrganisationType';
    MainDatasetOrganisationType.FieldName := 'OrganisationType';
    MainDatasetOrganisationType.Required := True;
    MainDatasetOrganisationType.Size := 16;
    MainDatasetOrganisationName.SetParentComponent(MainDataset);
    MainDatasetOrganisationName.Name := 'MainDatasetOrganisationName';
    MainDatasetOrganisationName.FieldName := 'OrganisationName';
    MainDatasetOrganisationName.Size := 250;
    MainDatasetSubmissionMethod.SetParentComponent(MainDataset);
    MainDatasetSubmissionMethod.Name := 'MainDatasetSubmissionMethod';
    MainDatasetSubmissionMethod.FieldName := 'SubmissionMethod';
    MainDatasetSubmissionMethod.Required := True;
    MainDatasetSubmissionMethod.Size := 4;
    MainDatasetRegulatorStatus.SetParentComponent(MainDataset);
    MainDatasetRegulatorStatus.Name := 'MainDatasetRegulatorStatus';
    MainDatasetRegulatorStatus.FieldName := 'RegulatorStatus';
    MainDatasetRegulatorStatus.Required := True;
    MainDatasetRegulatorStatus.Size := 8;
    MainDatasetGASDSelection.SetParentComponent(MainDataset);
    MainDatasetGASDSelection.Name := 'MainDatasetGASDSelection';
    MainDatasetGASDSelection.FieldName := 'GASDSelection';
    MainDatasetGASDSelection.Size := 18;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := MainDataset;
    DataSource.Left := 725;
    DataSource.Top := 21;
  finally
    edOrganisationName.AfterLoadDFMValues;
    edHMRCOfficial.AfterLoadDFMValues;
    cbCharityType.AfterLoadDFMValues;
    edGatewayId.AfterLoadDFMValues;
    cbRegulator.AfterLoadDFMValues;
    edOtherRegulator.AfterLoadDFMValues;
    edRegulatorNumber.AfterLoadDFMValues;
    edEndOfYear.AfterLoadDFMValues;
    edGatewayPassword.AfterLoadDFMValues;
    cbOrganisationType.AfterLoadDFMValues;
    cbSubmissionMethod.AfterLoadDFMValues;
    cbRegulatorStatus.AfterLoadDFMValues;
    cbGASDSelection.AfterLoadDFMValues;
    EditButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetHMRCOfficial.AfterLoadDFMValues;
    MainDatasetHMRCRef.AfterLoadDFMValues;
    MainDatasetCharityType.AfterLoadDFMValues;
    MainDatasetGatewayId.AfterLoadDFMValues;
    MainDatasetRegulator.AfterLoadDFMValues;
    MainDatasetOtherRegulator.AfterLoadDFMValues;
    MainDatasetRegulatorNumber.AfterLoadDFMValues;
    MainDatasetEndOfYear.AfterLoadDFMValues;
    MainDatasetGatewayPassword.AfterLoadDFMValues;
    MainDatasetOrganisationType.AfterLoadDFMValues;
    MainDatasetOrganisationName.AfterLoadDFMValues;
    MainDatasetSubmissionMethod.AfterLoadDFMValues;
    MainDatasetRegulatorStatus.AfterLoadDFMValues;
    MainDatasetGASDSelection.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
  end;
end;

end.