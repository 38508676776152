unit DonorDataEdit;

interface

uses
  System.SysUtils,
  System.Variants,
  System.Classes,
  Vcl.Graphics,
  Vcl.Controls,
  Vcl.Forms,
  Vcl.Dialogs,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Data.DB,
  WEBLib.DB,
  WEBLib.DBCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WebForm.Core;

type
  TDonorDataEditForm = class(TCoreWebForm)
    CloseButton: TWebButton;
    SaveButton: TWebButton;
    CancelButton: TWebButton;
    EditDataSource: TWebDataSource;
    TitleDonor: TWebDBEdit;
    FirstNameDonor: TWebDBEdit;
    MiddleNameDonor: TWebDBEdit;
    LastNameDonor: TWebDBEdit;
    Add1Donor: TWebDBEdit;
    Add2Donor: TWebDBEdit;
    Add3Donor: TWebDBEdit;
    CityDonor: TWebDBEdit;
    postCodeDonor: TWebDBEdit;
    EmailDonor: TWebDBEdit;
    MobileDonor: TWebDBEdit;
    TelephoneDonor: TWebDBEdit;
    ContactByPostDonor: TWebDBCheckBox;
    ContactByTextDonor: TWebDBCheckBox;
    ContactByEmailDonor: TWebDBCheckBox;
    ContactByPhoneDonor: TWebDBCheckBox;
    procedure WebFormCreate(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure SaveButtonClick(Sender: TObject);
    procedure CancelButtonClick(Sender: TObject);
    procedure ContactByClick(Sender: TObject);
    procedure EditDataSourceDataChange(Sender: TObject; Field: TField);
  private
    { Private declarations }
    fEditDataApplyUpdates: boolean;
    procedure SetDataSet(Value: TDataSet);
    procedure ResetButtons(Value: boolean);

  public
    { Public declarations }
    property Dataset: TDataSet write SetDataSet;
    property EditDataApplyUpdates: boolean read fEditDataApplyUpdates;
  end;

var
  DonorDataEditForm: TDonorDataEditForm;

implementation

{$R *.dfm}

uses
  DonationList;

procedure TDonorDataEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  fEditDataApplyUpdates := false;
end;

procedure TDonorDataEditForm.SetDataSet(Value: TDataSet);
begin
  EditDataSource.Dataset := Value;
end;

procedure TDonorDataEditForm.CloseButtonClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrOk;
end;

procedure TDonorDataEditForm.SaveButtonClick(Sender: TObject);
begin
  inherited;
  EditDataSource.Dataset.Post;
  fEditDataApplyUpdates := true;
  ResetButtons(false);
end;

procedure TDonorDataEditForm.CancelButtonClick(Sender: TObject);
begin
  inherited;
  EditDataSource.Dataset.Cancel;
  ResetButtons(false);
end;

procedure TDonorDataEditForm.ContactByClick(Sender: TObject);
begin
  ResetButtons(true);
end;

procedure TDonorDataEditForm.EditDataSourceDataChange(Sender: TObject; Field: TField);
begin
  if Field <> nil then
  begin
    ResetButtons(EditDataSource.Dataset.Modified);
  end;
end;

procedure TDonorDataEditForm.ResetButtons(Value: boolean);
begin
  SaveButton.Enabled := Value;
  CancelButton.Enabled := Value;
end;

end.
