unit MainForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Main.WebForm.Core,
  Sphinx.WebLogin,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Toast;

type
  TMainPage = class(TMainFormCore)
    GiftAidLink: TLabel;
    NewDonorsLabel: TLabel;
    DonorAdminLabel: TLabel;
    ImportLink: TLabel;
    ManualEntryLink: TLabel;
    UnclaimedDonationsLink: TLabel;
    MakeAClaimLink: TLabel;
    ManualDonationLink: TLabel;
    UpdateMeLink: TLabel;
    SysConfigLink: TLabel;
    OtherIncomeLink: TLabel;
    AggregatedDonationsLink: TLabel;
    GASDSLink: TLabel;
    GASDSAdjustmentsLink: TLabel;
    AdjustmentsLink: TLabel;
    CommunityBuildingsLink: TLabel;
    OtherClaimsCommunityBuildings: TLabel;
    ConnectedCharitiesLink: TLabel;
    OtherClaimsConnectedCharitiesLink: TLabel;
    OrganisationLink: TLabel;
    TenantNameLabel: TLabel;
    procedure AdjustmentsLinkClick(Sender: TObject);
    procedure AggregatedDonationsLinkClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure DonorAdminLabelClick(Sender: TObject);
    procedure GAConfigLinkClick(Sender: TObject);
    procedure GASDSAdjustmentsLinkClick(Sender: TObject);
    procedure GASDSLinkClick(Sender: TObject);
    procedure GiftAidLinkClick(Sender: TObject);
    procedure ImportLinkClick(Sender: TObject);
    procedure MakeAClaimLinkClick(Sender: TObject);
    procedure ManualDonationLinkClick(Sender: TObject);
    procedure ManualEntryLinkClick(Sender: TObject);
    procedure OtherIncomeLinkClick(Sender: TObject);
    procedure SysConfigLinkClick(Sender: TObject);
    procedure UnclaimedDonationsLinkClick(Sender: TObject);
    procedure UpdateMeLinkClick(Sender: TObject);
    procedure CommunityBuildingsLinkClick(Sender: TObject);
    procedure ConnectedCharitiesLinkClick(Sender: TObject);
    procedure LogoutLabelClick(Sender: TObject);
    procedure OrganisationLinkClick(Sender: TObject);
    procedure OtherClaimsCommunityBuildingsClick(Sender: TObject);
    procedure OtherClaimsConnectedCharitiesLinkClick(Sender: TObject);
  private
    { Private declarations }
    [async]
    procedure MakeAClaim; async;
    procedure SetLabelCaptions;

  public
    { Public declarations }
    class procedure DisplayPage(const AElementId: string);
  protected procedure LoadDFMValues; override; end;

var
  MainPage: TMainPage;

implementation

{$R *.dfm}

uses DonorList, GiftAidClaimsList, ImportList, DonationsForm, XData.Web.Client,
     MainDataModule, GiftAidClaimForm, SelfUpdateForm, SysConfigForm, GAConfigForm,
     ManualEntryList, OtherClaimsList, OtherClaimsAdjustmentsList, CommunityBuildingsList,
     OtherClaimsCommunityBuildingsList, ConnectedCharitiesList, OtherClaimsConnectedCharitiesList,
     CharityEdit, SMX.Auth.Shared, SMX.Web.Document.Utils, Auth.Service;


procedure TMainPage.WebFormCreate(Sender: TObject);
begin
  inherited;
end;

{ TMainAppForm }

class procedure TMainPage.DisplayPage(const AElementId: string);
  procedure AfterCreate(AForm: TObject);
  begin
    TMainPage(AForm).SetLabels;
    // TMainAppForm(AForm).SetLinksAuth;
  end;

begin
  if not Assigned(MainPage) then
  begin
    MainPage := TMainPage.CreateNew(AElementId, @AfterCreate);
  end;
end;

procedure TMainPage.DonorAdminLabelClick(Sender: TObject);
begin
  ShowForm(TDonorListForm);
end;

procedure TMainPage.GAConfigLinkClick(Sender: TObject);
begin
  ShowPopUp(TGAConfig);
end;

procedure TMainPage.GiftAidLinkClick(Sender: TObject);
begin
  ShowForm(TGiftAidClaims);
end;

procedure TMainPage.ImportLinkClick(Sender: TObject);
begin
   ShowForm(TImportListForm);
end;

procedure TMainPage.MakeAClaimLinkClick(Sender: TObject);
begin
  MakeAClaim;
end;

procedure TMainPage.ManualEntryLinkClick(Sender: TObject);
begin
  ShowForm(TManualEntryListForm);
end;

procedure TMainPage.UnclaimedDonationsLinkClick(Sender: TObject);

  procedure DonationsCreated(AForm: TObject);
  begin
    TDonationsList(AForm).ShowStatus := 'Unclaimed';
    TDonationsList(AForm).ShowCaption := 'Unclaimed Donations';
  end;

begin
  ShowForm(TDonationsList, @DonationsCreated);
end;

procedure TMainPage.MakeAClaim;
var
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  lCount: Integer;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderService.UnclaimedDonations', []));
  lResult := TJSObject(lRetval.Result);
  lCount := JS.toInteger(lResult['value']);

  if lCount = 0 then
  begin
    ShowMessage('There are no donations that are unclaimed');
    Exit;
  end
  else
    ShowPopUp(TGiftAidClaimJob);
end;

procedure TMainPage.ManualDonationLinkClick(Sender: TObject);

  procedure DonationsCreated(AForm: TObject);
  begin
    TDonationsList(AForm).ShowStatus := 'Unclaimed';
    TDonationsList(AForm).ShowCaption := 'Unclaimed Donations';
  end;

begin
  ShowForm(TDonationsList, @DonationsCreated);
end;

procedure TMainPage.SetLabelCaptions;
var lPath: string;
begin
  LoggedInUserLabel.Caption := AuthService.GetClaimValue(CLAIM_FIRSTNAME);
  TenantNameLabel.Caption := AuthService.GetClaimValue(CLAIM_ORGANISATION_NAME);

  lPath := 'tenants/' + AuthService.GetClaimValue(CLAIM_TENANT_ID) + '/tenant-logo.png';

  TDocUtils.writeImageSrc('tenant-logo', lPath);

end;

procedure TMainPage.SysConfigLinkClick(Sender: TObject);
begin
  ShowPopUp(TSysConfig);
end;

procedure TMainPage.UpdateMeLinkClick(Sender: TObject);
begin
  ShowForm(TSelfUpdate);
end;

procedure TMainPage.OtherIncomeLinkClick(Sender: TObject);

  procedure SetOtherClaimsType(AForm: TObject);
  begin
    TOtherClaimsListForm(AForm).OtherClaimsType := 'OtherIncome';
    TOtherClaimsListForm(AForm).OtherClaimsTitle := 'Other Income';
  end;

begin
  ShowForm(TOtherClaimsListForm, @SetOtherClaimsType, true);
end;

procedure TMainPage.AggregatedDonationsLinkClick(Sender: TObject);

  procedure SetOtherClaimsType(AForm: TObject);
  begin
    TOtherClaimsListForm(AForm).OtherClaimsType := 'AggregatedDonations';
    TOtherClaimsListForm(AForm).OtherClaimsTitle := 'Aggregated Donations';
  end;

begin
  ShowForm(TOtherClaimsListForm, @SetOtherClaimsType, true);
end;

procedure TMainPage.GASDSLinkClick(Sender: TObject);

  procedure SetOtherClaimsType(AForm: TObject);
  begin
    TOtherClaimsListForm(AForm).OtherClaimsType := 'GASDS';
    TOtherClaimsListForm(AForm).OtherClaimsTitle := 'GASDS (Gift Aid Small Donations Scheme)';
  end;

begin
  ShowForm(TOtherClaimsListForm, @SetOtherClaimsType, true);
end;

procedure TMainPage.GASDSAdjustmentsLinkClick(Sender: TObject);

  procedure SetOtherClaimsType(AForm: TObject);
  begin
    TOtherClaimsListForm(AForm).OtherClaimsType := 'GASDSAdjustment';
    TOtherClaimsListForm(AForm).OtherClaimsTitle := 'GASDS Adjustment';
  end;

begin
  ShowForm(TOtherClaimsListForm, @SetOtherClaimsType, true);
end;

procedure TMainPage.AdjustmentsLinkClick(Sender: TObject);
begin
  ShowForm(TOtherClaimsAdjustmentsListForm);
end;

procedure TMainPage.CommunityBuildingsLinkClick(Sender: TObject);
begin
  ShowForm(TCommunityBuildingsListForm);
end;

procedure TMainPage.OtherClaimsCommunityBuildingsClick(Sender: TObject);
begin
  ShowForm(TOtherClaimsCommunityBuildingsListForm);
end;

procedure TMainPage.ConnectedCharitiesLinkClick(Sender: TObject);
begin
  ShowForm(TConnectedCharitiesListForm);
end;

procedure TMainPage.LogoutLabelClick(Sender: TObject);
begin
  MainData.SphinxLogin.Logout;
  MainData.SphinxLogin.Login;
end;

procedure TMainPage.OrganisationLinkClick(Sender: TObject);
begin
//  inherited;
  ShowForm(TCharityEditForm);
end;

procedure TMainPage.OtherClaimsConnectedCharitiesLinkClick(Sender: TObject);
begin
  ShowForm(TOtherClaimsConnectedCharitiesListForm);
end;


procedure TMainPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  GiftAidLink := TLabel.Create('GiftAidLink');
  NewDonorsLabel := TLabel.Create('NewDonorsLink');
  DonorAdminLabel := TLabel.Create('DonorAdminLink');
  ImportLink := TLabel.Create('ImportLink');
  ManualEntryLink := TLabel.Create('ManualEntryLink');
  UnclaimedDonationsLink := TLabel.Create('UnclaimedDonationsLink');
  MakeAClaimLink := TLabel.Create('MakeAClaimLink');
  ManualDonationLink := TLabel.Create('ManualDonationLink');
  UpdateMeLink := TLabel.Create('UpdateMeLink');
  SysConfigLink := TLabel.Create('SysConfigLink');
  OtherIncomeLink := TLabel.Create('OtherIncome');
  AggregatedDonationsLink := TLabel.Create('AggregatedDonations');
  GASDSLink := TLabel.Create('GASDS');
  GASDSAdjustmentsLink := TLabel.Create('GASDSAdjustments');
  AdjustmentsLink := TLabel.Create('Adjustments');
  CommunityBuildingsLink := TLabel.Create('communityBuildingsLink');
  OtherClaimsCommunityBuildings := TLabel.Create('OtherClaimsCommunityBuildings');
  ConnectedCharitiesLink := TLabel.Create('ConnectedCharitiesLink');
  OtherClaimsConnectedCharitiesLink := TLabel.Create('OtherClaimsConnectedCharitiesLink');
  OrganisationLink := TLabel.Create('OrganisationLink');
  TenantNameLabel := TLabel.Create('TenantNameLabel');

  LogoutLabel.BeforeLoadDFMValues;
  GiftAidLink.BeforeLoadDFMValues;
  NewDonorsLabel.BeforeLoadDFMValues;
  DonorAdminLabel.BeforeLoadDFMValues;
  ImportLink.BeforeLoadDFMValues;
  ManualEntryLink.BeforeLoadDFMValues;
  UnclaimedDonationsLink.BeforeLoadDFMValues;
  MakeAClaimLink.BeforeLoadDFMValues;
  ManualDonationLink.BeforeLoadDFMValues;
  UpdateMeLink.BeforeLoadDFMValues;
  SysConfigLink.BeforeLoadDFMValues;
  OtherIncomeLink.BeforeLoadDFMValues;
  AggregatedDonationsLink.BeforeLoadDFMValues;
  GASDSLink.BeforeLoadDFMValues;
  GASDSAdjustmentsLink.BeforeLoadDFMValues;
  AdjustmentsLink.BeforeLoadDFMValues;
  CommunityBuildingsLink.BeforeLoadDFMValues;
  OtherClaimsCommunityBuildings.BeforeLoadDFMValues;
  ConnectedCharitiesLink.BeforeLoadDFMValues;
  OtherClaimsConnectedCharitiesLink.BeforeLoadDFMValues;
  OrganisationLink.BeforeLoadDFMValues;
  TenantNameLabel.BeforeLoadDFMValues;
  try
    SetEvent(LogoutLabel, Self, 'OnClick', 'LogoutLabelClick');
    GiftAidLink.SetParentComponent(Self);
    GiftAidLink.Name := 'GiftAidLink';
    GiftAidLink.Left := 19;
    GiftAidLink.Top := 134;
    GiftAidLink.Width := 24;
    GiftAidLink.Height := 10;
    GiftAidLink.Cursor := crHandPoint;
    GiftAidLink.Caption := 'Claims';
    GiftAidLink.ElementFont := efCSS;
    GiftAidLink.HeightStyle := ssAuto;
    GiftAidLink.HeightPercent := 100.000000000000000000;
    GiftAidLink.WidthStyle := ssAuto;
    GiftAidLink.WidthPercent := 100.000000000000000000;
    SetEvent(GiftAidLink, Self, 'OnClick', 'GiftAidLinkClick');
    NewDonorsLabel.SetParentComponent(Self);
    NewDonorsLabel.Name := 'NewDonorsLabel';
    NewDonorsLabel.Left := 19;
    NewDonorsLabel.Top := 112;
    NewDonorsLabel.Width := 43;
    NewDonorsLabel.Height := 10;
    NewDonorsLabel.Cursor := crHandPoint;
    NewDonorsLabel.Caption := 'New Donors';
    NewDonorsLabel.ElementFont := efCSS;
    NewDonorsLabel.HeightStyle := ssAuto;
    NewDonorsLabel.HeightPercent := 100.000000000000000000;
    NewDonorsLabel.Visible := False;
    NewDonorsLabel.WidthPercent := 100.000000000000000000;
    DonorAdminLabel.SetParentComponent(Self);
    DonorAdminLabel.Name := 'DonorAdminLabel';
    DonorAdminLabel.Left := 19;
    DonorAdminLabel.Top := 91;
    DonorAdminLabel.Width := 25;
    DonorAdminLabel.Height := 10;
    DonorAdminLabel.Cursor := crHandPoint;
    DonorAdminLabel.Caption := 'Donors';
    DonorAdminLabel.ElementFont := efCSS;
    DonorAdminLabel.ElementPosition := epIgnore;
    DonorAdminLabel.HeightStyle := ssAuto;
    DonorAdminLabel.HeightPercent := 100.000000000000000000;
    DonorAdminLabel.WidthStyle := ssAuto;
    DonorAdminLabel.WidthPercent := 100.000000000000000000;
    SetEvent(DonorAdminLabel, Self, 'OnClick', 'DonorAdminLabelClick');
    ImportLink.SetParentComponent(Self);
    ImportLink.Name := 'ImportLink';
    ImportLink.Left := 19;
    ImportLink.Top := 76;
    ImportLink.Width := 24;
    ImportLink.Height := 10;
    ImportLink.Cursor := crHandPoint;
    ImportLink.Caption := 'Import';
    ImportLink.ElementFont := efCSS;
    ImportLink.HeightStyle := ssAuto;
    ImportLink.HeightPercent := 100.000000000000000000;
    ImportLink.WidthPercent := 100.000000000000000000;
    SetEvent(ImportLink, Self, 'OnClick', 'ImportLinkClick');
    ManualEntryLink.SetParentComponent(Self);
    ManualEntryLink.Name := 'ManualEntryLink';
    ManualEntryLink.Left := 16;
    ManualEntryLink.Top := 155;
    ManualEntryLink.Width := 47;
    ManualEntryLink.Height := 10;
    ManualEntryLink.Caption := 'Manual Entry';
    ManualEntryLink.HeightPercent := 100.000000000000000000;
    ManualEntryLink.WidthPercent := 100.000000000000000000;
    SetEvent(ManualEntryLink, Self, 'OnClick', 'ManualEntryLinkClick');
    UnclaimedDonationsLink.SetParentComponent(Self);
    UnclaimedDonationsLink.Name := 'UnclaimedDonationsLink';
    UnclaimedDonationsLink.Left := 19;
    UnclaimedDonationsLink.Top := 225;
    UnclaimedDonationsLink.Width := 76;
    UnclaimedDonationsLink.Height := 10;
    UnclaimedDonationsLink.Cursor := crHandPoint;
    UnclaimedDonationsLink.Caption := 'Unclaimed Donations';
    UnclaimedDonationsLink.ElementFont := efCSS;
    UnclaimedDonationsLink.HeightStyle := ssAuto;
    UnclaimedDonationsLink.HeightPercent := 100.000000000000000000;
    UnclaimedDonationsLink.WidthStyle := ssAuto;
    UnclaimedDonationsLink.WidthPercent := 100.000000000000000000;
    SetEvent(UnclaimedDonationsLink, Self, 'OnClick', 'UnclaimedDonationsLinkClick');
    MakeAClaimLink.SetParentComponent(Self);
    MakeAClaimLink.Name := 'MakeAClaimLink';
    MakeAClaimLink.Left := 19;
    MakeAClaimLink.Top := 246;
    MakeAClaimLink.Width := 48;
    MakeAClaimLink.Height := 10;
    MakeAClaimLink.Cursor := crHandPoint;
    MakeAClaimLink.Caption := 'Make a Claim';
    MakeAClaimLink.ElementFont := efCSS;
    MakeAClaimLink.HeightStyle := ssAuto;
    MakeAClaimLink.HeightPercent := 100.000000000000000000;
    MakeAClaimLink.WidthStyle := ssAuto;
    MakeAClaimLink.WidthPercent := 100.000000000000000000;
    SetEvent(MakeAClaimLink, Self, 'OnClick', 'MakeAClaimLinkClick');
    ManualDonationLink.SetParentComponent(Self);
    ManualDonationLink.Name := 'ManualDonationLink';
    ManualDonationLink.Left := 20;
    ManualDonationLink.Top := 272;
    ManualDonationLink.Width := 74;
    ManualDonationLink.Height := 10;
    ManualDonationLink.Caption := 'ManualDonationLink';
    ManualDonationLink.ElementFont := efCSS;
    ManualDonationLink.HeightStyle := ssAuto;
    ManualDonationLink.HeightPercent := 100.000000000000000000;
    ManualDonationLink.WidthPercent := 100.000000000000000000;
    SetEvent(ManualDonationLink, Self, 'OnClick', 'ManualDonationLinkClick');
    UpdateMeLink.SetParentComponent(Self);
    UpdateMeLink.Name := 'UpdateMeLink';
    UpdateMeLink.Left := 16;
    UpdateMeLink.Top := 331;
    UpdateMeLink.Width := 51;
    UpdateMeLink.Height := 10;
    UpdateMeLink.Caption := 'UpdateMeLink';
    UpdateMeLink.ElementFont := efCSS;
    UpdateMeLink.ElementPosition := epIgnore;
    UpdateMeLink.HeightStyle := ssAuto;
    UpdateMeLink.HeightPercent := 100.000000000000000000;
    UpdateMeLink.WidthStyle := ssAuto;
    UpdateMeLink.WidthPercent := 100.000000000000000000;
    SetEvent(UpdateMeLink, Self, 'OnClick', 'UpdateMeLinkClick');
    SysConfigLink.SetParentComponent(Self);
    SysConfigLink.Name := 'SysConfigLink';
    SysConfigLink.Left := 14;
    SysConfigLink.Top := 351;
    SysConfigLink.Width := 51;
    SysConfigLink.Height := 10;
    SysConfigLink.Cursor := crHandPoint;
    SysConfigLink.Caption := 'System Config';
    SysConfigLink.ElementFont := efCSS;
    SysConfigLink.HeightStyle := ssAuto;
    SysConfigLink.HeightPercent := 100.000000000000000000;
    SysConfigLink.WidthPercent := 100.000000000000000000;
    SetEvent(SysConfigLink, Self, 'OnClick', 'SysConfigLinkClick');
    OtherIncomeLink.SetParentComponent(Self);
    OtherIncomeLink.Name := 'OtherIncomeLink';
    OtherIncomeLink.Left := 16;
    OtherIncomeLink.Top := 384;
    OtherIncomeLink.Width := 49;
    OtherIncomeLink.Height := 10;
    OtherIncomeLink.Caption := 'Other Income';
    OtherIncomeLink.HeightPercent := 100.000000000000000000;
    OtherIncomeLink.WidthPercent := 100.000000000000000000;
    SetEvent(OtherIncomeLink, Self, 'OnClick', 'OtherIncomeLinkClick');
    AggregatedDonationsLink.SetParentComponent(Self);
    AggregatedDonationsLink.Name := 'AggregatedDonationsLink';
    AggregatedDonationsLink.Left := 16;
    AggregatedDonationsLink.Top := 398;
    AggregatedDonationsLink.Width := 79;
    AggregatedDonationsLink.Height := 10;
    AggregatedDonationsLink.Caption := 'Aggregated Donations';
    AggregatedDonationsLink.HeightPercent := 100.000000000000000000;
    AggregatedDonationsLink.WidthPercent := 100.000000000000000000;
    SetEvent(AggregatedDonationsLink, Self, 'OnClick', 'AggregatedDonationsLinkClick');
    GASDSLink.SetParentComponent(Self);
    GASDSLink.Name := 'GASDSLink';
    GASDSLink.Left := 16;
    GASDSLink.Top := 412;
    GASDSLink.Width := 24;
    GASDSLink.Height := 10;
    GASDSLink.Caption := 'GASDS';
    GASDSLink.HeightPercent := 100.000000000000000000;
    GASDSLink.WidthPercent := 100.000000000000000000;
    SetEvent(GASDSLink, Self, 'OnClick', 'GASDSLinkClick');
    GASDSAdjustmentsLink.SetParentComponent(Self);
    GASDSAdjustmentsLink.Name := 'GASDSAdjustmentsLink';
    GASDSAdjustmentsLink.Left := 16;
    GASDSAdjustmentsLink.Top := 426;
    GASDSAdjustmentsLink.Width := 71;
    GASDSAdjustmentsLink.Height := 10;
    GASDSAdjustmentsLink.Caption := 'GASDS Adjustments';
    GASDSAdjustmentsLink.HeightPercent := 100.000000000000000000;
    GASDSAdjustmentsLink.WidthPercent := 100.000000000000000000;
    SetEvent(GASDSAdjustmentsLink, Self, 'OnClick', 'GASDSAdjustmentsLinkClick');
    AdjustmentsLink.SetParentComponent(Self);
    AdjustmentsLink.Name := 'AdjustmentsLink';
    AdjustmentsLink.Left := 17;
    AdjustmentsLink.Top := 443;
    AdjustmentsLink.Width := 45;
    AdjustmentsLink.Height := 10;
    AdjustmentsLink.Caption := 'Adjustments';
    AdjustmentsLink.HeightPercent := 100.000000000000000000;
    AdjustmentsLink.WidthPercent := 100.000000000000000000;
    SetEvent(AdjustmentsLink, Self, 'OnClick', 'AdjustmentsLinkClick');
    CommunityBuildingsLink.SetParentComponent(Self);
    CommunityBuildingsLink.Name := 'CommunityBuildingsLink';
    CommunityBuildingsLink.Left := 120;
    CommunityBuildingsLink.Top := 437;
    CommunityBuildingsLink.Width := 77;
    CommunityBuildingsLink.Height := 10;
    CommunityBuildingsLink.Caption := 'Community Buildings';
    CommunityBuildingsLink.ElementPosition := epIgnore;
    CommunityBuildingsLink.HeightStyle := ssAuto;
    CommunityBuildingsLink.HeightPercent := 100.000000000000000000;
    CommunityBuildingsLink.WidthStyle := ssAuto;
    CommunityBuildingsLink.WidthPercent := 100.000000000000000000;
    SetEvent(CommunityBuildingsLink, Self, 'OnClick', 'CommunityBuildingsLinkClick');
    OtherClaimsCommunityBuildings.SetParentComponent(Self);
    OtherClaimsCommunityBuildings.Name := 'OtherClaimsCommunityBuildings';
    OtherClaimsCommunityBuildings.Left := 215;
    OtherClaimsCommunityBuildings.Top := 437;
    OtherClaimsCommunityBuildings.Width := 127;
    OtherClaimsCommunityBuildings.Height := 10;
    OtherClaimsCommunityBuildings.Caption := 'Other Claims: Community Buildings';
    OtherClaimsCommunityBuildings.ElementPosition := epIgnore;
    OtherClaimsCommunityBuildings.HeightStyle := ssAuto;
    OtherClaimsCommunityBuildings.HeightPercent := 100.000000000000000000;
    OtherClaimsCommunityBuildings.WidthStyle := ssAuto;
    OtherClaimsCommunityBuildings.WidthPercent := 100.000000000000000000;
    SetEvent(OtherClaimsCommunityBuildings, Self, 'OnClick', 'OtherClaimsCommunityBuildingsClick');
    ConnectedCharitiesLink.SetParentComponent(Self);
    ConnectedCharitiesLink.Name := 'ConnectedCharitiesLink';
    ConnectedCharitiesLink.Left := 135;
    ConnectedCharitiesLink.Top := 331;
    ConnectedCharitiesLink.Width := 71;
    ConnectedCharitiesLink.Height := 10;
    ConnectedCharitiesLink.Caption := 'Connected Charities';
    ConnectedCharitiesLink.ElementPosition := epIgnore;
    ConnectedCharitiesLink.HeightStyle := ssAuto;
    ConnectedCharitiesLink.HeightPercent := 100.000000000000000000;
    ConnectedCharitiesLink.WidthStyle := ssAuto;
    ConnectedCharitiesLink.WidthPercent := 100.000000000000000000;
    SetEvent(ConnectedCharitiesLink, Self, 'OnClick', 'ConnectedCharitiesLinkClick');
    OtherClaimsConnectedCharitiesLink.SetParentComponent(Self);
    OtherClaimsConnectedCharitiesLink.Name := 'OtherClaimsConnectedCharitiesLink';
    OtherClaimsConnectedCharitiesLink.Left := 135;
    OtherClaimsConnectedCharitiesLink.Top := 351;
    OtherClaimsConnectedCharitiesLink.Width := 119;
    OtherClaimsConnectedCharitiesLink.Height := 10;
    OtherClaimsConnectedCharitiesLink.Caption := 'Other Claims Connected Charities';
    OtherClaimsConnectedCharitiesLink.ElementPosition := epIgnore;
    OtherClaimsConnectedCharitiesLink.HeightStyle := ssAuto;
    OtherClaimsConnectedCharitiesLink.HeightPercent := 100.000000000000000000;
    OtherClaimsConnectedCharitiesLink.WidthStyle := ssAuto;
    OtherClaimsConnectedCharitiesLink.WidthPercent := 100.000000000000000000;
    SetEvent(OtherClaimsConnectedCharitiesLink, Self, 'OnClick', 'OtherClaimsConnectedCharitiesLinkClick');
    OrganisationLink.SetParentComponent(Self);
    OrganisationLink.Name := 'OrganisationLink';
    OrganisationLink.Left := 379;
    OrganisationLink.Top := 437;
    OrganisationLink.Width := 45;
    OrganisationLink.Height := 10;
    OrganisationLink.Caption := 'Organisation';
    OrganisationLink.HeightPercent := 100.000000000000000000;
    OrganisationLink.WidthPercent := 100.000000000000000000;
    SetEvent(OrganisationLink, Self, 'OnClick', 'OrganisationLinkClick');
    TenantNameLabel.SetParentComponent(Self);
    TenantNameLabel.Name := 'TenantNameLabel';
    TenantNameLabel.Left := 517;
    TenantNameLabel.Top := 25;
    TenantNameLabel.Width := 63;
    TenantNameLabel.Height := 10;
    TenantNameLabel.Caption := 'TenantNameLabel';
    TenantNameLabel.ElementPosition := epIgnore;
    TenantNameLabel.HeightStyle := ssAuto;
    TenantNameLabel.HeightPercent := 100.000000000000000000;
    TenantNameLabel.HTMLType := tDIV;
    TenantNameLabel.WidthStyle := ssAuto;
    TenantNameLabel.WidthPercent := 100.000000000000000000;
  finally
    LogoutLabel.AfterLoadDFMValues;
    GiftAidLink.AfterLoadDFMValues;
    NewDonorsLabel.AfterLoadDFMValues;
    DonorAdminLabel.AfterLoadDFMValues;
    ImportLink.AfterLoadDFMValues;
    ManualEntryLink.AfterLoadDFMValues;
    UnclaimedDonationsLink.AfterLoadDFMValues;
    MakeAClaimLink.AfterLoadDFMValues;
    ManualDonationLink.AfterLoadDFMValues;
    UpdateMeLink.AfterLoadDFMValues;
    SysConfigLink.AfterLoadDFMValues;
    OtherIncomeLink.AfterLoadDFMValues;
    AggregatedDonationsLink.AfterLoadDFMValues;
    GASDSLink.AfterLoadDFMValues;
    GASDSAdjustmentsLink.AfterLoadDFMValues;
    AdjustmentsLink.AfterLoadDFMValues;
    CommunityBuildingsLink.AfterLoadDFMValues;
    OtherClaimsCommunityBuildings.AfterLoadDFMValues;
    ConnectedCharitiesLink.AfterLoadDFMValues;
    OtherClaimsConnectedCharitiesLink.AfterLoadDFMValues;
    OrganisationLink.AfterLoadDFMValues;
    TenantNameLabel.AfterLoadDFMValues;
  end;
end;

end.
