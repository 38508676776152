{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.DBCtrls;

{$DEFINE NOPP}

interface

uses
  Classes, DB, SysUtils, WEBLib.Controls, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  WEBLib.Buttons, WEBLib.Grids, WEBLib.Mask, WEBLib.EditAutoComplete, Web,
  WEBLib.REST, WEBLib.Lists, WEBLib.DropDown;

type
  TWebLinkType = (wlHttp, wlMail, wlHttps, wlFTP);

  TNavigateBtn = (nbFirst, nbPrior, nbNext, nbLast,
                  nbInsert, nbDelete, nbEdit, nbPost, nbCancel);

  TNavButtonSet = set of TNavigateBtn;

  TDataField = type string;

  TFieldDataLink = class(TDataLink)
  private
    FOnDataChange: TNotifyEvent;
    FOnUpdateData: TNotifyEvent;
    FOnActiveChange: TNotifyEvent;
    FField: TField;
    FFieldName: string;
    FModified: boolean;
  protected
    procedure UpdateField;
    procedure ActiveChanged; override;
    //procedure DataEvent(Event: TDataEvent; Info: NativeInt); override;
    //procedure EditingChanged; override;
    procedure RecordChanged(Field: TField); override;
    procedure UpdateData; override;
  public
    property Field: TField read FField write FField;
    procedure Modified;
  published
    property FieldName: string read FFieldName write FFieldName;
    property OnDataChange: TNotifyEvent read FOnDataChange write FOnDataChange;
    property OnUpdateData: TNotifyEvent read FOnUpdateData write FOnUpdateData;
    property OnActiveChange: TNotifyEvent read FOnActiveChange write FOnActiveChange;
  end;

  TDataScrollEvent = procedure(Sender: TObject; Distance: integer) of object;

  TDBDataLink = class(TDataLink)
  private
    FOnActiveChange: TNotifyEvent;
    FOnDataChange: TNotifyEvent;
    FOnRecordChange: TNotifyEvent;
    FOnUpdateData: TNotifyEvent;
    FOnDataScroll: TDataScrollEvent;
    FFieldName: string;
  protected
    procedure DataSetChanged; override;
    procedure ActiveChanged; override;
    procedure RecordChanged(Field: TField); override;
    procedure DataSetScrolled(Distance: Longint); override;
    procedure UpdateData; override;
  public
    property FieldName: string read FFieldName write FFieldName;
  published
    property OnRecordChange: TNotifyEvent read FOnRecordChange write FOnRecordChange;
    property OnDataChange: TNotifyEvent read FOnDataChange write FOnDataChange;
    property OnDataScroll: TDataScrollEvent read FOnDataScroll write FOnDataScroll;
    property OnActiveChange: TNotifyEvent read FOnActiveChange write FOnActiveChange;
    property OnUpdateData: TNotifyEvent read FOnUpdateData write FOnUpdateData;
  end;

  TDBEdit = class(TEdit)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;
  protected
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    function CheckDataSet: boolean; virtual;
    function EditCanModify: Boolean; virtual;
    function IsReadOnly: boolean; override;
    function IsEnabled: boolean; override;
    function GetDisplayText: string; override;
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure KeyPress(var Key: Char); override;
    procedure DoExit; override;
    function CanPaste(AValue: string): boolean; override;
    function CanCut: boolean; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Change; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
  end;

  TWebDBEdit = class(TDBEdit);

  TDBEditBtn = class(TEditBtn)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;
  protected
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    function CheckDataSet: boolean; virtual;
    function EditCanModify: Boolean; virtual;
    function IsReadOnly: boolean; override;
    function IsEnabled: boolean; override;
    function GetDisplayText: string; override;
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure KeyPress(var Key: Char); override;
    procedure DoExit; override;
    function CanPaste(AValue: string): boolean; override;
    function CanCut: boolean; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Change; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
  end;

  TWebDBEditBtn = class(TDBEditBtn);

  TDBMaskEdit = class(TMaskEdit)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;
    FFieldText: string;
    function GetDataField: TDataField;
    function GetDataSource: TDataSource;
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
  protected
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    function CheckDataSet: boolean; virtual;
    function EditCanModify: Boolean; override;
    function IsReadOnly: boolean; override;
    function IsEnabled: boolean; override;
    function GetDisplayText: string; override;
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure KeyPress(var Key: Char); override;
    procedure DoExit; override;
    function CanPaste(AValue: string): boolean; override;
    function CanCut: boolean; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Change; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
  end;

  TWebDBMaskEdit = class(TDBMaskEdit);


  TDBLabel = class(TLabel)
  private
    FDataLink: TFieldDataLink;
  protected
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    function CheckDataSet: boolean; virtual;
    function GetDisplayText: string; override;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
  end;

  TWebDBLabel = class(TDBLabel);

  TDBSpinEdit = class(TSpinEdit)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;
  protected
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    function EditCanModify: Boolean; virtual;
    function IsReadOnly: boolean; override;
    function IsEnabled: boolean; override;
    function CheckDataSet: boolean; virtual;
    function GetDisplayText: string; override;
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure KeyPress(var Key: Char); override;
    procedure Change; override;
    procedure DoExit; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
  end;

  TWebDBSpinEdit = class(TDBSpinEdit);

  TDBCheckBox = class(TCheckBox)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;
    FValueChecked: string;
    FValueUnChecked: string;
  protected
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    function CheckDataSet: boolean; virtual;
    function EditCanModify: Boolean; virtual;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    function IsEnabled: boolean; override;
    procedure Click; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property ValueChecked: string read FValueChecked write FValueChecked;
    property ValueUnChecked: string read FValueUnChecked write FValueUnChecked;
  end;

  TWebDBCheckBox = class(TDBCheckBox);

  TDBDateTimePicker = class(TDateTimePicker)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;
  protected
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    function CheckDataSet: boolean; virtual;
    function EditCanModify: Boolean; virtual;
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure KeyPress(var Key: Char); override;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure Change; override;
    function IsReadOnly: boolean; override;
    function IsEnabled: boolean; override;
    procedure DoExit; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
  end;

  TWebDBDateTimePicker = class(TDBDateTimePicker);


  TDBEditDropDownControl = class(TEditDropDownControl)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;
  protected
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    function CheckDataSet: boolean; virtual;
    function EditCanModify: Boolean; virtual;
    function IsReadOnly: boolean;
    function IsEnabled: boolean; override;
//    function  GetDisplayText: string; override;
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure KeyPress(var Key: Char); override;
    procedure DoExit; override;
//    function CanPaste(AValue: string): boolean; override;
//    function CanCut: boolean; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
//    procedure Change; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
  end;

  TWebDBEditDropDownControl = class(TDBEditDropDownControl);


  TDBComboBox = class(TComboBox)
  private
    FDataLink: TFieldDataLink;
    FListLink: TFieldDataLink;
    FEditChange: boolean;
    procedure SetListField(const Value: string);
    procedure SetListSource(const Value: TDataSource);
    function GetListField: string;
    function GetListSource: TDataSource;
  protected
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    function EditCanModify: Boolean; virtual;
    function CheckDataSet: boolean; virtual;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure ListActiveChange(Sender: TObject);
    procedure Change; override;
    function IsEnabled: boolean; override;
    procedure DoExit; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property ListField: string read GetListField write SetListField;
    property ListSource: TDataSource read GetListSource write SetListSource;
  end;

  TWebDBComboBox = class(TDBComboBox);

  TDBLookupComboBox = class(TCustomLookupComboBox)
  private
    FDataLink: TFieldDataLink;
    FListLink: TFieldDataLink;
    FEditChange: boolean;
    FKeyField: TDataField;
    FListChanging: boolean;
    FFieldValue: string;
    FBlockChange: boolean;
    function GetListField: TDataField;
    function GetListSource: TDataSource;
    procedure SetListField(const Value: TDataField);
    procedure SetListSource(const Value: TDataSource);
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    procedure SetKeyField(const Value: TDataField);
  protected
    procedure SetValue(const Value: string); override;
    procedure LoadListSource;
    function CheckDataSet: boolean; virtual;
    function EditCanModify: Boolean; virtual;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure ListDataChange(Sender: TObject);
    procedure ListActiveChange(Sender: TObject);
    procedure Change; override;
    function IsEnabled: boolean; override;
    procedure DoExit; override;
    procedure SyncListSource; virtual;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property KeyField: TDataField read FKeyField write SetKeyField;
    property ListField: TDataField read GetListField write SetListField;
    property ListSource: TDataSource read GetListSource write SetListSource;
  end;

  TWebDBLookupComboBox = class(TDBLookupComboBox);

  TDBEditAutoComplete = class(TEditAutoComplete)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;
  protected
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    function EditCanModify: Boolean; virtual;
    function CheckDataSet: boolean; virtual;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure KeyPress(var Key: Char); override;
    procedure DoExit; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Change; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
  end;

  TWebDBEditAutoComplete = class(TDBEditAutoComplete);


  TDBRadioGroup = class(TRadioGroup)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;
    FValues: TStringList;
  protected
    procedure SetValues(const Value: TStringList);
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    function CheckDataSet: boolean; virtual;
    function EditCanModify: Boolean; virtual;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure Click; override;
    function IsEnabled: boolean; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property Values: TStringList read FValues write SetValues;
  end;

  TWebDBRadioGroup = class(TDBRadioGroup);

  TDBLinkLabel = class(TCustomLinkLabel)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;
    FLinkType: TWebLinkType;
  protected
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    function CheckDataSet: boolean; virtual;
    function GetDisplayText: string; override;
    function EditCanModify: Boolean; virtual;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure Click; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property LinkType: TWebLinkType read FLinkType write FLinkType;
    property ParentFont;
  end;

  TWebDBLinkLabel = class(TDBLinkLabel);

  TDBMemo = class(TMemo)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;
  protected
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    function GetDataField: TDataField;
    function EditCanModify: Boolean; virtual;
    function IsReadOnly: boolean; override;
    function IsEnabled: boolean; override;
    function CheckDataSet: boolean; virtual;
    function GetDisplayText: string; override;
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure KeyPress(var Key: Char); override;
    function CanPaste(AValue: string): boolean; override;
    function CanCut: boolean; override;
    procedure DoExit; override;
    procedure Change; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
  end;

  TWebDBMemo = class(TDBMemo);

  TSetURLEvent = procedure(Sender: TObject; var AURL: string) of object;

  TDBImageControl = class(TCustomImageControl)
  private
    FDataLink: TFieldDataLink;
    FOnSetURL: TSetURLEvent;
    FBaseURL: string;
    function GetDataField: TDataField;
    function GetDataSource: TDataSource;
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
  protected
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property BaseURL: string read FBaseURL write FBaseURL;
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property OnSetURL: TSetURLEvent read FOnSetURL write FOnSetURL;
  end;

  TWebDBImageControl = class(TDBImageControl);

  TDBNavigator = class(TToolBar)
  private
    FDataLink: TFieldDataLink;
    FFirstBtn: TSpeedButton;
    FPriorBtn: TSpeedButton;
    FNextBtn: TSpeedButton;
    FLastBtn: TSpeedButton;
    FEditBtn: TSpeedButton;
    FPostBtn: TSpeedButton;
    FInsertBtn: TSpeedButton;
    FDeleteBtn: TSpeedButton;
    FCancelBtn: TSpeedButton;
    FVisibleButtons: TNavButtonSet;
    FHints: TStringList;
    FImages: TStringList;
    FBtnID: string;
    procedure SetVisibleButtons(const Value: TNavButtonSet);
    procedure SetHints(const Value: TStringList);
    procedure SetImages(const Value: TStringList);
  protected
    procedure UpdateButtons;
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    procedure CreateButton(var btn: TSpeedButton; BtnID, Glyph: string; Index: integer);
    procedure UpdateImage(btn: TSpeedButton; Glyph: string; Index: integer);
    procedure HandleSpeedButtonClick(Sender: TObject); virtual;
    procedure HandleSpeedButtonMouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X,Y: Integer); virtual;
    procedure UpdateElement; override;
    procedure Loaded; override;
    procedure GetChildren(Proc: TGetChildProc; Root: TComponent); override;
    procedure SetEnabled(Value: Boolean); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property Enabled;
    property Hints: TStringList read FHints write SetHints;
    property Images: TStringList read FImages write SetImages;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property VisibleButtons: TNavButtonSet read FVisibleButtons write SetVisibleButtons;
  end;

  TWebDBNavigator = class(TDBNavigator);

  TListItemFieldValueEvent = procedure(Sender: TObject; Index: integer; AFieldName: string; var AValue: string) of object;

  TDBListControl = class(TListControl)
  private
    FDataLink: TDBDataLink;
    FListLink: TDBDataLink;
    FItemTemplate: string;
    FOnListItemGetFieldValue: TListItemFieldValueEvent;
    function GetDataSource: TDataSource;
    function GetListSource: TDataSource;
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
    procedure SetListField(const Value: TDataField);
    procedure SetListSource(const Value: TDataSource);
    procedure SetItemTemplate(const Value: string);
    function GetDataField: TDataField;
    function GetListField: TDataField;
  protected
    procedure RecordChange(Sender: TObject);
    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure ListActiveChange(Sender: TObject);
    procedure DoItemGetFieldValue(Index: integer; AFieldName: string; var AValue: string); virtual;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;

    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property ItemTemplate: string read FItemTemplate write SetItemTemplate;
    property ListField: TDataField read GetListField write SetListField;
    property ListSource: TDataSource read GetListSource write SetListSource;
    property OnListItemGetFieldValue: TListItemFieldValueEvent read FOnListItemGetFieldValue write FOnListItemGetFieldValue;
  end;

  TWebDBListControl = class(TDBListControl);

  TColumnDataType = (cdText, cdImage, cdLink, cdCheck, cdRadio, cdExternalLink, cdMemo);

  TDBTableControl = class;

  TTableControlColumn = class(TCollectionItem)
  private
    FDataField: TDataField;
    FTitle: string;
    FDataType: TColumnDataType;
    FClassName: string;
    FTitleClassName: string;
    FTag: integer;
    FWidth: integer;
    procedure SetDataField(const Value: TDataField);
    procedure SetTitle(const Value: string);
    procedure SetWidth(const Value: integer);
  public
    procedure Assign(Source: TPersistent); override;
  published
    property ElementClassName: string read FClassName write FClassName;
    property DataField: TDataField read FDataField write SetDataField;
    property Title: string read FTitle write SetTitle;
    property TitleElementClassName: string read FTitleClassName write FTitleClassName;
    property DataType: TColumnDataType read FDataType write FDataType;
    property Tag: integer read FTag write FTag default 0;
    property Width: integer read FWidth write SetWidth default 0;
  end;

  TTableControlColumns = class(TOwnedCollection)
  private
    FOnChange: TNotifyEvent;
    function GetItem(Index: integer): TTableControlColumn; reintroduce;
    procedure SetItem(Index: integer; const Value: TTableControlColumn); reintroduce;
  protected
    procedure Changed; virtual; reintroduce;
    procedure Update(Item: TCollectionItem); override;
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Add: TTableControlColumn; reintroduce;
    function Insert(Index: integer): TTableControlColumn; reintroduce;
    property Items[Index: integer]: TTableControlColumn read GetItem write SetItem; default;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TDBTableControl = class(TCustomTableControl)
  private
    FDefaultFields: boolean;
    FDataLink: TDBDataLink;
    FColumns: TTableControlColumns;
    FDataChanging: boolean;
    procedure SetColumns(const Value: TTableControlColumns);
  protected
    procedure LoadData; virtual;
    procedure LoadRow(ARow: integer); virtual;
    procedure DataChange(Sender: TObject);
    procedure RecordChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    procedure Loaded; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property Columns: TTableControlColumns read FColumns write SetColumns;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property ElementFont;
    property ElementClassName;
    property ElementId;
    property ElementPosition;
  end;

  TWebDBTableControl = class(TDBTableControl);

  TDBEditDropDownTableControl = class(TEditDropDownTableControl)
  private
    FDataLink: TFieldDataLink;
    FEditChange: boolean;

    function GetTableSource: TDataSource;
    procedure SetTableSource(const Value: TDataSource);
    function GetDBTableControl: TDBTableControl;
    function GetDataField: TDataField;
    function GetDataSource: TDataSource;
    procedure SetDataField(const Value: TDataField);
    procedure SetDataSource(const Value: TDataSource);
  protected
    function CreateTable(Aowner: TComponent): TCustomTableControl; override;

    procedure DataUpdate(Sender: TObject);
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    function CheckDataSet: boolean; virtual;
    function EditCanModify: Boolean; virtual;
    function IsReadOnly: boolean;
    function IsEnabled: boolean; override;
//    function  GetDisplayText: string; override;
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    procedure KeyPress(var Key: Char); override;
    procedure DoExit; override;

  public
    procedure CreateInitialize; override;
    destructor Destroy; override;

    property Table: TDBTableControl read GetDBTableControl;
  published
    property TableSource: TDataSource read GetTableSource write SetTableSource;
    property DataField: TDataField read GetDataField write SetDataField;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
  end;

  TWebDBEditDropDownTableControl = class(TDBEditDropDownTableControl);


  TDBResponsiveGrid = class(TResponsiveGrid)
  private
    FDataLink: TDBDataLink;
    FModifying: boolean;
    FRecordCount: integer;
    FOldState: TDatasetState;
    FDataChanging: boolean;
  protected
    procedure DataChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure SetDataSource(const Value: TDataSource);
    function GetDataSource: TDataSource;
    procedure HandleDoItemClick(Index: integer); override;
    procedure LoadData; virtual;
    procedure OptionsChanged(Sender: TObject); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Refresh; virtual;
  published
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property ElementFont;
    property ElementClassName;
    property ElementId;
    property ElementPosition;
  end;

  TWebDBResponsiveGrid = class(TDBResponsiveGrid);

  TGridColumn = class(TCollectionItem)
  private
    FDataField: TDataField;
    FTitle: string;
    FWidth: integer;
    FDataType: TColumnDataType;
    FClassName: string;
    FTitleClassName: string;
    FSortIndicator: TGridSortIndicator;
    FEditor: TGridCellEditor;
    FEditMask: TEditMask;
    FComboBoxItems: TStrings;
    FTag: integer;
    FDBField: TField;
    FImageWidth: integer;
    FAlignment: TAlignment;
    procedure SetDataField(const Value: TDataField);
    procedure SetTitle(const Value: string);
    procedure SetWidth(const Value: integer);
    procedure SetSortIndicator(const Value: TGridSortIndicator);
    procedure SetComboBoxItems(const Value: TStrings);
    procedure SetAlignment(const Value: TAlignment);
  protected
    property DBField: TField read FDBField write FDBField;
    function GetDBField: TField;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(Collection: TCollection); override;
    destructor Destroy; override;
    property SortIndicator: TGridSortIndicator read FSortIndicator write SetSortIndicator;
  published
    property Alignment: TAlignment read FAlignment write SetAlignment default taLeftJustify;
    property ComboBoxItems: TStrings read FComboBoxItems write SetComboBoxItems;
    property DataField: TDataField read FDataField write SetDataField;
    property DataType: TColumnDataType read FDataType write FDataType;
    property EditMask: TEditMask read FEditMask write FEditMask;
    property Editor: TGridCellEditor read FEditor write FEditor default geText;
    property ElementClassName: string read FClassName write FClassName;
    property ImageWidth: integer read FImageWidth write FImageWidth default 0;
    property Tag: integer read FTag write FTag default 0;
    property Title: string read FTitle write SetTitle;
    property TitleElementClassName: string read FTitleClassName write FTitleClassName;
    property Width: integer read FWidth write SetWidth default 64;
  end;

  TSortIndicatorChanged = procedure(Sender: TObject; ACol: integer; AIndicator: TGridSortIndicator) of object;

  TGridColumns = class(TOwnedCollection)
  private
    FOnChange: TNotifyEvent;
    FOnSortIndicatorChange: TSortIndicatorChanged;
    function GetItem(Index: integer): TGridColumn; reintroduce;
    procedure SetItem(Index: integer; const Value: TGridColumn); reintroduce;
  protected
    procedure Changed; virtual; reintroduce;
    procedure Update(Item: TCollectionItem); override;
    property OnSortIndicatorChange: TSortIndicatorChanged read FOnSortIndicatorChange write FOnSortIndicatorChange;
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Add: TGridColumn; reintroduce;
    function Insert(Index: integer): TGridColumn; reintroduce;
    property Items[Index: integer]: TGridColumn read GetItem write SetItem; default;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;


  TDBGrid = class(TCustomStringGrid)
  private
    FDefaultFields: boolean;
    FModifying: boolean;
    FDataLink: TDBDataLink;
    FColumns: TGridColumns;
    FDataChanging: boolean;
    FOldState: TDatasetState;
    FOldFiltered: boolean;
    FOldIndicator: integer;
    FShowIndicator: boolean;
    function GetDataSource: TDataSource;
    procedure SetColumns(const Value: TGridColumns);
    procedure SetDataSource(const Value: TDataSource);
    procedure SetShowIndicator(const Value: boolean);
  protected
    procedure LoadData; virtual;
    procedure LoadRow(ARow: integer); virtual;
    procedure InsertData(ARow: Integer); virtual;
    procedure DataChange(Sender: TObject);
    procedure DataScroll(Sender: TObject; Distance: integer);
    procedure RecordChange(Sender: TObject);
    procedure ActiveChange(Sender: TObject);
    procedure ColumnsChanged(Sender: TObject);
    procedure UpdateData(Sender: TObject);
    procedure UpdateCell(ACol, ARow: integer; var AValue: string); override;
    procedure SelectCell(ACol, ARow: integer); override;
    procedure ChangeRow; override;
    procedure UpdateIndicator(ARow: integer);
    procedure DoCheckClick(ACol, ARow: Longint; Checked: boolean); override;
    procedure DoSortIndicatorChange(Sender: TObject; ACol: integer; AIndicator: TGridSortIndicator);
    function StopEdit: string; override;
    procedure StartEdit(ch: string); override;
    procedure Loaded; override;
    function CanEditCell(ACol,ARow: integer): boolean; override;
    function CanRowAdvance: boolean; override;
    procedure GetCellEditor(const ACol,ARow: integer; var AEditor: TGridCellEditor); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Refresh;
    procedure HideEdit(Advance: boolean = false); override;
    procedure ShowEdit; override;
    procedure EndUpdate; override;
  published
    property DragMode;
    property Columns: TGridColumns read FColumns write SetColumns;
    property DataSource: TDataSource read GetDataSource write SetDataSource;
    property ElementFont;
    property ElementClassName;
    property ElementId;
    property ElementPosition;
    property Font;
    property ParentFont;
    property ShowIndicator: boolean read FShowIndicator write SetShowIndicator;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebDBGrid = class(TDBGrid);

implementation

uses
  WEBLib.WebTools, Math;

type
  TResponsiveGridItemEx = class(TResponsiveGridItem);

function CheckDB(ADataSource: TDataSource; ADataField: string; ADataLink: TDataLink): boolean;
begin
  Result := false;
  if Assigned(ADataSource) and (ADataSource.Enabled) and (ADataField <> '') then
  begin
    Result := Assigned(ADataLink.DataSet) and (ADataLink.DataSet.Active) and ((ADataLink.DataSet.RecordCount > 0) or (ADataLink.DataSet.State = dsInsert));
  end;
end;

function TableFieldToCellData(AElement: TJSHTMLElement; AField: TField; ColumnDataType: TColumnDataType; Enabled: boolean; ImgWidth: integer = 0): string;
var
  b: boolean;
  fs,t,d,imgv: string;
  dt: TDateTime;
begin
  Result := '';

  case ColumnDataType of
  cdText,cdMemo:
    begin
      if (AField.DataType <> ftBlob) and TRESTClient.IsIsoDateTime(AField.AsString) then
      begin
        dt := TRESTClient.IsoToDateTime(AField.AsString);
        if Frac(dt) = 0 then
          Result := DateToStr(dt)
        else
          if Int(dt) = 0 then
            Result := TimeToStr(dt)
          else
            Result := DateToStr(dt) +'  ' + TimeToStr(dt);
      end
      else
      begin
        if ColumnDataType = cdMemo then
        begin
          try
            Result := window.atob(string(AField.AsJSValue))
          except
            Result := AField.DisplayText;
          end;
        end
        else
        begin
          if AField.DataType in [ftDate,ftDateTime] then // required format by inplace datepicker
            Result := FormatDateTime('YYYY-MM-dd', AField.AsDateTime)
          else
            Result := AField.DisplayText;
        end;
      end;
    end;
  cdImage:
    begin
      imgv := AField.DisplayText;
      if (imgv <> '') then
      begin
        if ImgWidth > 0 then
          Result := '<IMG src="'+imgv+'" width="'+ImgWidth.ToString+'">'
        else
          Result := '<IMG src="'+imgv+'">'
      end;
    end;
  cdLink: Result := '<A href="'+AField.DisplayText+'">'+ AField.DisplayText+'</A>';
  cdExternalLink: Result := '<A href="'+AField.DisplayText+'" target="_blank">'+ AField.DisplayText+'</A>';
  cdCheck, cdRadio:
    begin
      if AField.DataType = ftBoolean then
        b := AField.AsBoolean
      else
      begin
        fs := LowerCase(AField.AsString);
        b := (fs = 'y') or (fs = 'true') or (fs = '1');
      end;

      if ColumnDataType = cdCheck then
        t := 'CHECKBOX'
      else
        t := 'RADIO';

      if not Enabled then
        d := ' DISABLED'
      else
        d := '';

      if b then
        Result := '<INPUT TYPE="' + t + '" CHECKED'+ d + '>'
      else
        Result := '<INPUT TYPE="' + t + '"' + d + '>';
    end;
  end;
end;

function FieldToCellData(AElement: TJSHTMLElement; AField: TField; Column: TGridColumn; Enabled: boolean): string;
begin
  Result := TableFieldToCellData(AElement, AField, Column.DataType, Enabled, Column.ImageWidth);
end;


{ TFieldDataLink }

procedure TFieldDataLink.UpdateField;
begin
  FField := nil;
  if Assigned(DataSource) and Assigned(DataSource.DataSet) and (FieldName <> '') then
  begin
    if DataSource.DataSet.Active then
    begin
      try
        FField := DataSource.DataSet.FieldByName(FieldName);
      except
        FField := nil;
      end;
    end;
  end;
end;

procedure TFieldDataLink.ActiveChanged;
begin
  if not Active then
    FField := nil
  else
    UpdateField;

  if Assigned(OnActiveChange) then
    OnActiveChange(Self);
end;

procedure TFieldDataLink.RecordChanged(Field: TField);
begin
  if (Field = nil) or (Field = FField) then
  begin
    if Assigned(FOnDataChange) then
      FOnDataChange(Self);
  end;
end;

procedure TFieldDataLink.UpdateData;
begin
  if FModified then
  begin
    if (Field <> nil) and Assigned(FOnUpdateData) then
      FOnUpdateData(Self);
    FModified := False;
  end;
end;

procedure TFieldDataLink.Modified;
begin
  FModified := true;
end;

{ TDBEdit }

function TDBEdit.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

procedure TDBEdit.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

function TDBEdit.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBEdit.GetDisplayText: string;
begin
  if not CheckDataSet then
    Result := ''
  else
    Result := inherited GetDisplayText;
end;

function TDBEdit.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet;
end;

function TDBEdit.IsReadOnly: boolean;
begin
  Result := ReadOnly or not CheckDataSet;
end;

procedure TDBEdit.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

procedure TDBEdit.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;
  inherited;
end;

destructor TDBEdit.Destroy;
begin
  FDataLink.Free;
  inherited;
end;

procedure TDBEdit.DoExit;
begin
  if not FDataLink.ReadOnly then
  begin
//    try
      FEditChange := true;
      FDataLink.UpdateRecord;     { tell data link to update database }

//    except
//      on Exception do
//      begin
//        SetFocus;   { if it failed, don't let focus leave }
//        raise;
//      end;
//    end;
  end;

  inherited DoExit;

  FEditChange := false;
end;

function TDBEdit.CanCut: boolean;
var
  canedit: boolean;
begin
  if not CheckDataSet then
    Exit;

  if ReadOnly then
  begin
    Result := false;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
  begin
    Result := false;
    Exit;
  end
  else
  begin
    Result := inherited;
    FDataLink.Edit;
  end;
end;

function TDBEdit.CanPaste(AValue: string): boolean;
begin
  Result := CanCut;
end;

procedure TDBEdit.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    FDataLink.Field.AsString := Text;
  end;
end;

procedure TDBEdit.DataChange(Sender: TObject);
begin
  if FEditChange then
    Exit;

  UpdateElement;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
    Text := FDataLink.Field.DisplayText
  else
    Text := '';
end;

procedure TDBEdit.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
      Text := ''
    else
      DataChange(Self);
  end;
end;

procedure TDBEdit.KeyDown(var Key: Word; Shift: TShiftState);
var
  canedit: boolean;

begin
  if not CheckDataSet then
    Exit;

  if (Key = VK_DELETE) or (Key = VK_BACK) or ((Key = VK_INSERT) and (ssShift in Shift)) then
  begin
    FEditChange := true;
    canedit := EditCanModify;
    FEditChange := false;

    if not canedit then
    begin
      key := 0;
      inherited;
      Exit;
    end
    else
    begin
      inherited;
      FDataLink.Edit;
    end;
  end
  else
    inherited;
end;

procedure TDBEdit.KeyPress(var Key: Char);
var
  canedit: boolean;
begin
  if not CheckDataSet or ReadOnly then
  begin
    inherited;
    Exit;
  end;

  if FDataLink.DataSet.ControlsDisabled then
  begin
    PreventDefault;
    StopPropagation;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
  begin
    Key := #0;
    Exit;
  end;

  if {((Key >= #32) and (FDataLink.Field <> nil) and
    not FDataLink.Field.IsValidChar(Key)) or} (FDataLink.ReadOnly) then
  begin
    Key := #0;
  end;

  inherited Keypress(Key);
end;

procedure TDBEdit.Change;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  if DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert]) then
    FDataLink.Modified;
end;

function TDBEdit.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

function TDBEdit.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert])) and FDataLink.Edit
  else
    Result := True;
end;

{ TDBLabel }

function TDBLabel.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

procedure TDBLabel.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

function TDBLabel.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;


function TDBLabel.GetDisplayText: string;
begin
  if CheckDataSet then
    Result := inherited GetDisplayText
  else
  begin
    if (csDesigning in ComponentState) then
      Result := Self.Name;
  end;
end;

procedure TDBLabel.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

function TDBLabel.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBLabel.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  inherited;
end;

destructor TDBLabel.Destroy;
begin
  FDataLink.Free;
  inherited;
end;

procedure TDBLabel.DataUpdate(Sender: TObject);
begin
//  ShowMessage('data update');
//  if Assigned(FDataLink.Field) then
//    FDataLink.Field.Text := Caption;
end;

procedure TDBLabel.DataChange(Sender: TObject);
begin
  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
    Caption := FDataLink.Field.DisplayText
  else
    Caption := '';
end;

procedure TDBLabel.ActiveChange(Sender: TObject);
begin
   if Assigned(FDataLink.DataSet) then
   begin
     if not FDataLink.DataSet.Active or not DataSource.Enabled then
       Caption := ''
     else
       DataChange(Self);
   end;
end;


{ TDBNavigator }

procedure TDBNavigator.CreateInitialize;
var
  css: string;
begin
  inherited;

  ControlStyle := ControlStyle - [csAcceptsControls];

  css :=
    // Rules for sizing the icon.
    '.material-icons.md-18 { font-size: 18px; }'+
    '.material-icons.md-24 { font-size: 24px; }'+
    '.material-icons.md-36 { font-size: 36px; }'+
    '.material-icons.md-48 { font-size: 48px; }'+

    // Rules for using icons as black on a light background.
    '.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }'+
    '.material-icons.md-dark.md-inactive { color: rgba(255, 0, 0, 1); }'+

    // Rules for using icons as white on a dark background.
    '.material-icons.md-light { color: rgba(255, 255, 255, 1); }'+
    '.material-icons.md-light.md-inactive { color: rgba(128, 128, 128, 1); }';

  AddControlStyle(css);

  Width := 32*9;

  FImages := TStringList.Create;

  FHints := TStringList.Create;
  FHints.Add('First');
  FHints.Add('Prior');
  FHints.Add('Next');
  FHints.Add('Last');
  FHints.Add('Edit');
  FHints.Add('Post');
  FHints.Add('Insert');
  FHints.Add('Delete');
  FHints.Add('Cancel');

  FDataLink := TFieldDataLink.Create;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;

  FBtnID := FindUniqueName('DBNavBtn');

  CreateButton(FFirstBtn, FBtnID + '_first', '&#xE5DC;',0);
  CreateButton(FPriorBtn, FBtnID + '_prior', '&#xE5CB;',1);
  CreateButton(FNextBtn, FBtnID + '_next', '&#xE5CC;',2);
  CreateButton(FLastBtn, FBtnID + '_last', '&#xE5DD;',3);

  CreateButton(FEditBtn, FBtnID + '_edit', '&#xE5C7;',4);
  CreateButton(FPostBtn, FBtnID + '_post', '&#xE5CA;',5);
  CreateButton(FInsertBtn, FBtnID + '_insert', '&#xE145;',6);
  CreateButton(FDeleteBtn, FBtnID + '_delete','&#xE15B;',7);
  CreateButton(FCancelBtn, FBtnID + '_cancel','&#xE14C;',8);

  FVisibleButtons := [nbFirst, nbPrior, nbNext, nbLast,
                  nbInsert, nbDelete, nbEdit, nbPost, nbCancel];

  UpdateButtons;
end;

destructor TDBNavigator.Destroy;
begin
  FDataLink.Free;
  FHints.Free;
  FImages.Free;
  inherited Destroy;
end;

procedure TDBNavigator.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

procedure TDBNavigator.SetEnabled(Value: Boolean);
begin
  inherited;
  UpdateButtons;
end;

procedure TDBNavigator.SetHints(const Value: TStringList);
begin
  FHints.Assign(Value);
  UpdateButtons;
end;

procedure TDBNavigator.SetImages(const Value: TStringList);
begin
  FImages.Assign(Value);
  UpdateButtons;
end;

procedure TDBNavigator.SetVisibleButtons(const Value: TNavButtonSet);
begin
  FVisibleButtons := Value;
  UpdateButtons;
end;

procedure TDBNavigator.GetChildren(Proc: TGetChildProc; Root: TComponent);
begin
  // do nothing
end;

function TDBNavigator.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

procedure TDBNavigator.CreateButton(var btn: TSpeedButton; BtnID, Glyph: string; Index: integer);
begin
  btn := TSpeedButton.Create(BtnID);
  btn.Flat := true;
  btn.Parent := Self;

  if (FImages.Count > Index) and (FImages[Index] <> '') then
    btn.GlyphURL := FImages[Index]
  else
    btn.MaterialGlyph := Glyph;

  btn.Width := 32;
  btn.Height := 24;
  btn.Align := alLeft;

  if FHints.Count > Index then
  begin
    btn.Hint := FHints[Index];
  end;
  btn.ShowHint := (FHints.Count > Index) and (btn.Hint <> '');
  btn.OnClick := HandleSpeedButtonClick;
  btn.OnMouseDown := HandleSpeedButtonMouseDown;
end;

procedure TDBNavigator.UpdateButtons;
begin
  if Assigned(FDataLink) and Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and Assigned(FDataLink.DataSource.DataSet.Active)  then
  begin
    FFirstBtn.Enabled := Enabled and (FDataLink.DataSet.Active and not FDataLink.DataSet.BOF);
    FPriorBtn.Enabled := Enabled and (FDataLink.DataSet.Active and not FDataLink.DataSet.BOF);
    FNextBtn.Enabled := Enabled and (FDataLink.DataSet.Active and not FDataLink.DataSet.EOF);
    FLastBtn.Enabled := Enabled and (FDataLink.DataSet.Active and not FDataLink.DataSet.EOF);
    FPostBtn.Enabled := Enabled and (FDataLink.DataSet.Active  and (FDataLink.DataSet.State in [dsEdit, dsInsert]));
    FEditBtn.Enabled := Enabled and (FDataLink.DataSet.Active  and (FDataLink.DataSet.State = dsBrowse) and not FDataLink.DataSet.IsEmpty);
    FInsertBtn.Enabled := Enabled and (FDataLink.DataSet.Active and not (FDataLink.DataSet.State = dsInsert));
    FDeleteBtn.Enabled := Enabled and (FDataLink.DataSet.Active and not FDataLink.DataSet.IsEmpty);
    FCancelBtn.Enabled := Enabled and (FDataLink.DataSet.Active and (FDataLink.DataSet.State in [dsInsert, dsEdit]));
  end
  else
  begin
    FFirstBtn.Enabled := false;
    FPriorBtn.Enabled := false;
    FNextBtn.Enabled := false;
    FLastBtn.Enabled := false;
    FEditBtn.Enabled := false;
    FPostBtn.Enabled := false;
    FInsertBtn.Enabled := false;
    FDeleteBtn.Enabled := false;
    FCancelBtn.Enabled := false;
  end;

  FFirstBtn.Visible := nbFirst in VisibleButtons;
  FPriorBtn.Visible := nbPrior in VisibleButtons;
  FNextBtn.Visible := nbNext in VisibleButtons;
  FLastBtn.Visible := nbLast in VisibleButtons;
  FEditBtn.Visible := nbEdit in VisibleButtons;
  FPostBtn.Visible := nbPost in VisibleButtons;
  FInsertBtn.Visible := nbInsert in VisibleButtons;
  FDeleteBtn.Visible := nbDelete in VisibleButtons;
  FCancelBtn.Visible := nbCancel in VisibleButtons;

  while FHints.Count < 9 do
    FHints.Add('');

  FFirstBtn.Hint := Hints.Strings[0];
  FPriorBtn.Hint := Hints.Strings[1];
  FNextBtn.Hint := Hints.Strings[2];
  FLastBtn.Hint := Hints.Strings[3];
  FEditBtn.Hint := Hints.Strings[4];
  FPostBtn.Hint := Hints.Strings[5];
  FInsertBtn.Hint := Hints.Strings[6];
  FDeleteBtn.Hint := Hints.Strings[7];
  FCancelBtn.Hint := Hints.Strings[8];

  UpdateImage(FFirstBtn, '&#xE5DC;', 0);
  UpdateImage(FPriorBtn, '&#xE5CB;', 1);
  UpdateImage(FNextBtn, '&#xE5CC;', 2);
  UpdateImage(FLastBtn, '&#xE5DD;', 3);

  UpdateImage(FEditBtn, '&#xE5C7;', 4);
  UpdateImage(FPostBtn, '&#xE5CA;', 5);
  UpdateImage(FInsertBtn, '&#xE145;', 6);
  UpdateImage(FDeleteBtn, '&#xE15B;', 7);
  UpdateImage(FCancelBtn, '&#xE14C;', 8);
end;

procedure TDBNavigator.UpdateElement;
begin
  inherited;
  if not IsUpdating then
    AddControlLink('googlematerial', 'https://fonts.googleapis.com/icon?family=Material+Icons');
end;

procedure TDBNavigator.UpdateImage(btn: TSpeedButton; Glyph: string; Index: integer);
begin
  if (FImages.Count > Index) and (FImages[Index] <> '') then
  begin
    btn.MaterialGlyph := '';
    btn.GlyphURL := FImages[Index];
  end
  else
  begin
    btn.MaterialGlyph := Glyph;
    btn.GlyphURL := '';
  end;
end;

procedure TDBNavigator.HandleSpeedButtonClick(Sender: TObject);
var
  BID: string;
begin
  if Assigned(DataSource) and Assigned(DataSource.DataSet) then
  begin
    BID := TControl(Sender).GetID;

    if BID = FBtnID + '_first' then
      FDataLink.DataSet.First;

    if BID = FBtnID + '_prior' then
      FDataLink.DataSet.Prior;

    if BID = FBtnID + '_next' then
      FDataLink.DataSet.Next;

    if BID = FBtnID + '_last' then
      FDataLink.DataSet.Last;

    if BID = FBtnID + '_edit' then
      FDataLink.DataSet.Edit;

    if BID = FBtnID + '_insert' then
      FDataLink.DataSet.Insert;

    if BID = FBtnID + '_delete' then
      FDataLink.DataSet.Delete;

    if BID = FBtnID + '_post' then
      FDataLink.DataSet.Post;

    if BID = FBtnID + '_cancel' then
      FDataLink.DataSet.Cancel;
  end;
end;

procedure TDBNavigator.HandleSpeedButtonMouseDown(Sender: TObject;
  Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
begin
  SetFocus;
end;

procedure TDBNavigator.Loaded;
begin
  inherited;
  UpdateButtons;
end;

procedure TDBNavigator.DataChange(Sender: TObject);
begin
  UpdateButtons;
end;

procedure TDBNavigator.ActiveChange(Sender: TObject);
begin
  UpdateButtons;
end;


{ TDBSpinEdit }

function TDBSpinEdit.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

procedure TDBSpinEdit.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

function TDBSpinEdit.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBSpinEdit.GetDisplayText: string;
begin
  if not CheckDataSet then
    Result := ''
  else
    Result := inherited GetDisplayText;
end;

function TDBSpinEdit.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet and (DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert]));
end;

function TDBSpinEdit.IsReadOnly: boolean;
begin
  Result := ReadOnly or not CheckDataSet;
end;

procedure TDBSpinEdit.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

procedure TDBSpinEdit.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;
  inherited;
end;

destructor TDBSpinEdit.Destroy;
begin
  FDataLink.Free;
  inherited;
end;

procedure TDBSpinEdit.DoExit;
begin
  if not FDataLink.ReadOnly then
  begin
//    try
      FEditChange := true;
      FDataLink.UpdateRecord;     { tell data link to update database }

//    except
//      on Exception do
//      begin
//        SetFocus;   { if it failed, don't let focus leave }
//        raise;
//      end;
//    end;
  end;

  inherited DoExit;

  FEditChange := false;
end;

function TDBSpinEdit.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (FDataLink.DataSet.State in [dsInsert, dsEdit])) and FDataLink.Edit
  else
    Result := true;
end;

procedure TDBSpinEdit.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    FDataLink.Field.AsInteger := Value;
  end;
end;

procedure TDBSpinEdit.DataChange(Sender: TObject);
begin
  if FEditChange then
    Exit;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  UpdateElement;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
    Value := FDataLink.Field.AsInteger
  else
    Value := 0;
end;

procedure TDBSpinEdit.ActiveChange(Sender: TObject);
begin
   if Assigned(FDataLink.DataSet) then
   begin
     if not FDataLink.DataSet.Active or not DataSource.Enabled then
     begin
       Text := '';
       Value := 0;
     end
     else
       DataChange(Self);
   end;
end;

procedure TDBSpinEdit.KeyDown(var Key: Word; Shift: TShiftState);
var
  canedit: boolean;

begin
  if not Assigned(DataSource) then
  begin
    inherited;
    Exit;
  end;

  if not FDataLink.DataSet.Active or not DataSource.Enabled then
  begin
    inherited;
    Exit;
  end;

  if (Key = VK_DELETE) or (Key = VK_BACK) or ((Key = VK_INSERT) and (ssShift in Shift)) then
  begin
    FEditChange := true;
    canedit := EditCanModify;
    FEditChange := false;

    if not canedit then
    begin
      key := 0;
      inherited;
      Exit;
    end
    else
    begin
      inherited;
      FDataLink.Edit;
    end;
  end;
end;

procedure TDBSpinEdit.KeyPress(var Key: Char);
var
  canedit: boolean;
begin
  if not CheckDataSet or ReadOnly then
  begin
    inherited;
    Exit;
  end;

  if FDataLink.DataSet.ControlsDisabled then
  begin
    PreventDefault;
    StopPropagation;
    Exit;
  end;

  if not FDataLink.DataSet.Active or not DataSource.Enabled then
  begin
    inherited;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
    Exit;
 
  if {(Key >= #32) and (FDataLink.Field <> nil) and
    not FDataLink.Field.IsValidChar(Key) or} (FDataLink.ReadOnly) then
  begin
    Key := #0;
  end;

  inherited Keypress(Key);
end;

procedure TDBSpinEdit.Change;
var
  canedit: boolean;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if canedit then
  begin
    FDataLink.Edit;
    FDataLink.Modified;
  end;
end;

function TDBSpinEdit.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

{ TDBCheckBox }

procedure TDBCheckBox.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBCheckBox.SetDataSource(const Value: TDataSource);
begin
  FDatalink.DataSource := Value;
end;

function TDBCheckBox.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBCheckBox.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet and (DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert]));
end;

function TDBCheckBox.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

procedure TDBCheckBox.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    if FDataLink.Field.DataType = ftString then
    begin
      if Checked then
        FDataLink.Field.AsString := ValueChecked
      else
        FDataLink.Field.AsString := ValueUnChecked;
    end
    else
      FDataLink.Field.AsBoolean := Checked;
  end;
end;

procedure TDBCheckBox.DataChange(Sender: TObject);
begin
  UpdateElement;

  if FEditChange then
    Exit;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
  begin
    if FDataLink.Field.DataType = ftString then
      Checked := FDataLink.Field.AsString = ValueChecked
    else
      Checked := FDataLink.Field.AsBoolean;
  end
  else
    Checked := false;
end;

function TDBCheckBox.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (FDataLink.DataSet.State in [dsEdit, dsInsert])) and FDataLink.Edit
  else
    Result := true;
end;


procedure TDBCheckBox.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
      Checked := false
    else
      DataChange(Self);
  end;
end;

procedure TDBCheckBox.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;
  FValueChecked := 'True';
  FValueUnChecked := 'False';
  inherited;
end;

destructor TDBCheckBox.Destroy; 
begin
  FDataLink.Free;
  inherited Destroy;
end;

function TDBCheckBox.CheckDataSet: boolean;
begin
   Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBCheckBox.Click;
var
  canedit: boolean;
begin
  inherited;

  if not Assigned(DataSource) then
    Exit;

  if not FDataLink.DataSet.Active or not DataSource.Enabled then
    Exit;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if canedit then
  begin
    FDataLink.Edit;
    FDataLink.Modified;
  end;
end;

{ TDBDateTimePicker }

procedure TDBDateTimePicker.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBDateTimePicker.SetDataSource(const Value: TDataSource);
begin
  FDatalink.DataSource := Value;
end;

function TDBDateTimePicker.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBDateTimePicker.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet and (DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert]));
end;

function TDBDateTimePicker.IsReadOnly: boolean;
begin
  Result := ReadOnly or not CheckDataSet;
end;

procedure TDBDateTimePicker.KeyDown(var Key: Word; Shift: TShiftState);
var
  canedit: boolean;

begin
  if not CheckDataSet then
  begin
    inherited;
    Exit;
  end;

  if not FDataLink.DataSet.Active or not DataSource.Enabled then
  begin
    inherited;
    Exit;
  end;

  if (Key = VK_DELETE) or (Key = VK_BACK) or ((Key = VK_INSERT) and (ssShift in Shift)) then
  begin
    FEditChange := true;
    canedit := EditCanModify;
    FEditChange := false;

    if not canedit then
    begin
      key := 0;
      inherited;
      Exit;
    end
    else
    begin
      inherited;
      FDataLink.Edit;
    end;
  end;
end;

procedure TDBDateTimePicker.KeyPress(var Key: Char);
begin
  if not CheckDataSet or ReadOnly then
  begin
    inherited;
    Exit;
  end;

  if FDataLink.DataSet.ControlsDisabled then
  begin
    PreventDefault;
    StopPropagation;
    Exit;
  end;

  inherited;
end;

function TDBDateTimePicker.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

procedure TDBDateTimePicker.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    FEditChange := true;

    FDataLink.Field.AsDateTime := DateTime;
  end;

  FEditChange := false;
end;

procedure TDBDateTimePicker.DataChange(Sender: TObject);
var
  dt: TDateTime;
begin
  UpdateElement;

  if FEditChange then
    Exit;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
  begin
    if FDataLink.Field.AsString = '' then
      Clear
    else
      if TRESTClient.IsIsoDateTime(FDataLink.Field.AsString) then
        DateTime := TRESTClient.IsoToDateTime(FDataLink.Field.AsString)
      else
        if TryStrToDateTime(FDataLink.Field.AsString, dt) then
          DateTime := dt
        else
          Clear;
  end
  else
  begin
    Date := 0;
    Time := 0;
  end;
end;


function TDBDateTimePicker.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (FDataLink.DataSet.State in [dsEdit, dsInsert])) and FDataLink.Edit
  else
    Result := true;
end;


procedure TDBDateTimePicker.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
    begin
      Date := Now;
    end
    else
      DataChange(Self);
  end;
end;

procedure TDBDateTimePicker.Change;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  if DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit, dsInsert]) then
  begin
    FEditChange := true;
    FDataLink.Edit;
    FDataLink.Modified;
    FEditChange := false;
  end;
end;

function TDBDateTimePicker.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBDateTimePicker.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;
  inherited;
end;

destructor TDBDateTimePicker.Destroy;
begin
  FDataLink.Free;
  inherited Destroy;
end;


procedure TDBDateTimePicker.DoExit;
begin
  if not FDataLink.ReadOnly then
  begin
//    try
      FEditChange := true;
      FDataLink.UpdateRecord;     { tell data link to update database }

//    except
//      on Exception do
//      begin
//        SetFocus;   { if it failed, don't let focus leave }
//        raise;
//      end;
//    end;
  end;

  inherited DoExit;

  FEditChange := false;
end;

{ TDBComboBox }

procedure TDBComboBox.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBComboBox.SetDataSource(const Value: TDataSource);
begin
  FDatalink.DataSource := Value;
end;

procedure TDBComboBox.SetListField(const Value: string);
begin
  FListLink.FieldName := Value;
  ListActiveChange(Self);
end;

procedure TDBComboBox.SetListSource(const Value: TDataSource);
begin
  FListLink.DataSource := Value;
end;

function TDBComboBox.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBComboBox.GetListField: string;
begin
  Result := FListLink.FieldName;
end;

function TDBComboBox.GetListSource: TDataSource;
begin
  Result := FListLink.DataSource;
end;

function TDBComboBox.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet and (DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert]));
end;

procedure TDBComboBox.ListActiveChange(Sender: TObject);
var
  fld: TField;
  bk: TBookmark;
begin
  if not Assigned(ListSource) then
    Exit;

  if Assigned(FListLink.Field) and Assigned(FListLink.DataSet) and FListLink.DataSet.Active and not FListLink.DataSet.IsEmpty then
  begin
    fld := FListLink.DataSet.FieldByName(FListLink.FieldName);

    if Assigned(fld) then
    begin
      FListLink.DataSet.DisableControls;
      bk := FListLink.DataSet.GetBookmark;
      Items.Clear;
      FListLink.DataSet.First;

      while not FListLink.DataSet.Eof do
      begin
        Items.Add(fld.DisplayText);
        FListLink.DataSet.Next;
      end;

      FListLink.DataSet.GotoBookmark(bk);

      FListLink.DataSet.EnableControls;
    end;
  end;
end;

function TDBComboBox.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

procedure TDBComboBox.DataUpdate(Sender: TObject);
var
  s: string;
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    s := Text;
    // in case key/value pairs are found
    if (Pos(Items.NameValueSeparator, s) > 0) and not Assigned(ListSource) and (Style = csDropDownList) and (ItemIndex >= 0) then
    begin
      s := Items.Names[ItemIndex];
    end;

    FDataLink.Field.AsString := s;
  end;
end;

procedure TDBComboBox.DataChange(Sender: TObject);
begin
  UpdateElement;

  if FEditChange then
    Exit;

  if not Assigned(DataSource) then
    Exit;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
  begin
    if Style = csDropDown then
      Text := FDataLink.Field.DisplayText
    else
    begin
      if (Items.Count > 0) and (Pos(Items.NameValueSeparator, Items[0]) > 0) and not Assigned(ListSource) then
      begin
        ItemIndex := Items.IndexOfName(FDataLink.Field.DisplayText);
      end
      else
        ItemIndex := Items.IndexOf(FDataLink.Field.DisplayText);
    end;
  end
  else
    ItemIndex := -1;
end;


function TDBComboBox.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (FDataLink.DataSet.State in [dsEdit, dsInsert])) and FDataLink.Edit
  else
    Result := true;
end;

procedure TDBComboBox.Change;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  if DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit, dsInsert]) then
  begin
    FEditChange := true;
    FDataLink.Edit;
    FDataLink.Modified;
    FEditChange := false;
  end;
end;

function TDBComboBox.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBComboBox.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin

    if not FDataLink.DataSet.Active or not DataSource.Enabled then
    begin
      Text := '';
    end
    else
      DataChange(Self);
  end;
end;

procedure TDBComboBox.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;

  FListLink := TFieldDataLink.Create;
  FListLink.OnActiveChange := ListActiveChange;

  FEditChange := false;
  inherited;
end;

destructor TDBComboBox.Destroy;
begin
  FDataLink.Free;
  FListLink.Free;
  inherited Destroy;
end;

procedure TDBComboBox.DoExit;
begin
  if not FDataLink.ReadOnly then
  begin
//    try
      FEditChange := true;
      FDataLink.UpdateRecord;     { tell data link to update database }

//    except
//      on Exception do
//      begin
//        SetFocus;   { if it failed, don't let focus leave }
//        raise;
//      end;
//    end;
  end;

  inherited DoExit;

  FEditChange := false;

end;

{ TDBRadioGroup }

procedure TDBRadioGroup.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBRadioGroup.SetDataSource(const Value: TDataSource);
begin
  FDatalink.DataSource := Value;
end;

function TDBRadioGroup.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBRadioGroup.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet and (DataSource.AutoEdit or (FDataLink.DataSet.State in [dsEdit, dsInsert]));
end;

function TDBRadioGroup.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

procedure TDBRadioGroup.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    if FDataLink.Field.DataType = ftString then
    begin
      if Values.Count > ItemIndex then
        FDataLink.Field.AsString := Values[ItemIndex];
    end
    else
      FDataLink.Field.AsInteger := ItemIndex + 1;
  end;
end;

procedure TDBRadioGroup.DataChange(Sender: TObject);
begin
  UpdateElement;

  if FEditChange then
    Exit;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
  begin
    if FDataLink.Field.DataType = ftString then
    begin
       ItemIndex := Values.IndexOf(FDataLink.Field.AsString);
    end
    else
      ItemIndex := FDataLink.Field.AsInteger - 1;
  end
  else
    ItemIndex := -1;
end;


function TDBRadioGroup.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (FDataLink.DataSet.State in [dsEdit, dsInsert])) and FDataLink.Edit
  else
    Result := true;
end;

function TDBRadioGroup.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBRadioGroup.Click;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  if DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit, dsInsert]) then
  begin
    FEditChange := true;
    FDataLink.Edit;
    FDataLink.Modified;
    FEditChange := false;
  end;
end;

procedure TDBRadioGroup.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
    begin
      ItemIndex := -1;
    end
    else
      DataChange(Self);
  end;
end;

procedure TDBRadioGroup.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;
  FValues := TStringList.Create;
  FValues.SkipLastLineBreak := true;
  inherited;
end;

destructor TDBRadioGroup.Destroy; 
begin
  FValues.Free;
  FDataLink.Free;
  inherited Destroy;
end;

procedure TDBRadioGroup.SetValues(const Value: TStringList);
begin
  FValues.Assign(Value);
end;


{ TDBLinkLabel }

procedure TDBLinkLabel.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBLinkLabel.SetDataSource(const Value: TDataSource);
begin
  FDatalink.DataSource := Value;
end;

function TDBLinkLabel.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBLinkLabel.GetDisplayText: string;
begin
  if CheckDataSet then
    Result := inherited GetDisplayText
  else
  begin
    if (csDesigning in ComponentState) then
      Result := Self.Name;
  end;
end;

function TDBLinkLabel.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

procedure TDBLinkLabel.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;
  end;
end;

procedure TDBLinkLabel.DataChange(Sender: TObject);
var
  url, protocol, tgt: string;
begin
  if FEditChange then
    Exit;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
  begin
    case LinkType of
    wlHttp: protocol := 'http://';
    wlHttps: protocol := 'https://';
    wlMail: protocol := 'mailto:';
    wlFTP: protocol := 'ftp://';
    end;

    tgt := '';

    if Target = ltNewTab then
      tgt := ' target="_blank"';

    url := '<a href="' + protocol + FDataLink.Field.DisplayText + '"'+ tgt + '>' + FDataLink.Field.DisplayText + '</a>';

    Caption := url;
  end
  else
    Caption := '';
end;


function TDBLinkLabel.EditCanModify: Boolean; 
begin
  Result := false;
end;

function TDBLinkLabel.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBLinkLabel.Click;
begin
  inherited;
end;

procedure TDBLinkLabel.ActiveChange(Sender: TObject);
begin
   if Assigned(FDataLink.DataSet) then
   begin
     if not FDataLink.DataSet.Active or not DataSource.Enabled then 
     begin
       Caption := '';
     end
     else
       DataChange(Self);
   end;
end;

procedure TDBLinkLabel.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;
  FLinkType := wlHttp;
  inherited;
end;

destructor TDBLinkLabel.Destroy; 
begin
  FDataLink.Free;
  inherited Destroy;
end;



{ TDBMemo }

procedure TDBMemo.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
      Lines.Text := ''
    else
      DataChange(Self);
  end;
end;

function TDBMemo.CanCut: boolean;
var
  canedit: boolean;
begin
  if not CheckDataSet then
    Exit;

  if ReadOnly then
  begin
    Result := false;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
  begin
    Result := false;
    Exit;
  end
  else
  begin
    Result := inherited;
    FDataLink.Edit;
  end;
end;

function TDBMemo.CanPaste(AValue: string): boolean;
begin
  Result := CanCut;
end;

procedure TDBMemo.Change;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  if DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert]) then
  begin
    FEditChange := true;
    FDataLink.Edit;
    FDataLink.Modified;
    FEditChange := false;
  end;
end;

function TDBMemo.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBMemo.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;
  inherited;
end;

procedure TDBMemo.DataChange(Sender: TObject);
begin
  if FEditChange then
    Exit;

  UpdateElement;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
  begin
    if (FDataLink.Field is TBlobField) and not (FDataLink.Field is TMemoField) then
    begin
      try
        Lines.Text := window.atob(string(FDataLink.Field.AsJSValue));
      except
        Lines.Text := FDataLink.Field.DisplayText;
      end;
    end
    else
    begin
      Lines.Text := FDataLink.Field.DisplayText
    end;
  end
  else
    Lines.Text := '';
end;

procedure TDBMemo.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    if (FDataLink.Field is TBlobField) and not (FDataLink.Field is TMemoField) then
    begin
      FDataLink.Field.AsJSValue := window.btoa(Lines.Text);
    end
    else
    begin
      Lines.TextLineBreakStyle := tlbsCR;
      FDataLink.Field.AsString := Lines.Text;
    end;
  end;
end;

destructor TDBMemo.Destroy;
begin
  FDataLink.Free;
  inherited;
end;

procedure TDBMemo.DoExit;
begin
  if not FDataLink.ReadOnly then
  begin
//    try
      FEditChange := true;
      FDataLink.UpdateRecord;     { tell data link to update database }

//    except
//      on Exception do
//      begin
//        SetFocus;   { if it failed, don't let focus leave }
//        raise;
//      end;
//    end;
  end;

  inherited DoExit;

  FEditChange := false;
end;

function TDBMemo.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (FDataLink.DataSet.State in [dsEdit, dsInsert])) and FDataLink.Edit
  else
    Result := true;
end;

function TDBMemo.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

function TDBMemo.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBMemo.GetDisplayText: string;
begin
  if not CheckDataSet then
    Result := ''
  else
    Result := inherited GetDisplayText;
end;

function TDBMemo.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet;
end;

function TDBMemo.IsReadOnly: boolean;
begin
  Result := ReadOnly or not CheckDataSet;
end;

procedure TDBMemo.KeyDown(var Key: Word; Shift: TShiftState);
var
  canedit: boolean;

begin
  if not CheckDataSet then
    Exit;

  if (Key = VK_DELETE) or (Key = VK_BACK) or ((Key = VK_INSERT) and (ssShift in Shift)) then
  begin
    FEditChange := true;
    canedit := EditCanModify;
    FEditChange := false;

    if not canedit then
    begin
      key := 0;
      inherited;
      Exit;
    end
    else
    begin
      inherited;
      FDataLink.Edit;
    end;
  end;
end;

procedure TDBMemo.KeyPress(var Key: Char);
var
  canedit: boolean;
begin
  if not CheckDataSet or ReadOnly then
    Exit;

  if FDataLink.DataSet.ControlsDisabled then
  begin
    PreventDefault;
    StopPropagation;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
    Exit;

  if {(Key >= #32) and (FDataLink.Field <> nil) and
    not FDataLink.Field.IsValidChar(Key) or} (FDataLink.ReadOnly) then
  begin
    Key := #0;
  end;

  inherited Keypress(Key);
end;

procedure TDBMemo.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBMemo.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

{ TDBTableControl }

procedure TDBTableControl.ActiveChange(Sender: TObject);
begin
  // load data here from dataset
  if not Assigned(DataSource) then
    Exit;

  if not Assigned(DataSource.DataSet) then
    Exit;

  if DataSource.Dataset.ControlsDisabled then
    Exit;

  if DataSource.DataSet.Active then
  begin
    FDataChanging := true;
    LoadData;
    FDataChanging := false;
  end
  else
  begin
    RowCount := 2;
    Clear;
    if FDefaultFields then
      Columns.Clear;
  end;
end;

procedure TDBTableControl.CreateInitialize;
begin
  inherited;
  RowCount := 2;
  FDataLink := TDBDataLink.Create;
  FDataLink.OnActiveChange := ActiveChange;
  FDataLink.OnRecordChange := RecordChange;
  FDataLink.OnDataChange := DataChange;
  FColumns := TTableControlColumns.Create(Self);
  FColumns.PropName := 'Columns';
  FDefaultFields := false;
  FDataChanging := false;
end;

procedure TDBTableControl.DataChange(Sender: TObject);
begin
  if FDataChanging then
    Exit;

  FDataChanging := true;

  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active and (FDataLink.DataSource.DataSet.State = dsBrowse) then
  begin
    LoadData;
  end;

  FDataChanging := false;
end;

procedure TDBTableControl.RecordChange(Sender: TObject);
var
  r: integer;
begin
  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active and (FDataLink.DataSource.DataSet.State = dsBrowse) then
  begin
    r := FDataLink.DataSource.DataSet.RecNo - 1;

    if r = -1  then
      Exit;

    if RowHeader then
      inc(r);

    if r < RowCount then
      LoadRow(r);
  end;
end;

destructor TDBTableControl.Destroy;
begin
  FDataLink.Free;
  FColumns.Free;
  inherited;
end;

function TDBTableControl.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

procedure TDBTableControl.LoadData;
var
  c,r,i: integer;
  lField: TField;
  noflds: boolean;
  cd,cn: string;
  ce: TJSHTMLElement;
  bk: TBookmark;
  isBOF, isEOF: boolean;
  scrollevent: TDataSetNotifyEvent;
begin
  isBOF := DataSource.DataSet.BOF;
  isEOF := DataSource.DataSet.EOF;

  bk := DataSource.DataSet.GetBookmark;

  DataSource.DataSet.DisableControls;

  scrollevent := DataSource.DataSet.AfterScroll;
  DataSource.DataSet.AfterScroll := nil;

  DataSource.DataSet.First;

  if RowHeader then
    r := 1
  else
    r := 0;

  noflds := true;

  for i := 0 to Columns.Count - 1 do
  begin
    if Columns[i].DataField <> '' then
      noflds := false;
  end;

  FDefaultFields := noflds;

  if noflds and (Columns.Count > 0) then
  begin
    for i := 0 to DataSource.DataSet.FieldCount - 1 do
    begin
      if i >= Columns.Count then
        Columns.Add;

      with Columns[i] do
      begin
        if DataField = '' then
          DataField := DataSource.DataSet.Fields[i].FieldName;
        if Title = '' then
          Title :=  DataSource.DataSet.Fields[i].DisplayLabel;
      end;
    end;
  end;

  if Columns.Count = 0 then
  begin
    for c := 0 to DataSource.DataSet.FieldCount - 1 do
    begin
      with Columns.Add do
      begin
        lField := DataSource.DataSet.Fields[c];
        DataField := lField.FieldName;
        Title := DataField;
        if (lField is TBlobField) and not (lField is TMemoField) then
        begin
          DataType := cdMemo;
        end;
      end;
    end;
  end;

  ColCount := Columns.Count;

  RowCount := DataSource.DataSet.RecordCount + r;

  if RowHeader then
  begin
    for c := 0 to ColCount - 1 do
    begin
      lField := nil;

      if (Columns[c].DataField <> '') then
      begin
        try
          lField := DataSource.DataSet.FieldByName(Columns[c].DataField);
        except
          lField := nil;
        end;
      end;

      cd := Columns[c].Title;

      GetCellData(c, 0, nil, cd);
      Cells[c, 0] := cd;

      cn := Columns[c].TitleElementClassName;

      ce := TJSHTMLElement(CellElements[c,0]);

      GetCellChildren(c, 0, lField, cd, ce);

      GetCellClassName(c, 0, lField, cd, cn);

      if cn <> '' then
      begin
        ce.setAttribute('class',cn);
      end;

    end;
  end;

  // load all rows

  while not DataSource.DataSet.EOF do
  begin
    LoadRow(r);
    inc(r);
    DataSource.DataSet.Next;
  end;

  if DataSource.DataSet.Filtered then
    RowCount := r;

  if DataSource.DataSet.BookmarkValid(bk) then
    DataSource.DataSet.GotoBookmark(bk);

  if isBOF then
    DataSource.DataSet.First;

  if isEOF then
    DataSource.DataSet.Last;

  DataSource.DataSet.EnableControls;
  DataSource.DataSet.AfterScroll := scrollevent;
end;

procedure TDBTableControl.Loaded;
begin
  inherited;
  ColCount := Columns.Count;
end;

procedure TDBTableControl.LoadRow(ARow: integer);
var
  c,r,cr: integer;
  lField: TField;
  cd,cn,wcss: string;
  ce: TJSHTMLElement;

begin
  c := 0;
  r := ARow;

  for c := 0 to ColCount - 1 do
  begin
    lField := nil;
    cd := '';
    wcss := '';

    cr := r;

    if Columns[c].Width > 0 then
      wcss := 'width:'+Columns[c].Width.Tostring+'px;overflow:hidden;text-overflow:ellipsis;max-width:'+Columns[c].Width.ToString+'px;white-space:nowrap';

    if (Columns[c].DataField <> '') then
    begin
      try
        lField := DataSource.DataSet.FieldByName(Columns[c].DataField);
      except
        lField := nil;
      end;
      if Assigned(lField) then
        cd := TableFieldToCellData(ce, lField, Columns[c].DataType, false);
    end;

    GetCellData(c,r,lField,cd);
    Cells[c,r] := cd;

    cn := Columns[c].ElementClassName;

    if InVisiblePage(cr) then
    begin
      ce := TJSHTMLElement(CellElements[c,cr]);

      if wcss <> '' then
        ce['style'] := wcss;

      GetCellChildren(c, r, lField, cd, ce);

      GetCellClassName(c, r, lField, cd, cn);

      if Assigned(ce) and (cn <> '') then
      begin
        ce.setAttribute('class',cn);
      end;
    end;
  end;
end;

procedure TDBTableControl.SetColumns(const Value: TTableControlColumns);
begin
  FColumns.Assign(Value);
end;

procedure TDBTableControl.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

{ TTableControlColumns }

function TTableControlColumns.Add: TTableControlColumn;
begin
   Result := TTableControlColumn(inherited Add);
end;

procedure TTableControlColumns.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTableControlColumns.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TTableControlColumn);
end;

function TTableControlColumns.GetItem(Index: integer): TTableControlColumn;
begin
  Result := TTableControlColumn(inherited Items[Index]);
end;

function TTableControlColumns.Insert(Index: integer): TTableControlColumn;
begin
  Result := TTableControlColumn(inherited Insert(Index));
end;

procedure TTableControlColumns.SetItem(Index: integer;
  const Value: TTableControlColumn);
begin
  inherited Items[Index] := Value;
end;

procedure TTableControlColumns.Update(Item: TCollectionItem);
begin
  inherited;
  Changed;
end;

{ TDBDataLink }

procedure TDBDataLink.ActiveChanged;
begin
  inherited;
  if Assigned(OnActiveChange) then
    OnActiveChange(Self);
end;

procedure TDBDataLink.DataSetScrolled(Distance: Longint);
begin
  inherited;
  if Assigned(OnDataScroll) then
    OnDataScroll(Self, Distance);
end;

procedure TDBDataLink.DataSetChanged;
begin
  inherited;
  if Assigned(OnDataChange) then
    OnDataChange(Self);
end;

procedure TDBDataLink.RecordChanged(Field: TField);
begin
  inherited;
  if Assigned(OnRecordChange) then
    OnRecordChange(Self);
end;

procedure TDBDataLink.UpdateData;
begin
  inherited;
  if Assigned(OnUpdateData) then
    OnUpdateData(Self);
end;

{ TTableControlColumn }

procedure TTableControlColumn.Assign(Source: TPersistent);
begin
  if (Source is TTableControlColumn) then
  begin
    FClassName := (Source as TTableControlColumn).ElementClassName;
    FDataField := (Source as TTableControlColumn).DataField;
    FTitle := (Source as TTableControlColumn).Title;
    FTitleClassName := (Source as TTableControlColumn).TitleElementClassName;
    FDataType := (Source as TTableControlColumn).DataType;
    FTag := (Source as TTableControlColumn).Tag;
    FWidth := (Source as TTableControlColumn).Width;
  end;
end;

procedure TTableControlColumn.SetDataField(const Value: TDataField);
begin
  if FDataField <> Value then
  begin
    FDataField := Value;
    (Collection as TTableControlColumns).Update(Self);
  end;
end;

procedure TTableControlColumn.SetTitle(const Value: string);
begin
  if FTitle <> Value then
  begin
    FTitle := Value;
    (Collection as TTableControlColumns).Update(Self);
  end;
end;

procedure TTableControlColumn.SetWidth(const Value: integer);
begin
  FWidth := Value;
  ((Collection as TTableControlColumns).Owner as TDBTableControl).ColWidths[Index] := FWidth;
end;

{ TDBImageControl }

procedure TDBImageControl.ActiveChange(Sender: TObject);
begin
  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
      URL := ''
    else
      DataChange(Self);
  end;
end;

procedure TDBImageControl.CreateInitialize;
begin
  inherited;
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
end;

procedure TDBImageControl.DataChange(Sender: TObject);
var
  AURL, LContents: string;
begin
  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
  begin
    if (FDataLink.Field is TBlobField) then
    begin
      LContents := string(FDataLink.Field.AsJSValue);
      if (length(LContents) > 0) and (LContents <> 'null') then
      begin
        AURL := 'data:image/jpeg;base64,' + LContents;
      end 
      else 
      begin
        AURL := EmptyImage;
      end;
    end 
    else 
    begin
      AURL := BaseURL + FDataLink.Field.DisplayText;
    end;

    if Assigned(OnSetURL) then
      OnSetURL(Self, AURL);
    URL := AURL;
  end;
end;

destructor TDBImageControl.Destroy;
begin
  FDataLink.Free;
  inherited;
end;

function TDBImageControl.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

function TDBImageControl.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

procedure TDBImageControl.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBImageControl.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

{ TDBResponsiveGrid }

procedure TDBResponsiveGrid.ActiveChange(Sender: TObject);
begin
  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active then
  begin
    FDataChanging := true;
    LoadData;
    FDataChanging := false;
  end
  else
    Items.Clear;
end;

procedure TDBResponsiveGrid.CreateInitialize;
begin
  inherited;
  FDataChanging := false;
  FDataLink := TDBDataLink.Create;
  FDataLink.OnActiveChange := ActiveChange;
  FDataLink.OnRecordChange := DataChange;
  FModifying := false;
end;

procedure TDBResponsiveGrid.DataChange(Sender: TObject);
var
  nstate: TDataSetState;
  i: integer;
begin
  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active and not FModifying then
  begin
    nstate := FOldState;
    FOldState := FDataLink.DataSet.State;

    if not FDataChanging and (FRecordCount <> FDataLink.DataSet.RecordCount) then
    begin
      FDataChanging := true;
      LoadData;
      FDataChanging := false;
    end
    else
    if not FDataChanging and (nstate = dsEdit) and (FDataLink.DataSet.State = dsBrowse) then
    begin
      FDataChanging := true;
      LoadData;
      FDataChanging := false;
    end;

    for i := 0 to Items.Count - 1 do
    begin
      if TResponsiveGridItemEx(Items[i]).RecNo = FDataLink.DataSource.DataSet.RecNo then
      begin
        ItemIndex := i;
        break;
      end;
    end;

  end;
end;

destructor TDBResponsiveGrid.Destroy;
begin
  FDataLink.Free;
  inherited;
end;

function TDBResponsiveGrid.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

procedure TDBResponsiveGrid.HandleDoItemClick(Index: integer);
begin
  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active then
  begin
    FModifying := true;

    if (Index >= 0) and (Index < Items.Count) then
      FDataLink.DataSource.DataSet.RecNo := TResponsiveGridItemEx(Items[Index]).RecNo;
    FModifying := false;
  end;
  inherited;
end;

procedure TDBResponsiveGrid.Refresh;
begin
  LoadData;
end;

procedure TDBResponsiveGrid.LoadData;
var
  html, ident, htmlvalue: string;
  c: integer;
  itm: TResponsiveGridItem;
  bk: TBookmark;
  scrollevent: TDataSetNotifyEvent;

begin
  // get the items from the dataset here
  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active then
  begin
    FDataLink.DataSource.DataSet.DisableControls;

    scrollevent := DataSource.DataSet.AfterScroll;
    DataSource.DataSet.AfterScroll := nil;

    bk := FDataLink.DataSet.GetBookmark;

    Items.Clear;

    FDataLink.DataSource.DataSet.First;

    while not FDataLink.DataSource.DataSet.EOF do
    begin
      itm := Items.Add;

      TResponsiveGridItemEx(itm).RecNo := FDataLink.DataSource.DataSet.RecNo;

      html := Options.ItemTemplate;

      ident := '(%ITEMINDEX%)';
      if pos(ident, html) > 0 then
      begin
        html := StringReplace(html, ident, inttostr(itm.Index), [rfReplaceAll]);
      end;

      for c := 0 to FDataLink.DataSource.DataSet.Fields.Count - 1 do
      begin
        ident := '(%' +  FDataLink.DataSource.DataSet.Fields[c].FieldName + '%)';
        if Pos(Uppercase(ident), Uppercase(html)) > 0 then
        begin
          htmlvalue := FDataLink.DataSource.DataSet.Fields[c].DisplayText;
          DoItemGetFieldValue(itm.Index, FDataLink.DataSource.DataSet.Fields[c].FieldName, htmlvalue);
          html := StringReplace(html, ident, htmlvalue, [rfReplaceAll, rfIgnoreCase]);
        end;
      end;

      itm.HTML := html;

      DoItemCreated(itm.Index);

      FDataLink.DataSource.DataSet.Next;
    end;

    FRecordCount := FDataLink.DataSet.RecordCount;

    if DataSource.DataSet.BookmarkValid(bk) then
      FDataLink.DataSet.GotoBookMark(bk);

    FDataLink.DataSource.DataSet.EnableControls;

    DataSource.DataSet.AfterScroll := scrollevent;
  end
  else
    Items.Clear;
end;

procedure TDBResponsiveGrid.OptionsChanged(Sender: TObject);
begin
  inherited;
  LoadData;
end;

procedure TDBResponsiveGrid.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
  LoadData;
end;


{ TGridColumn }

procedure TGridColumn.Assign(Source: TPersistent);
begin
  if (Source is TTableControlColumn) then
  begin
    FClassName := (Source as TGridColumn).ElementClassName;
    FDataField := (Source as TGridColumn).DataField;
    FTitle := (Source as TGridColumn).Title;
    FTitleClassName := (Source as TGridColumn).TitleElementClassName;
    FDataType := (Source as TGridColumn).DataType;
    FWidth := (Source as TGridColumn).Width;
    FEditor := (Source as TGridColumn).Editor;
    FEditMask := (Source as TGridColumn).EditMask;
    FComboBoxItems.Assign((Source as TGridColumn).ComboBoxItems);
    FTag := (Source as TGridColumn).Tag;
    FDBField := (Source as TGridColumn).DBField;
    FImageWidth := (Source as TGridColumn).ImageWidth;
    FAlignment := (Source as TGridColumn).Alignment;
  end;
end;

constructor TGridColumn.Create(Collection: TCollection);
begin
  inherited;
  FWidth := 64;
  FDataType := cdText;
  FComboBoxItems := TStringList.Create;
end;

destructor TGridColumn.Destroy;
begin
  FComboBoxItems.Free;
  inherited;
end;

function TGridColumn.GetDBField: TField;
var
  grd: TDBGrid;
begin
  if (DataField <> '') and (DBField = nil) then
  begin
    grd := TDBGrid((Collection as TGridColumns).Owner);
    if Assigned(grd) and Assigned(grd.DataSource) and Assigned(grd.DataSource.DataSet) then

    try
      DBField := grd.DataSource.DataSet.FieldByName(DataField);
    except
      DBField := nil;
    end;
  end;

  Result := DBField;
end;

procedure TGridColumn.SetAlignment(const Value: TAlignment);
var
  grd: TDBGrid;
begin
  FAlignment := Value;
  grd := TDBGrid((Collection as TGridColumns).Owner);
  grd.ColAlignments[Index + grd.FixedCols] := FAlignment;
end;

procedure TGridColumn.SetComboBoxItems(const Value: TStrings);
begin
  FComboBoxItems := Value;
end;

procedure TGridColumn.SetDataField(const Value: TDataField);
begin
  if FDataField <> Value then
  begin
    FDataField := Value;
    (Collection as TGridColumns).Update(Self);
    FDBField := nil;
  end;
end;

procedure TGridColumn.SetSortIndicator(const Value: TGridSortIndicator);
var
  i: integer;
begin
  for i := 0 to (Collection as TGridColumns).Count - 1 do
  begin
    if (Collection as TGridColumns).Items[i].FSortIndicator <> siNone then
    begin
      (Collection as TGridColumns).Items[i].FSortIndicator := siNone;
      if Assigned((Collection as TGridColumns).OnSortIndicatorChange) then
        (Collection as TGridColumns).OnSortIndicatorChange((Collection as TGridColumns), i, siNone);
    end;
  end;

  FSortIndicator := Value;

  if Assigned((Collection as TGridColumns).OnSortIndicatorChange) then
    (Collection as TGridColumns).OnSortIndicatorChange((Collection as TGridColumns), Index, FSortIndicator);
end;

procedure TGridColumn.SetTitle(const Value: string);
begin
  if FTitle <> Value then
  begin
    FTitle := Value;
    (Collection as TGridColumns).Update(Self);
  end;
end;


procedure TGridColumn.SetWidth(const Value: integer);
begin
  if (FWidth <> Value) then
  begin
    FWidth := Value;
    (Collection as TGridColumns).Update(Self);
  end;
end;

{ TGridColumns }

function TGridColumns.Add: TGridColumn;
begin
   Result := TGridColumn(inherited Add);
end;

procedure TGridColumns.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TGridColumns.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TGridColumn);
end;

function TGridColumns.GetItem(Index: integer): TGridColumn;
begin
  Result := TGridColumn(inherited Items[Index]);
end;

function TGridColumns.Insert(Index: integer): TGridColumn;
begin
  Result := TGridColumn(inherited Insert(Index));
end;

procedure TGridColumns.SetItem(Index: integer;
  const Value: TGridColumn);
begin
  inherited Items[Index] := Value;
end;

procedure TGridColumns.Update(Item: TCollectionItem);
begin
  inherited;
  Changed;
end;

{ TDBGrid }

procedure TDBGrid.ActiveChange(Sender: TObject);
var
  c: integer;
begin
  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active then
  begin
    FDataChanging := true;
    LoadData;
    FDataChanging := false;
  end
  else
  begin
    Clear;
    RowCount := FixedRows;
    if FDefaultFields then
      Columns.Clear;
    for c := 0 to Columns.Count - 1 do
      Columns[c].DBField := nil;

    FOldIndicator := -1;
  end;
end;

function TDBGrid.CanEditCell(ACol, ARow: integer): boolean;
var
  fld: TField;
begin
  Result := inherited;

  if Result and (ACol - FixedCols < Columns.Count) and (ACol >= FixedCols) then
  begin
    fld := Columns[ACol - FixedCols].DBField;
    if Assigned(fld) and fld.readOnly then
    begin
      Result := false;
    end;
  end;
end;

function TDBGrid.CanRowAdvance: boolean;
begin
  Result := false;
end;

procedure TDBGrid.ChangeRow;
begin
  inherited;
end;

procedure TDBGrid.ColumnsChanged(Sender: TObject);
var
  c: integer;
begin
  if not Assigned(ElementHandle) or IsUpdating then
    Exit;

  ColCount := Columns.Count + FixedCols;

  if (FixedRows > 0) and (RowCount > 0) then
  begin
    for c := 0 to Columns.Count - 1 do
    begin
      Cells[c + FixedCols, 0] := Columns[c].Title;
      ColWidths[c + FixedCols] := Columns[c].Width;
    end;
  end;

end;

procedure TDBGrid.CreateInitialize;
begin
  inherited;
  FDataLink := TDBDataLink.Create;
  FDataLink.OnActiveChange := ActiveChange;
  FDataLink.OnRecordChange := RecordChange;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnUpdateData := UpdateData;
  FDataLink.OnDataScroll := DataScroll;
  FColumns := TGridColumns.Create(Self);
  FColumns.OnChange := ColumnsChanged;
  FColumns.OnSortIndicatorChange := DoSortIndicatorChange;
  FColumns.PropName := 'Columns';
  FModifying := false;
  FDefaultFields := false;
  FDataChanging := false;
  FOldIndicator := -1;
  FShowIndicator := true;
end;

procedure TDBGrid.DataChange(Sender: TObject);
var
  r: integer;
  LOldRow,LOldCol: integer;
  filterchange: boolean;
begin
  if FDataChanging then
    Exit;

  if (FOldState <> FDataLink.DataSource.DataSet.State) and not FModifying then
  begin
    if EditMode and (FOldState in [dsEdit, dsInsert]) then
    begin
      StopEdit;
    end;

    // handle reindex
    if (FOldState in [dsInactive]) and (FDataLink.DataSource.DataSet.State = dsBrowse) then
    begin
//      if (RowCount <> FDataLink.DataSource.DataSet.RecordCount + FixedRows) then
      begin
        FDataChanging := true;
        LoadData;
        FDataChanging := false;
        FOldState := FDataLink.DataSource.DataSet.State;
      end;

      Exit;
    end;

    // handle after insert
    if (FOldState in [dsInsert]) then
    begin
      FDataChanging := true;
      LoadData;
      FDataChanging := false;
      FOldState := FDataLink.DataSource.DataSet.State;
      Exit;
    end;
  end;

  // handle sort - datasetchange and stays in dsBrowse state
  if (FOldState in [dsBrowse]) and (FDataLink.DataSource.DataSet.State = dsBrowse) then
  begin
    FDataChanging := true;
    LoadData;
    FDataChanging := false;
    FOldState := FDataLink.DataSource.DataSet.State;
    Exit;
  end;

  FOldState := FDataLink.DataSource.DataSet.State;

  if (FOldState in [{dsInsert,} dsEdit]) then
    Exit;

  FDataChanging := true;

  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active and not FModifying and not EditMode then
  begin
    filterchange := (FOldFiltered <> FDataLink.DataSource.DataSet.Filtered);

    if (RowCount <> FDataLink.DataSource.DataSet.RecordCount + FixedRows) or filterchange then
    begin
      FOldFiltered := FDataLink.DataSource.DataSet.Filtered;
      FModifying := true;

      LOldRow := Row;
      LOldCol := Col;

      FDataChanging := true;
      LoadData;
      FDataChanging := false;
      if filterchange then
      begin
        FDataLink.DataSource.DataSet.First;
        if RowCount > FixedRows then
          Row := FixedRows;
      end;

      FModifying := false;
      SelectCell(LOldCol,LOldRow);
    end
    else
    begin
      r := FixedRows + FDataLink.DataSource.DataSet.RecNo - 1;

      if (FOldState = dsInsert) then
      begin
        LOldCol := Col;
        RowCount := RowCount + 1;
        Row := Max(r, FixedRows);
        Col := LOldCol;
        InsertData(Row);
        //r := RowCount - 1;
        //Row := RowCount - 1;
        //LoadRow(Row);
      end
      else
      begin
        if (FOldState <> dsBrowse) or (FDataLink.DataSource.DataSet.State <> dsBrowse) then
          LoadData;

        Row := r;
      end;

      UpdateIndicator(r);
    end;
  end;

  FDataChanging := false;
end;

procedure TDBGrid.DataScroll(Sender: TObject; Distance: integer);
begin
  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Filtered then
  begin
    Row := Row + Distance;
    UpdateIndicator(Row);
  end;
end;

procedure TDBGrid.RecordChange(Sender: TObject);
var
  r: integer;
begin
  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active then
  begin
    if not FModifying and not EditMode then
    begin
      if not FDataLink.DataSource.DataSet.Filtered then
        r := FixedRows + FDataLink.DataSource.DataSet.RecNo - 1;

      if FDataLink.DataSource.DataSet.State = dsInsert then
      begin

      end
      else
      if (r <= RowCount - 1) and not FDataLink.DataSource.DataSet.Filtered then
      begin
        Row := r;
        LoadRow(r);
      end
      else
      begin
        FModifying := true;
        LoadData;

        if not FDataLink.DataSource.DataSet.Filtered then
          Row := r;

        FModifying := false;
      end;

      if not FDataLink.DataSource.DataSet.Filtered then
      begin
        UpdateIndicator(r);
      end;
    end
    else
    begin
      if EditMode then
        LoadRow(Row);
    end;
  end;
end;

procedure TDBGrid.Refresh;
begin
  LoadData;
end;

destructor TDBGrid.Destroy;
begin
  FDataLink.Free;
  FColumns.Free;
  inherited;
end;

procedure TDBGrid.DoCheckClick(ACol, ARow: Longint; Checked: boolean);
var
  insUpdate: boolean;
  fldName: string;
  fld: TField;
begin
  inherited;

  SelectCell(ACol,ARow);

  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active and (goEditing in Options) then
  begin
    insupdate := FDataLink.DataSource.DataSet.State in dsEditModes;
    fldName := Columns[ACol - FixedCols].DataField;

    if fldName <> '' then
    begin
      fld := nil;
      try
        fld := FDataLink.DataSource.DataSet.FieldByName(fldName);

        if not fld.ReadOnly then
        begin
          if not insupdate then
            FDataLink.DataSource.DataSet.Edit;

          fld.AsBoolean := Checked;
        end;
      except
        fld := nil;
      end;

    end;
  end;
end;

procedure TDBGrid.DoSortIndicatorChange(Sender: TObject; ACol: integer;
  AIndicator: TGridSortIndicator);
var
  s: string;
begin
  s := Columns[ACol].Title;
  case AIndicator of
  siAscending: s := s + '&nbsp;&#x25B2;';
  siDescending: s := s + '&nbsp;&#x25BC;';
  end;
  Cells[ACol + FixedCols, 0] := s;
end;

procedure TDBGrid.EndUpdate;
begin
  ColumnsChanged(Self);
  inherited;
end;

procedure TDBGrid.GetCellEditor(const ACol, ARow: integer;
  var AEditor: TGridCellEditor);
begin
  if (ACol - FixedCols < Columns.Count) and (ACol >= FixedCols) then
  begin
    AEditor := Columns[ACol - FixedCols].Editor;
    ComboBoxItems.Assign(Columns[ACol - FixedCols].ComboBoxItems);
    EditMask :=  Columns[ACol - FixedCols].EditMask;
  end;

  inherited;
end;

function TDBGrid.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

procedure TDBGrid.HideEdit(Advance: boolean = false);
begin
  inherited;
  UpdateIndicator(Row);
end;

procedure TDBGrid.LoadData;
var
  c,r,i: integer;
  bk: TBookmark;
  noflds: boolean;
  isBOF,isEOF: boolean;
  LTitle,cn: string;
  fld: TField;
  ce: TJSHTMLElement;
begin
  noflds := true;
  r := 0;

  for i := 0 to Columns.Count - 1 do
  begin
    if Columns[i].DataField <> '' then
      noflds := false;
  end;

  FDefaultFields := noflds;


  // only unbound columns found
  if noflds and (Columns.Count > 0) then
  begin
    Columns.BeginUpdate;

    for i := 0 to FDataLink.DataSet.FieldCount - 1 do
    begin
      if (faHiddenCol in FDataLink.DataSet.Fields[i].FieldDef.Attributes) then
        Continue;

      if not FDataLink.DataSet.Fields[i].Visible then
        Continue;

      if i >= Columns.Count then
        Columns.Add;

      with Columns[i] do
      begin
        FDataField := FDataLink.DataSet.Fields[i].FieldName;
        FTitle :=  FDataLink.DataSet.Fields[i].DisplayLabel;
      end;
    end;

    while (Columns.Count > FDataLink.DataSet.FieldCount) do
    begin
      Columns.Delete(Columns.Count - 1);
    end;
  end;

  if (Columns.Count = 0) then
  begin
    for i := 0 to FDataLink.DataSet.FieldCount - 1 do
    begin
      if (faHiddenCol in FDataLink.DataSet.Fields[i].FieldDef.Attributes) then
        Continue;

      with Columns.Add do
      begin
        FDataField := FDataLink.DataSet.Fields[i].FieldName;
        FTitle :=  FDataLink.DataSet.Fields[i].DisplayLabel;
      end;
    end;
    Columns.EndUpdate;
  end;


  ColCount := Columns.Count + FixedCols;

  RowCount := FDataLink.DataSource.DataSet.RecordCount + FixedRows;

  RenderGrid;

  isBOF := FDataLink.DataSet.Bof;
  isEOF := FDataLink.DataSet.Eof;

  FDataLink.DataSource.DataSet.DisableControls;

  bk := FDataLink.DataSet.GetBookmark;

  FDataLink.DataSource.DataSet.First;

  if FixedRows > 0 then
  begin
    for c := 0 to Columns.Count - 1 do
    begin
      LTitle := Columns[c].Title;
      case Columns[c].SortIndicator of
      siAscending: LTitle := LTitle + '&nbsp;&#x25B2;';
      siDescending: LTitle := LTitle + '&nbsp;&#x25BC';
      end;

      Cells[c + FixedCols, r] := LTitle;

      if Columns[c].TitleElementClassName <> '' then
      begin
        ce := GetDirectCellElements(c + FixedCols, 0);

        if Assigned(ce) then
          ce.classList.add(Columns[c].TitleElementClassName);
      end;

      fld := Columns[c].GetDBField;

      ce := GetDirectCellElements(c + FixedCols, 0);

      GetCellChildren(c + FixedCols, 0, fld, LTitle, ce);

      BindCellChildren(c + FixedCols, 0, ce);

      cn := Columns[c].TitleElementClassName;

      GetCellClassName(c + FixedCols, 0, fld, LTitle, cn);

      if (cn <> '') and Assigned(ce) then
        ce.classList.add(cn);
    end;
  end;

  r := FixedRows;

  {
  FDataLink.BufferCount := FDataLink.DataSet.RecordCount;

  for i := 0 to FDataLink.RecordCount - 1 do
  begin
    FDataLink.ActiveRecord := i;
    LoadRow(i + FixedRows);
  end;

  FDataLink.ActiveRecord := 0;
  }

  while not FDataLink.DataSource.DataSet.EOF do
  begin
    LoadRow(r);
    inc(r);
    FDataLink.DataSource.DataSet.Next;
  end;

  if FDataLink.DataSource.DataSet.Filtered then
    RowCount := r;

  if DataSource.DataSet.BookmarkValid(bk) then
    FDataLink.DataSource.DataSet.GotoBookmark(bk);

  if isBOF then
    FDataLink.DataSource.DataSet.First;

  if isEOF then
    FDataLink.DataSource.DataSet.Last;

  FDataLink.DataSource.DataSet.EnableControls;
end;

procedure TDBGrid.Loaded;
begin
  inherited;
  ColumnsChanged(Self);
end;

procedure TDBGrid.InsertData(ARow: Integer);
var
  R: Integer;
  C: Integer;
begin
  // move all cells down
  for R := RowCount - 1 downto ARow + 1 do
  begin
    for C := 0 to ColCount - 1 do
    begin
      if (C = 0) and (FixedCols > 0) then
        Cells[C, R] := '&nbsp;'
      else
        Cells[C, R] := Cells[C, R-1];
    end;
  end;
  LoadRow(ARow);
end;

procedure TDBGrid.LoadRow(ARow: integer);
var
  c,r: integer;
  fld: TField;
  cd,cn: string;
  ce: TJSHTMLElement;

begin
  if ARow < FixedRows then
    Exit;

  r := ARow;

  for c := 0 to Columns.Count - 1 do
  begin
    if EditMode and (c = Col - FixedCols) and (ARow = Row) then
      continue;

    cd := '';
    ce := GetDirectCellElements(c + FixedCols, r);

    UnBindCellChildren(c,r,ce);

    fld := Columns[c].GetDBField;

    if Assigned(fld) then
    begin
      cd := FieldToCellData(ce, fld, Columns[c], goEditing in Options);
    end;

    GetCellData(c, r, fld, cd);

    Cells[c + FixedCols, r] := cd;

    cn := Columns[c].ElementClassName;

    GetCellChildren(c, r, fld, cd, ce);

    BindCellChildren(c, r, ce);

    GetCellClassName(c, r, fld, cd, cn);

    if cn <> '' then
      ce.classList.add(cn);
  end;
end;

procedure TDBGrid.SelectCell(ACol, ARow: integer);
var
  LOldrow,d: integer;
begin
  LOldrow := Row;

  inherited;

  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active then
  begin
    if (ARow = LOldRow) or (LOldRow < 0) then
      Exit;

    FModifying := true;

    d := FDataLink.MoveBy(ARow - LOldRow);

    if d <> 0 then
    begin
      UpdateIndicator(ARow);
    end
    else
    begin
      // reload row when post failed
      LoadRow(LOldRow);
      UpdateIndicator(LOldRow);
      Row := LOldRow;
    end;

    FModifying := false;
  end;
end;

procedure TDBGrid.SetColumns(const Value: TGridColumns);
begin
  FColumns.Assign(Value);
end;

procedure TDBGrid.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

procedure TDBGrid.SetShowIndicator(const Value: boolean);
begin
  FShowIndicator := Value;
  UpdateIndicator(Row);
end;

procedure TDBGrid.ShowEdit;
var
  fldName: string;
  fld: TField;
begin
  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active  then
  begin
    fldName := Columns[Col - FixedCols].DataField;

    if (fldName <> '') then
    begin
      fld := nil;

      try
        fld := FDataLink.DataSource.DataSet.FieldByName(fldName);
      except
        fld := nil;
      end;

      if Assigned(fld) and not fld.ReadOnly then
      begin
        FDataLink.DataSource.DataSet.Edit;
        inherited;
      end;
    end
    else
      inherited;
  end;
end;

procedure TDBGrid.StartEdit(ch: string);
begin
  if (Col > 0) and (Col <= Columns.Count) then
    if Columns[Col - 1].DataType in [cdCheck, cdRadio]  then
      Exit;

  inherited StartEdit(ch);
  UpdateIndicator(Row);
end;

function TDBGrid.StopEdit: string;
begin
  Result := inherited StopEdit;
  UpdateIndicator(Row);
end;

procedure TDBGrid.UpdateCell(ACol, ARow: integer; var AValue: string);
var
  fldName: string;
  insupdate: boolean;
  fld: TField;
begin
  inherited;

  if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active then
  begin
    insupdate := FDataLink.DataSource.DataSet.State in dsEditModes;
    fldName := Columns[ACol - FixedCols].DataField;

    if fldName <> '' then
    begin
      if not insupdate then
      begin
        FDataLink.DataSource.DataSet.Edit;
      end;

      fld := nil;

      try
        fld := FDataLink.DataSource.DataSet.FieldByName(fldName);

        if Assigned(fld) then
        begin
          if fld.DataType in [ftDate, ftDateTime] then
          begin
            fld.AsDateTime := PickerDateToDate(AValue);
          end
          else
          begin
            if fld.AsString <> AValue then
            begin
              fld.AsString := AValue;
            end;
          end;
        end;
      except
        fld := nil;
      end;
    end;
  end;
end;

procedure TDBGrid.UpdateData(Sender: TObject);
begin
  if FDataLink.DataSource.DataSet.State = dsInsert then
  begin
    HideEdit;
  end;
end;

procedure TDBGrid.UpdateIndicator(ARow: integer);
var
  LSym: string;
begin
  if FixedCols = 0 then
    Exit;

  if (FOldIndicator <> ARow) or not FShowIndicator then
  begin
    if (FOldIndicator >= 0) and (FOldIndicator < RowCount) then
      Cells[0, FOldIndicator] := '';
  end;

  if FShowIndicator then
  begin
    if Assigned(FDataLink.DataSource) and Assigned(FDataLink.DataSource.DataSet) and FDataLink.DataSource.DataSet.Active and (Row >= FixedRows) then
    begin
      if EditMode or (FDataLink.DataSource.DataSet.State = dsEdit) then
      begin
        LSym := '&#x270E;'
      end
      else
      begin
        if FDataLink.DataSource.DataSet.State = dsInsert then
          LSym := '&brvbar'
        else
          LSym := '&#x25B6;';
      end;

      Cells[0, ARow] := '&nbsp;' + LSym;
    end;
  end;

  FOldIndicator := ARow;
end;

{ TDBMaskEdit }

procedure TDBMaskEdit.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
      EditText := ''
    else
      DataChange(Self);
  end;
end;

function TDBMaskEdit.CanCut: boolean;
var
  canedit: boolean;
begin
  if not CheckDataSet then
    Exit;

  if ReadOnly then
  begin
    Result := false;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
  begin
    Result := false;
    Exit;
  end
  else
  begin
    Result := inherited;
    FDataLink.Edit;
  end;
end;

function TDBMaskEdit.CanPaste(AValue: string): boolean;
begin
  Result := CanCut;
end;

procedure TDBMaskEdit.Change;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  if DataSource.AutoEdit or (DataSource.DataSet.State in [dsInsert, dsEdit]) then
  begin
    FDataLink.Modified;
  end;
end;

function TDBMaskEdit.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBMaskEdit.CreateInitialize;
begin
  inherited;
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;
end;

procedure TDBMaskEdit.DataChange(Sender: TObject);
begin
  if FEditChange then
    Exit;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and  not FDataLink.DataSet.IsEmpty then
    FFieldText := FDataLink.Field.DisplayText
  else
    FFieldText := '';

  EditText := FFieldText;
end;

procedure TDBMaskEdit.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    FDataLink.Field.AsString := EditText;
  end;
end;

destructor TDBMaskEdit.Destroy;
begin
  FDataLink.Free;
  inherited;
end;

procedure TDBMaskEdit.DoExit;
begin
  if not FDataLink.ReadOnly then
  begin
    FEditChange := true;
    FDataLink.UpdateRecord; // tell data link to update database
  end;

  inherited DoExit;

  FEditChange := false;
end;

function TDBMaskEdit.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (FDataLink.DataSet.State in [dsEdit, dsInsert])) and FDataLink.Edit
  else
    Result := True;
end;

function TDBMaskEdit.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

function TDBMaskEdit.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBMaskEdit.GetDisplayText: string;
begin
  if not CheckDataSet then
  begin
    Result := '';
  end
  else
    Result := inherited GetDisplayText;
end;

function TDBMaskEdit.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet;
end;

function TDBMaskEdit.IsReadOnly: boolean;
begin
  Result := ReadOnly or not CheckDataSet;
end;

procedure TDBMaskEdit.KeyDown(var Key: Word; Shift: TShiftState);
var
  canedit: boolean;
begin
  if not CheckDataSet then
    Exit;

  if (Key = VK_DELETE) or (Key = VK_BACK) or ((Key = VK_INSERT) and (ssShift in Shift)) then
  begin
    FEditChange := true;
    canedit := EditCanModify;
    FEditChange := false;

    if not canedit then
    begin
      key := 0;
      inherited;
      Exit;
    end
    else
    begin
      inherited;
      FDataLink.Edit;
    end;
  end
  else
    inherited;
end;

procedure TDBMaskEdit.KeyPress(var Key: Char);
var
  canedit: boolean;
begin
  if not CheckDataSet or ReadOnly then
  begin
    inherited;
    Exit;
  end;

  if FDataLink.DataSet.ControlsDisabled then
  begin
    PreventDefault;
    StopPropagation;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
    Exit;

  if {(Key >= #32) and (FDataLink.Field <> nil) and
    not FDataLink.Field.IsValidChar(Key) or} (FDataLink.ReadOnly) then
  begin
    Key := #0;
  end;

  inherited Keypress(Key);
end;

procedure TDBMaskEdit.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBMaskEdit.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
  FDataLink.UpdateField;
end;

{ TDBEditAutoComplete }

procedure TDBEditAutoComplete.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
    begin
      Text := '';
    end
    else
      DataChange(Self);
  end;
end;

procedure TDBEditAutoComplete.Change;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  if DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert]) then
  begin
    FDataLink.Edit;
    FDataLink.Modified;
  end;
end;

function TDBEditAutoComplete.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBEditAutoComplete.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;

  inherited;

end;

procedure TDBEditAutoComplete.DataChange(Sender: TObject);
begin
  UpdateElement;

  if FEditChange then
    Exit;

  if not Assigned(DataSource) then
    Exit;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
    Text := FDataLink.Field.DisplayText
  else
    Text := '';
end;

procedure TDBEditAutoComplete.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    FDataLink.Field.AsString := Text;
  end;
end;

destructor TDBEditAutoComplete.Destroy;
begin
  FDataLink.Free;
  inherited;
end;

procedure TDBEditAutoComplete.DoExit;
begin
  if not FDataLink.ReadOnly then
  begin
    FEditChange := true;
    FDataLink.UpdateRecord;     { tell data link to update database }
  end;

  inherited DoExit;

  FEditChange := false;
end;

function TDBEditAutoComplete.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (FDataLink.DataSet.State in [dsEdit, dsInsert])) and FDataLink.Edit
  else
    Result := true;
end;

function TDBEditAutoComplete.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

function TDBEditAutoComplete.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

procedure TDBEditAutoComplete.KeyDown(var Key: Word; Shift: TShiftState);
var
  canedit: boolean;

begin
  if not CheckDataSet then
    Exit;

  if (Key = VK_DELETE) or (Key = VK_BACK) or ((Key = VK_INSERT) and (ssShift in Shift)) then
  begin
    FEditChange := true;
    canedit := EditCanModify;
    FEditChange := false;

    if not canedit then
    begin
      key := 0;
      inherited;
      Exit;
    end
    else
    begin
      inherited;
      FDataLink.Edit;
    end;
  end
  else
    inherited;
end;

procedure TDBEditAutoComplete.KeyPress(var Key: Char);
var
  canedit: boolean;
begin
  if not CheckDataSet or ReadOnly then
  begin
    inherited;
    Exit;
  end;

  if FDataLink.DataSet.ControlsDisabled then
  begin
    PreventDefault;
    StopPropagation;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
    Exit;

  if (FDataLink.ReadOnly) then
  begin
    Key := #0;
  end;

  inherited Keypress(Key);
end;

procedure TDBEditAutoComplete.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBEditAutoComplete.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

{ TDBLookupComboBox }

procedure TDBLookupComboBox.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
    begin
      ItemIndex := -1;
    end
    else
      DataChange(Self);
  end;
end;

procedure TDBLookupComboBox.Change;
var
  fld: TField;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  if DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit, dsInsert]) then
  begin
    FEditChange := true;
    FDataLink.Edit;
    fld := nil;
    try
      fld := FDataLink.DataSet.FieldByName(DataField);
      fld.AsString := Value;
      FDataLink.Modified;
    except

    end;
    FEditChange := false;
  end;

  SyncListSource;
end;

function TDBLookupComboBox.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBLookupComboBox.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;

  FListLink := TFieldDataLink.Create;
  FListLink.OnActiveChange := ListActiveChange;
  FListLink.OnDataChange := ListDataChange;
  FListChanging := false;
  FEditChange := false;
  FBlockChange := false;
  inherited;
end;

procedure TDBLookupComboBox.DataChange(Sender: TObject);
begin
  UpdateElement;

  if FEditChange then
    Exit;

  if not Assigned(DataSource) then
    Exit;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
  begin
    Value := FDataLink.Field.DisplayText;
  end
  else
    ItemIndex := -1;

  SyncListSource;
end;

procedure TDBLookupComboBox.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    FDataLink.Field.AsString := Value;
  end;
end;

destructor TDBLookupComboBox.Destroy;
begin
  FDataLink.Free;
  FListLink.Free;
  inherited Destroy;
end;

procedure TDBLookupComboBox.DoExit;
begin
  if not FDataLink.ReadOnly then
  begin
//    try
      FEditChange := true;
      FDataLink.UpdateRecord;     { tell data link to update database }

//    except
//      on Exception do
//      begin
//        SetFocus;   { if it failed, don't let focus leave }
//        raise;
//      end;
//    end;
  end;

  inherited DoExit;

  FEditChange := false;
end;

function TDBLookupComboBox.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (FDataLink.DataSet.State in [dsEdit, dsInsert])) and FDataLink.Edit
  else
    Result := true;
end;

function TDBLookupComboBox.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

function TDBLookupComboBox.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBLookupComboBox.GetListField: TDataField;
begin
  Result := FListLink.FieldName;
end;

function TDBLookupComboBox.GetListSource: TDataSource;
begin
  Result := FListLink.DataSource;
end;

function TDBLookupComboBox.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet;
end;

procedure TDBLookupComboBox.ListActiveChange(Sender: TObject);
begin
  if not (csLoading in ComponentState) then
    LoadListSource;
end;

procedure TDBLookupComboBox.ListDataChange(Sender: TObject);
begin
  if FBlockChange then
    Exit;

  if not (csLoading in ComponentState) then
    LoadListSource;
end;

procedure TDBLookupComboBox.LoadListSource;
var
  kfld,lfld: TField;
  isBOF,isEOF: boolean;
  bk: TBookmark;
begin
  if Assigned(ListSource) and Assigned(ListSource.DataSet) and (ListSource.DataSet.Active) and (KeyField <> '') and (ListField <> '') and not FListChanging then
  begin
    FListChanging := true;
    try
      kfld := ListSource.Dataset.FieldByName(KeyField);
      lfld := ListSource.Dataset.FieldByName(ListField);
    except
      kfld := nil;
      lfld := nil;
    end;

    if Assigned(kfld) and Assigned(lfld) then
    begin
      isBOF := ListSource.DataSet.BOF;
      isEOF := ListSource.DataSet.EOF;

      ListSource.DataSet.DisableControls;

      bk := ListSource.DataSet.GetBookmark;

      LookupValues.BeginUpdate;
      LookupValues.Clear;

      ListSource.DataSet.First;

      while not ListSource.DataSet.EOF do
      begin
        LookupValues.AddPair(kfld.DisplayText, lfld.DisplayText);
        ListSource.DataSet.Next;
      end;

      LookupValues.EndUpdate;

      if ListSource.DataSet.BookmarkValid(bk) then
       ListSource.DataSet.GotoBookmark(bk);

      if isBOF then
        ListSource.DataSet.First;

      if isEOF then
        ListSource.DataSet.Last;

      DoUpdateList;

      ListSource.DataSet.EnableControls;
    end;
    FListChanging := false;
  end;

  // set value in case it was set prior to listsource being loaded
  if FFieldValue <> '' then
    Value := FFieldValue;
end;

procedure TDBLookupComboBox.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBLookupComboBox.SetDataSource(const Value: TDataSource);
begin
  if FDatalink.DataSource <> Value then
  begin
    FDatalink.DataSource := Value;
    LoadListSource;
  end;
end;

procedure TDBLookupComboBox.SetKeyField(const Value: TDataField);
begin
  FKeyField := Value;
end;

procedure TDBLookupComboBox.SetListField(const Value: TDataField);
begin
  FListLink.FieldName := Value;
end;

procedure TDBLookupComboBox.SetListSource(const Value: TDataSource);
begin
  FListLink.DataSource := Value;
end;

procedure TDBLookupComboBox.SetValue(const Value: string);
begin
  inherited;
  FFieldValue := Value;
end;

procedure TDBLookupComboBox.SyncListSource;
begin
  if Assigned(FListLink.Field) and Assigned(FListLink.DataSet) and FListLink.DataSet.Active and not FListLink.DataSet.IsEmpty and (ItemIndex >= 0) then
  begin
    FBlockChange := true;
    FListLink.DataSet.First;
    FListLink.DataSet.MoveBy(ItemIndex);
    FBlockChange := false;
  end;
end;

{ TDBListControl }

procedure TDBListControl.ActiveChange(Sender: TObject);
begin

end;

procedure TDBListControl.CreateInitialize;
begin
  inherited;
  FDataLink := TDBDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FDataLink.OnRecordChange := RecordChange;


  FListLink := TDBDataLink.Create;
  FListLink.OnActiveChange := ListActiveChange;
end;

procedure TDBListControl.DataChange(Sender: TObject);
begin

end;

procedure TDBListControl.DataUpdate(Sender: TObject);
begin

end;

destructor TDBListControl.Destroy;
begin
  FDataLink.Free;
  FListLink.Free;
  inherited;
end;

procedure TDBListControl.DoItemGetFieldValue(Index: integer; AFieldName: string;
  var AValue: string);
begin
  if Assigned(OnListItemGetFieldValue) then
    OnListItemGetFieldValue(Self, Index, AFieldName, AValue);
end;

function TDBListControl.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

function TDBListControl.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBListControl.GetListField: TDataField;
begin
  Result := FListLink.FieldName;
end;

function TDBListControl.GetListSource: TDataSource;
begin
  Result := FListLink.DataSource;
end;

procedure TDBListControl.ListActiveChange(Sender: TObject);
var
  fld: TField;
  bk: TBookmark;
  itemtext,ident,html,htmlvalue: string;
  c: integer;
  itm: TListItem;
begin
  if not Assigned(ListSource) then
    Exit;

  if Assigned(FListLink.DataSet) and FListLink.DataSet.Active and not FListLink.DataSet.IsEmpty then
  begin
    fld := nil;
    if FListLink.FieldName <> '' then
      fld := FListLink.DataSet.FieldByName(FListLink.FieldName);

    if Assigned(fld) or (ItemTemplate <> '') then
    begin
      FListLink.DataSet.DisableControls;
      bk := FListLink.DataSet.GetBookmark;
      Items.Clear;
      FListLink.DataSet.First;

      while not FListLink.DataSet.Eof do
      begin
        itm := Items.Add;

        if ItemTemplate <> '' then
        begin
          html := ItemTemplate;

          if Assigned(fld) then
          begin
            itm.Data := fld.DisplayText;
          end;

          for c := 0 to FListLink.DataSource.DataSet.Fields.Count - 1 do
          begin
            ident := '(%' +  FListLink.DataSource.DataSet.Fields[c].FieldName + '%)';
            if Pos(Uppercase(ident), Uppercase(html)) > 0 then
            begin
              htmlvalue := FListLink.DataSource.DataSet.Fields[c].DisplayText;
              DoItemGetFieldValue(itm.Index, FListLink.DataSource.DataSet.Fields[c].FieldName, htmlvalue);
              html := StringReplace(html, ident, htmlvalue, [rfReplaceAll, rfIgnoreCase]);
            end;
          end;

          itemtext := html;
        end
        else
        begin
          itemtext := fld.DisplayText;
        end;

        itm.Text := itemtext;
        FListLink.DataSet.Next;
      end;

      FListLink.DataSet.GotoBookmark(bk);

      FListLink.DataSet.EnableControls;
    end;
  end;

end;

procedure TDBListControl.RecordChange(Sender: TObject);
var
  i: integer;
  dt: string;
begin
  if AutoSelect then
  begin
    if Assigned(FDataLink.DataSet) and FDataLink.DataSet.Active and not FDataLink.DataSet.IsEmpty then
    begin
      if (ListSource = DataSource) then
        ItemIndex := FDataLink.DataSet.RecNo - 1
      else
      if (DataField <> '') then
      begin
        dt := FDataLink.DataSet.FieldByName(DataField).DisplayText;

        for i := 0 to Items.Count - 1 do
        begin
          if Items[i].Data = dt then
          begin
            ItemIndex := i;
            break;
          end
          else
          if (Items[i].Text = dt) then
          begin
            ItemIndex := i;
            break;
          end;
        end;
      end;

    end;
  end;
end;

procedure TDBListControl.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  ActiveChange(Self);
end;

procedure TDBListControl.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

procedure TDBListControl.SetItemTemplate(const Value: string);
begin
  FItemTemplate := Value;
end;

procedure TDBListControl.SetListField(const Value: TDataField);
begin
  FListLink.FieldName := Value;
  ActiveChange(Self);
end;

procedure TDBListControl.SetListSource(const Value: TDataSource);
begin
  FListLink.DataSource := Value;
end;

{ TDBEditBtn }

procedure TDBEditBtn.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
      Text := ''
    else
      DataChange(Self);
  end;
end;

function TDBEditBtn.CanCut: boolean;
var
  canedit: boolean;
begin
  if not CheckDataSet then
    Exit;

  if ReadOnly then
  begin
    Result := false;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
  begin
    Result := false;
    Exit;
  end
  else
  begin
    Result := inherited;
    FDataLink.Edit;
  end;
end;

function TDBEditBtn.CanPaste(AValue: string): boolean;
begin
  Result := CanCut;
end;

procedure TDBEditBtn.Change;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  if DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert]) then
    FDataLink.Modified;
end;

function TDBEditBtn.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBEditBtn.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;
  inherited;
end;

procedure TDBEditBtn.DataChange(Sender: TObject);
begin
  if FEditChange then
    Exit;

  UpdateElement;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
    Text := FDataLink.Field.DisplayText
  else
    Text := '';
end;

procedure TDBEditBtn.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    FDataLink.Field.AsString := Text;
  end;
end;

destructor TDBEditBtn.Destroy;
begin
  FDataLink.Free;
  inherited;
end;

procedure TDBEditBtn.DoExit;
begin
  if not FDataLink.ReadOnly then
  begin
    FEditChange := true;
    FDataLink.UpdateRecord;     { tell data link to update database }
  end;

  inherited DoExit;

  FEditChange := false;
end;

function TDBEditBtn.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert])) and FDataLink.Edit
  else
    Result := True;
end;

function TDBEditBtn.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

function TDBEditBtn.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBEditBtn.GetDisplayText: string;
begin
  if not CheckDataSet then
    Result := ''
  else
    Result := inherited GetDisplayText;
end;

function TDBEditBtn.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet;
end;

function TDBEditBtn.IsReadOnly: boolean;
begin
  Result := ReadOnly or not CheckDataSet;
end;

procedure TDBEditBtn.KeyDown(var Key: Word; Shift: TShiftState);
var
  canedit: boolean;

begin
  if not CheckDataSet then
    Exit;

  if (Key = VK_DELETE) or (Key = VK_BACK) or ((Key = VK_INSERT) and (ssShift in Shift)) then
  begin
    FEditChange := true;
    canedit := EditCanModify;
    FEditChange := false;

    if not canedit then
    begin
      key := 0;
      inherited;
      Exit;
    end
    else
    begin
      inherited;
      FDataLink.Edit;
    end;
  end
  else
    inherited;
end;

procedure TDBEditBtn.KeyPress(var Key: Char);
var
  canedit: boolean;
begin
  if not CheckDataSet or ReadOnly then
  begin
    inherited;
    Exit;
  end;

  if FDataLink.DataSet.ControlsDisabled then
  begin
    PreventDefault;
    StopPropagation;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
  begin
    Key := #0;
    Exit;
  end;

  if {((Key >= #32) and (FDataLink.Field <> nil) and
    not FDataLink.Field.IsValidChar(Key)) or} (FDataLink.ReadOnly) then
  begin
    Key := #0;
  end;

  inherited Keypress(Key);
end;

procedure TDBEditBtn.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBEditBtn.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

{ TDBEditDropDownControl }

procedure TDBEditDropDownControl.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
      Text := ''
    else
      DataChange(Self);
  end;
end;

(*
function TDBEditDropDownControl.CanCut: boolean;
var
  canedit: boolean;
begin
  if not CheckDataSet then
    Exit;

  if ReadOnly then
  begin
    Result := false;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
  begin
    Result := false;
    Exit;
  end
  else
  begin
    Result := inherited;
    FDataLink.Edit;
  end;
end;

function TDBEditDropDownControl.CanPaste(AValue: string): boolean;
begin
  Result := CanCut;
end;

procedure TDBEditDropDownControl.Change;
begin
  inherited;

  if not CheckDataSet then
    Exit;

  if DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert]) then
    FDataLink.Modified;
end;
*)

function TDBEditDropDownControl.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBEditDropDownControl.CreateInitialize;
begin
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;
  inherited;
end;

procedure TDBEditDropDownControl.DataChange(Sender: TObject);
begin
  if FEditChange then
    Exit;

  UpdateElement;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
    Text := FDataLink.Field.DisplayText
  else
    Text := '';
end;

procedure TDBEditDropDownControl.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    FDataLink.Field.AsString := Text;
  end;
end;

destructor TDBEditDropDownControl.Destroy;
begin
  FDataLink.Free;
  inherited;
end;

procedure TDBEditDropDownControl.DoExit;
begin
  if not FDataLink.ReadOnly then
  begin
    FEditChange := true;
    FDataLink.UpdateRecord;     { tell data link to update database }
  end;

  inherited DoExit;

  FEditChange := false;
end;

function TDBEditDropDownControl.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert])) and FDataLink.Edit
  else
    Result := True;
end;

function TDBEditDropDownControl.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

function TDBEditDropDownControl.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

(*
function TDBEditDropDownControl.GetDisplayText: string;
begin
  if not CheckDataSet then
    Result := ''
  else
    Result := inherited GetDisplayText;
end;
*)

function TDBEditDropDownControl.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet;
end;

function TDBEditDropDownControl.IsReadOnly: boolean;
begin
  Result := ReadOnly or not CheckDataSet;
end;

procedure TDBEditDropDownControl.KeyDown(var Key: Word; Shift: TShiftState);
var
  canedit: boolean;

begin
  if not CheckDataSet then
    Exit;

  if (Key = VK_DELETE) or (Key = VK_BACK) or ((Key = VK_INSERT) and (ssShift in Shift)) then
  begin
    FEditChange := true;
    canedit := EditCanModify;
    FEditChange := false;

    if not canedit then
    begin
      key := 0;
      inherited;
      Exit;
    end
    else
    begin
      inherited;
      FDataLink.Edit;
    end;
  end
  else
    inherited;
end;

procedure TDBEditDropDownControl.KeyPress(var Key: Char);
var
  canedit: boolean;
begin
  if not CheckDataSet {or ReadOnly} then
  begin
    inherited;
    Exit;
  end;

  if FDataLink.DataSet.ControlsDisabled then
  begin
    PreventDefault;
    StopPropagation;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
  begin
    Key := #0;
    Exit;
  end;

  if {((Key >= #32) and (FDataLink.Field <> nil) and
    not FDataLink.Field.IsValidChar(Key)) or} (FDataLink.ReadOnly) then
  begin
    Key := #0;
  end;

  inherited Keypress(Key);
end;

procedure TDBEditDropDownControl.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBEditDropDownControl.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

{ TDBEditDropDownTableControl }

procedure TDBEditDropDownTableControl.ActiveChange(Sender: TObject);
begin
  UpdateElement;

  if Assigned(FDataLink.DataSet) then
  begin
    if not FDataLink.DataSet.Active or not DataSource.Enabled then
      Text := ''
    else
      DataChange(Self);
  end;
end;

function TDBEditDropDownTableControl.CheckDataSet: boolean;
begin
  Result := CheckDB(DataSource, DataField, FDataLink);
end;

procedure TDBEditDropDownTableControl.CreateInitialize;
begin
  inherited;
  FDataLink := TFieldDataLink.Create;
  FDataLink.OnUpdateData := DataUpdate;
  FDataLink.OnDataChange := DataChange;
  FDataLink.OnActiveChange := ActiveChange;
  FEditChange := false;
end;

function TDBEditDropDownTableControl.CreateTable(
  Aowner: TComponent): TCustomTableControl;
begin
  Result := TWebDBTableControl.Create(AOwner);
end;

procedure TDBEditDropDownTableControl.DataChange(Sender: TObject);
begin
  if FEditChange then
    Exit;

  UpdateElement;

  if not Assigned(FDataLink.DataSet) then
    Exit;

  if not Assigned(FDataLink.Field) then
    FDataLink.UpdateField;

  if Assigned(FDataLink.Field) and not FDataLink.DataSet.IsEmpty then
    Text := FDataLink.Field.DisplayText
  else
    Text := '';
end;

procedure TDBEditDropDownTableControl.DataUpdate(Sender: TObject);
begin
  if Assigned(FDataLink.Field) then
  begin
    if not Assigned(FDataLink.Field) then
      FDataLink.UpdateField;

    FDataLink.Field.AsString := Text;
  end;
end;

destructor TDBEditDropDownTableControl.Destroy;
begin
  FDataLink.Free;
  inherited;
end;

procedure TDBEditDropDownTableControl.DoExit;
begin
  if not FDataLink.ReadOnly then
  begin
    FEditChange := true;
    FDataLink.UpdateRecord;     { tell data link to update database }
  end;

  inherited DoExit;

  FEditChange := false;
end;

function TDBEditDropDownTableControl.EditCanModify: Boolean;
begin
  if Assigned(DataSource) then
    Result := not FDatalink.DataSet.ControlsDisabled and (DataSource.AutoEdit or (DataSource.DataSet.State in [dsEdit,dsInsert])) and FDataLink.Edit
  else
    Result := True;
end;

function TDBEditDropDownTableControl.GetDataField: TDataField;
begin
  Result := FDataLink.FieldName;
end;

function TDBEditDropDownTableControl.GetDataSource: TDataSource;
begin
  Result := FDataLink.DataSource;
end;

function TDBEditDropDownTableControl.GetDBTableControl: TDBTableControl;
begin
  Result := TDBTableControl(TableControl);
end;

function TDBEditDropDownTableControl.GetTableSource: TDataSource;
begin
  Result := GetDBTableControl.DataSource;
end;

function TDBEditDropDownTableControl.IsEnabled: boolean;
begin
  Result := Enabled and CheckDataSet;
end;

function TDBEditDropDownTableControl.IsReadOnly: boolean;
begin
  Result := ReadOnly or not CheckDataSet;
end;

procedure TDBEditDropDownTableControl.KeyDown(var Key: Word;
  Shift: TShiftState);
begin
  inherited;
end;

procedure TDBEditDropDownTableControl.KeyPress(var Key: Char);
var
  canedit: boolean;
begin
  if not CheckDataSet {or ReadOnly} then
  begin
    inherited;
    Exit;
  end;

  if FDataLink.DataSet.ControlsDisabled then
  begin
    PreventDefault;
    StopPropagation;
    Exit;
  end;

  FEditChange := true;
  canedit := EditCanModify;
  FEditChange := false;

  if not canedit then
  begin
    Key := #0;
    Exit;
  end;

  if {((Key >= #32) and (FDataLink.Field <> nil) and
    not FDataLink.Field.IsValidChar(Key)) or} (FDataLink.ReadOnly) then
  begin
    Key := #0;
  end;

  inherited Keypress(Key);
end;

procedure TDBEditDropDownTableControl.SetDataField(const Value: TDataField);
begin
  FDataLink.FieldName := Value;
  FDataLink.UpdateField;
  ActiveChange(Self);
end;

procedure TDBEditDropDownTableControl.SetDataSource(const Value: TDataSource);
begin
  FDataLink.DataSource := Value;
end;

procedure TDBEditDropDownTableControl.SetTableSource(const Value: TDataSource);
begin
  GetDBTableControl.DataSource := Value;
end;

end.
