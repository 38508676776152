unit CommunityBuildingEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseForm, WEBLib.ExtCtrls, WEBLib.Actions,
  Data.DB, WEBLib.DB, WEBLib.WebCtrls, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.DBCtrls;

type
  TCommunityBuildingEditForm = class(TBaseEditForm)
    BuildingNameEdit: TDBEdit;
    AddressEdit: TDBEdit;
    PostCodeEdit: TDBEdit;
    ExternalIdEdit: TDBEdit;
    procedure DataSourceDataChange(Sender: TObject; Field: TField);
    procedure DataSourceStateChange(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  CommunityBuildingEditForm: TCommunityBuildingEditForm;

implementation

{$R *.dfm}

procedure TCommunityBuildingEditForm.DataSourceDataChange(Sender: TObject;
    Field: TField);
begin
  inherited;
  SaveButton.Enabled := true;
end;

procedure TCommunityBuildingEditForm.DataSourceStateChange(Sender: TObject);
begin
  inherited;
  SaveButton.Enabled := true;
end;

procedure TCommunityBuildingEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BuildingNameEdit := TDBEdit.Create('BuildingNameEdit');
  AddressEdit := TDBEdit.Create('AddressEdit');
  PostCodeEdit := TDBEdit.Create('PostCodeEdit');
  ExternalIdEdit := TDBEdit.Create('ExternalIdEdit');

  FormCaption.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  BuildingNameEdit.BeforeLoadDFMValues;
  AddressEdit.BeforeLoadDFMValues;
  PostCodeEdit.BeforeLoadDFMValues;
  ExternalIdEdit.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  try
    FormCaption.Width := 93;
    FormCaption.Caption := 'Community Buildings Edit';
    CancelButton.Left := 157;
    BuildingNameEdit.SetParentComponent(Self);
    BuildingNameEdit.Name := 'BuildingNameEdit';
    BuildingNameEdit.Left := 36;
    BuildingNameEdit.Top := 85;
    BuildingNameEdit.Width := 198;
    BuildingNameEdit.Height := 15;
    BuildingNameEdit.ChildOrder := 3;
    BuildingNameEdit.ElementPosition := epIgnore;
    BuildingNameEdit.HeightStyle := ssAuto;
    BuildingNameEdit.HeightPercent := 100.000000000000000000;
    BuildingNameEdit.Text := 'BuildingNameEdit';
    BuildingNameEdit.WidthStyle := ssAuto;
    BuildingNameEdit.WidthPercent := 100.000000000000000000;
    BuildingNameEdit.DataField := 'BuildingName';
    BuildingNameEdit.DataSource := DataSource;
    AddressEdit.SetParentComponent(Self);
    AddressEdit.Name := 'AddressEdit';
    AddressEdit.Left := 36;
    AddressEdit.Top := 104;
    AddressEdit.Width := 198;
    AddressEdit.Height := 15;
    AddressEdit.ChildOrder := 4;
    AddressEdit.ElementPosition := epIgnore;
    AddressEdit.HeightStyle := ssAuto;
    AddressEdit.HeightPercent := 100.000000000000000000;
    AddressEdit.TabOrder := 1;
    AddressEdit.Text := 'AddressEdit';
    AddressEdit.WidthStyle := ssAuto;
    AddressEdit.WidthPercent := 100.000000000000000000;
    AddressEdit.DataField := 'Address';
    AddressEdit.DataSource := DataSource;
    PostCodeEdit.SetParentComponent(Self);
    PostCodeEdit.Name := 'PostCodeEdit';
    PostCodeEdit.Left := 36;
    PostCodeEdit.Top := 123;
    PostCodeEdit.Width := 59;
    PostCodeEdit.Height := 15;
    PostCodeEdit.ChildOrder := 5;
    PostCodeEdit.ElementPosition := epIgnore;
    PostCodeEdit.HeightStyle := ssAuto;
    PostCodeEdit.HeightPercent := 100.000000000000000000;
    PostCodeEdit.TabOrder := 2;
    PostCodeEdit.Text := 'PostCodeEdit';
    PostCodeEdit.WidthStyle := ssAuto;
    PostCodeEdit.WidthPercent := 100.000000000000000000;
    PostCodeEdit.DataField := 'PostCode';
    PostCodeEdit.DataSource := DataSource;
    ExternalIdEdit.SetParentComponent(Self);
    ExternalIdEdit.Name := 'ExternalIdEdit';
    ExternalIdEdit.Left := 36;
    ExternalIdEdit.Top := 141;
    ExternalIdEdit.Width := 198;
    ExternalIdEdit.Height := 15;
    ExternalIdEdit.ChildOrder := 6;
    ExternalIdEdit.ElementPosition := epIgnore;
    ExternalIdEdit.HeightStyle := ssAuto;
    ExternalIdEdit.HeightPercent := 100.000000000000000000;
    ExternalIdEdit.TabOrder := 3;
    ExternalIdEdit.Text := 'ExternalIdEdit';
    ExternalIdEdit.WidthStyle := ssAuto;
    ExternalIdEdit.WidthPercent := 100.000000000000000000;
    ExternalIdEdit.DataField := 'ExternalId';
    ExternalIdEdit.DataSource := DataSource;
    DataSource.Left := 288;
    DataSource.Top := 64;
  finally
    FormCaption.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    BuildingNameEdit.AfterLoadDFMValues;
    AddressEdit.AfterLoadDFMValues;
    PostCodeEdit.AfterLoadDFMValues;
    ExternalIdEdit.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
  end;
end;

end.