unit DonationList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.WebCtrls, WEBLib.ExtCtrls, WebForm.Core;

type

  TDonationListType = (dltImport, dltDonor);

  TDonations_List = class(TCoreWebForm)
    DonationsData: TXDataWebDataSet;
    DonationsSource: TDataSource;
    DonationsTable: TDBTableControl;
    DonationsCloseButton: TButton;
    Donors: TXDataWebDataSet;
    RefreshTimer: TTimer;
    DonationsDataId: TIntegerField;
    DonationsDataDateAdded: TDateTimeField;
    DonationsDataAddedBy: TIntegerField;
    DonationsDataLastUpdatedBy: TIntegerField;
    DonationsDataLastUpdated: TDateTimeField;
    DonationsDataExternalId: TStringField;
    DonationsDataTransactionDate: TDateField;
    DonationsDataImportId: TIntegerField;
    DonationsDataClaimId: TIntegerField;
    DonationsDataGiftAid: TFloatField;
    DonationsDataAmount: TFloatField;
    DonationsDataClaimStatus: TStringField;
    DonationsDataClaimDate: TDateTimeField;
    DonationsDataTaxYear: TIntegerField;
    DonationsDataErrorText: TStringField;
    DonationsDataCharityTenant: TStringField;
    DonationsDataSourceType: TStringField;
    DonationsDataComment: TStringField;
    DonationsDataSponsored: TBooleanField;
    DonationsDataDonorId: TIntegerField;
    DonationsDataDonorPostCode: TStringField;
    DonationsDataDonorName: TStringField;
    DonorsDeclarations: TXDataWebDatasetField;
    DonorsId: TIntegerField;
    DonorsDateAdded: TDateTimeField;
    DonorsAddedBy: TIntegerField;
    DonorsLastUpdatedBy: TIntegerField;
    DonorsLastUpdated: TDateTimeField;
    DonorsTitle: TStringField;
    DonorsFirstName: TStringField;
    DonorsMiddleName: TStringField;
    DonorsLastName: TStringField;
    DonorsSuffix: TStringField;
    DonorsAdd1: TStringField;
    DonorsAdd2: TStringField;
    DonorsAdd3: TStringField;
    DonorsCity: TStringField;
    DonorsPostCode: TStringField;
    DonorsEmail: TStringField;
    DonorsMobile: TStringField;
    DonorsTelephone: TStringField;
    DonorsMatchHash: TStringField;
    DonorsContactByPhone: TBooleanField;
    DonorsContactByPost: TBooleanField;
    DonorsContactByText: TBooleanField;
    DonorsContactByEmail: TBooleanField;
    DonorsDOB: TDateField;
    DonorsHearingStatus: TStringField;
    DonorsEmailStatus: TStringField;
    DonorsLatitude: TFloatField;
    DonorsLongitude: TFloatField;
    DonorsExternalId: TStringField;
    DonorsCountryCode: TStringField;
    DonorsVisualStatus: TStringField;
    DonorsMobilityStatus: TStringField;
    DonorsSourceType: TStringField;
    DonorsImportId: TIntegerField;
    DonorsClaimStatus: TStringField;
    DonorsCharityTenant: TStringField;
    DonorsComments: TStringField;
    DonationsDataAdjustmentID: TIntegerField;
    AddDonationButton: TButton;
    procedure AddDonationButtonClick(Sender: TObject);
    procedure CloseThisButtonClick(Sender: TObject);
    procedure DonationsCloseButtonClick(Sender: TObject);
    procedure DonationsDataAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure DonationsTableGetCellChildren(Sender: TObject; ACol, ARow: Integer;
        AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure RefreshTimerTimer(Sender: TObject);
  private
    { Private declarations }
    FId: Integer;
    FListType: TDonationListType;
    FImportId: Integer;
    FDonorId: integer;
    //procedure SetImportId(const Value: Integer);
    [async]
    procedure EditRecord(const RecordId: Integer); async;
    [async]
    procedure EditDonorRecord(const DonorId: Integer); async;
    [async]
    procedure GetDonorId(DonorId: integer); async;
    procedure SetDonorId(const Value: Integer);
    procedure SetImportId(const Value: Integer);
    procedure EnableRefreshTimer(const AnInterval: Integer = 1000);

  public
    { Public declarations }
    procedure AfterCreated;
    property DonorId: Integer write SetDonorId;
    property ImportId: Integer write SetImportId;
  protected procedure LoadDFMValues; override; end;

var
  Donations_List: TDonations_List;

implementation

{$R *.dfm}

uses MainDataModule, SMX.Web.Layout.Utils, DonationDataEdit, DonorDataEdit;


procedure TDonations_List.AddDonationButtonClick(Sender: TObject);
begin
  //inherited;
  EditRecord(0);
end;

procedure TDonations_List.AfterCreated;
const
filter_field: array[TDonationListType] of string = ('ImportId', 'DonorId');
begin
  DonationsData.QueryString := '$filter=' + filter_field[FListType] + ' eq ' + FId.ToString;
  DonationsData.Load;
end;

procedure TDonations_List.CloseThisButtonClick(Sender: TObject);
begin
 ModalResult := mrOK;
end;

procedure TDonations_List.DonationsCloseButtonClick(Sender: TObject);
begin
 ModalResult := mrOK;
end;

procedure TDonations_List.DonationsDataAfterApplyUpdates(Sender: TDataSet;
    Info: TResolveResults);
begin
  inherited;
  EnableRefreshTimer;
end;

procedure TDonations_List.EditDonorRecord(const DonorId: Integer);
var
  AForm: TDonorDataEditForm;
  nInt: integer;
  sString: string;
begin
  AForm := TDonorDataEditForm.Create(Self);
  GetDonorId(DonorId);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    AForm.DataSet := Donors;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    if AForm.EditDataApplyUpdates then
    begin
      Donors.ApplyUpdates;
    end;
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TDonations_List.EditRecord(const RecordId: Integer);
var
  AForm: TDonationDataEditForm;
begin
  AForm := TDonationDataEditForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());

    if RecordId = 0 then
    begin
      DonationsData.Insert;
      DonationsData.FieldByName('DonorId').AsInteger := FId;
      DonationsData.FieldByName('ImportId').AsInteger := FImportId;
    end
    else
    begin
      DonationsData.Locate('Id', RecordId, []);
      DonationsData.Edit;
    end;
    AForm.DataSet := DonationsData;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    if AForm.EditDataApplyUpdates then
    begin
      DonationsData.ApplyUpdates;
    end;
    AForm.Free;
    AForm := nil;
  end;
end;


procedure TDonations_List.EnableRefreshTimer(const AnInterval: Integer);
begin
  if RefreshTimer.Enabled then
    Exit;
  RefreshTimer.Interval := AnInterval;
  RefreshTimer.Enabled := True;
end;

procedure TDonations_List.DonationsTableGetCellChildren(Sender: TObject; ACol,
    ARow: Integer; AField: TField; AValue: string; AElement:
    TJSHTMLElementRecord);
var
  RecordId: integer;
  DonorId: integer;
  Span: THTMLSpan;


  procedure EditClick(Sender: TObject);
  begin
    EditRecord(RecordId);
  end;

  procedure EditDonorClick(Sender: TObject);
  begin
    EditDonorRecord(DonorId);
  end;


begin
  if ARow = 0 then
    Exit;


  if not (DonationsTable.Columns[ACol].Title = 'Actions') then
  begin
    Exit;
  end;

  RecordId := DonationsData.FieldByName('Id').AsInteger;
  DonorId := DonationsData.FieldByName('DonorId').AsInteger;

  if (DonationsData.FieldByName('ClaimStatus').AsString = 'Unclaimed') or
     (DonationsData.FieldByName('ClaimStatus').AsString = 'Invalid') then
  begin
    Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-edit', 'Edit donation');
    Span.OnClick := @EditClick;

    //KSS Removed this
    //Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-user-edit', 'Edit Donor');
    //Span.OnClick := @EditDonorClick;
  end;

end;

procedure TDonations_List.SetDonorId(const Value: Integer);
begin
  FListType := dltDonor;
  FId := Value;
end;

procedure TDonations_List.SetImportId(const Value: Integer);
begin
  FListType := dltImport;
  FId := Value;
end;

procedure TDonations_List.GetDonorId(DonorId: integer);
begin
  Donors.Close;
  Donors.QueryString := '$filter=Id eq ' + inttostr(DonorId);
  Donors.Load;
end;

procedure TDonations_List.RefreshTimerTimer(Sender: TObject);
begin
  DonationsData.DisableControls;
  DonationsData.Close;
  DonationsData.Load;
  DonationsData.EnableControls;
  RefreshTimer.Enabled := False;
end;

procedure TDonations_List.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DonationsTable := TDBTableControl.Create('DonationsTable');
  DonationsCloseButton := TButton.Create('DonationsCloseButton');
  AddDonationButton := TButton.Create('AddDonationButton');
  DonationsData := TXDataWebDataSet.Create(Self);
  DonationsDataId := TIntegerField.Create(Self);
  DonationsDataDateAdded := TDateTimeField.Create(Self);
  DonationsDataAddedBy := TIntegerField.Create(Self);
  DonationsDataLastUpdatedBy := TIntegerField.Create(Self);
  DonationsDataLastUpdated := TDateTimeField.Create(Self);
  DonationsDataExternalId := TStringField.Create(Self);
  DonationsDataTransactionDate := TDateField.Create(Self);
  DonationsDataImportId := TIntegerField.Create(Self);
  DonationsDataClaimId := TIntegerField.Create(Self);
  DonationsDataGiftAid := TFloatField.Create(Self);
  DonationsDataAmount := TFloatField.Create(Self);
  DonationsDataClaimStatus := TStringField.Create(Self);
  DonationsDataClaimDate := TDateTimeField.Create(Self);
  DonationsDataTaxYear := TIntegerField.Create(Self);
  DonationsDataErrorText := TStringField.Create(Self);
  DonationsDataCharityTenant := TStringField.Create(Self);
  DonationsDataSourceType := TStringField.Create(Self);
  DonationsDataComment := TStringField.Create(Self);
  DonationsDataSponsored := TBooleanField.Create(Self);
  DonationsDataDonorId := TIntegerField.Create(Self);
  DonationsDataDonorPostCode := TStringField.Create(Self);
  DonationsDataDonorName := TStringField.Create(Self);
  DonationsDataAdjustmentID := TIntegerField.Create(Self);
  DonationsSource := TDataSource.Create(Self);
  Donors := TXDataWebDataSet.Create(Self);
  DonorsDeclarations := TXDataWebDatasetField.Create(Self);
  DonorsId := TIntegerField.Create(Self);
  DonorsDateAdded := TDateTimeField.Create(Self);
  DonorsAddedBy := TIntegerField.Create(Self);
  DonorsLastUpdatedBy := TIntegerField.Create(Self);
  DonorsLastUpdated := TDateTimeField.Create(Self);
  DonorsTitle := TStringField.Create(Self);
  DonorsFirstName := TStringField.Create(Self);
  DonorsMiddleName := TStringField.Create(Self);
  DonorsLastName := TStringField.Create(Self);
  DonorsSuffix := TStringField.Create(Self);
  DonorsAdd1 := TStringField.Create(Self);
  DonorsAdd2 := TStringField.Create(Self);
  DonorsAdd3 := TStringField.Create(Self);
  DonorsCity := TStringField.Create(Self);
  DonorsPostCode := TStringField.Create(Self);
  DonorsEmail := TStringField.Create(Self);
  DonorsMobile := TStringField.Create(Self);
  DonorsTelephone := TStringField.Create(Self);
  DonorsMatchHash := TStringField.Create(Self);
  DonorsContactByPhone := TBooleanField.Create(Self);
  DonorsContactByPost := TBooleanField.Create(Self);
  DonorsContactByText := TBooleanField.Create(Self);
  DonorsContactByEmail := TBooleanField.Create(Self);
  DonorsDOB := TDateField.Create(Self);
  DonorsHearingStatus := TStringField.Create(Self);
  DonorsEmailStatus := TStringField.Create(Self);
  DonorsLatitude := TFloatField.Create(Self);
  DonorsLongitude := TFloatField.Create(Self);
  DonorsExternalId := TStringField.Create(Self);
  DonorsCountryCode := TStringField.Create(Self);
  DonorsVisualStatus := TStringField.Create(Self);
  DonorsMobilityStatus := TStringField.Create(Self);
  DonorsSourceType := TStringField.Create(Self);
  DonorsImportId := TIntegerField.Create(Self);
  DonorsClaimStatus := TStringField.Create(Self);
  DonorsCharityTenant := TStringField.Create(Self);
  DonorsComments := TStringField.Create(Self);
  RefreshTimer := TTimer.Create(Self);

  DonationsTable.BeforeLoadDFMValues;
  DonationsCloseButton.BeforeLoadDFMValues;
  AddDonationButton.BeforeLoadDFMValues;
  DonationsData.BeforeLoadDFMValues;
  DonationsDataId.BeforeLoadDFMValues;
  DonationsDataDateAdded.BeforeLoadDFMValues;
  DonationsDataAddedBy.BeforeLoadDFMValues;
  DonationsDataLastUpdatedBy.BeforeLoadDFMValues;
  DonationsDataLastUpdated.BeforeLoadDFMValues;
  DonationsDataExternalId.BeforeLoadDFMValues;
  DonationsDataTransactionDate.BeforeLoadDFMValues;
  DonationsDataImportId.BeforeLoadDFMValues;
  DonationsDataClaimId.BeforeLoadDFMValues;
  DonationsDataGiftAid.BeforeLoadDFMValues;
  DonationsDataAmount.BeforeLoadDFMValues;
  DonationsDataClaimStatus.BeforeLoadDFMValues;
  DonationsDataClaimDate.BeforeLoadDFMValues;
  DonationsDataTaxYear.BeforeLoadDFMValues;
  DonationsDataErrorText.BeforeLoadDFMValues;
  DonationsDataCharityTenant.BeforeLoadDFMValues;
  DonationsDataSourceType.BeforeLoadDFMValues;
  DonationsDataComment.BeforeLoadDFMValues;
  DonationsDataSponsored.BeforeLoadDFMValues;
  DonationsDataDonorId.BeforeLoadDFMValues;
  DonationsDataDonorPostCode.BeforeLoadDFMValues;
  DonationsDataDonorName.BeforeLoadDFMValues;
  DonationsDataAdjustmentID.BeforeLoadDFMValues;
  DonationsSource.BeforeLoadDFMValues;
  Donors.BeforeLoadDFMValues;
  DonorsDeclarations.BeforeLoadDFMValues;
  DonorsId.BeforeLoadDFMValues;
  DonorsDateAdded.BeforeLoadDFMValues;
  DonorsAddedBy.BeforeLoadDFMValues;
  DonorsLastUpdatedBy.BeforeLoadDFMValues;
  DonorsLastUpdated.BeforeLoadDFMValues;
  DonorsTitle.BeforeLoadDFMValues;
  DonorsFirstName.BeforeLoadDFMValues;
  DonorsMiddleName.BeforeLoadDFMValues;
  DonorsLastName.BeforeLoadDFMValues;
  DonorsSuffix.BeforeLoadDFMValues;
  DonorsAdd1.BeforeLoadDFMValues;
  DonorsAdd2.BeforeLoadDFMValues;
  DonorsAdd3.BeforeLoadDFMValues;
  DonorsCity.BeforeLoadDFMValues;
  DonorsPostCode.BeforeLoadDFMValues;
  DonorsEmail.BeforeLoadDFMValues;
  DonorsMobile.BeforeLoadDFMValues;
  DonorsTelephone.BeforeLoadDFMValues;
  DonorsMatchHash.BeforeLoadDFMValues;
  DonorsContactByPhone.BeforeLoadDFMValues;
  DonorsContactByPost.BeforeLoadDFMValues;
  DonorsContactByText.BeforeLoadDFMValues;
  DonorsContactByEmail.BeforeLoadDFMValues;
  DonorsDOB.BeforeLoadDFMValues;
  DonorsHearingStatus.BeforeLoadDFMValues;
  DonorsEmailStatus.BeforeLoadDFMValues;
  DonorsLatitude.BeforeLoadDFMValues;
  DonorsLongitude.BeforeLoadDFMValues;
  DonorsExternalId.BeforeLoadDFMValues;
  DonorsCountryCode.BeforeLoadDFMValues;
  DonorsVisualStatus.BeforeLoadDFMValues;
  DonorsMobilityStatus.BeforeLoadDFMValues;
  DonorsSourceType.BeforeLoadDFMValues;
  DonorsImportId.BeforeLoadDFMValues;
  DonorsClaimStatus.BeforeLoadDFMValues;
  DonorsCharityTenant.BeforeLoadDFMValues;
  DonorsComments.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  try
    DonationsTable.SetParentComponent(Self);
    DonationsTable.Name := 'DonationsTable';
    DonationsTable.Left := 21;
    DonationsTable.Top := 69;
    DonationsTable.Width := 342;
    DonationsTable.Height := 166;
    DonationsTable.HeightStyle := ssAuto;
    DonationsTable.WidthStyle := ssAuto;
    DonationsTable.BorderColor := clSilver;
    DonationsTable.ChildOrder := 4;
    DonationsTable.ElementFont := efCSS;
    DonationsTable.ElementHeaderClassName := 'thead-light';
    DonationsTable.ElementPosition := epIgnore;
    DonationsTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    SetEvent(DonationsTable, Self, 'OnGetCellChildren', 'DonationsTableGetCellChildren');
    DonationsTable.Columns.Clear;
    with DonationsTable.Columns.Add do
    begin
      DataField := 'TransactionDate';
      Title := 'Transaction Date';
    end;
    with DonationsTable.Columns.Add do
    begin
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with DonationsTable.Columns.Add do
    begin
      DataField := 'ClaimStatus';
      Title := 'Status';
    end;
    with DonationsTable.Columns.Add do
    begin
      DataField := 'ErrorText';
      Title := 'Error';
      Width := 133;
    end;
    with DonationsTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    DonationsTable.DataSource := DonationsSource;
    DonationsCloseButton.SetParentComponent(Self);
    DonationsCloseButton.Name := 'DonationsCloseButton';
    DonationsCloseButton.Left := 299;
    DonationsCloseButton.Top := 248;
    DonationsCloseButton.Width := 64;
    DonationsCloseButton.Height := 17;
    DonationsCloseButton.Caption := 'Close';
    DonationsCloseButton.ChildOrder := 3;
    DonationsCloseButton.ElementClassName := 'btn btn-light';
    DonationsCloseButton.ElementFont := efCSS;
    DonationsCloseButton.ElementPosition := epIgnore;
    DonationsCloseButton.HeightStyle := ssAuto;
    DonationsCloseButton.HeightPercent := 100.000000000000000000;
    DonationsCloseButton.WidthStyle := ssAuto;
    DonationsCloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(DonationsCloseButton, Self, 'OnClick', 'DonationsCloseButtonClick');
    AddDonationButton.SetParentComponent(Self);
    AddDonationButton.Name := 'AddDonationButton';
    AddDonationButton.Left := 231;
    AddDonationButton.Top := 248;
    AddDonationButton.Width := 64;
    AddDonationButton.Height := 17;
    AddDonationButton.Caption := 'Add';
    AddDonationButton.ChildOrder := 2;
    AddDonationButton.ElementClassName := 'btn btn-secondary';
    AddDonationButton.HeightPercent := 100.000000000000000000;
    AddDonationButton.WidthPercent := 100.000000000000000000;
    SetEvent(AddDonationButton, Self, 'OnClick', 'AddDonationButtonClick');
    DonationsData.SetParentComponent(Self);
    DonationsData.Name := 'DonationsData';
    DonationsData.AfterApplyUpdates := DonationsDataAfterApplyUpdates;
    DonationsData.EntitySetName := 'Donation';
    DonationsData.Connection := MainData.DataConnection;
    DonationsData.Left := 48;
    DonationsData.Top := 5;
    DonationsDataId.SetParentComponent(DonationsData);
    DonationsDataId.Name := 'DonationsDataId';
    DonationsDataId.FieldName := 'Id';
    DonationsDataId.Required := True;
    DonationsDataDateAdded.SetParentComponent(DonationsData);
    DonationsDataDateAdded.Name := 'DonationsDataDateAdded';
    DonationsDataDateAdded.FieldName := 'DateAdded';
    DonationsDataDateAdded.Required := True;
    DonationsDataAddedBy.SetParentComponent(DonationsData);
    DonationsDataAddedBy.Name := 'DonationsDataAddedBy';
    DonationsDataAddedBy.FieldName := 'AddedBy';
    DonationsDataAddedBy.Required := True;
    DonationsDataLastUpdatedBy.SetParentComponent(DonationsData);
    DonationsDataLastUpdatedBy.Name := 'DonationsDataLastUpdatedBy';
    DonationsDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    DonationsDataLastUpdated.SetParentComponent(DonationsData);
    DonationsDataLastUpdated.Name := 'DonationsDataLastUpdated';
    DonationsDataLastUpdated.FieldName := 'LastUpdated';
    DonationsDataExternalId.SetParentComponent(DonationsData);
    DonationsDataExternalId.Name := 'DonationsDataExternalId';
    DonationsDataExternalId.FieldName := 'ExternalId';
    DonationsDataExternalId.Size := 64;
    DonationsDataTransactionDate.SetParentComponent(DonationsData);
    DonationsDataTransactionDate.Name := 'DonationsDataTransactionDate';
    DonationsDataTransactionDate.FieldName := 'TransactionDate';
    DonationsDataTransactionDate.Required := True;
    DonationsDataImportId.SetParentComponent(DonationsData);
    DonationsDataImportId.Name := 'DonationsDataImportId';
    DonationsDataImportId.FieldName := 'ImportId';
    DonationsDataClaimId.SetParentComponent(DonationsData);
    DonationsDataClaimId.Name := 'DonationsDataClaimId';
    DonationsDataClaimId.FieldName := 'ClaimId';
    DonationsDataGiftAid.SetParentComponent(DonationsData);
    DonationsDataGiftAid.Name := 'DonationsDataGiftAid';
    DonationsDataGiftAid.FieldName := 'GiftAid';
    DonationsDataGiftAid.Required := True;
    DonationsDataAmount.SetParentComponent(DonationsData);
    DonationsDataAmount.Name := 'DonationsDataAmount';
    DonationsDataAmount.FieldName := 'Amount';
    DonationsDataAmount.Required := True;
    DonationsDataClaimStatus.SetParentComponent(DonationsData);
    DonationsDataClaimStatus.Name := 'DonationsDataClaimStatus';
    DonationsDataClaimStatus.FieldName := 'ClaimStatus';
    DonationsDataClaimStatus.Required := True;
    DonationsDataClaimStatus.Size := 10;
    DonationsDataClaimDate.SetParentComponent(DonationsData);
    DonationsDataClaimDate.Name := 'DonationsDataClaimDate';
    DonationsDataClaimDate.FieldName := 'ClaimDate';
    DonationsDataTaxYear.SetParentComponent(DonationsData);
    DonationsDataTaxYear.Name := 'DonationsDataTaxYear';
    DonationsDataTaxYear.FieldName := 'TaxYear';
    DonationsDataErrorText.SetParentComponent(DonationsData);
    DonationsDataErrorText.Name := 'DonationsDataErrorText';
    DonationsDataErrorText.FieldName := 'ErrorText';
    DonationsDataErrorText.Size := 1000;
    DonationsDataCharityTenant.SetParentComponent(DonationsData);
    DonationsDataCharityTenant.Name := 'DonationsDataCharityTenant';
    DonationsDataCharityTenant.FieldName := 'CharityTenant';
    DonationsDataSourceType.SetParentComponent(DonationsData);
    DonationsDataSourceType.Name := 'DonationsDataSourceType';
    DonationsDataSourceType.FieldName := 'SourceType';
    DonationsDataSourceType.Size := 9;
    DonationsDataComment.SetParentComponent(DonationsData);
    DonationsDataComment.Name := 'DonationsDataComment';
    DonationsDataComment.FieldName := 'Comment';
    DonationsDataComment.Size := 250;
    DonationsDataSponsored.SetParentComponent(DonationsData);
    DonationsDataSponsored.Name := 'DonationsDataSponsored';
    DonationsDataSponsored.FieldName := 'Sponsored';
    DonationsDataSponsored.Required := True;
    DonationsDataDonorId.SetParentComponent(DonationsData);
    DonationsDataDonorId.Name := 'DonationsDataDonorId';
    DonationsDataDonorId.FieldName := 'DonorId';
    DonationsDataDonorId.Required := True;
    DonationsDataDonorPostCode.SetParentComponent(DonationsData);
    DonationsDataDonorPostCode.Name := 'DonationsDataDonorPostCode';
    DonationsDataDonorPostCode.FieldName := 'DonorPostCode';
    DonationsDataDonorPostCode.Size := 15;
    DonationsDataDonorName.SetParentComponent(DonationsData);
    DonationsDataDonorName.Name := 'DonationsDataDonorName';
    DonationsDataDonorName.FieldName := 'DonorName';
    DonationsDataDonorName.Size := 50;
    DonationsDataAdjustmentID.SetParentComponent(DonationsData);
    DonationsDataAdjustmentID.Name := 'DonationsDataAdjustmentID';
    DonationsDataAdjustmentID.FieldName := 'AdjustmentID';
    DonationsSource.SetParentComponent(Self);
    DonationsSource.Name := 'DonationsSource';
    DonationsSource.DataSet := DonationsData;
    DonationsSource.Left := 117;
    DonationsSource.Top := 5;
    Donors.SetParentComponent(Self);
    Donors.Name := 'Donors';
    Donors.EntitySetName := 'Donor';
    Donors.Connection := MainData.DataConnection;
    Donors.Left := 27;
    Donors.Top := 272;
    DonorsDeclarations.SetParentComponent(Donors);
    DonorsDeclarations.Name := 'DonorsDeclarations';
    DonorsDeclarations.FieldName := 'Declarations';
    DonorsDeclarations.ReadOnly := True;
    DonorsId.SetParentComponent(Donors);
    DonorsId.Name := 'DonorsId';
    DonorsId.FieldName := 'Id';
    DonorsId.Required := True;
    DonorsDateAdded.SetParentComponent(Donors);
    DonorsDateAdded.Name := 'DonorsDateAdded';
    DonorsDateAdded.FieldName := 'DateAdded';
    DonorsDateAdded.Required := True;
    DonorsAddedBy.SetParentComponent(Donors);
    DonorsAddedBy.Name := 'DonorsAddedBy';
    DonorsAddedBy.FieldName := 'AddedBy';
    DonorsAddedBy.Required := True;
    DonorsLastUpdatedBy.SetParentComponent(Donors);
    DonorsLastUpdatedBy.Name := 'DonorsLastUpdatedBy';
    DonorsLastUpdatedBy.FieldName := 'LastUpdatedBy';
    DonorsLastUpdated.SetParentComponent(Donors);
    DonorsLastUpdated.Name := 'DonorsLastUpdated';
    DonorsLastUpdated.FieldName := 'LastUpdated';
    DonorsTitle.SetParentComponent(Donors);
    DonorsTitle.Name := 'DonorsTitle';
    DonorsTitle.FieldName := 'Title';
    DonorsTitle.Size := 25;
    DonorsFirstName.SetParentComponent(Donors);
    DonorsFirstName.Name := 'DonorsFirstName';
    DonorsFirstName.FieldName := 'FirstName';
    DonorsFirstName.Size := 50;
    DonorsMiddleName.SetParentComponent(Donors);
    DonorsMiddleName.Name := 'DonorsMiddleName';
    DonorsMiddleName.FieldName := 'MiddleName';
    DonorsMiddleName.Size := 50;
    DonorsLastName.SetParentComponent(Donors);
    DonorsLastName.Name := 'DonorsLastName';
    DonorsLastName.FieldName := 'LastName';
    DonorsLastName.Size := 50;
    DonorsSuffix.SetParentComponent(Donors);
    DonorsSuffix.Name := 'DonorsSuffix';
    DonorsSuffix.FieldName := 'Suffix';
    DonorsSuffix.Size := 50;
    DonorsAdd1.SetParentComponent(Donors);
    DonorsAdd1.Name := 'DonorsAdd1';
    DonorsAdd1.FieldName := 'Add1';
    DonorsAdd1.Size := 75;
    DonorsAdd2.SetParentComponent(Donors);
    DonorsAdd2.Name := 'DonorsAdd2';
    DonorsAdd2.FieldName := 'Add2';
    DonorsAdd2.Size := 75;
    DonorsAdd3.SetParentComponent(Donors);
    DonorsAdd3.Name := 'DonorsAdd3';
    DonorsAdd3.FieldName := 'Add3';
    DonorsAdd3.Size := 75;
    DonorsCity.SetParentComponent(Donors);
    DonorsCity.Name := 'DonorsCity';
    DonorsCity.FieldName := 'City';
    DonorsCity.Size := 75;
    DonorsPostCode.SetParentComponent(Donors);
    DonorsPostCode.Name := 'DonorsPostCode';
    DonorsPostCode.FieldName := 'PostCode';
    DonorsPostCode.Size := 15;
    DonorsEmail.SetParentComponent(Donors);
    DonorsEmail.Name := 'DonorsEmail';
    DonorsEmail.FieldName := 'Email';
    DonorsEmail.Size := 75;
    DonorsMobile.SetParentComponent(Donors);
    DonorsMobile.Name := 'DonorsMobile';
    DonorsMobile.FieldName := 'Mobile';
    DonorsMobile.Size := 25;
    DonorsTelephone.SetParentComponent(Donors);
    DonorsTelephone.Name := 'DonorsTelephone';
    DonorsTelephone.FieldName := 'Telephone';
    DonorsTelephone.Size := 25;
    DonorsMatchHash.SetParentComponent(Donors);
    DonorsMatchHash.Name := 'DonorsMatchHash';
    DonorsMatchHash.FieldName := 'MatchHash';
    DonorsMatchHash.Size := 64;
    DonorsContactByPhone.SetParentComponent(Donors);
    DonorsContactByPhone.Name := 'DonorsContactByPhone';
    DonorsContactByPhone.FieldName := 'ContactByPhone';
    DonorsContactByPhone.Required := True;
    DonorsContactByPost.SetParentComponent(Donors);
    DonorsContactByPost.Name := 'DonorsContactByPost';
    DonorsContactByPost.FieldName := 'ContactByPost';
    DonorsContactByPost.Required := True;
    DonorsContactByText.SetParentComponent(Donors);
    DonorsContactByText.Name := 'DonorsContactByText';
    DonorsContactByText.FieldName := 'ContactByText';
    DonorsContactByText.Required := True;
    DonorsContactByEmail.SetParentComponent(Donors);
    DonorsContactByEmail.Name := 'DonorsContactByEmail';
    DonorsContactByEmail.FieldName := 'ContactByEmail';
    DonorsContactByEmail.Required := True;
    DonorsDOB.SetParentComponent(Donors);
    DonorsDOB.Name := 'DonorsDOB';
    DonorsDOB.FieldName := 'DOB';
    DonorsHearingStatus.SetParentComponent(Donors);
    DonorsHearingStatus.Name := 'DonorsHearingStatus';
    DonorsHearingStatus.FieldName := 'HearingStatus';
    DonorsHearingStatus.Required := True;
    DonorsHearingStatus.Size := 8;
    DonorsEmailStatus.SetParentComponent(Donors);
    DonorsEmailStatus.Name := 'DonorsEmailStatus';
    DonorsEmailStatus.FieldName := 'EmailStatus';
    DonorsEmailStatus.Required := True;
    DonorsEmailStatus.Size := 10;
    DonorsLatitude.SetParentComponent(Donors);
    DonorsLatitude.Name := 'DonorsLatitude';
    DonorsLatitude.FieldName := 'Latitude';
    DonorsLongitude.SetParentComponent(Donors);
    DonorsLongitude.Name := 'DonorsLongitude';
    DonorsLongitude.FieldName := 'Longitude';
    DonorsExternalId.SetParentComponent(Donors);
    DonorsExternalId.Name := 'DonorsExternalId';
    DonorsExternalId.FieldName := 'ExternalId';
    DonorsExternalId.Size := 64;
    DonorsCountryCode.SetParentComponent(Donors);
    DonorsCountryCode.Name := 'DonorsCountryCode';
    DonorsCountryCode.FieldName := 'CountryCode';
    DonorsCountryCode.Size := 3;
    DonorsVisualStatus.SetParentComponent(Donors);
    DonorsVisualStatus.Name := 'DonorsVisualStatus';
    DonorsVisualStatus.FieldName := 'VisualStatus';
    DonorsVisualStatus.Required := True;
    DonorsVisualStatus.Size := 8;
    DonorsMobilityStatus.SetParentComponent(Donors);
    DonorsMobilityStatus.Name := 'DonorsMobilityStatus';
    DonorsMobilityStatus.FieldName := 'MobilityStatus';
    DonorsMobilityStatus.Required := True;
    DonorsMobilityStatus.Size := 8;
    DonorsSourceType.SetParentComponent(Donors);
    DonorsSourceType.Name := 'DonorsSourceType';
    DonorsSourceType.FieldName := 'SourceType';
    DonorsSourceType.Size := 9;
    DonorsImportId.SetParentComponent(Donors);
    DonorsImportId.Name := 'DonorsImportId';
    DonorsImportId.FieldName := 'ImportId';
    DonorsClaimStatus.SetParentComponent(Donors);
    DonorsClaimStatus.Name := 'DonorsClaimStatus';
    DonorsClaimStatus.FieldName := 'ClaimStatus';
    DonorsClaimStatus.Required := True;
    DonorsClaimStatus.Size := 19;
    DonorsCharityTenant.SetParentComponent(Donors);
    DonorsCharityTenant.Name := 'DonorsCharityTenant';
    DonorsCharityTenant.FieldName := 'CharityTenant';
    DonorsComments.SetParentComponent(Donors);
    DonorsComments.Name := 'DonorsComments';
    DonorsComments.FieldName := 'Comments';
    DonorsComments.Size := 255;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 197;
    RefreshTimer.Top := 5;
  finally
    DonationsTable.AfterLoadDFMValues;
    DonationsCloseButton.AfterLoadDFMValues;
    AddDonationButton.AfterLoadDFMValues;
    DonationsData.AfterLoadDFMValues;
    DonationsDataId.AfterLoadDFMValues;
    DonationsDataDateAdded.AfterLoadDFMValues;
    DonationsDataAddedBy.AfterLoadDFMValues;
    DonationsDataLastUpdatedBy.AfterLoadDFMValues;
    DonationsDataLastUpdated.AfterLoadDFMValues;
    DonationsDataExternalId.AfterLoadDFMValues;
    DonationsDataTransactionDate.AfterLoadDFMValues;
    DonationsDataImportId.AfterLoadDFMValues;
    DonationsDataClaimId.AfterLoadDFMValues;
    DonationsDataGiftAid.AfterLoadDFMValues;
    DonationsDataAmount.AfterLoadDFMValues;
    DonationsDataClaimStatus.AfterLoadDFMValues;
    DonationsDataClaimDate.AfterLoadDFMValues;
    DonationsDataTaxYear.AfterLoadDFMValues;
    DonationsDataErrorText.AfterLoadDFMValues;
    DonationsDataCharityTenant.AfterLoadDFMValues;
    DonationsDataSourceType.AfterLoadDFMValues;
    DonationsDataComment.AfterLoadDFMValues;
    DonationsDataSponsored.AfterLoadDFMValues;
    DonationsDataDonorId.AfterLoadDFMValues;
    DonationsDataDonorPostCode.AfterLoadDFMValues;
    DonationsDataDonorName.AfterLoadDFMValues;
    DonationsDataAdjustmentID.AfterLoadDFMValues;
    DonationsSource.AfterLoadDFMValues;
    Donors.AfterLoadDFMValues;
    DonorsDeclarations.AfterLoadDFMValues;
    DonorsId.AfterLoadDFMValues;
    DonorsDateAdded.AfterLoadDFMValues;
    DonorsAddedBy.AfterLoadDFMValues;
    DonorsLastUpdatedBy.AfterLoadDFMValues;
    DonorsLastUpdated.AfterLoadDFMValues;
    DonorsTitle.AfterLoadDFMValues;
    DonorsFirstName.AfterLoadDFMValues;
    DonorsMiddleName.AfterLoadDFMValues;
    DonorsLastName.AfterLoadDFMValues;
    DonorsSuffix.AfterLoadDFMValues;
    DonorsAdd1.AfterLoadDFMValues;
    DonorsAdd2.AfterLoadDFMValues;
    DonorsAdd3.AfterLoadDFMValues;
    DonorsCity.AfterLoadDFMValues;
    DonorsPostCode.AfterLoadDFMValues;
    DonorsEmail.AfterLoadDFMValues;
    DonorsMobile.AfterLoadDFMValues;
    DonorsTelephone.AfterLoadDFMValues;
    DonorsMatchHash.AfterLoadDFMValues;
    DonorsContactByPhone.AfterLoadDFMValues;
    DonorsContactByPost.AfterLoadDFMValues;
    DonorsContactByText.AfterLoadDFMValues;
    DonorsContactByEmail.AfterLoadDFMValues;
    DonorsDOB.AfterLoadDFMValues;
    DonorsHearingStatus.AfterLoadDFMValues;
    DonorsEmailStatus.AfterLoadDFMValues;
    DonorsLatitude.AfterLoadDFMValues;
    DonorsLongitude.AfterLoadDFMValues;
    DonorsExternalId.AfterLoadDFMValues;
    DonorsCountryCode.AfterLoadDFMValues;
    DonorsVisualStatus.AfterLoadDFMValues;
    DonorsMobilityStatus.AfterLoadDFMValues;
    DonorsSourceType.AfterLoadDFMValues;
    DonorsImportId.AfterLoadDFMValues;
    DonorsClaimStatus.AfterLoadDFMValues;
    DonorsCharityTenant.AfterLoadDFMValues;
    DonorsComments.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
  end;
end;

end.
