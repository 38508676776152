unit OtherClaimsEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Data.DB, WEBLib.DB, WEBLib.DBCtrls, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.ExtCtrls, WEBLib.WebCtrls;

type
  TOtherClaimsEditForm = class(TCoreWebForm)
    CloseButton: TButton;
    SaveButton: TButton;
    CancelButton: TButton;
    EditDataSource: TDataSource;
    AmountEdit: TDBEdit;
    TransactionDateEdit: TDBDateTimePicker;
    NoteMemo: TDBMemo;
    RulesMemo: THTMLSpan;
    MessageDialog: TMessageDlg;
    procedure AmountEditKeyPress(Sender: TObject; var Key: Char);
    procedure CancelButtonClick(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure EditDataSourceDataChange(Sender: TObject; Field: TField);
    procedure NoteMemoKeyPress(Sender: TObject; var Key: Char);
    procedure SaveButtonClick(Sender: TObject);
    procedure TransactionDateEditChange(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    fEditDataApplyUpdates: boolean;
    fOtherClaimsType: string;
    fRecordId: integer;
    fTransactionDate: boolean;
    fTransactionDateValid: boolean;
    procedure SetOtherClaimsType(AOtherClaimsType: string);
    procedure SetDataSet(Value: TDataSet);
    procedure SetTransactionDateValid(AValid: boolean);
    procedure CheckValidations;
  protected
    [async]
    procedure GetRules; async; virtual;
    procedure ResetButtons(value: boolean);
  public
    { Public declarations }
    procedure AfterCreated; override;
    property EditDataApplyUpdates: boolean read fEditDataApplyUpdates;
    property OtherClaimsType: string read fOtherClaimsType write SetOtherClaimsType;
    property DataSet: TDataSet write SetDataSet;
    property TransactionDateValid: boolean read fTransactionDateValid write SetTransactionDateValid;
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsEditForm: TOtherClaimsEditForm;

implementation

uses
  MainDataModule, XData.Web.Client, SMX.Web.Document.Utils;
{$R *.dfm}

procedure TOtherClaimsEditForm.CancelButtonClick(Sender: TObject);
begin
  inherited;
  EditDataSource.DataSet.Cancel;
  ModalResult := mrOk;
end;

procedure TOtherClaimsEditForm.CheckValidations;
begin
  if fTransactionDateValid then
  begin
    SaveButton.Enabled := true;
  end
  else
  begin
    SaveButton.Enabled := false;
  end;
end;

procedure TOtherClaimsEditForm.CloseButtonClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrOk;
end;

procedure TOtherClaimsEditForm.EditDataSourceDataChange(Sender: TObject;
    Field: TField);
begin
  if Field <> nil then
  begin
    ResetButtons(EditDataSource.DataSet.Modified);
  end;
end;

procedure TOtherClaimsEditForm.SaveButtonClick(Sender: TObject);
begin
  //inherited;
  EditDataSource.DataSet.Post;
  fEditDataApplyUpdates := true;
  ResetButtons(False);
  ModalResult := mrOk;
end;

procedure TOtherClaimsEditForm.WebFormCreate(Sender: TObject);
begin
  inherited;
  fEditDataApplyUpdates := false;
end;

procedure TOtherClaimsEditForm.ResetButtons(value: boolean);
begin
  SaveButton.Enabled := Value;
  CancelButton.Enabled := Value;
end;

procedure TOtherClaimsEditForm.SetOtherClaimsType(AOtherClaimsType: string);
begin
  fOtherClaimsType := AOtherClaimsType;
end;

procedure TOtherClaimsEditForm.SetTransactionDateValid(AValid: boolean);
begin
  fTransactionDateValid := AValid;
  CheckValidations;
end;

procedure TOtherClaimsEditForm.AfterCreated;
begin
  GetRules;
end;

procedure TOtherClaimsEditForm.AmountEditKeyPress(Sender: TObject; var Key:
    Char);
begin
  if not (Key in ['0'..'9','.','-']) then
  begin
    TDocUtils.setControlValidity('AmountEdit', vsInvalid);
    Key := #0;
  end
  else
  begin
    TDocUtils.setControlValidity('AmountEdit', vsValid);
  end;
end;

procedure TOtherClaimsEditForm.GetRules;
var
  lJobParams: JS.TJSObject;
  lRetval: TXDataClientResponse;
  lRulesStr: string;
begin
  lRetval := await(TXDataClientResponse,
    MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.OtherClaimsRules',[fOtherClaimsType]));

  lRulesStr := JS.ToString(lRetval.ResultAsObject['value']);
  RulesMemo.HTML.Text := lRulesStr;

  TransactionDateEdit.SetFocus;
end;

procedure TOtherClaimsEditForm.NoteMemoKeyPress(Sender: TObject; var Key: Char);
var
  NoteLength: integer;
const
  MaxNoteLength = 250;
begin
  NoteLength := Length(NoteMemo.Lines.Text);
  if NoteLength > MaxNoteLength then
  begin
    Key := #0;
    MessageDialog.ShowDialog('Can not enter more than 250 characters ', WEBLib.Dialogs.mtWarning, [mbOK]);
    NoteMemo.Lines.Text := Copy(NoteMemo.Lines.Text, 1, MaxNoteLength);
  end;
end;

procedure TOtherClaimsEditForm.SetDataSet(Value: TDataSet);
begin
  EditDataSource.DataSet := Value;
end;

procedure TOtherClaimsEditForm.TransactionDateEditChange(Sender: TObject);
begin
  if TransactionDateEdit.Date > Date then
  begin
    TransactionDateEdit.Date := 0;
    TDocUtils.setControlValidity('TransactionDateEdit', vsInvalid);
    TransactionDateValid := false;
  end
  else
  begin
    TDocUtils.setControlValidity('TransactionDateEdit', vsValid);
    TransactionDateValid := true;
  end;
end;

procedure TOtherClaimsEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CloseButton := TButton.Create('CloseButton');
  SaveButton := TButton.Create('SaveButton');
  CancelButton := TButton.Create('CancelButton');
  AmountEdit := TDBEdit.Create('AmountEdit');
  TransactionDateEdit := TDBDateTimePicker.Create('TransactionDateEdit');
  NoteMemo := TDBMemo.Create('NotesEdit');
  RulesMemo := THTMLSpan.Create('RulesMemo');
  MessageDialog := TMessageDlg.Create(Self);
  EditDataSource := TDataSource.Create(Self);

  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  AmountEdit.BeforeLoadDFMValues;
  TransactionDateEdit.BeforeLoadDFMValues;
  NoteMemo.BeforeLoadDFMValues;
  RulesMemo.BeforeLoadDFMValues;
  MessageDialog.BeforeLoadDFMValues;
  EditDataSource.BeforeLoadDFMValues;
  try
    Caption := 'Comment';
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 113;
    CloseButton.Top := 235;
    CloseButton.Width := 64;
    CloseButton.Height := 17;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 12;
    CloseButton.ElementClassName := 'btn btn-light';
    CloseButton.ElementFont := efCSS;
    CloseButton.ElementPosition := epIgnore;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.TabOrder := 14;
    CloseButton.WidthStyle := ssAuto;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 181;
    SaveButton.Top := 235;
    SaveButton.Width := 64;
    SaveButton.Height := 17;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 13;
    SaveButton.ElementClassName := 'btn btn-light';
    SaveButton.ElementFont := efCSS;
    SaveButton.ElementPosition := epIgnore;
    SaveButton.Enabled := False;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.TabOrder := 12;
    SaveButton.WidthStyle := ssAuto;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 249;
    CancelButton.Top := 235;
    CancelButton.Width := 64;
    CancelButton.Height := 17;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 14;
    CancelButton.ElementClassName := 'btn btn-light';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.Enabled := False;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.TabOrder := 13;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    AmountEdit.SetParentComponent(Self);
    AmountEdit.Name := 'AmountEdit';
    AmountEdit.Left := 11;
    AmountEdit.Top := 77;
    AmountEdit.Width := 81;
    AmountEdit.Height := 15;
    AmountEdit.ChildOrder := 6;
    AmountEdit.ElementClassName := 'form-control';
    AmountEdit.ElementPosition := epIgnore;
    AmountEdit.HeightStyle := ssAuto;
    AmountEdit.HeightPercent := 100.000000000000000000;
    AmountEdit.Text := 'AmountEdit';
    AmountEdit.WidthStyle := ssAuto;
    AmountEdit.WidthPercent := 100.000000000000000000;
    SetEvent(AmountEdit, Self, 'OnKeyPress', 'AmountEditKeyPress');
    AmountEdit.DataField := 'Amount';
    AmountEdit.DataSource := EditDataSource;
    TransactionDateEdit.SetParentComponent(Self);
    TransactionDateEdit.Name := 'TransactionDateEdit';
    TransactionDateEdit.Left := 11;
    TransactionDateEdit.Top := 53;
    TransactionDateEdit.Width := 113;
    TransactionDateEdit.Height := 15;
    TransactionDateEdit.ElementClassName := 'form-control';
    TransactionDateEdit.HeightStyle := ssAuto;
    TransactionDateEdit.WidthStyle := ssAuto;
    TransactionDateEdit.BorderStyle := bsSingle;
    TransactionDateEdit.ChildOrder := 7;
    TransactionDateEdit.Color := clWhite;
    TransactionDateEdit.Date := 45226.369823090280000000;
    TransactionDateEdit.ElementPosition := epIgnore;
    TransactionDateEdit.Role := '';
    TransactionDateEdit.Text := '';
    SetEvent(TransactionDateEdit, Self, 'OnChange', 'TransactionDateEditChange');
    TransactionDateEdit.DataField := 'TransactionDate';
    TransactionDateEdit.DataSource := EditDataSource;
    NoteMemo.SetParentComponent(Self);
    NoteMemo.Name := 'NoteMemo';
    NoteMemo.Left := 11;
    NoteMemo.Top := 107;
    NoteMemo.Width := 123;
    NoteMemo.Height := 59;
    NoteMemo.AutoSize := False;
    NoteMemo.ElementClassName := 'form-control';
    NoteMemo.ElementPosition := epIgnore;
    NoteMemo.HeightStyle := ssAuto;
    NoteMemo.HeightPercent := 100.000000000000000000;
    NoteMemo.Lines.BeginUpdate;
    try
      NoteMemo.Lines.Clear;
      NoteMemo.Lines.Add('NoteMemo');
    finally
      NoteMemo.Lines.EndUpdate;
    end;
    NoteMemo.SelLength := 0;
    NoteMemo.SelStart := 0;
    NoteMemo.WidthStyle := ssAuto;
    NoteMemo.WidthPercent := 100.000000000000000000;
    SetEvent(NoteMemo, Self, 'OnKeyPress', 'NoteMemoKeyPress');
    NoteMemo.DataField := 'Comment';
    NoteMemo.DataSource := EditDataSource;
    RulesMemo.SetParentComponent(Self);
    RulesMemo.Name := 'RulesMemo';
    RulesMemo.Left := 138;
    RulesMemo.Top := 53;
    RulesMemo.Width := 123;
    RulesMemo.Height := 166;
    RulesMemo.HeightStyle := ssAuto;
    RulesMemo.WidthStyle := ssAuto;
    RulesMemo.ChildOrder := 7;
    RulesMemo.ElementPosition := epIgnore;
    RulesMemo.ElementFont := efCSS;
    RulesMemo.Role := '';
    MessageDialog.SetParentComponent(Self);
    MessageDialog.Name := 'MessageDialog';
    MessageDialog.Left := 325;
    MessageDialog.Top := 53;
    MessageDialog.Width := 16;
    MessageDialog.Height := 16;
    MessageDialog.HeightStyle := ssAuto;
    MessageDialog.WidthStyle := ssAuto;
    MessageDialog.Buttons := [];
    MessageDialog.Opacity := 0.200000000000000000;
    EditDataSource.SetParentComponent(Self);
    EditDataSource.Name := 'EditDataSource';
    SetEvent(EditDataSource, Self, 'OnDataChange', 'EditDataSourceDataChange');
    EditDataSource.Left := 324;
    EditDataSource.Top := 11;
  finally
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    AmountEdit.AfterLoadDFMValues;
    TransactionDateEdit.AfterLoadDFMValues;
    NoteMemo.AfterLoadDFMValues;
    RulesMemo.AfterLoadDFMValues;
    MessageDialog.AfterLoadDFMValues;
    EditDataSource.AfterLoadDFMValues;
  end;
end;

end.

