unit ErrorForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  XData.Web.Connection,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.WebCtrls;

type
  TErrorPage = class(TForm)
    TitleLabel: TLabel;
    CloseButton: TButton;
    MessageSpan: THTMLSpan;
    procedure CloseButtonClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
    [async]
    class procedure Display(ATitle, AErrorMessage: string; const AsPopup, CanClose: Boolean); async;
    [async]
    class procedure DisplayConnectionError(AError: TXDataWebConnectionError; const AsPopup: Boolean); async;
  protected procedure LoadDFMValues; override; end;

var
  ErrorPage: TErrorPage;

implementation

{$R *.dfm}

procedure TErrorPage.CloseButtonClick(Sender: TObject);
begin
   ModalResult := mrOK;
end;

{ TErrorPage }

class procedure TErrorPage.Display(ATitle, AErrorMessage: string; const AsPopup, CanClose: Boolean);
begin
  if Assigned(ErrorPage) then
    ErrorPage.Free;
  ErrorPage := TErrorPage.Create(Application);

  if AsPopup then
  begin
    ErrorPage.Popup := True;
    ErrorPage.Border := TFormBorderStyle.fbDialog;
    ErrorPage.Caption := 'Error';
  end;

  await(TErrorPage, ErrorPage.Load);
  ErrorPage.TitleLabel.Caption := ATitle;
  ErrorPage.MessageSpan.HTML.Text := AErrorMessage;
  ErrorPage.CloseButton.Visible := CanClose;

end;

class procedure TErrorPage.DisplayConnectionError(AError: TXDataWebConnectionError; const AsPopup: Boolean);
begin
  Display('Connection Error', AError.ErrorMessage + ' : ' + AError.RequestUrl, AsPopup, False);
end;

procedure TErrorPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TitleLabel := TLabel.Create('TitleLabel');
  CloseButton := TButton.Create('CloseButton');
  MessageSpan := THTMLSpan.Create('MessageDetail');

  TitleLabel.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  MessageSpan.BeforeLoadDFMValues;
  try
    Name := 'ErrorPage';
    Width := 305;
    Height := 250;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    TitleLabel.SetParentComponent(Self);
    TitleLabel.Name := 'TitleLabel';
    TitleLabel.Left := 64;
    TitleLabel.Top := 5;
    TitleLabel.Width := 33;
    TitleLabel.Height := 10;
    TitleLabel.Caption := 'TitleLabel';
    TitleLabel.ElementPosition := epIgnore;
    TitleLabel.HeightStyle := ssAuto;
    TitleLabel.HeightPercent := 100.000000000000000000;
    TitleLabel.HTMLType := tH4;
    TitleLabel.WidthStyle := ssAuto;
    TitleLabel.WidthPercent := 100.000000000000000000;
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 117;
    CloseButton.Top := 219;
    CloseButton.Width := 64;
    CloseButton.Height := 17;
    CloseButton.Caption := 'OK';
    CloseButton.ChildOrder := 2;
    CloseButton.ElementClassName := 'btn btn-primary';
    CloseButton.ElementPosition := epIgnore;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthStyle := ssAuto;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    MessageSpan.SetParentComponent(Self);
    MessageSpan.Name := 'MessageSpan';
    MessageSpan.Left := 21;
    MessageSpan.Top := 27;
    MessageSpan.Width := 262;
    MessageSpan.Height := 166;
    MessageSpan.HeightStyle := ssAuto;
    MessageSpan.WidthStyle := ssAuto;
    MessageSpan.ChildOrder := 2;
    MessageSpan.ElementPosition := epIgnore;
    MessageSpan.ElementFont := efCSS;
    MessageSpan.Role := '';
  finally
    TitleLabel.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    MessageSpan.AfterLoadDFMValues;
  end;
end;

end.

