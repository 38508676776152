unit DonationDataEdit;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Data.DB,
  WEBLib.DB,
  WEBLib.DBCtrls,
  WebForm.Core;

type
  TDonationDataEditForm = class(TCoreWebForm)
    AmountDonation: TDBEdit;
    DonorPostCode: TDBEdit;
    SaveButton: TButton;
    CancelButton: TButton;
    CloseButton: TButton;
    ClaimStatus: TDBEdit;
    EditDataSource: TDataSource;
    DonationDateEdit: TDBDateTimePicker;
    procedure CancelButtonClick(Sender: TObject);
    procedure CloseButtonClick(Sender: TObject);
    procedure DonationDateEditExit(Sender: TObject);
    procedure EditDataSourceDataChange(Sender: TObject; Field: TField);
    procedure SaveButtonClick(Sender: TObject);
  private
    { Private declarations }
    fEditDataApplyUpdates: boolean;
    procedure SetDataSet(Value: TDataSet);
    procedure ResetButtons(Value: boolean);
  public
    { Public declarations }
    property DataSet: TDataSet write SetDataSet;
    property EditDataApplyUpdates: boolean read fEditDataApplyUpdates;
  protected procedure LoadDFMValues; override; end;

var
  DonationDataEditForm: TDonationDataEditForm;

implementation

{$R *.dfm}

uses
  DonationList;

procedure TDonationDataEditForm.CancelButtonClick(Sender: TObject);
begin
  inherited;
  EditDataSource.DataSet.Cancel;
  ResetButtons(False);
end;

procedure TDonationDataEditForm.CloseButtonClick(Sender: TObject);
begin
  inherited;
  ModalResult := mrOk;
end;

procedure TDonationDataEditForm.DonationDateEditExit(Sender: TObject);
begin
  if DonationDateEdit.Date > Date then
  begin
    DonationDateEdit.Date := 0;
    ShowAToast('Error', 'Can not enter a date in the future', 'clock.png');
  end;
end;

procedure TDonationDataEditForm.EditDataSourceDataChange(Sender: TObject; Field: TField);
begin
  if Field <> nil then
  begin
    ResetButtons(EditDataSource.DataSet.Modified);
  end;
end;

procedure TDonationDataEditForm.ResetButtons(Value: boolean);
begin
  SaveButton.Enabled := Value;
  CancelButton.Enabled := Value;
end;

procedure TDonationDataEditForm.SaveButtonClick(Sender: TObject);
begin
  inherited;
  EditDataSource.DataSet.Post;
  fEditDataApplyUpdates := true;
  ResetButtons(False);
  ModalResult := mrOk;
end;

procedure TDonationDataEditForm.SetDataSet(Value: TDataSet);
begin
  EditDataSource.DataSet := Value;
end;

procedure TDonationDataEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  AmountDonation := TDBEdit.Create('AmountDonation');
  DonorPostCode := TDBEdit.Create('DonorPostCode');
  SaveButton := TButton.Create('SaveButton');
  CancelButton := TButton.Create('CancelButton');
  CloseButton := TButton.Create('CloseButton');
  ClaimStatus := TDBEdit.Create('ClaimStatus');
  DonationDateEdit := TDBDateTimePicker.Create('DonationDateEdit');
  EditDataSource := TDataSource.Create(Self);

  AmountDonation.BeforeLoadDFMValues;
  DonorPostCode.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  ClaimStatus.BeforeLoadDFMValues;
  DonationDateEdit.BeforeLoadDFMValues;
  EditDataSource.BeforeLoadDFMValues;
  try
    AmountDonation.SetParentComponent(Self);
    AmountDonation.Name := 'AmountDonation';
    AmountDonation.Left := 27;
    AmountDonation.Top := 5;
    AmountDonation.Width := 81;
    AmountDonation.Height := 15;
    AmountDonation.ChildOrder := 1;
    AmountDonation.ElementClassName := 'form-control';
    AmountDonation.ElementFont := efCSS;
    AmountDonation.ElementPosition := epIgnore;
    AmountDonation.HeightStyle := ssAuto;
    AmountDonation.HeightPercent := 100.000000000000000000;
    AmountDonation.Text := 'AmountDonation';
    AmountDonation.WidthStyle := ssAuto;
    AmountDonation.WidthPercent := 100.000000000000000000;
    AmountDonation.DataField := 'Amount';
    AmountDonation.DataSource := EditDataSource;
    DonorPostCode.SetParentComponent(Self);
    DonorPostCode.Name := 'DonorPostCode';
    DonorPostCode.Left := 27;
    DonorPostCode.Top := 43;
    DonorPostCode.Width := 81;
    DonorPostCode.Height := 15;
    DonorPostCode.ChildOrder := 3;
    DonorPostCode.ElementClassName := 'form-control';
    DonorPostCode.ElementFont := efCSS;
    DonorPostCode.ElementPosition := epIgnore;
    DonorPostCode.HeightStyle := ssAuto;
    DonorPostCode.HeightPercent := 100.000000000000000000;
    DonorPostCode.TabOrder := 2;
    DonorPostCode.Text := 'DonorPostCode';
    DonorPostCode.Visible := False;
    DonorPostCode.WidthStyle := ssAuto;
    DonorPostCode.WidthPercent := 100.000000000000000000;
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 27;
    SaveButton.Top := 112;
    SaveButton.Width := 64;
    SaveButton.Height := 17;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 4;
    SaveButton.ElementClassName := 'btn btn-light';
    SaveButton.ElementFont := efCSS;
    SaveButton.ElementPosition := epIgnore;
    SaveButton.Enabled := False;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthStyle := ssAuto;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CancelButton.SetParentComponent(Self);
    CancelButton.Name := 'CancelButton';
    CancelButton.Left := 107;
    CancelButton.Top := 112;
    CancelButton.Width := 64;
    CancelButton.Height := 17;
    CancelButton.Caption := 'Cancel';
    CancelButton.ChildOrder := 5;
    CancelButton.ElementClassName := 'btn btn-light';
    CancelButton.ElementFont := efCSS;
    CancelButton.ElementPosition := epIgnore;
    CancelButton.Enabled := False;
    CancelButton.HeightStyle := ssAuto;
    CancelButton.HeightPercent := 100.000000000000000000;
    CancelButton.WidthStyle := ssAuto;
    CancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(CancelButton, Self, 'OnClick', 'CancelButtonClick');
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 181;
    CloseButton.Top := 112;
    CloseButton.Width := 64;
    CloseButton.Height := 17;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 6;
    CloseButton.ElementClassName := 'btn btn-light';
    CloseButton.ElementFont := efCSS;
    CloseButton.ElementPosition := epIgnore;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthStyle := ssAuto;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    ClaimStatus.SetParentComponent(Self);
    ClaimStatus.Name := 'ClaimStatus';
    ClaimStatus.Left := 27;
    ClaimStatus.Top := 61;
    ClaimStatus.Width := 200;
    ClaimStatus.Height := 15;
    ClaimStatus.ChildOrder := 4;
    ClaimStatus.ElementClassName := 'form-control';
    ClaimStatus.ElementFont := efCSS;
    ClaimStatus.ElementPosition := epIgnore;
    ClaimStatus.HeightStyle := ssAuto;
    ClaimStatus.HeightPercent := 100.000000000000000000;
    ClaimStatus.TabOrder := 3;
    ClaimStatus.Text := 'ClaimStatus';
    ClaimStatus.Visible := False;
    ClaimStatus.WidthStyle := ssAuto;
    ClaimStatus.WidthPercent := 100.000000000000000000;
    DonationDateEdit.SetParentComponent(Self);
    DonationDateEdit.Name := 'DonationDateEdit';
    DonationDateEdit.Left := 27;
    DonationDateEdit.Top := 24;
    DonationDateEdit.Width := 81;
    DonationDateEdit.Height := 15;
    DonationDateEdit.ElementClassName := 'form-control';
    DonationDateEdit.HeightStyle := ssAuto;
    DonationDateEdit.WidthStyle := ssAuto;
    DonationDateEdit.BorderStyle := bsSingle;
    DonationDateEdit.ChildOrder := 8;
    DonationDateEdit.Color := clWhite;
    DonationDateEdit.Date := 45191.330952881940000000;
    DonationDateEdit.ElementFont := efCSS;
    DonationDateEdit.ElementPosition := epIgnore;
    DonationDateEdit.Role := '';
    DonationDateEdit.Text := 'DonationDateEdit';
    SetEvent(DonationDateEdit, Self, 'OnExit', 'DonationDateEditExit');
    DonationDateEdit.DataField := 'TransactionDate';
    DonationDateEdit.DataSource := EditDataSource;
    EditDataSource.SetParentComponent(Self);
    EditDataSource.Name := 'EditDataSource';
    SetEvent(EditDataSource, Self, 'OnDataChange', 'EditDataSourceDataChange');
    EditDataSource.Left := 144;
    EditDataSource.Top := 16;
  finally
    AmountDonation.AfterLoadDFMValues;
    DonorPostCode.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    ClaimStatus.AfterLoadDFMValues;
    DonationDateEdit.AfterLoadDFMValues;
    EditDataSource.AfterLoadDFMValues;
  end;
end;

end.
