unit ImportData;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.DB,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.WebCtrls, WebForm.Core,
  Grid.Plugins, WEBLib.Lists,
  smx.webcore.types;

type
  TImportDataList = class(TCoreWebForm)
    ImportDonationsTable: TDBTableControl;
    ImportDonationSource: TDataSource;
    ImportDonationData: TXDataWebDataSet;
    ImportDonationDataId: TIntegerField;
    ImportDonationDataDateAdded: TDateTimeField;
    ImportDonationDataAddedBy: TIntegerField;
    ImportDonationDataLastUpdatedBy: TIntegerField;
    ImportDonationDataLastUpdated: TDateTimeField;
    ImportDonationDataTitle: TStringField;
    ImportDonationDataFirstName: TStringField;
    ImportDonationDataMiddleName: TStringField;
    ImportDonationDataLastName: TStringField;
    ImportDonationDataSuffix: TStringField;
    ImportDonationDataAdd1: TStringField;
    ImportDonationDataAdd2: TStringField;
    ImportDonationDataAdd3: TStringField;
    ImportDonationDataCity: TStringField;
    ImportDonationDataPostCode: TStringField;
    ImportDonationDataEmail: TStringField;
    ImportDonationDataMobile: TStringField;
    ImportDonationDataTelephone: TStringField;
    ImportDonationDataMatchHash: TStringField;
    ImportDonationDataContactByPhone: TBooleanField;
    ImportDonationDataContactByPost: TBooleanField;
    ImportDonationDataContactByText: TBooleanField;
    ImportDonationDataContactByEmail: TBooleanField;
    ImportDonationDataDOB: TDateField;
    ImportDonationDataEmailStatus: TStringField;
    ImportDonationDataLatitude: TFloatField;
    ImportDonationDataLongitude: TFloatField;
    ImportDonationDataExternalId: TStringField;
    ImportDonationDataCountryCode: TStringField;
    ImportDonationDataClaimStatus: TStringField;
    ImportDonationDataCharityTenant: TStringField;
    ImportDonationDataImportState: TStringField;
    ImportDonationDataErrorText: TStringField;
    ImportDonationDataImportBatchId: TIntegerField;
    ImportDonationDataSponsored: TBooleanField;
    ImportDonationDataDonationDate: TDateTimeField;
    ImportDonationDataGiftAid: TFloatField;
    ImportDonationDataAmount: TFloatField;
    ImportDonationDataExternalDonationId: TStringField;
    ImportDonationDataMatchedPerson: TIntegerField;
    ImportDonationDataHearingStatus: TStringField;
    ImportDonationDataVisualStatus: TStringField;
    ImportDonationDataMobilityStatus: TStringField;
    ImportDonationDataSourceType: TStringField;
    ImportDonationDataCounty: TStringField;
    ImportDonationDataImportId: TIntegerField;
    ImportDonationDataCountry: TStringField;
    PageSizeCombo: TComboBox;
    PaginationInfo: TListControl;
    PaginationInfoLabel: TLabel;
    CloseButton: TButton;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
    procedure DonationsCloseButtonClick(Sender: TObject);
    procedure ImportDonationDataAfterApplyUpdates(Sender: TDataSet; Info:
        TResolveResults);
    procedure ImportDonationsTableGetCellChildren(Sender: TObject; ACol, ARow:
        Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure PageSizeComboChange(Sender: TObject);
  private
    { Private declarations }
    FGridPlugin: TGridPlugin;
    FIdField: string;

    FImportId: Integer;
    procedure SetImportId(const Value: Integer);
    [Async]
    procedure EditRecord(const RecordId: Integer); async;
    [ASync]
    procedure RefreshData; async;
    procedure CreateGridPlugin;

  public
    procedure AfterCreated; override;
    property ImportId: Integer write SetImportId;
  protected procedure LoadDFMValues; override; end;

var
  ImportDataList: TImportDataList;

implementation

{$R *.dfm}

uses
  MainDataModule, SMX.Web.Layout.Utils, ImportDataEdit;

procedure TImportDataList.WebFormCreate(Sender: TObject);
begin
  inherited;
  CreateGridPlugin;
end;

procedure TImportDataList.WebFormDestroy(Sender: TObject);
begin
  FGridPlugin.Free;
  inherited;
end;

procedure TImportDataList.CreateGridPlugin;
begin
  FGridPlugin := TGridPlugin.Create(ImportDonationsTable, ImportDonationData, PaginationInfo, PaginationInfoLabel,
    TSortPair.Create(0, sdDesc));
  FGridPlugin.SetPageSize(10);
end;


procedure TImportDataList.AfterCreated;
begin
  inherited;
end;

procedure TImportDataList.DonationsCloseButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TImportDataList.EditRecord(const RecordId: Integer);
var
  AForm: TImportDataEditForm;
begin
  AForm := TImportDataEditForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());
    ImportDonationData.Locate('Id', RecordId, []);
    AForm.DataSet := ImportDonationData;
    AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    if AForm.EditDataApplyUpdates then
    begin
      ImportDonationData.ApplyUpdates;
    end;
    AForm.Free;
    AForm := nil;
  end;
end;

procedure TImportDataList.ImportDonationDataAfterApplyUpdates(Sender: TDataSet;
    Info: TResolveResults);
begin
  inherited;
  RefreshData;
end;

procedure TImportDataList.ImportDonationsTableGetCellChildren(Sender: TObject;
    ACol, ARow: Integer; AField: TField; AValue: string; AElement:
    TJSHTMLElementRecord);
var
  RecordId: integer;
  Span: THTMLSpan;


  procedure EditClick(Sender: TObject);
  begin
    EditRecord(RecordId);
  end;

begin
  if ARow = 0 then
    Exit;

  if not (ImportDonationsTable.Columns[ACol].Title = 'Actions') then
  begin
    Exit;
  end;

  if ImportDonationData.FieldByName('ImportState').AsString <> 'Error' then
  begin
    Exit;
  end;

  RecordId := ImportDonationData.FieldByName('Id').AsInteger;
  Span := TLayoutUtils.RowActionSpan(AElement.Element, 'fad fa-edit', 'Edit');
  Span.OnClick := @EditClick;

end;

procedure TImportDataList.PageSizeComboChange(Sender: TObject);
begin
  FGridPlugin.SetPageSize(StrToInt(PageSizeCombo.Text), True);
end;



procedure TImportDataList.SetImportId(const Value: Integer);
var
  lFilter: string;
begin
  FImportId := Value;
  lFilter := Format('ImportBatchId eq %d',[FImportId]);
  FGridPlugin.SetPreparedFilter(lFilter);
end;

procedure TImportDataList.RefreshData;
var
  lFilter: string;
begin
  lFilter := Format('ImportBatchId eq %d',[FImportId]);
  FGridPlugin.SetPreparedFilter(lFilter);
end;

procedure TImportDataList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  PaginationInfoLabel := TLabel.Create('PageInfoLabel');
  ImportDonationsTable := TDBTableControl.Create('ImportDonationsTable');
  PageSizeCombo := TComboBox.Create('PageSize');
  PaginationInfo := TListControl.Create('PaginationNumbers');
  CloseButton := TButton.Create('CloseDataButton');
  ImportDonationSource := TDataSource.Create(Self);
  ImportDonationData := TXDataWebDataSet.Create(Self);
  ImportDonationDataId := TIntegerField.Create(Self);
  ImportDonationDataDateAdded := TDateTimeField.Create(Self);
  ImportDonationDataAddedBy := TIntegerField.Create(Self);
  ImportDonationDataLastUpdatedBy := TIntegerField.Create(Self);
  ImportDonationDataLastUpdated := TDateTimeField.Create(Self);
  ImportDonationDataTitle := TStringField.Create(Self);
  ImportDonationDataFirstName := TStringField.Create(Self);
  ImportDonationDataMiddleName := TStringField.Create(Self);
  ImportDonationDataLastName := TStringField.Create(Self);
  ImportDonationDataSuffix := TStringField.Create(Self);
  ImportDonationDataAdd1 := TStringField.Create(Self);
  ImportDonationDataAdd2 := TStringField.Create(Self);
  ImportDonationDataAdd3 := TStringField.Create(Self);
  ImportDonationDataCity := TStringField.Create(Self);
  ImportDonationDataPostCode := TStringField.Create(Self);
  ImportDonationDataEmail := TStringField.Create(Self);
  ImportDonationDataMobile := TStringField.Create(Self);
  ImportDonationDataTelephone := TStringField.Create(Self);
  ImportDonationDataMatchHash := TStringField.Create(Self);
  ImportDonationDataContactByPhone := TBooleanField.Create(Self);
  ImportDonationDataContactByPost := TBooleanField.Create(Self);
  ImportDonationDataContactByText := TBooleanField.Create(Self);
  ImportDonationDataContactByEmail := TBooleanField.Create(Self);
  ImportDonationDataDOB := TDateField.Create(Self);
  ImportDonationDataEmailStatus := TStringField.Create(Self);
  ImportDonationDataLatitude := TFloatField.Create(Self);
  ImportDonationDataLongitude := TFloatField.Create(Self);
  ImportDonationDataExternalId := TStringField.Create(Self);
  ImportDonationDataCountryCode := TStringField.Create(Self);
  ImportDonationDataClaimStatus := TStringField.Create(Self);
  ImportDonationDataCharityTenant := TStringField.Create(Self);
  ImportDonationDataImportState := TStringField.Create(Self);
  ImportDonationDataErrorText := TStringField.Create(Self);
  ImportDonationDataImportBatchId := TIntegerField.Create(Self);
  ImportDonationDataSponsored := TBooleanField.Create(Self);
  ImportDonationDataDonationDate := TDateTimeField.Create(Self);
  ImportDonationDataGiftAid := TFloatField.Create(Self);
  ImportDonationDataAmount := TFloatField.Create(Self);
  ImportDonationDataExternalDonationId := TStringField.Create(Self);
  ImportDonationDataMatchedPerson := TIntegerField.Create(Self);
  ImportDonationDataHearingStatus := TStringField.Create(Self);
  ImportDonationDataVisualStatus := TStringField.Create(Self);
  ImportDonationDataMobilityStatus := TStringField.Create(Self);
  ImportDonationDataSourceType := TStringField.Create(Self);
  ImportDonationDataCounty := TStringField.Create(Self);
  ImportDonationDataImportId := TIntegerField.Create(Self);
  ImportDonationDataCountry := TStringField.Create(Self);

  PaginationInfoLabel.BeforeLoadDFMValues;
  ImportDonationsTable.BeforeLoadDFMValues;
  PageSizeCombo.BeforeLoadDFMValues;
  PaginationInfo.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  ImportDonationSource.BeforeLoadDFMValues;
  ImportDonationData.BeforeLoadDFMValues;
  ImportDonationDataId.BeforeLoadDFMValues;
  ImportDonationDataDateAdded.BeforeLoadDFMValues;
  ImportDonationDataAddedBy.BeforeLoadDFMValues;
  ImportDonationDataLastUpdatedBy.BeforeLoadDFMValues;
  ImportDonationDataLastUpdated.BeforeLoadDFMValues;
  ImportDonationDataTitle.BeforeLoadDFMValues;
  ImportDonationDataFirstName.BeforeLoadDFMValues;
  ImportDonationDataMiddleName.BeforeLoadDFMValues;
  ImportDonationDataLastName.BeforeLoadDFMValues;
  ImportDonationDataSuffix.BeforeLoadDFMValues;
  ImportDonationDataAdd1.BeforeLoadDFMValues;
  ImportDonationDataAdd2.BeforeLoadDFMValues;
  ImportDonationDataAdd3.BeforeLoadDFMValues;
  ImportDonationDataCity.BeforeLoadDFMValues;
  ImportDonationDataPostCode.BeforeLoadDFMValues;
  ImportDonationDataEmail.BeforeLoadDFMValues;
  ImportDonationDataMobile.BeforeLoadDFMValues;
  ImportDonationDataTelephone.BeforeLoadDFMValues;
  ImportDonationDataMatchHash.BeforeLoadDFMValues;
  ImportDonationDataContactByPhone.BeforeLoadDFMValues;
  ImportDonationDataContactByPost.BeforeLoadDFMValues;
  ImportDonationDataContactByText.BeforeLoadDFMValues;
  ImportDonationDataContactByEmail.BeforeLoadDFMValues;
  ImportDonationDataDOB.BeforeLoadDFMValues;
  ImportDonationDataEmailStatus.BeforeLoadDFMValues;
  ImportDonationDataLatitude.BeforeLoadDFMValues;
  ImportDonationDataLongitude.BeforeLoadDFMValues;
  ImportDonationDataExternalId.BeforeLoadDFMValues;
  ImportDonationDataCountryCode.BeforeLoadDFMValues;
  ImportDonationDataClaimStatus.BeforeLoadDFMValues;
  ImportDonationDataCharityTenant.BeforeLoadDFMValues;
  ImportDonationDataImportState.BeforeLoadDFMValues;
  ImportDonationDataErrorText.BeforeLoadDFMValues;
  ImportDonationDataImportBatchId.BeforeLoadDFMValues;
  ImportDonationDataSponsored.BeforeLoadDFMValues;
  ImportDonationDataDonationDate.BeforeLoadDFMValues;
  ImportDonationDataGiftAid.BeforeLoadDFMValues;
  ImportDonationDataAmount.BeforeLoadDFMValues;
  ImportDonationDataExternalDonationId.BeforeLoadDFMValues;
  ImportDonationDataMatchedPerson.BeforeLoadDFMValues;
  ImportDonationDataHearingStatus.BeforeLoadDFMValues;
  ImportDonationDataVisualStatus.BeforeLoadDFMValues;
  ImportDonationDataMobilityStatus.BeforeLoadDFMValues;
  ImportDonationDataSourceType.BeforeLoadDFMValues;
  ImportDonationDataCounty.BeforeLoadDFMValues;
  ImportDonationDataImportId.BeforeLoadDFMValues;
  ImportDonationDataCountry.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    PaginationInfoLabel.SetParentComponent(Self);
    PaginationInfoLabel.Name := 'PaginationInfoLabel';
    PaginationInfoLabel.Left := 21;
    PaginationInfoLabel.Top := 275;
    PaginationInfoLabel.Width := 71;
    PaginationInfoLabel.Height := 10;
    PaginationInfoLabel.Caption := 'PaginationInfoLabel';
    PaginationInfoLabel.HeightPercent := 100.000000000000000000;
    PaginationInfoLabel.WidthPercent := 100.000000000000000000;
    ImportDonationsTable.SetParentComponent(Self);
    ImportDonationsTable.Name := 'ImportDonationsTable';
    ImportDonationsTable.Left := 21;
    ImportDonationsTable.Top := 69;
    ImportDonationsTable.Width := 342;
    ImportDonationsTable.Height := 166;
    ImportDonationsTable.HeightStyle := ssAuto;
    ImportDonationsTable.WidthStyle := ssAuto;
    ImportDonationsTable.BorderColor := clSilver;
    ImportDonationsTable.ChildOrder := 4;
    ImportDonationsTable.ElementFont := efCSS;
    ImportDonationsTable.ElementHeaderClassName := 'thead-light';
    ImportDonationsTable.ElementPosition := epIgnore;
    ImportDonationsTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    SetEvent(ImportDonationsTable, Self, 'OnGetCellChildren', 'ImportDonationsTableGetCellChildren');
    ImportDonationsTable.Columns.Clear;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'FirstName';
      Title := 'Firstname';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'LastName';
      Title := 'Lastname';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'DonationDate';
      Title := 'Date';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'PostCode';
      Title := 'Post Code';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'ImportState';
      Title := 'State';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      DataField := 'ErrorText';
      Title := 'Errors';
    end;
    with ImportDonationsTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    ImportDonationsTable.DataSource := ImportDonationSource;
    PageSizeCombo.SetParentComponent(Self);
    PageSizeCombo.Name := 'PageSizeCombo';
    PageSizeCombo.Left := 21;
    PageSizeCombo.Top := 50;
    PageSizeCombo.Width := 97;
    PageSizeCombo.Height := 15;
    PageSizeCombo.ElementClassName := 'form-select';
    PageSizeCombo.ElementPosition := epIgnore;
    PageSizeCombo.HeightStyle := ssAuto;
    PageSizeCombo.HeightPercent := 100.000000000000000000;
    PageSizeCombo.Text := 'PageSize';
    PageSizeCombo.WidthStyle := ssAuto;
    PageSizeCombo.WidthPercent := 100.000000000000000000;
    SetEvent(PageSizeCombo, Self, 'OnChange', 'PageSizeComboChange');
    PageSizeCombo.ItemIndex := -1;
    PageSizeCombo.Items.BeginUpdate;
    try
      PageSizeCombo.Items.Clear;
      PageSizeCombo.Items.Add('10');
      PageSizeCombo.Items.Add('25');
      PageSizeCombo.Items.Add('50');
      PageSizeCombo.Items.Add('100');
      PageSizeCombo.Items.Add('');
    finally
      PageSizeCombo.Items.EndUpdate;
    end;
    PaginationInfo.SetParentComponent(Self);
    PaginationInfo.Name := 'PaginationInfo';
    PaginationInfo.Left := 171;
    PaginationInfo.Top := 269;
    PaginationInfo.Width := 200;
    PaginationInfo.Height := 17;
    PaginationInfo.HeightStyle := ssAuto;
    PaginationInfo.WidthStyle := ssAuto;
    PaginationInfo.HeightPercent := 100.000000000000000000;
    PaginationInfo.WidthPercent := 100.000000000000000000;
    PaginationInfo.ChildOrder := 7;
    PaginationInfo.DefaultItemClassName := 'page-item';
    PaginationInfo.DefaultItemLinkClassName := 'page-link';
    PaginationInfo.ElementListClassName := 'pagination';
    PaginationInfo.Items.Clear;
    with PaginationInfo.Items.Add do
    begin
      ItemClassName := 'page-item';
      LinkClassName := 'page-link';
      Text := 'Item 0';
    end;
    with PaginationInfo.Items.Add do
    begin
      ItemClassName := 'page-item';
      LinkClassName := 'page-link';
      Text := 'Item 1';
    end;
    with PaginationInfo.Items.Add do
    begin
      ItemClassName := 'page-item';
      LinkClassName := 'page-link';
      Text := 'Item 2';
    end;
    PaginationInfo.Style := lsPagination;
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 299;
    CloseButton.Top := 248;
    CloseButton.Width := 64;
    CloseButton.Height := 17;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 5;
    CloseButton.ElementClassName := 'btn btn-secondary';
    CloseButton.ElementPosition := epIgnore;
    CloseButton.HeightStyle := ssAuto;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.ModalResult := 1;
    CloseButton.WidthStyle := ssAuto;
    CloseButton.WidthPercent := 100.000000000000000000;
    ImportDonationSource.SetParentComponent(Self);
    ImportDonationSource.Name := 'ImportDonationSource';
    ImportDonationSource.DataSet := ImportDonationData;
    ImportDonationSource.Left := 96;
    ImportDonationSource.Top := 5;
    ImportDonationData.SetParentComponent(Self);
    ImportDonationData.Name := 'ImportDonationData';
    ImportDonationData.AfterApplyUpdates := ImportDonationDataAfterApplyUpdates;
    ImportDonationData.EntitySetName := 'ImportDonation';
    ImportDonationData.Connection := MainData.DataConnection;
    ImportDonationData.Left := 48;
    ImportDonationData.Top := 5;
    ImportDonationDataId.SetParentComponent(ImportDonationData);
    ImportDonationDataId.Name := 'ImportDonationDataId';
    ImportDonationDataId.FieldName := 'Id';
    ImportDonationDataId.Required := True;
    ImportDonationDataDateAdded.SetParentComponent(ImportDonationData);
    ImportDonationDataDateAdded.Name := 'ImportDonationDataDateAdded';
    ImportDonationDataDateAdded.FieldName := 'DateAdded';
    ImportDonationDataDateAdded.Required := True;
    ImportDonationDataAddedBy.SetParentComponent(ImportDonationData);
    ImportDonationDataAddedBy.Name := 'ImportDonationDataAddedBy';
    ImportDonationDataAddedBy.FieldName := 'AddedBy';
    ImportDonationDataAddedBy.Required := True;
    ImportDonationDataLastUpdatedBy.SetParentComponent(ImportDonationData);
    ImportDonationDataLastUpdatedBy.Name := 'ImportDonationDataLastUpdatedBy';
    ImportDonationDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    ImportDonationDataLastUpdated.SetParentComponent(ImportDonationData);
    ImportDonationDataLastUpdated.Name := 'ImportDonationDataLastUpdated';
    ImportDonationDataLastUpdated.FieldName := 'LastUpdated';
    ImportDonationDataTitle.SetParentComponent(ImportDonationData);
    ImportDonationDataTitle.Name := 'ImportDonationDataTitle';
    ImportDonationDataTitle.FieldName := 'Title';
    ImportDonationDataTitle.Size := 25;
    ImportDonationDataFirstName.SetParentComponent(ImportDonationData);
    ImportDonationDataFirstName.Name := 'ImportDonationDataFirstName';
    ImportDonationDataFirstName.FieldName := 'FirstName';
    ImportDonationDataFirstName.Size := 50;
    ImportDonationDataMiddleName.SetParentComponent(ImportDonationData);
    ImportDonationDataMiddleName.Name := 'ImportDonationDataMiddleName';
    ImportDonationDataMiddleName.FieldName := 'MiddleName';
    ImportDonationDataMiddleName.Size := 50;
    ImportDonationDataLastName.SetParentComponent(ImportDonationData);
    ImportDonationDataLastName.Name := 'ImportDonationDataLastName';
    ImportDonationDataLastName.FieldName := 'LastName';
    ImportDonationDataLastName.Size := 50;
    ImportDonationDataSuffix.SetParentComponent(ImportDonationData);
    ImportDonationDataSuffix.Name := 'ImportDonationDataSuffix';
    ImportDonationDataSuffix.FieldName := 'Suffix';
    ImportDonationDataSuffix.Size := 50;
    ImportDonationDataAdd1.SetParentComponent(ImportDonationData);
    ImportDonationDataAdd1.Name := 'ImportDonationDataAdd1';
    ImportDonationDataAdd1.FieldName := 'Add1';
    ImportDonationDataAdd1.Size := 75;
    ImportDonationDataAdd2.SetParentComponent(ImportDonationData);
    ImportDonationDataAdd2.Name := 'ImportDonationDataAdd2';
    ImportDonationDataAdd2.FieldName := 'Add2';
    ImportDonationDataAdd2.Size := 75;
    ImportDonationDataAdd3.SetParentComponent(ImportDonationData);
    ImportDonationDataAdd3.Name := 'ImportDonationDataAdd3';
    ImportDonationDataAdd3.FieldName := 'Add3';
    ImportDonationDataAdd3.Size := 75;
    ImportDonationDataCity.SetParentComponent(ImportDonationData);
    ImportDonationDataCity.Name := 'ImportDonationDataCity';
    ImportDonationDataCity.FieldName := 'City';
    ImportDonationDataCity.Size := 75;
    ImportDonationDataPostCode.SetParentComponent(ImportDonationData);
    ImportDonationDataPostCode.Name := 'ImportDonationDataPostCode';
    ImportDonationDataPostCode.FieldName := 'PostCode';
    ImportDonationDataPostCode.Size := 15;
    ImportDonationDataEmail.SetParentComponent(ImportDonationData);
    ImportDonationDataEmail.Name := 'ImportDonationDataEmail';
    ImportDonationDataEmail.FieldName := 'Email';
    ImportDonationDataEmail.Size := 75;
    ImportDonationDataMobile.SetParentComponent(ImportDonationData);
    ImportDonationDataMobile.Name := 'ImportDonationDataMobile';
    ImportDonationDataMobile.FieldName := 'Mobile';
    ImportDonationDataMobile.Size := 25;
    ImportDonationDataTelephone.SetParentComponent(ImportDonationData);
    ImportDonationDataTelephone.Name := 'ImportDonationDataTelephone';
    ImportDonationDataTelephone.FieldName := 'Telephone';
    ImportDonationDataTelephone.Size := 25;
    ImportDonationDataMatchHash.SetParentComponent(ImportDonationData);
    ImportDonationDataMatchHash.Name := 'ImportDonationDataMatchHash';
    ImportDonationDataMatchHash.FieldName := 'MatchHash';
    ImportDonationDataMatchHash.Size := 64;
    ImportDonationDataContactByPhone.SetParentComponent(ImportDonationData);
    ImportDonationDataContactByPhone.Name := 'ImportDonationDataContactByPhone';
    ImportDonationDataContactByPhone.FieldName := 'ContactByPhone';
    ImportDonationDataContactByPhone.Required := True;
    ImportDonationDataContactByPost.SetParentComponent(ImportDonationData);
    ImportDonationDataContactByPost.Name := 'ImportDonationDataContactByPost';
    ImportDonationDataContactByPost.FieldName := 'ContactByPost';
    ImportDonationDataContactByPost.Required := True;
    ImportDonationDataContactByText.SetParentComponent(ImportDonationData);
    ImportDonationDataContactByText.Name := 'ImportDonationDataContactByText';
    ImportDonationDataContactByText.FieldName := 'ContactByText';
    ImportDonationDataContactByText.Required := True;
    ImportDonationDataContactByEmail.SetParentComponent(ImportDonationData);
    ImportDonationDataContactByEmail.Name := 'ImportDonationDataContactByEmail';
    ImportDonationDataContactByEmail.FieldName := 'ContactByEmail';
    ImportDonationDataContactByEmail.Required := True;
    ImportDonationDataDOB.SetParentComponent(ImportDonationData);
    ImportDonationDataDOB.Name := 'ImportDonationDataDOB';
    ImportDonationDataDOB.FieldName := 'DOB';
    ImportDonationDataEmailStatus.SetParentComponent(ImportDonationData);
    ImportDonationDataEmailStatus.Name := 'ImportDonationDataEmailStatus';
    ImportDonationDataEmailStatus.FieldName := 'EmailStatus';
    ImportDonationDataEmailStatus.Required := True;
    ImportDonationDataEmailStatus.Size := 10;
    ImportDonationDataLatitude.SetParentComponent(ImportDonationData);
    ImportDonationDataLatitude.Name := 'ImportDonationDataLatitude';
    ImportDonationDataLatitude.FieldName := 'Latitude';
    ImportDonationDataLongitude.SetParentComponent(ImportDonationData);
    ImportDonationDataLongitude.Name := 'ImportDonationDataLongitude';
    ImportDonationDataLongitude.FieldName := 'Longitude';
    ImportDonationDataExternalId.SetParentComponent(ImportDonationData);
    ImportDonationDataExternalId.Name := 'ImportDonationDataExternalId';
    ImportDonationDataExternalId.FieldName := 'ExternalId';
    ImportDonationDataExternalId.Size := 64;
    ImportDonationDataCountryCode.SetParentComponent(ImportDonationData);
    ImportDonationDataCountryCode.Name := 'ImportDonationDataCountryCode';
    ImportDonationDataCountryCode.FieldName := 'CountryCode';
    ImportDonationDataCountryCode.Size := 3;
    ImportDonationDataClaimStatus.SetParentComponent(ImportDonationData);
    ImportDonationDataClaimStatus.Name := 'ImportDonationDataClaimStatus';
    ImportDonationDataClaimStatus.FieldName := 'ClaimStatus';
    ImportDonationDataClaimStatus.Required := True;
    ImportDonationDataClaimStatus.Size := 22;
    ImportDonationDataCharityTenant.SetParentComponent(ImportDonationData);
    ImportDonationDataCharityTenant.Name := 'ImportDonationDataCharityTenant';
    ImportDonationDataCharityTenant.FieldName := 'CharityTenant';
    ImportDonationDataImportState.SetParentComponent(ImportDonationData);
    ImportDonationDataImportState.Name := 'ImportDonationDataImportState';
    ImportDonationDataImportState.FieldName := 'ImportState';
    ImportDonationDataImportState.Required := True;
    ImportDonationDataImportState.Size := 5;
    ImportDonationDataErrorText.SetParentComponent(ImportDonationData);
    ImportDonationDataErrorText.Name := 'ImportDonationDataErrorText';
    ImportDonationDataErrorText.FieldName := 'ErrorText';
    ImportDonationDataErrorText.Size := 1000;
    ImportDonationDataImportBatchId.SetParentComponent(ImportDonationData);
    ImportDonationDataImportBatchId.Name := 'ImportDonationDataImportBatchId';
    ImportDonationDataImportBatchId.FieldName := 'ImportBatchId';
    ImportDonationDataImportBatchId.Required := True;
    ImportDonationDataSponsored.SetParentComponent(ImportDonationData);
    ImportDonationDataSponsored.Name := 'ImportDonationDataSponsored';
    ImportDonationDataSponsored.FieldName := 'Sponsored';
    ImportDonationDataSponsored.Required := True;
    ImportDonationDataDonationDate.SetParentComponent(ImportDonationData);
    ImportDonationDataDonationDate.Name := 'ImportDonationDataDonationDate';
    ImportDonationDataDonationDate.FieldName := 'DonationDate';
    ImportDonationDataDonationDate.Required := True;
    ImportDonationDataGiftAid.SetParentComponent(ImportDonationData);
    ImportDonationDataGiftAid.Name := 'ImportDonationDataGiftAid';
    ImportDonationDataGiftAid.FieldName := 'GiftAid';
    ImportDonationDataGiftAid.Required := True;
    ImportDonationDataAmount.SetParentComponent(ImportDonationData);
    ImportDonationDataAmount.Name := 'ImportDonationDataAmount';
    ImportDonationDataAmount.FieldName := 'Amount';
    ImportDonationDataAmount.Required := True;
    ImportDonationDataExternalDonationId.SetParentComponent(ImportDonationData);
    ImportDonationDataExternalDonationId.Name := 'ImportDonationDataExternalDonationId';
    ImportDonationDataExternalDonationId.FieldName := 'ExternalDonationId';
    ImportDonationDataExternalDonationId.Size := 255;
    ImportDonationDataMatchedPerson.SetParentComponent(ImportDonationData);
    ImportDonationDataMatchedPerson.Name := 'ImportDonationDataMatchedPerson';
    ImportDonationDataMatchedPerson.FieldName := 'MatchedPerson';
    ImportDonationDataHearingStatus.SetParentComponent(ImportDonationData);
    ImportDonationDataHearingStatus.Name := 'ImportDonationDataHearingStatus';
    ImportDonationDataHearingStatus.FieldName := 'HearingStatus';
    ImportDonationDataHearingStatus.Required := True;
    ImportDonationDataHearingStatus.Size := 8;
    ImportDonationDataVisualStatus.SetParentComponent(ImportDonationData);
    ImportDonationDataVisualStatus.Name := 'ImportDonationDataVisualStatus';
    ImportDonationDataVisualStatus.FieldName := 'VisualStatus';
    ImportDonationDataVisualStatus.Required := True;
    ImportDonationDataVisualStatus.Size := 8;
    ImportDonationDataMobilityStatus.SetParentComponent(ImportDonationData);
    ImportDonationDataMobilityStatus.Name := 'ImportDonationDataMobilityStatus';
    ImportDonationDataMobilityStatus.FieldName := 'MobilityStatus';
    ImportDonationDataMobilityStatus.Required := True;
    ImportDonationDataMobilityStatus.Size := 8;
    ImportDonationDataSourceType.SetParentComponent(ImportDonationData);
    ImportDonationDataSourceType.Name := 'ImportDonationDataSourceType';
    ImportDonationDataSourceType.FieldName := 'SourceType';
    ImportDonationDataSourceType.Size := 9;
    ImportDonationDataCounty.SetParentComponent(ImportDonationData);
    ImportDonationDataCounty.Name := 'ImportDonationDataCounty';
    ImportDonationDataCounty.FieldName := 'County';
    ImportDonationDataCounty.Size := 75;
    ImportDonationDataImportId.SetParentComponent(ImportDonationData);
    ImportDonationDataImportId.Name := 'ImportDonationDataImportId';
    ImportDonationDataImportId.FieldName := 'ImportId';
    ImportDonationDataCountry.SetParentComponent(ImportDonationData);
    ImportDonationDataCountry.Name := 'ImportDonationDataCountry';
    ImportDonationDataCountry.FieldName := 'Country';
    ImportDonationDataCountry.Size := 75;
  finally
    PaginationInfoLabel.AfterLoadDFMValues;
    ImportDonationsTable.AfterLoadDFMValues;
    PageSizeCombo.AfterLoadDFMValues;
    PaginationInfo.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    ImportDonationSource.AfterLoadDFMValues;
    ImportDonationData.AfterLoadDFMValues;
    ImportDonationDataId.AfterLoadDFMValues;
    ImportDonationDataDateAdded.AfterLoadDFMValues;
    ImportDonationDataAddedBy.AfterLoadDFMValues;
    ImportDonationDataLastUpdatedBy.AfterLoadDFMValues;
    ImportDonationDataLastUpdated.AfterLoadDFMValues;
    ImportDonationDataTitle.AfterLoadDFMValues;
    ImportDonationDataFirstName.AfterLoadDFMValues;
    ImportDonationDataMiddleName.AfterLoadDFMValues;
    ImportDonationDataLastName.AfterLoadDFMValues;
    ImportDonationDataSuffix.AfterLoadDFMValues;
    ImportDonationDataAdd1.AfterLoadDFMValues;
    ImportDonationDataAdd2.AfterLoadDFMValues;
    ImportDonationDataAdd3.AfterLoadDFMValues;
    ImportDonationDataCity.AfterLoadDFMValues;
    ImportDonationDataPostCode.AfterLoadDFMValues;
    ImportDonationDataEmail.AfterLoadDFMValues;
    ImportDonationDataMobile.AfterLoadDFMValues;
    ImportDonationDataTelephone.AfterLoadDFMValues;
    ImportDonationDataMatchHash.AfterLoadDFMValues;
    ImportDonationDataContactByPhone.AfterLoadDFMValues;
    ImportDonationDataContactByPost.AfterLoadDFMValues;
    ImportDonationDataContactByText.AfterLoadDFMValues;
    ImportDonationDataContactByEmail.AfterLoadDFMValues;
    ImportDonationDataDOB.AfterLoadDFMValues;
    ImportDonationDataEmailStatus.AfterLoadDFMValues;
    ImportDonationDataLatitude.AfterLoadDFMValues;
    ImportDonationDataLongitude.AfterLoadDFMValues;
    ImportDonationDataExternalId.AfterLoadDFMValues;
    ImportDonationDataCountryCode.AfterLoadDFMValues;
    ImportDonationDataClaimStatus.AfterLoadDFMValues;
    ImportDonationDataCharityTenant.AfterLoadDFMValues;
    ImportDonationDataImportState.AfterLoadDFMValues;
    ImportDonationDataErrorText.AfterLoadDFMValues;
    ImportDonationDataImportBatchId.AfterLoadDFMValues;
    ImportDonationDataSponsored.AfterLoadDFMValues;
    ImportDonationDataDonationDate.AfterLoadDFMValues;
    ImportDonationDataGiftAid.AfterLoadDFMValues;
    ImportDonationDataAmount.AfterLoadDFMValues;
    ImportDonationDataExternalDonationId.AfterLoadDFMValues;
    ImportDonationDataMatchedPerson.AfterLoadDFMValues;
    ImportDonationDataHearingStatus.AfterLoadDFMValues;
    ImportDonationDataVisualStatus.AfterLoadDFMValues;
    ImportDonationDataMobilityStatus.AfterLoadDFMValues;
    ImportDonationDataSourceType.AfterLoadDFMValues;
    ImportDonationDataCounty.AfterLoadDFMValues;
    ImportDonationDataImportId.AfterLoadDFMValues;
    ImportDonationDataCountry.AfterLoadDFMValues;
  end;
end;

end.
