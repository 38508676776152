unit ImportForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  JobBaseForm,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  Vcl.Imaging.GIFImg,
  WEBLib.Toast,
  SMX.Web.Service.Consts;

type
  TImportPage = class(TJobBase)
    FilePicker: TFilePicker;
    JobsPlacedDateAdded: TDateTimeField;
    JobsPlacedAddedBy: TIntegerField;
    JobsPlacedLastUpdatedBy: TIntegerField;
    JobsPlacedLastUpdated: TDateTimeField;
    JobsPlacedJobCategory: TStringField;
    JobsPlacedLastPolled: TDateTimeField;
    JobsPlacedPollInterval: TLargeintField;
    JobsPlacedPollCount: TIntegerField;
    NewMappingButton: TButton;
    ImportMappings: TXDataWebDataSet;
    MappingsDS: TDataSource;
    ImportMappingsId: TIntegerField;
    ImportMappingsDateAdded: TDateTimeField;
    ImportMappingsAddedBy: TIntegerField;
    ImportMappingsLastUpdatedBy: TIntegerField;
    ImportMappingsLastUpdated: TDateTimeField;
    ImportMappingsName: TStringField;
    ImportMappingsMapping: TStringField;
    ImportMappingsHasTitles: TBooleanField;
    RefreshTimer: TTimer;
    MappingsCombo: TComboBox;
    procedure CancelButtonClick(Sender: TObject);
    procedure FilePickerChange(Sender: TObject);
    procedure ImportMappingsAfterPost(DataSet: TDataSet);
    procedure MappingsComboChange(Sender: TObject);
    procedure NewMappingButtonClick(Sender: TObject);
    procedure RefreshTimerTimer(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
    FImportMapId: integer;
    FNewMappingName: string;

    procedure SetImportMapId(MappingName: string);
    [async]
    procedure MappingConfigurationParameters(AJSONFileData: string; ADelimeter: char); async;

  protected
    procedure JobParams(AJobParams: JS.TJSObject); override;
    function CanPlaceJob: boolean; override;
    function JobDescription: string; override;
    function JobDisplayName: string; override;
    function JobClass: string; override;
    [async]
    procedure PlaceJob; async; override;
    [async]
    procedure NewMapping; async;
    procedure OkToPlaceJob;
    procedure LoadMappingsCombo;

  public
    { Public declarations }

  protected procedure LoadDFMValues; override; end;

implementation

uses
  XData.Web.Client,
  MainDataModule,
  ImportMappings;

{$R *.dfm}

procedure TImportPage.CancelButtonClick(Sender: TObject);
begin
  inherited;
end;

{ TImportDonorsJob }

function TImportPage.CanPlaceJob: boolean;
begin
  result := inherited CanPlaceJob;
  if result then
  begin
    while not JobsPlaced.eof do
    begin
      if IsJobActive(JobsPlacedStatus.Value) then
      begin
        SetJobMessage('You cannot create a new import while there is still one in progress');
        Exit(False);
      end;
      JobsPlaced.Next;
    end;
  end;

  if FLoading then
    Result := False
  else if (not Assigned(FilePicker.Files)) or (FilePicker.Files.Count = 0) then
  begin
    SetJobMessage('Please select the CSV file to upload');
    Result := False;
  end
  else if FilePicker.Files.Count > 1 then
  begin
    SetJobMessage('You can only select one CSV file to upload');
    Result := False;
  end;

end;

procedure TImportPage.FilePickerChange(Sender: TObject);
begin
  ValidateJob;
  OkToPlaceJob;
end;

procedure TImportPage.ImportMappingsAfterPost(DataSet: TDataSet);
begin
  //inherited;
  ImportMappings.ApplyUpdates;
end;

function TImportPage.JobClass: string;
begin
  result := 'TDonationImportJob';
end;

function TImportPage.JobDescription: string;
begin
  Result := 'Import Donations';
end;

function TImportPage.JobDisplayName: string;
begin
  Result := 'Donation Import';
end;

procedure TImportPage.PlaceJob;
var
  lParams: string;
  lFileName, lFile: string;
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  sData: string;
  FieldCount: integer;
  DelimeterCharacter: char;
  JSONFileData: string;

begin
  WaitMessage.Show;

  lParams := GetParams;

  lFileName := FilePicker.Files[0].Name;

  lFile := await(string, FilePicker.Files[0].FileAsBase64);

 { TODO : To sort out - diff for sphinx? }
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_SCHEDULE_FILE_JOB, [JobClass,
    JobVisibility.Value, lParams, OutputOption.Value, lFileName, lFile]));

  FJobId := JS.toInteger(lRetval.ResultAsObject['value']);
  AfterPlaceJob;

  FilePicker.Files.Clear;

  ShowAToast(JobDisplayName, 'Your job ' + JobDescription + ' has been placed. The Job Number is ' + FJobId.ToString,
    'clock.png');

  CloseTimer.Enabled := True;
  WaitMessage.Hide;
  ModalResult := mrOK;
end;

procedure TImportPage.MappingConfigurationParameters(AJSONFileData: string; ADelimeter: char);
var
  AForm: TMappingsForm;
  lNewMappingName: string;
begin
  AForm := TMappingsForm.Create(Self);
  try
    AForm.Popup := True;
    AForm.PopupOpacity := 1;
    AForm.ElementClassName := 'PopUpForm';
    await(TForm, AForm.Load());

    AForm.Delimeter := ADelimeter;
    AForm.JSONFileData := AJSONFileData;
    //AForm.AfterCreated;
    await(TModalResult, AForm.Execute);
  finally
    lNewMappingName := AForm.MappingName;
    ImportMappings.Close;
    ImportMappings.Load;

    AForm.Free;
    AForm := nil;
    RefreshTimer.Enabled := true;
    FNewMappingName := lNewMappingName;
  end;
end;

procedure TImportPage.NewMapping;
var
  lFileName, lFile: string;
  lRetval: TXDataClientResponse;
  lResult: JS.TJSObject;
  JSONFileData: string;

begin
  lFileName := FilePicker.Files[0].Name;
  lFile := await(string, FilePicker.Files[0].FileAsBase64);

  lRetval := await(TXDataClientResponse,
    MainData.WebClient.RawInvokeAsync('IGiftAiderService.ImportMappingUpload', [lFileName, lFile]));
  lResult := lRetval.ResultAsObject;
  JSONFileData := JS.toString(lResult['value']);

  MappingConfigurationParameters(JSONFileData, ',');
end;

procedure TImportPage.NewMappingButtonClick(Sender: TObject);
begin
  NewMapping;
end;

procedure TImportPage.RefreshTimerTimer(Sender: TObject);
begin
  inherited;
  RefreshTimer.Enabled := false;

  LoadMappingsCombo;

  if FNewMappingName <> '' then
    SetImportMapId(FNewMappingName)
  else
    MappingsCombo.Text := '-- Choose Mapping --';

  OkToPlaceJob;
end;


procedure TImportPage.WebFormShow(Sender: TObject);
begin
  inherited;
  ImportMappings.Load;
  RefreshTimer.Enabled := true;
end;

procedure TImportPage.JobParams(AJobParams: JS.TJSObject);
begin
  inherited;
  AJobParams.Properties['ImportMap'] := FImportMapId;
end;

procedure TImportPage.LoadMappingsCombo;
begin
  MappingsCombo.Clear;
  ImportMappings.First;

  while not ImportMappings.eof do
  begin
    MappingsCombo.Items.Add(ImportMappings.FieldByName('Name').AsString);
    ImportMappings.Next;
  end;
end;

procedure TImportPage.MappingsComboChange(Sender: TObject);
var
  lMappingName: string;
begin
  lMappingName := MappingsCombo.items[MappingsCombo.ItemIndex];
  SetImportMapId(lMappingName);
  OkToPlaceJob;
end;

procedure TImportPage.SetImportMapId(MappingName: string);
var
  MappingItemIndex: integer;
begin
  if ImportMappings.Locate('Name', MappingName, []) then
  begin
    FImportMapId := ImportMappings.FieldByName('Id').AsInteger;
    MappingItemIndex := MappingsCombo.Items.IndexOf(MappingName);
    MappingsCombo.ItemIndex := MappingItemIndex;
  end;
end;

procedure TImportPage.OkToPlaceJob;
var
  lFileCount: integer;
begin
  lFileCount := FilePicker.Files.Count;

  if (MappingsCombo.ItemIndex <> -1) and (lFileCount > 0) then
  begin
    PlaceJobButton.Enabled := true;
  end
  else
  begin
    PlaceJobButton.Enabled := false;
  end;
end;

procedure TImportPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FilePicker := TFilePicker.Create('FilePicker');
  NewMappingButton := TButton.Create('NewMappingButton');
  MappingsCombo := TComboBox.Create('MappingsCombo');
  JobsPlacedDateAdded := TDateTimeField.Create(Self);
  JobsPlacedAddedBy := TIntegerField.Create(Self);
  JobsPlacedLastUpdatedBy := TIntegerField.Create(Self);
  JobsPlacedLastUpdated := TDateTimeField.Create(Self);
  JobsPlacedJobCategory := TStringField.Create(Self);
  JobsPlacedLastPolled := TDateTimeField.Create(Self);
  JobsPlacedPollInterval := TLargeintField.Create(Self);
  JobsPlacedPollCount := TIntegerField.Create(Self);
  ImportMappings := TXDataWebDataSet.Create(Self);
  ImportMappingsId := TIntegerField.Create(Self);
  ImportMappingsDateAdded := TDateTimeField.Create(Self);
  ImportMappingsAddedBy := TIntegerField.Create(Self);
  ImportMappingsLastUpdatedBy := TIntegerField.Create(Self);
  ImportMappingsLastUpdated := TDateTimeField.Create(Self);
  ImportMappingsName := TStringField.Create(Self);
  ImportMappingsMapping := TStringField.Create(Self);
  ImportMappingsHasTitles := TBooleanField.Create(Self);
  MappingsDS := TDataSource.Create(Self);
  RefreshTimer := TTimer.Create(Self);

  PlaceJobButton.BeforeLoadDFMValues;
  OutputOption.BeforeLoadDFMValues;
  JobMessage.BeforeLoadDFMValues;
  FilePicker.BeforeLoadDFMValues;
  NewMappingButton.BeforeLoadDFMValues;
  MappingsCombo.BeforeLoadDFMValues;
  JobsPlaced.BeforeLoadDFMValues;
  JobsPlacedDateAdded.BeforeLoadDFMValues;
  JobsPlacedAddedBy.BeforeLoadDFMValues;
  JobsPlacedLastUpdatedBy.BeforeLoadDFMValues;
  JobsPlacedLastUpdated.BeforeLoadDFMValues;
  JobsPlacedJobCategory.BeforeLoadDFMValues;
  JobsPlacedLastPolled.BeforeLoadDFMValues;
  JobsPlacedPollInterval.BeforeLoadDFMValues;
  JobsPlacedPollCount.BeforeLoadDFMValues;
  ImportMappings.BeforeLoadDFMValues;
  ImportMappingsId.BeforeLoadDFMValues;
  ImportMappingsDateAdded.BeforeLoadDFMValues;
  ImportMappingsAddedBy.BeforeLoadDFMValues;
  ImportMappingsLastUpdatedBy.BeforeLoadDFMValues;
  ImportMappingsLastUpdated.BeforeLoadDFMValues;
  ImportMappingsName.BeforeLoadDFMValues;
  ImportMappingsMapping.BeforeLoadDFMValues;
  ImportMappingsHasTitles.BeforeLoadDFMValues;
  MappingsDS.BeforeLoadDFMValues;
  RefreshTimer.BeforeLoadDFMValues;
  try
    Caption := 'MappingsDS';
    SetEvent(Self, 'OnShow', 'WebFormShow');
    PlaceJobButton.Top := 285;
    PlaceJobButton.Enabled := False;
    OutputOption.Visible := False;
    JobMessage.Left := 43;
    JobMessage.Top := 255;
    FilePicker.SetParentComponent(Self);
    FilePicker.Name := 'FilePicker';
    FilePicker.Left := 48;
    FilePicker.Top := 208;
    FilePicker.Width := 214;
    FilePicker.Height := 13;
    FilePicker.ElementClassName := 'form-control';
    FilePicker.HeightStyle := ssAuto;
    FilePicker.WidthStyle := ssAuto;
    FilePicker.Accept := '.csv';
    FilePicker.ChildOrder := 7;
    FilePicker.ElementFont := efCSS;
    FilePicker.ElementPosition := epIgnore;
    SetEvent(FilePicker, Self, 'OnChange', 'FilePickerChange');
    NewMappingButton.SetParentComponent(Self);
    NewMappingButton.Name := 'NewMappingButton';
    NewMappingButton.Left := 277;
    NewMappingButton.Top := 224;
    NewMappingButton.Width := 64;
    NewMappingButton.Height := 17;
    NewMappingButton.Caption := 'New Mapping';
    NewMappingButton.ChildOrder := 11;
    NewMappingButton.ElementClassName := 'btn btn-secondary';
    NewMappingButton.HeightPercent := 100.000000000000000000;
    NewMappingButton.WidthPercent := 100.000000000000000000;
    SetEvent(NewMappingButton, Self, 'OnClick', 'NewMappingButtonClick');
    MappingsCombo.SetParentComponent(Self);
    MappingsCombo.Name := 'MappingsCombo';
    MappingsCombo.Left := 48;
    MappingsCombo.Top := 225;
    MappingsCombo.Width := 214;
    MappingsCombo.Height := 15;
    MappingsCombo.ElementClassName := 'form-select';
    MappingsCombo.ElementPosition := epIgnore;
    MappingsCombo.HeightStyle := ssAuto;
    MappingsCombo.HeightPercent := 100.000000000000000000;
    MappingsCombo.Text := '-- Choose Mapping --';
    MappingsCombo.WidthStyle := ssAuto;
    MappingsCombo.WidthPercent := 100.000000000000000000;
    SetEvent(MappingsCombo, Self, 'OnChange', 'MappingsComboChange');
    MappingsCombo.ItemIndex := -1;
    JobsPlacedDateAdded.SetParentComponent(JobsPlaced);
    JobsPlacedDateAdded.Name := 'JobsPlacedDateAdded';
    JobsPlacedDateAdded.FieldName := 'DateAdded';
    JobsPlacedDateAdded.Required := True;
    JobsPlacedAddedBy.SetParentComponent(JobsPlaced);
    JobsPlacedAddedBy.Name := 'JobsPlacedAddedBy';
    JobsPlacedAddedBy.FieldName := 'AddedBy';
    JobsPlacedAddedBy.Required := True;
    JobsPlacedLastUpdatedBy.SetParentComponent(JobsPlaced);
    JobsPlacedLastUpdatedBy.Name := 'JobsPlacedLastUpdatedBy';
    JobsPlacedLastUpdatedBy.FieldName := 'LastUpdatedBy';
    JobsPlacedLastUpdated.SetParentComponent(JobsPlaced);
    JobsPlacedLastUpdated.Name := 'JobsPlacedLastUpdated';
    JobsPlacedLastUpdated.FieldName := 'LastUpdated';
    JobsPlacedJobCategory.SetParentComponent(JobsPlaced);
    JobsPlacedJobCategory.Name := 'JobsPlacedJobCategory';
    JobsPlacedJobCategory.FieldName := 'JobCategory';
    JobsPlacedJobCategory.Size := 8;
    JobsPlacedLastPolled.SetParentComponent(JobsPlaced);
    JobsPlacedLastPolled.Name := 'JobsPlacedLastPolled';
    JobsPlacedLastPolled.FieldName := 'LastPolled';
    JobsPlacedPollInterval.SetParentComponent(JobsPlaced);
    JobsPlacedPollInterval.Name := 'JobsPlacedPollInterval';
    JobsPlacedPollInterval.FieldName := 'PollInterval';
    JobsPlacedPollCount.SetParentComponent(JobsPlaced);
    JobsPlacedPollCount.Name := 'JobsPlacedPollCount';
    JobsPlacedPollCount.FieldName := 'PollCount';
    ImportMappings.SetParentComponent(Self);
    ImportMappings.Name := 'ImportMappings';
    ImportMappings.AfterPost := ImportMappingsAfterPost;
    ImportMappings.EntitySetName := 'ImportMappings';
    ImportMappings.Connection := MainData.DataConnection;
    ImportMappings.Left := 384;
    ImportMappings.Top := 128;
    ImportMappingsId.SetParentComponent(ImportMappings);
    ImportMappingsId.Name := 'ImportMappingsId';
    ImportMappingsId.FieldName := 'Id';
    ImportMappingsId.Required := True;
    ImportMappingsDateAdded.SetParentComponent(ImportMappings);
    ImportMappingsDateAdded.Name := 'ImportMappingsDateAdded';
    ImportMappingsDateAdded.FieldName := 'DateAdded';
    ImportMappingsDateAdded.Required := True;
    ImportMappingsAddedBy.SetParentComponent(ImportMappings);
    ImportMappingsAddedBy.Name := 'ImportMappingsAddedBy';
    ImportMappingsAddedBy.FieldName := 'AddedBy';
    ImportMappingsAddedBy.Required := True;
    ImportMappingsLastUpdatedBy.SetParentComponent(ImportMappings);
    ImportMappingsLastUpdatedBy.Name := 'ImportMappingsLastUpdatedBy';
    ImportMappingsLastUpdatedBy.FieldName := 'LastUpdatedBy';
    ImportMappingsLastUpdated.SetParentComponent(ImportMappings);
    ImportMappingsLastUpdated.Name := 'ImportMappingsLastUpdated';
    ImportMappingsLastUpdated.FieldName := 'LastUpdated';
    ImportMappingsName.SetParentComponent(ImportMappings);
    ImportMappingsName.Name := 'ImportMappingsName';
    ImportMappingsName.FieldName := 'Name';
    ImportMappingsName.Size := 255;
    ImportMappingsMapping.SetParentComponent(ImportMappings);
    ImportMappingsMapping.Name := 'ImportMappingsMapping';
    ImportMappingsMapping.FieldName := 'Mapping';
    ImportMappingsMapping.Size := 4096;
    ImportMappingsHasTitles.SetParentComponent(ImportMappings);
    ImportMappingsHasTitles.Name := 'ImportMappingsHasTitles';
    ImportMappingsHasTitles.FieldName := 'HasTitles';
    ImportMappingsHasTitles.Required := True;
    MappingsDS.SetParentComponent(Self);
    MappingsDS.Name := 'MappingsDS';
    MappingsDS.DataSet := ImportMappings;
    MappingsDS.Left := 384;
    MappingsDS.Top := 171;
    RefreshTimer.SetParentComponent(Self);
    RefreshTimer.Name := 'RefreshTimer';
    RefreshTimer.Enabled := False;
    SetEvent(RefreshTimer, Self, 'OnTimer', 'RefreshTimerTimer');
    RefreshTimer.Left := 309;
    RefreshTimer.Top := 48;
  finally
    PlaceJobButton.AfterLoadDFMValues;
    OutputOption.AfterLoadDFMValues;
    JobMessage.AfterLoadDFMValues;
    FilePicker.AfterLoadDFMValues;
    NewMappingButton.AfterLoadDFMValues;
    MappingsCombo.AfterLoadDFMValues;
    JobsPlaced.AfterLoadDFMValues;
    JobsPlacedDateAdded.AfterLoadDFMValues;
    JobsPlacedAddedBy.AfterLoadDFMValues;
    JobsPlacedLastUpdatedBy.AfterLoadDFMValues;
    JobsPlacedLastUpdated.AfterLoadDFMValues;
    JobsPlacedJobCategory.AfterLoadDFMValues;
    JobsPlacedLastPolled.AfterLoadDFMValues;
    JobsPlacedPollInterval.AfterLoadDFMValues;
    JobsPlacedPollCount.AfterLoadDFMValues;
    ImportMappings.AfterLoadDFMValues;
    ImportMappingsId.AfterLoadDFMValues;
    ImportMappingsDateAdded.AfterLoadDFMValues;
    ImportMappingsAddedBy.AfterLoadDFMValues;
    ImportMappingsLastUpdatedBy.AfterLoadDFMValues;
    ImportMappingsLastUpdated.AfterLoadDFMValues;
    ImportMappingsName.AfterLoadDFMValues;
    ImportMappingsMapping.AfterLoadDFMValues;
    ImportMappingsHasTitles.AfterLoadDFMValues;
    MappingsDS.AfterLoadDFMValues;
    RefreshTimer.AfterLoadDFMValues;
  end;
end;

end.

