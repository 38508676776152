unit OtherClaimsCommunityBuildingsEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, OtherClaimsEdit, Data.DB, WEBLib.DB,
  WEBLib.WebCtrls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.DBCtrls, Vcl.Controls,
  WEBLib.ExtCtrls;

type
  TOtherClaimsCommunityBuildingsEditForm = class(TOtherClaimsEditForm)
    BuildingsDataSource: TDataSource;
    BuildingComboBox: TComboBox;
    procedure BuildingComboBoxChange(Sender: TObject);
  private
    { Private declarations }
    procedure SetCommunityBuildingsDataSet(const Value: TDataSet);
  protected
    [async]
    procedure GetRules; async; override;

    //procedure GetCommunityBuildings;

    function GetBuildingId: integer;
    procedure SetBuildingNameComboBox(BuildingId: integer);

  public
    { Public declarations }
    property CommunityBuildingsDataSet: TDataSet write SetCommunityBuildingsDataSet;
  protected procedure LoadDFMValues; override; end;

var
  OtherClaimsCommunityBuildingsEditForm: TOtherClaimsCommunityBuildingsEditForm;

implementation

{$R *.dfm}

uses
  OtherClaimsCommunityBuildingsList;

procedure TOtherClaimsCommunityBuildingsEditForm.BuildingComboBoxChange(Sender:
    TObject);
begin
  GetBuildingId;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.GetRules;
begin
  OtherClaimsType := 'CommunityBuildings';
  inherited;
end;

procedure TOtherClaimsCommunityBuildingsEditForm.SetCommunityBuildingsDataSet(
  const Value: TDataSet);
var
  BuildingName: string;
  BuildingId: integer;
  CurrentBuildingId: integer;
begin
  BuildingsDataSource.DataSet := Value;
  BuildingsDataSource.DataSet.First;

  while not BuildingsDataSource.DataSet.Eof do
  begin
    BuildingName := BuildingsDataSource.DataSet.FieldByName('BuildingName').AsString;
    BuildingId := BuildingsDataSource.DataSet.FieldByName('Id').AsInteger;
    BuildingComboBox.Items.AddPair(BuildingName, BuildingId.ToString);
    BuildingsDataSource.DataSet.Next;
  end;

  CurrentBuildingId := EditDataSource.DataSet.FieldByName('BuildingId').AsInteger;

  if CurrentBuildingId > 0 then
    SetBuildingNameComboBox(CurrentBuildingId);
end;

procedure TOtherClaimsCommunityBuildingsEditForm.SetBuildingNameComboBox(
  BuildingId: integer);
var
  Idx: integer;
  ValueStr: string;
begin
  for Idx := 0 to BuildingComboBox.Items.Count-1 do
  begin
    ValueStr := BuildingComboBox.Values[Idx];

    try
      if ValueStr.ToInteger() = BuildingId then
      begin
        BuildingComboBox.ItemIndex := Idx;
        break;
      end;
    except

    end;
  end;
end;

function TOtherClaimsCommunityBuildingsEditForm.GetBuildingId: integer;
var
  Idx: integer;
  ValueStr: string;
begin
  ValueStr := BuildingComboBox.Values[BuildingComboBox.ItemIndex];
  EditDataSource.DataSet.FieldByName('BuildingId').AsInteger := ValueStr.ToInteger;
end;


procedure TOtherClaimsCommunityBuildingsEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BuildingComboBox := TComboBox.Create('BuildingComboBox');
  BuildingsDataSource := TDataSource.Create(Self);

  CloseButton.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CancelButton.BeforeLoadDFMValues;
  AmountEdit.BeforeLoadDFMValues;
  TransactionDateEdit.BeforeLoadDFMValues;
  NoteMemo.BeforeLoadDFMValues;
  BuildingComboBox.BeforeLoadDFMValues;
  BuildingsDataSource.BeforeLoadDFMValues;
  try
    CloseButton.TabOrder := 7;
    SaveButton.TabOrder := 5;
    CancelButton.TabOrder := 6;
    AmountEdit.Left := 5;
    AmountEdit.Top := 99;
    AmountEdit.TabOrder := 3;
    TransactionDateEdit.Left := 5;
    TransactionDateEdit.Top := 75;
    TransactionDateEdit.TabOrder := 2;
    NoteMemo.Left := 5;
    NoteMemo.Top := 128;
    NoteMemo.TabOrder := 4;
    BuildingComboBox.SetParentComponent(Self);
    BuildingComboBox.Name := 'BuildingComboBox';
    BuildingComboBox.Left := 5;
    BuildingComboBox.Top := 50;
    BuildingComboBox.Width := 123;
    BuildingComboBox.Height := 15;
    BuildingComboBox.ElementPosition := epIgnore;
    BuildingComboBox.HeightStyle := ssAuto;
    BuildingComboBox.HeightPercent := 100.000000000000000000;
    BuildingComboBox.TabOrder := 1;
    BuildingComboBox.Text := 'BuildingComboBox';
    BuildingComboBox.WidthStyle := ssAuto;
    BuildingComboBox.WidthPercent := 100.000000000000000000;
    SetEvent(BuildingComboBox, Self, 'OnChange', 'BuildingComboBoxChange');
    BuildingComboBox.ItemIndex := -1;
    BuildingsDataSource.SetParentComponent(Self);
    BuildingsDataSource.Name := 'BuildingsDataSource';
    BuildingsDataSource.Left := 319;
    BuildingsDataSource.Top := 101;
  finally
    CloseButton.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CancelButton.AfterLoadDFMValues;
    AmountEdit.AfterLoadDFMValues;
    TransactionDateEdit.AfterLoadDFMValues;
    NoteMemo.AfterLoadDFMValues;
    BuildingComboBox.AfterLoadDFMValues;
    BuildingsDataSource.AfterLoadDFMValues;
  end;
end;

end.
